<form #loginForm="ngForm">
    <div class="main-wrapper" (keydown.enter)="login(loginForm)">
        <div class="account-content">
            <div class="container">
                <div class="account-logo">
                    <img class="login-logo" src="{{global.companyLogo}}">
                </div>
                <div class="account-box">
                    <div class="account-wrapper">
                        <h3 class="account-title">Login</h3>
                        <div class="form-group">
                            <label>User Name</label>
                            <input class="form-control" type="text" [(ngModel)]="user.user_name" id="uname" name="uname"
                                #uname="ngModel" required tabindex="1">
                            <div *ngIf="uname.invalid && (uname.dirty || uname.touched)"
                                class="badge badge-danger position-absolute">
                                <div *ngIf="uname.errors.required">
                                    User Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label>Password</label>
                                </div>
                                <div class="col-auto">
                                    <a class="text-muted" href="javascript:void(0)" (click)="openForgotPassword()">
                                        <span>Forgot password?</span>
                                    </a>
                                </div>
                            </div>
                            <input class="form-control" type="password" [(ngModel)]="user.password" id="password"
                                name="password" #password="ngModel" required tabindex="2">
                            <div *ngIf="password.invalid && (password.dirty || password.touched)"
                                class="badge badge-danger position-absolute">
                                <div *ngIf="password.errors.required">
                                    Password is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-center">
                            <button class="btn btn-primary account-btn" type="button"
                                [disabled]="!loginForm.valid || (loading$ | async)"
                                (click)="login(loginForm)">Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<app-change-password #changePassword *ngIf="global.loggedUser!=null && global.loggedUser.is_password_reset"
    [user]="user" name="changepass"></app-change-password>
<ng-template #forgotpasscontent let-c="close" let-d="dismiss">
    <form #forgotpassForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Forgot Password
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <div class="row">
                <div class="col-sm-12">
                    <label for="username">User Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="username" name="username"
                        [(ngModel)]="userNameForgotPassword" #username required />
                    <div *ngIf="username.invalid && (username.dirty || username.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="username.errors.required">
                            User Name is required.
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-sm-3 offset-sm-9">
                    <label></label>&nbsp;
                    <button type="button" class="btn btn-block btn-secondary" (click)="forgotPassword(forgotpassForm)"
                        [disabled]="!forgotpassForm.valid">Send Email</button>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
        </div>
    </form>
</ng-template>