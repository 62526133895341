import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, retry } from 'rxjs/operators';
import { Global } from '../shared/global';
import { ApiResponse } from '../model/common';

@Injectable({
  providedIn: 'root'
})
export class PayrollApiService {
  base_proxy_path = 'http://hrm.krishivinfotech.com:1004';
  base_path: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'x-access-token': ''
    })
  };
  constructor(private http: HttpClient, private datePipe: DatePipe, public global: Global) {
    this.base_path = global.payrollApiEndPoint;
  }
  setToken(token: string) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'x-access-token': token
      })
    };
  }
  createEmployeeMonthlySalary(
    year: number,
    month: number,
    isRecalculate: boolean = false,
    employeeId: number = null,
    locationId: number = null,
    statusId: number = null
  ) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .post<ApiResponse>(`${this.base_path}/create-employee-monthly-salary`, { pay_year: year, pay_month: month, is_recalculate: isRecalculate, employee_id: employeeId, location_id: locationId, status_id: statusId }, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postSalaryIncrement(s: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-salary-increment', s, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postEmployeeSalaryMaster(employee_id: number, comp: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-salary-master?employeeid=' + employee_id, comp, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  updateEmployeeSalary(year: number, month: number, statid: number, employee_id: number = null) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .post<ApiResponse>(this.base_path + '/salary-status', { salarystatus: statid, employeeid: employee_id, salarymonth: month, salaryyear: year }, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postEmployeeSalary(employeeSalary: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-salary', employeeSalary, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  deleteSalaryIncrement(increment_id: number) {
    return this.http
      .delete<ApiResponse>(this.base_path + '/employee-salary-increment?incrementid=' + increment_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeTotalCompensation(employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-total-compensation?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeBonusHistory(employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-bonus-report?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeHikeHistory(employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-hike-report?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeSalaryIncrement(employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-salary-increment?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getCashSalary(month: number, year: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-cash-salary?salarymonth=' + month + '&salaryyear=' + year, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getSalaryComparisonData(employee_id: number, fromMonth: number, fromYear: number) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(this.base_path + '/employee-salary-comparision?employeeid=' + employee_id + '&frommonth=' + fromMonth + '&fromyear=' + fromYear, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getSalaryForBank(month: number, year: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-bank-details?salarymonth=' + month + '&salaryyear=' + year, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getSalaryForPF(month: number, year: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-pf-details?salarymonth=' + month + '&salaryyear=' + year, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeCTC(employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-ctc?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeSalaryMaster(employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-salary-master?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  // tslint:disable-next-line:max-line-length
  getEmployeeSalary(year: number, month: number, employeeid: number = null, ispayslip: boolean = false, isAltLogin: boolean = false, allEmployee: boolean = false, locationid: number = null, statusid: number = null) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(`${this.base_path}/employee-salary?salarymonth=${month}&salaryyear=${year}&employeeid=${employeeid}&ispayslip=${ispayslip}&altlogin=${isAltLogin}&allemployee=${allEmployee}&locationid=${locationid}&statusid=${statusid}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
}
