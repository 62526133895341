<ng-template #mobile>
    <div class="row stats-info bg-card bg-mobile-holder align-left min-height-100" id="leave-stat-div">
        <div *ngFor="let l of leaveStat; let i = index" class="col-md-12 mt-3">
            <i class="fa fa-dot-circle-o text-warning mr-2"></i> {{l.leave_type}} <div class="pull-right">Total :
                {{l.no_of_days_total}}&nbsp;&nbsp;&nbsp;Balance: {{l.no_of_days_total - l.no_of_taken}}</div>
        </div>
    </div>
</ng-template>
<ng-template #desktop>
    <div class="row min-height-100" id="leave-stat-div">
        <div *ngFor="let l of leaveStat; let i = index"
            class="{{(i === 4 && leaveStat.length===5) ? statClass + ' offset-md-4 mt-3 mb-3' : statClass + ' mt-3 mb-3' }}">
            <div class="stats-info bg-card bg-holder" *ngIf='l.leave_type_id!=0 && l.leave_type_id!=171'>
                <h6>{{l.leave_type}}</h6>
                <h4><span>Total </span> {{l.no_of_days_total}}<span> Balance </span> {{l.no_of_days_total - l.no_of_taken}}</h4>
            </div>
            <div class="stats-info bg-card bg-holder" *ngIf='l.leave_type_id==0 || l.leave_type_id==171'>
                <h6>{{l.leave_type}}</h6>
                <h4>{{l.no_of_taken?l.no_of_taken:0}} <span>Days Taken</span></h4>
            </div>
        </div>
    </div>
</ng-template>
<ng-container *ngTemplateOutlet="global.isMobile===true?mobile:desktop">
</ng-container>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#leave-stat-div' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#leave-stat-div">
    </dx-load-panel>
</div>