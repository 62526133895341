<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title" *ngIf="hrRole==true">Manage Holidays</h3>
                <h3 class="page-title" *ngIf="hrRole==false">Holidays</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
                <button *ngIf="hrRole==true" class="btn add-btn" (click)="openModel(holidaymodalcontent)"><i
                        class="fa fa-plus"></i> Add
                    Holiday</button>
            </div>
        </div>
    </div>
    <div class="row filter-row" *ngIf="hrRole==true">
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
            <select class="form-control filter-control" id="searchYear" name="searchYear" [(ngModel)]="holidayYear"
                [ngClass]="{'loading-control': (loading$ | async) === true }" (change)="onYearChange($event)">
                <option *ngFor="let item of holidayYearList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
            <select class="form-control filter-control" id="holidayCalendar" name="holidayCalendar"
                [ngClass]="{'loading-control': (loading$ | async) === true }" [(ngModel)]="holidayCalendarId">
                <option [ngValue]="undefined" selected>Select Holiday Calendar</option>
                <option *ngFor="let item of holidayCalendarList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <button class="btn btn-secondary btn-block" (click)="searchHoliday()"> Search </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-3 table-responsive" id="{{hrRole===true ? 'holiday-grid': 'holiday-grid-hr'}}">
            <dx-data-grid #gridholiday id="gridContainer" [dataSource]="holidayList" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false">
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxi-column dataField="holiday_name" caption="Name" dataType="string"></dxi-column>
                <dxi-column dataField="holiday_date" caption="Date"  cellTemplate="dateTemplate" [allowFiltering]="false"></dxi-column>
                <dxi-column dataField="holiday_date" caption="Day"  cellTemplate="dayTemplate" [allowFiltering]="false"></dxi-column>
                <dxi-column dataField="holiday_type" caption="Type" dataType="string"></dxi-column>
                <dxi-column dataField="holiday_calendar" caption="Calendar" dataType="string"></dxi-column>
                <dxi-column dataField="holiday_id" [allowFiltering]="false" width=50 caption="" [allowSorting]="false"
                    cellTemplate="actionTemplate" alignment="center" *ngIf="hrRole==true">
                    <div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
                        <div ngbDropdown container="body">
                            <a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle><i
                                    class="material-icons">more_vert</i></a>
                            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript:void(0);" (click)="editHoliday(cellInfo)"><i
                                        class="fa fa-pencil m-r-5"></i> Edit</a>
                                <a class="dropdown-item" href="javascript:void(0);" (click)="deleteHoliday(cellInfo)"><i
                                        class="fa fa-trash m-r-5"></i> Delete</a>
                            </div>
                        </div>
                    </div>
                </dxi-column>
                <div *dxTemplate="let data of 'dateTemplate'">
                    {{data.value | date }}
                </div>
                <div *dxTemplate="let data of 'dayTemplate'">
                    {{data.value | date : "EEEE" }}
                </div>
            </dx-data-grid>
        </div>
    </div>
    <ng-template #holidaymodalcontent let-c="close" let-d="dismiss">
        <form #holidayForm="ngForm">
            <div class="modal-header">
                <div *ngIf="isNew;else edit_content">
                    <h4 class="modal-title" id="modal-basic-title">Add Holiday</h4>
                </div>
                <ng-template #edit_content>
                    <h4 class="modal-title" id="modal-basic-title">Edit Holiday</h4>
                </ng-template>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="holidayCalendar">Holiday Calendar <span class="text-danger">*</span></label>
                        <select class="form-control" id="holidayCalendar" name="holidayCalendar"
                            [(ngModel)]="holiday.holiday_calendar_id" #holidayCalendar="ngModel"
                            [ngClass]="{'is-invalid': holidayCalendar.invalid && holidayCalendar.touched}" required>
                            <option [ngValue]="null" selected>Select Holiday Calendar</option>
                            <option *ngFor="let item of holidayCalendarList; let i = index" [ngValue]="item.key">
                                {{item.value}}</option>
                        </select>
                        <div *ngIf="holidayCalendar.invalid && (holidayCalendar.dirty || holidayCalendar.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="holidayCalendar.errors.required">
                                Holiday Calendar is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="holidayName">Holiday Name <span class="text-danger">*</span></label>
                        <input class="form-control" [(ngModel)]="holiday.holiday_name" type="text" id="holidayName"
                            name="holidayName" #holidayName="ngModel" [pattern]="global.alphaNumericValidator">
                        <div *ngIf="holidayName.invalid && (holidayName.dirty || holidayName.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="holidayName.errors.pattern">
                                Holiday Name is invalid.
                            </div>
                            <div *ngIf="holidayName.errors.required">
                                Holiday Name is Required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="holidayType">Holiday Type <span class="text-danger">*</span></label>
                        <select class="form-control" id="holidayType" name="holidayType"
                            [(ngModel)]="holiday.holiday_type_id" #holidayType="ngModel"
                            [ngClass]="{'is-invalid': holidayType.invalid && holidayType.touched}" required>
                            <option [ngValue]="null" selected>Select Holiday Type</option>
                            <option *ngFor="let item of holidayTypeList; let i = index" [ngValue]="item.key">
                                {{item.value}}</option>
                        </select>
                        <div *ngIf="holidayType.invalid && (holidayType.dirty || holidayType.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="holidayType.errors.required">
                                Holiday Type is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="hDate">Holiday Date <span class="text-danger">*</span></label>
                        <dx-date-box [(ngModel)]="holiday.holiday_date" name="hDate" id="hDate" #hDate="ngModel"
                            type="date" class="form-control" dateSerializationFormat="yyyy-MM-dd" required
                            [max]="maxDate" [min]="minDate">
                        </dx-date-box>
                        <div *ngIf="hDate.invalid && (hDate.dirty || hDate.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="hDate.errors.required">
                                Holiday date is required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="saveHoliday(holidayForm)"
                    [disabled]="!holidayForm.valid">Save</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
</div>
<div *ngIf="(gridloading$ | async) && hrRole===true">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#holiday-grid' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#holiday-grid">
    </dx-load-panel>
</div>
<div *ngIf="(gridloading$ | async) && hrRole!==true">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#holiday-grid-hr' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#holiday-grid-hr">
    </dx-load-panel>
</div>