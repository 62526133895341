import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationMessage } from 'src/app/model/common';
import { CommonApiService } from 'src/app/services/common-api.service';
import { indicate } from 'src/app/services/loading.service';
import { ToastService } from 'src/app/services/toast.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit {
  designationList: KeyValue<number, string>[];
  departmentList: KeyValue<number, string>[];
  locationList: KeyValue<number, string>[];
  notificationTypeList: KeyValue<number, string>[];
  notification: NotificationMessage = new NotificationMessage();
  file: File;
  loading$ = new Subject<boolean>();
  controlloading$ = new Subject<boolean>();
  locationloading$ = new Subject<boolean>();
  @ViewChild('fileupload') fileupload: ElementRef;
  constructor(public global: Global, public commonApiService: CommonApiService, private toastService: ToastService) { }

  ngOnInit() {
    this.commonApiService.getMasterData('designation,department,notification_type')
      .pipe(indicate(this.controlloading$))
      .subscribe(apiresponse => {
        this.designationList = apiresponse.data.filter((d: { type: string; }) => d.type === 'designation');
        this.departmentList = apiresponse.data.filter((d: { type: string; }) => d.type === 'department');
        this.notificationTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'notification_type');
        this.notification.notification_type_id = 42;
      });
    this.commonApiService.getUtilityData('location')
      .pipe(indicate(this.locationloading$))
      .subscribe(apiresponse => {
        this.locationList = apiresponse.data;
      });
  }
  refresh() {
    this.ngOnInit();
  }
  onFileChange(e) {
    this.file = e.target.files[0];
  }
  sendNotification(frm: { valid: any; reset: () => void; }) {
    if (frm.valid) {
      this.notification.created_by = this.global.loggedUser.user_type_internal_id;
      if (this.file) {
        this.notification.file_path = this.file.name;
      }
      this.commonApiService.uploadNotification(this.file, this.notification)
        .pipe(indicate(this.loading$))
        .subscribe(apiresponse => {
          this.toastService.success('Notification sent successfully.');
          this.file = null;
          this.fileupload.nativeElement.value = '';
          this.notification = new NotificationMessage();
          this.notification.notification_type_id = 42;
        });
    }
  }
}
