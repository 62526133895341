<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 *ngIf="employee && employee.is_new===true" class="page-title">Add Employee</h3>
                <h3 *ngIf="employee && employee.is_new===false" class="page-title">Edit Employee</h3>
            </div>
        </div>
    </div>
    <form #empForm="ngForm">
        <div class="row">
            <div class="col-md-12 d-flex">
                <div class="card profile-box flex-fill">
                    <div class="card-body p-5" id="emp-personal-loading">
                        <h3 class="card-title text-muted bottom-border">Personal Information</h3>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="fname">First Name <span class="text-danger">*</span></label>
                                <input class="form-control " [(ngModel)]="employee.first_name" required type="text"
                                    id="fname" name="fname" #fname="ngModel" [pattern]="global.alphaNumericValidator">
                                <div *ngIf="fname.invalid && (fname.dirty || fname.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="fname.errors.required">
                                        First Name is required.
                                    </div>
                                    <div *ngIf="fname.errors.pattern">
                                        First Name is invalid.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="lname">Last Name</label>
                                <input class="form-control " [(ngModel)]="employee.last_name" type="text" id="lname"
                                    name="lname" #lname="ngModel" [pattern]="global.alphaNumericValidator">
                                <div *ngIf="lname.invalid && (lname.dirty || lname.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="lname.errors.pattern">
                                        Last Name is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="prefix">Prefix</label>
                                <select class="form-control " id="prefix" name="prefix"
                                    [(ngModel)]="employee.personal_info.prefix_id" #prefix="ngModel"
                                    [ngClass]="{'is-invalid': prefix.invalid && prefix.touched, 'loading-control': (ddloading$ | async) === true}">
                                    <option [ngValue]="null" selected>Select Prefix</option>
                                    <option *ngFor="let item of prefixList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <label for="maritalstatus">Marital Status </label>
                                <select class="form-control " id="maritalstatus" name="maritalstatus"
                                    [(ngModel)]="employee.personal_info.marital_status_id" #maritalstatus="ngModel"
                                    [ngClass]="{'is-invalid': maritalstatus.invalid && maritalstatus.touched, 'loading-control': (ddloading$ | async) === true}">
                                    <option [ngValue]="null" selected>Select Marital Status</option>
                                    <option *ngFor="let item of maritalstatusList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="fathername">Father or Husband Name </label>
                                <input class="form-control " [(ngModel)]="employee.personal_info.father_or_husband_name"
                                    type="text" id="fathername" name="fathername" #fathername="ngModel"
                                    [pattern]="global.alphaNumericPeriodValidator">
                                <div *ngIf="fathername.invalid && (fathername.dirty || fathername.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="fathername.errors.pattern">
                                        Father or Husband is invalid.
                                    </div>
                                    <div *ngIf="fathername.errors.required">
                                        Father or Husband is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="relation">Relation </label>
                                <select class="form-control " id="relation" name="relation"
                                    [(ngModel)]="employee.personal_info.father_or_husband_relationship_id"
                                    #relation="ngModel"
                                    [ngClass]="{'is-invalid': relation.invalid && relation.touched, 'loading-control': (ddloading$ | async) === true}">
                                    <option [ngValue]="null" selected>Select Relation</option>
                                    <option *ngFor="let item of relationshipList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                                <div *ngIf="relation.invalid && (relation.dirty || relation.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="relation.errors.required">
                                        Relation is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="qualification">Qualification</label>
                                <input class="form-control " [(ngModel)]="employee.personal_info.qualification"
                                    type="text" id="qualification" name="qualification" #qualification="ngModel">
                            </div>
                            <div class="col-sm-6">
                                <label for="bgroup">Blood Group</label>
                                <select class="form-control " id="bgroup" name="bgroup"
                                    [(ngModel)]="employee.personal_info.blood_group_id" #bgroup="ngModel"
                                    [ngClass]="{'is-invalid': bgroup.invalid && bgroup.touched}">
                                    <option [ngValue]="null" selected>Select Blood Group</option>
                                    <option *ngFor="let item of bloodGroupList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="pan">PAN</label>
                                <input class="form-control " [(ngModel)]="employee.bank_details.pan_number" type="text"
                                    id="pan" name="pan" #pan="ngModel" [pattern]="global.alphaNumericValidator">
                                <div *ngIf="pan.invalid && (pan.dirty || pan.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="pan.errors.pattern">
                                        PAN is invalid.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="aadhar">Aadhar</label>
                                <input class="form-control " [(ngModel)]="employee.personal_info.aadhar" type="text"
                                    id="aadhar" name="aadhar" #aadhar="ngModel" [pattern]="global.numericValidator">
                                <div *ngIf="aadhar.invalid && (aadhar.dirty || aadhar.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="aadhar.errors.pattern">
                                        Aadhar is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="dob">Date of Birth <span class="text-danger">*</span></label>
                                <dx-date-box [(ngModel)]="employee.personal_info.date_of_birth" name="dob" id="dob"
                                    #dob="ngModel" required type="date" displayFormat="dd/MM/yyyy"
                                    dateSerializationFormat="yyyy-MM-dd">
                                </dx-date-box>
                                <div *ngIf="dob.invalid && (dob.dirty || dob.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="dob.errors.required">
                                        Date of Birth is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6" [ngClass]="{'loading-control': (ddloading$ | async) === true}">
                                <label for="gender">Gender</label><br>
                                <div *ngFor="let item of genderList" class="d-inline">
                                    <input id="gender_{{item.key}}" [value]='item.key' type="radio" name="gender"
                                        class="radio-inline" [(ngModel)]="employee.personal_info.gender_id">
                                    {{item.value}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex">
                <div class="card profile-box flex-fill">
                    <div class="card-body p-5" id="emp-contact-loading">
                        <h3 class="card-title text-muted bottom-border">Contact Information</h3>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="phone">Personal Phone</label>
                                <input class="form-control " [(ngModel)]="employee.personal_info.phone" type="text"
                                    id="phone" name="phone" #phone="ngModel" [pattern]="global.numericValidator">
                                <div *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="phone.errors.pattern">
                                        Personal Phone Number is invalid.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="email">Personal Email</label>
                                <input class="form-control " [(ngModel)]="employee.personal_info.email" type="text"
                                    id="email" name="email" #email="ngModel" [pattern]="global.emailValidator">
                                <div *ngIf="email.invalid && (email.dirty || email.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="email.errors.pattern">
                                        Personal Email is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="officephone">Official Phone</label>
                                <input class="form-control " [(ngModel)]="employee.personal_info.official_phone"
                                    type="text" id="officephone" name="officephone" #officephone="ngModel"
                                    [pattern]="global.numericValidator">
                                <div *ngIf="officephone.invalid && (officephone.dirty || officephone.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="officephone.errors.pattern">
                                        Official Phone Number is invalid.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="officeemail">Official Email</label>
                                <input class="form-control" [(ngModel)]="employee.personal_info.official_email"
                                    type="text" id="officeemail" name="officeemail" #officeemail="ngModel"
                                    [pattern]="global.emailValidator">
                                <div *ngIf="officeemail.invalid && (officeemail.dirty || officeemail.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="officeemail.errors.pattern">
                                        Official Email is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="econtact">Emergency Contact</label>
                                <input class="form-control " [(ngModel)]="employee.personal_info.emergency_contact"
                                    type="text" id="econtact" name="econtact" #econtact="ngModel"
                                    [pattern]="global.numericValidator">
                                <div *ngIf="econtact.invalid && (econtact.dirty || econtact.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="econtact.errors.pattern">
                                        Emergency Contact is invalid.
                                    </div>
                                    <div *ngIf="econtact.errors.required">
                                        Emergency Contact is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="erelation">Emergency Contact Relation </label>
                                <select class="form-control " id="erelation" name="erelation"
                                    [(ngModel)]="employee.personal_info.emergency_contact_relation_id"
                                    #erelation="ngModel"
                                    [ngClass]="{'is-invalid': erelation.invalid && erelation.touched}">
                                    <option [ngValue]="null" selected>Select Relationship</option>
                                    <option *ngFor="let item of emergencyContactRelationList; let i = index"
                                        [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                                <div *ngIf="erelation.invalid && (erelation.dirty || erelation.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="erelation.errors.required">
                                        Emergency Contact relation is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-5 row">
                            <div class="col-sm-12">
                                <h6 class="text-muted bottom-border">Present Address</h6>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <label for="address">Address</label><br>
                                <textarea rows="4" class="form-control" id="address" name="address"
                                    [(ngModel)]="employee.personal_info.address"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="city">City</label>
                                <input class="form-control " [(ngModel)]="employee.personal_info.city" type="text"
                                    id="city" name="city" #city="ngModel" [pattern]="global.alphaNumericValidator">
                                <div *ngIf="city.invalid && (city.dirty || city.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="city.errors.pattern">
                                        City is invalid.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="state">State</label>
                                <select class="form-control " id="state" name="state"
                                    [(ngModel)]="employee.personal_info.state_id" #state="ngModel"
                                    [ngClass]="{'is-invalid': state.invalid && state.touched}">
                                    <option [ngValue]="null" selected>Select State</option>
                                    <option *ngFor="let item of stateList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="pincode">Pincode</label>
                                <input class="form-control " [(ngModel)]="employee.personal_info.pincode" type="text"
                                    id="pincode" name="pincode" #pincode="ngModel" [pattern]="global.numericValidator">
                                <div *ngIf="pincode.invalid && (pincode.dirty || pincode.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="pincode.errors.pattern">
                                        Pincode is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-5 row bottom-border">
                            <div class="col-sm-6">
                                <h6 class="text-muted">Permanent Address</h6>
                            </div>
                            <div class="col-sm-6">
                                <span class="float-right">&nbsp; Same as Present Address</span>
                                <input id="ckpermanentaddress" type="checkbox" name="ckpermanentaddress"
                                    class="radio-inline float-right" (click)="copyPresentAddress()"
                                    [(ngModel)]="togglePermanentAddress">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <label for="permanentaddress">Address</label><br>
                                <textarea rows="4" class="form-control" id="permanentaddress" name="permanentaddress"
                                    [(ngModel)]="employee.personal_info.permanent_address"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="permanentcity">City</label>
                                <input class="form-control " [(ngModel)]="employee.personal_info.permanent_city"
                                    type="text" id="permanentcity" name="permanent_permanentcity"
                                    #permanentcity="ngModel" [pattern]="global.alphaNumericValidator">
                                <div *ngIf="permanentcity.invalid && (permanentcity.dirty || permanentcity.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="permanentcity.errors.pattern">
                                        Permanent City is invalid.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="permanentstate">State</label>
                                <select class="form-control" id="permanentstate" name="permanentstate"
                                    [(ngModel)]="employee.personal_info.permanent_state_id" #permanentstate="ngModel"
                                    [ngClass]="{'is-invalid': permanentstate.invalid && permanentstate.touched}">
                                    <option [ngValue]="null" selected>Select State</option>
                                    <option *ngFor="let item of stateList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="permanentpincode">Pincode</label>
                                <input class="form-control " [(ngModel)]="employee.personal_info.permanent_pincode"
                                    type="text" id="permanentpincode" name="permanentpincode"
                                    #permanentpincode="ngModel" [pattern]="global.numericValidator">
                                <div *ngIf="permanentpincode.invalid && (permanentpincode.dirty || permanentpincode.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="permanentpincode.errors.pattern">
                                        Permanent Pincode is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex">
                <div class="card profile-box flex-fill">
                    <div class="card-body p-5" id="emp-info-loading">
                        <h3 class="card-title text-muted bottom-border">Employee Information</h3>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="ecode">Employee Code</label>
                                <input class="form-control " [(ngModel)]="employee.employee_code" required type="text"
                                    id="ecode" name="ecode" readonly
                                    [ngClass]="{'loading-control': (empcodeloading$ | async) === true}">
                            </div>
                            <div class="col-sm-6">
                                <label for="uname">User Name <span class="text-danger">*</span></label>
                                <input class="form-control " [(ngModel)]="employee.user_name" required type="text"
                                    id="uname" name="uname" #uname="ngModel" [pattern]="global.alphaNumericValidator"
                                    [app-contains]="userNameList" [exclude]="employee.user_name"
                                    [ngClass]="{'is-invalid': uname.invalid && uname.touched, 'loading-control': (usernameloading$ | async) === true}">
                                <div *ngIf="uname.invalid && (uname.dirty || uname.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="uname.errors.required">
                                        User Name is required.
                                    </div>
                                    <div *ngIf="uname.errors.pattern">
                                        User Name is invalid.
                                    </div>
                                    <div *ngIf="uname.errors.contains">
                                        User Name already exist.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="hcalendar">Holiday Calendar <span class="text-danger">*</span></label>
                                <select class="form-control " id="hcalendar" name="hcalendar"
                                    [(ngModel)]="employee.holiday_calendar_id" #hcalendar="ngModel"
                                    [ngClass]="{'is-invalid': hcalendar.invalid && hcalendar.touched}" required>
                                    <option [ngValue]="null" selected>Select Holiday Calendar</option>
                                    <option *ngFor="let item of holidayCalendarList; let i = index"
                                        [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                                <div *ngIf="hcalendar.invalid && (hcalendar.dirty || hcalendar.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="hcalendar.errors.required">
                                        Holiday Calendar is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="joining">Date of Joining <span class="text-danger">*</span></label>
                                <dx-date-box [(ngModel)]="employee.date_of_joining" name="joining" id="joining"
                                    #joining="ngModel" required type="date" displayFormat="dd/MM/yyyy"
                                    dateSerializationFormat="yyyy-MM-dd">
                                </dx-date-box>
                                <div *ngIf="joining.invalid && (joining.dirty || joining.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="joining.errors.required">
                                        Joining date is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="designation">Designation <span class="text-danger">*</span></label>
                                <select class="form-control " id="designation" name="designation"
                                    [(ngModel)]="employee.designation_id" #designation="ngModel"
                                    [ngClass]="{'is-invalid': designation.invalid && designation.touched}" required>
                                    <option [ngValue]="null" selected>Select Designation</option>
                                    <option *ngFor="let item of designationList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                                <div *ngIf="designation.invalid && (designation.dirty || designation.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="designation.errors.required">
                                        Designation is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="department">Department <span class="text-danger">*</span></label>
                                <select class="form-control " id="department" name="department"
                                    [(ngModel)]="employee.department_id" #department="ngModel"
                                    [ngClass]="{'is-invalid': department.invalid && department.touched}" required>
                                    <option [ngValue]="null" selected>Select Department Type</option>
                                    <option *ngFor="let item of departmentList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                                <div *ngIf="department.invalid && (department.dirty || department.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="department.errors.required">
                                        Department is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="category">Category </label>
                                <select class="form-control " id="category" name="category"
                                    [(ngModel)]="employee.category_id">
                                    <option [ngValue]="null" selected>Select Category Type</option>
                                    <option *ngFor="let item of categoryList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <label for="etype">Employee Type</label>
                                <select class="form-control " id="etype" name="etype"
                                    [(ngModel)]="employee.employee_type_id" #etype="ngModel"
                                    [ngClass]="{'is-invalid': etype.invalid && etype.touched}">
                                    <option [ngValue]="null" selected>Select Employee Type</option>
                                    <option *ngFor="let item of employeeTypeList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="shift">Shift <span class="text-danger">*</span></label>
                                <select class="form-control " id="shift" name="shift" [(ngModel)]="employee.shift_id"
                                    #shift="ngModel"
                                    [ngClass]="{'is-invalid': shift.invalid && shift.touched, 'loading-control': (shiftloading$ | async) === true}"
                                    required>
                                    <option [ngValue]="null" selected>Select Shift Type</option>
                                    <option *ngFor="let item of shiftList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                                <div *ngIf="shift.invalid && (shift.dirty || shift.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="shift.errors.required">
                                        Shift is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="location">Job Location <span class="text-danger">*</span></label>
                                <select class="form-control " id="location" name="location"
                                    [(ngModel)]="employee.location_id" #location="ngModel"
                                    [ngClass]="{'is-invalid': location.invalid && location.touched, 'loading-control': (locationloading$ | async) === true}"
                                    required>
                                    <option [ngValue]="null" selected>Select Location</option>
                                    <option *ngFor="let item of locationList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                                <div *ngIf="location.invalid && (location.dirty || location.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="location.errors.required">
                                        Job Location is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="reportingTo">Reporting To</label>
                                <dx-autocomplete class="form-control" id="reportingTo" name="reportingTo"
                                    placeholder="Type Employee Name...."
                                    (onSelectionChanged)="selectReportingName($event)" [dataSource]="employeeNameList"
                                    [value]="employee.reporting_to_name" [showClearButton]="true">
                                </dx-autocomplete>
                            </div>
                            <div class="col-sm-6">
                                <label for="referredby">Referred By</label>
                                <dx-autocomplete class="form-control" id="referredby" name="referredby"
                                    placeholder="Type Employee Name...."
                                    (onSelectionChanged)="selectReferredByName($event)" [dataSource]="employeeNameList"
                                    [value]="employee.referred_by_name" [showClearButton]="true">
                                </dx-autocomplete>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="atype">Attendance Type <span class="text-danger">*</span></label>
                                <select class="form-control " id="atype" name="atype"
                                    [(ngModel)]="employee.attendance_type_id" #atype="ngModel"
                                    [ngClass]="{'is-invalid': atype.invalid && atype.touched}" required>
                                    <option [ngValue]="null" selected>Select Attendance Type</option>
                                    <option *ngFor="let item of attendanceTypeList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                                <div *ngIf="atype.invalid && (atype.dirty || atype.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="atype.errors.required">
                                        Attendance policy is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="lpolicy">Leave Policy <span class="text-danger">*</span></label>
                                <select class="form-control " id="lpolicy" name="lpolicy"
                                    [(ngModel)]="employee.leave_policy_id" #lpolicy="ngModel"
                                    [ngClass]="{'is-invalid': lpolicy.invalid && lpolicy.touched}" required>
                                    <option [ngValue]="null" selected>Select Leave Policy</option>
                                    <option *ngFor="let item of leavePolicyList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                                <div *ngIf="lpolicy.invalid && (lpolicy.dirty || lpolicy.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="lpolicy.errors.required">
                                        Leave policy is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="ppolicy">Permission Policy</label>
                                <select class="form-control " id="ppolicy" name="ppolicy"
                                    [(ngModel)]="employee.permission_policy_id" #ppolicy="ngModel">
                                    <option [ngValue]="null" selected>Select Permission Policy</option>
                                    <option *ngFor="let item of permissionPolicyList; let i = index"
                                        [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <label for="dempid">Device Employee Id</label><br>
                                <input class="form-control " [(ngModel)]="employee.device_employee_id" type="text"
                                    id="dempid" name="dempid">
                            </div>
                        </div>
                        <div class="form-group row">
                            <!-- <div class="col-sm-6">
                                <label for="atype">Create ERP User</label><br>
                                <div class="d-inline">
                                    <input id="erpuser_1" [value]='1' type="checkbox" name="erpuser"
                                        class="radio-inline" [(ngModel)]="employee.create_erp_user_flag">
                                    Yes
                                </div>
                            </div> -->
                            <div class="col-sm-6">
                                <label for="overallExp">Overall Experience (Months)</label><br>
                                <input class="form-control " [(ngModel)]="employee.overall_experience" type="number"
                                    id="overallExp" name="overallExp" #overallExp="ngModel"
                                    [pattern]="global.numericValidator">
                                <div *ngIf="overallExp.invalid && (overallExp.dirty || overallExp.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="salary.errors.pattern">
                                        Overall Experience is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex">
                <div class="card profile-box flex-fill">
                    <div class="card-body p-5" id="emp-payroll-loading">
                        <h3 class="card-title text-muted bottom-border">Employee Payroll Setup</h3>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="pearnings">Payroll Earning Policy <span class="text-danger">*</span></label>
                                <select class="form-control " id="pearnings" name="pearnings"
                                    [(ngModel)]="employee.payroll_earning_policy_id" #pearnings="ngModel"
                                    [ngClass]="{'is-invalid': pearnings.invalid && pearnings.touched}" required>
                                    <option [ngValue]="null" selected>Select Payroll Earning Policy</option>
                                    <option *ngFor="let item of earningList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                                <div *ngIf="pearnings.invalid && (pearnings.dirty || pearnings.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="pearnings.errors.required">
                                        Payroll earning policy is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="pdeductions">Payroll Deduction Policy </label>
                                <select class="form-control " id="pdeductions" name="pdeductions"
                                    [(ngModel)]="employee.payroll_deduction_policy_id" #pdeductions="ngModel"
                                    [ngClass]="{'is-invalid': pdeductions.invalid && pdeductions.touched}">
                                    <option [ngValue]="null" selected>Select Payroll Deduction Policy</option>
                                    <option *ngFor="let item of deductionList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="salary">Salary Type <span class="text-danger">*</span></label>
                                <select class="form-control " id="salary" name="salary"
                                    [(ngModel)]="employee.salary_type_id" #salary="ngModel"
                                    [ngClass]="{'is-invalid': salary.invalid && salary.touched}" required>
                                    <option [ngValue]="null" selected>Select Salary Type</option>
                                    <option *ngFor="let item of salaryList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                                <div *ngIf="salary.invalid && (salary.dirty || salary.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="salary.errors.required">
                                        Salary Type is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="bonus">Bonus </label>
                                <select class="form-control " id="bonus" name="bonus"
                                    [(ngModel)]="employee.bonus_type_id">
                                    <option [ngValue]="null" selected>Select Bonus Type</option>
                                    <option *ngFor="let item of bonusList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="ctc">CTC Policy</label>
                                <select class="form-control " id="ctc" name="ctc" [(ngModel)]="employee.ctc_policy_id">
                                    <option [ngValue]="null" selected>Select CTC Policy</option>
                                    <option *ngFor="let item of ctcComponentList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <label for="grosssalary">Gross Salary <span class="text-danger">*</span></label>
                                <input class="form-control " [(ngModel)]="employee.gross_salary" required type="number"
                                    id="grosssalary" name="grosssalary" #grosssalary="ngModel"
                                    [pattern]="global.numericValidator">
                                <div *ngIf="grosssalary.invalid && (grosssalary.dirty || grosssalary.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="grosssalary.errors.required">
                                        Gross Salary is required.
                                    </div>
                                    <div *ngIf="grosssalary.errors.pattern">
                                        Gross Salary is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex">
                <div class="card profile-box flex-fill">
                    <div class="card-body p-5" id="emp-bank-loading">
                        <h3 class="card-title text-muted bottom-border">Employee Bank Information</h3>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="accnumber">Account Number <span *ngIf="employee.salary_type_id==8"
                                        class="text-danger">*</span></label>
                                <input class="form-control " [(ngModel)]="employee.bank_details.account_number"
                                    type="text" id="accnumber" name="accnumber" #accnumber="ngModel"
                                    [pattern]="global.numericValidator"
                                    [required]="employee.salary_type_id==8">
                                <div *ngIf="accnumber.invalid && (accnumber.dirty || accnumber.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="accnumber.errors.pattern">
                                        Account Number is invalid.
                                    </div>
                                    <div *ngIf="accnumber.errors.required">
                                        Account Number is Required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="ifsc">IFSC Code <span *ngIf="employee.salary_type_id==8"
                                        class="text-danger">*</span></label>
                                <input class="form-control " [(ngModel)]="employee.bank_details.ifsc_code" type="text"
                                    id="ifsc" name="ifsc" #ifsc="ngModel" [pattern]="global.alphaNumericValidator"
                                    [required]="employee.salary_type_id==8">
                                <div *ngIf="ifsc.invalid && (ifsc.dirty || ifsc.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="ifsc.errors.pattern">
                                        IFSC Code is invalid.
                                    </div>
                                    <div *ngIf="ifsc.errors.required">
                                        IFSC Code is Required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="bankname">Bank Name <span *ngIf="employee.salary_type_id==8"
                                        class="text-danger">*</span></label>
                                <input class="form-control " [(ngModel)]="employee.bank_details.bank_name" type="text"
                                    id="bankname" name="bankname" #bankname="ngModel"
                                    [pattern]="global.alphaNumericValidator" [required]="employee.salary_type_id==8">
                                <div *ngIf="bankname.invalid && (bankname.dirty || bankname.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="bankname.errors.pattern">
                                        Bank Name is invalid.
                                    </div>
                                    <div *ngIf="bankname.errors.required">
                                        Bank Name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="branch">Branch </label>
                                <input class="form-control " [(ngModel)]="employee.bank_details.bank_branch" type="text"
                                    id="branch" name="branch" #branch="ngModel"
                                    [pattern]="global.alphaNumericValidator">
                                <div *ngIf="branch.invalid && (branch.dirty || branch.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="branch.errors.pattern">
                                        Branch is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="pfnumber">PF Number</label>
                                <input class="form-control " [(ngModel)]="employee.pf_number" type="text" id="pfnumber"
                                    name="pfnumber" #pfnumber="ngModel" [pattern]="global.alphaNumericValidator">
                                <div *ngIf="pfnumber.invalid && (pfnumber.dirty || pfnumber.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="pfnumber.errors.pattern">
                                        PF Number is invalid.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="uannumber">UAN Number</label>
                                <input class="form-control " [(ngModel)]="employee.uan_number" type="text"
                                    id="uannumber" name="uannumber" #uannumber="ngModel"
                                    [pattern]="global.numericValidator">
                                <div *ngIf="uannumber.invalid && (uannumber.dirty || uannumber.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="uannumber.errors.pattern">
                                        UAN Number is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="esicnumber">ESIC Number</label>
                                <input class="form-control " [(ngModel)]="employee.esic_no" type="text" id="esicnumber"
                                    name="esicnumber" #esicnumber="ngModel" [pattern]="global.alphaNumericValidator">
                                <div *ngIf="esicnumber.invalid && (esicnumber.dirty || esicnumber.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="esicnumber.errors.pattern">
                                        ESIC Number is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex">
                <div class="card profile-box flex-fill">
                    <div class="card-body p-5" id="emp-role-loading">
                        <h3 class="card-title text-muted bottom-border">Roles</h3>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <div *ngFor="let item of roleList" class="d-inline">
                                    <input id="role_{{item.key}}" [value]='item.key' type="checkbox" name="{{item.key}}"
                                        class="check-inline" [checked]="checkRole(item.key)"
                                        (change)="updateRoles($event)">
                                    {{item.value}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12" [ngClass]="{'loading-control': (ddloading$ | async) === true}">
                                <label for="gender">Restrict by location</label><br>
                                <div class="d-inline">
                                    <input id="restrict_location_yes" [value]='1' type="radio" name="restrict_location"
                                        class="radio-inline" [(ngModel)]="employee.restrict_location" [disabled]="!enableHrLocation">
                                    Yes
                                </div>
                                <div class="d-inline">
                                    <input id="restrict_location_no" [value]='0' type="radio" name="restrict_location"
                                        class="radio-inline" [(ngModel)]="employee.restrict_location" [disabled]="!enableHrLocation">
                                    No
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex">
                <div class="card profile-box flex-fill">
                    <div class="card-body p-5" id="emp-access-loading">
                        <h3 class="card-title text-muted bottom-border">User Access</h3>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <div *ngFor="let item of employee.access" class="d-inline">
                                    <input id="access_{{item.module_id}}" [value]='item.has_access' type="checkbox"
                                        name="{{item.module_id}}" class="check-inline"
                                        [checked]="checkAccess(item.module_id)" (change)="updateAccess($event)">
                                    {{item.module_name}}&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" [hidden]="!customFieldList || customFieldList===null || customFieldList?.length===0">
            <div class="col-md-12 d-flex">
                <div class="card profile-box flex-fill">
                    <div class="card-body p-5" id="emp-other-loading">
                        <h3 class="card-title text-muted bottom-border">Additional Information</h3>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <app-custom-fields #customField *ngIf="customFieldList"
                                    [customFieldList]="customFieldList"></app-custom-fields>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-md-3 d-flex offset-md-9">
                <button type="button" class="btn btn-secondary btn-block" (click)="saveEmployee(empForm)">Save</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-role-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#emp-role-loading">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-bank-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#emp-bank-loading">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-payroll-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#emp-payroll-loading">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-info-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#emp-info-loading">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-contact-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#emp-contact-loading">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-personal-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#emp-personal-loading">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-other-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#emp-other-loading">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-access-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#emp-access-loading">
    </dx-load-panel>
</div>
<div *ngIf="(customloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-other-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#emp-other-loading">
    </dx-load-panel>
</div>
<div *ngIf="(empcodeloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-access-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#emp-access-loading">
    </dx-load-panel>
</div>