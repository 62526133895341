import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { OfficeLocation, Shift } from 'src/app/model/admin';
import {
  AttendanceDashboardReport,
  AttendancePermission,
  EmployeeDailyAttendance,
  Leave,
  MonthlyAttendanceReport,
  PunchReport
} from 'src/app/model/attendance';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { indicate } from 'src/app/services/loading.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-daily-attendance-report',
  templateUrl: './daily-attendance-report.component.html',
  styleUrls: ['./daily-attendance-report.component.scss']
})
export class DailyAttendanceReportComponent implements OnInit, OnDestroy {
  reportDate: Date;
  departmentId: number;
  categoryId: number;
  locationId: number;
  ddlocationloading$ = new Subject<boolean>();
  ddloading$ = new Subject<boolean>();
  leaveloading$ = new Subject<boolean>();
  permissionloading$ = new Subject<boolean>();
  activityReportloading$ = new Subject<boolean>();
  ddshiftloading$ = new Subject<boolean>();
  puchReportloading$ = new Subject<boolean>();
  activityDashboardloading$ = new Subject<boolean>();
  locationList: OfficeLocation[] = [];
  leaveData: Leave[] = [];
  departmentList: KeyValue<number, string>[];
  categoryList: KeyValue<number, string>[];
  permissionList: AttendancePermission[] = [];
  shiftList: Shift[] = [];
  punchReportData: PunchReport = new PunchReport();
  dashboardReportData: AttendanceDashboardReport = new AttendanceDashboardReport();
  missedPunchReportData: PunchReport = new PunchReport();
  activityReportData: EmployeeDailyAttendance[] = [];
  shiftId: number;
  defaultVisible = false;
  dailyPunchData: MonthlyAttendanceReport[] = [];
  subscriptions: Subscription[] = [];
  today_date: Date = new Date();
  constructor(
    public global: Global,
    public commonApiService: CommonApiService,
    private adminApiService: AdminApiService,
    public attendanceApiService: AttendanceApiService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.reportDate = new Date();
    this.subscriptions.push(
      this.commonApiService.getMasterData('department,employee_category')
        .pipe(indicate(this.ddloading$))
        .subscribe(apiresponse => {
          this.departmentList = apiresponse.data.filter((d: { type: string; }) => d.type === 'department');
          this.categoryList = apiresponse.data.filter((d: { type: string; }) => d.type === 'employee_category');
        }));
    this.subscriptions.push(
      this.adminApiService.getLocation()
        .pipe(indicate(this.ddlocationloading$))
        .subscribe(apiresponse => {
          this.locationList = apiresponse.data;
        }));
    this.subscriptions.push(
      this.adminApiService.getShift()
        .pipe(indicate(this.ddshiftloading$))
        .subscribe(apiresponse => {
          this.shiftList = apiresponse.data;
        }));
    this.loadReport();
  }
  loadReport() {
    this.subscriptions.push(
      this.attendanceApiService
        .getLeave(null, null, null, null, null, this.reportDate, this.locationId, this.departmentId, this.shiftId, this.categoryId)
        .pipe(indicate(this.leaveloading$))
        .subscribe(apiresponse => {
          this.leaveData = apiresponse.data;
        }));
    this.subscriptions.push(
      this.attendanceApiService
        .getPermissionRequest
        (
          null,
          null,
          null,
          true,
          this.locationId,
          this.departmentId,
          this.shiftId,
          this.reportDate,
          null,
          this.categoryId
        )
        .pipe(indicate(this.permissionloading$))
        .subscribe(apiresponse => {
          this.permissionList = apiresponse.data;
        }));
    this.subscriptions.push(
      this.attendanceApiService
        .getDailyLatePunchReport(this.locationId, this.departmentId, this.shiftId, this.reportDate, null, null, null, this.categoryId)
        .pipe(indicate(this.puchReportloading$))
        .subscribe(apiresponse => {
          this.punchReportData = apiresponse.data.filter(r => r.punch_status === 'Late');
          this.missedPunchReportData = apiresponse.data.filter(r => r.punch_status === 'Not Available');
        }));
    this.subscriptions.push(
      this.attendanceApiService
        .getDailyAttendanceActivityReport(this.locationId, this.departmentId, this.shiftId, this.reportDate, this.categoryId)
        .pipe(indicate(this.activityReportloading$))
        .subscribe(apiresponse => {
          this.activityReportData = apiresponse.data;
        }));
    this.subscriptions.push(
      this.attendanceApiService
        .getDailyAttendanceActivityDashboard(this.locationId, this.departmentId, this.shiftId, this.reportDate, this.categoryId)
        .pipe(indicate(this.activityDashboardloading$))
        .subscribe(apiresponse => {
          this.dashboardReportData = apiresponse.data.dashboard_data[0];
          this.dailyPunchData = apiresponse.data.punch_report;
        }));
  }
  reportdateChanged(e: any) {

  }
  toggleTooltip(p: any) {
    p.tooltipvisible = !p.tooltipvisible;
  }
  employeeAttendanceExcelSheet() {
    const win = window.open(
      this.commonApiService.getAttendanceDocumentUrl(
      this.reportDate,
      this.locationId,
      this.departmentId,
      this.shiftId,
      this.categoryId),
      '_blank');
    win.focus();
  }
}
