import { BrowserModule, Title } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DxCircularGaugeModule } from 'devextreme-angular/ui/circular-gauge';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxAutocompleteModule } from 'devextreme-angular/ui/autocomplete';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DatePipe, UpperCasePipe } from '@angular/common';
import { TabContentComponent } from './components/common/tab-content/tab-content.component';
import { ContentContainerDirective } from './directive/content-container.directive';
import { ToastsContainerComponent } from './components/common/toasts-container/toasts-container.component';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './components/common/login/login.component';
import { HomeComponent } from './components/common/home/home.component';
import { Global } from './shared/global';
import { LeaveViewComponent } from './components/attendance/leave-view/leave-view.component';
import { LeaveAdminViewComponent } from './components/attendance/leave-admin-view/leave-admin-view.component';
import { AttendanceAdminViewComponent } from './components/attendance/attendance-admin-view/attendance-admin-view.component';
import { DailyAttendanceSummaryComponent } from './components/attendance/daily-attendance-summary/daily-attendance-summary.component';
import { DailyAttendanceActivityComponent } from './components/attendance/daily-attendance-activity/daily-attendance-activity.component';
import { AttendanceViewComponent } from './components/attendance/attendance-view/attendance-view.component';
import { UserProfileComponent } from './components/common/user-profile/user-profile.component';
import { EmployeeListComponent } from './components/admin/employee-list/employee-list.component';
import { MasterAdminComponent } from './components/admin/master-admin/master-admin.component';
import { HolidayListComponent } from './components/admin/holiday-list/holiday-list.component';
import { EmployeeSalaryComponent } from './components/payroll/employee-salary/employee-salary.component';
import { PayslipViewComponent } from './components/payroll/payslip-view/payslip-view.component';
import { PolicyViewComponent } from './components/admin/policy-view/policy-view.component';
import { ContainsValidator } from './directive/contains-validator.directive';
import { CompareValidator } from './directive/compare-validator.directive';
import { AssetListComponent } from './components/admin/asset-list/asset-list.component';
import { AssetViewComponent } from './components/admin/asset-view/asset-view.component';
import { AssetRequestComponent } from './components/admin/asset-request/asset-request.component';
import { MessageTimePipe } from './shared/message-time.pipe';
import { MinutesToHours } from './shared/minutes-to-hour.pipe';
import { AvatarModule } from 'ngx-avatar';
import { NotificationComponent } from './components/common/notification/notification.component';
import { SalaryComponentComponent } from './components/payroll/salary-component/salary-component.component';
import { RelievingLetterComponent } from './components/admin/relieving-letter/relieving-letter.component';
import { PrintPageComponent } from './components/common/print-page/print-page.component';
import { SanitizeHtmlPipe } from './shared/sanitize-html.pipe';
import { LocationListComponent } from './components/admin/location-list/location-list.component';
import { ShiftListComponent } from './components/admin/shift-list/shift-list.component';
import { AttendancePunchComponent } from './components/attendance/attendance-punch/attendance-punch.component';
// tslint:disable-next-line:max-line-length
import { AttendanceRegularizeAdminComponent } from './components/attendance/attendance-regularize-admin/attendance-regularize-admin.component';
import { AttendanceRegularizeComponent } from './components/attendance/attendance-regularize/attendance-regularize.component';
import { SalarySheetComponent } from './components/payroll/salary-sheet/salary-sheet.component';
import { ChangePasswordComponent } from './components/common/change-password/change-password.component';
import { httpInterceptorProviders } from './shared/global';
import { HrDashboardComponent } from './components/common/hr-dashboard/hr-dashboard.component';
import { AgmCoreModule } from '@agm/core';
import { SendNotificationComponent } from './components/common/send-notification/send-notification.component';
import { EditEmployeeComponent } from './components/admin/edit-employee/edit-employee.component';
import { EditRecurverComponent } from './components/admin/edit-recurver/edit-recurver.component';
import { ToWordsPipe } from './shared/to-words.pipe';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ApplyLeaveComponent } from './components/attendance/apply-leave/apply-leave.component';
import { RequestRegularizeComponent } from './components/attendance/request-regularize/request-regularize.component';
import { PermissionViewComponent } from './components/attendance/permission-view/permission-view.component';
import { CustomFieldsComponent } from './components/common/custom-fields/custom-fields.component';
import { FilterPipe } from './shared/filter.pipe';
import { LeaveBalanceComponent } from './components/attendance/leave-balance/leave-balance.component';
import { DailyAttendanceReportComponent } from './components/attendance/daily-attendance-report/daily-attendance-report.component';
// tslint:disable-next-line:max-line-length
import { DateRangeAttendanceReportComponent } from './components/attendance/date-range-attendance-report/date-range-attendance-report.component';
import { ProfilePictureUploadComponent } from './components/common/profile-picture-upload/profile-picture-upload.component';
import { AgmDirectionModule } from 'agm-direction';
import { PerformanceViewComponent } from './components/admin/performance-view/performance-view.component';
import { SurveyViewComponent } from './components/admin/survey-view/survey-view.component';
import { SurveyListComponent } from './components/admin/survey-list/survey-list.component';
import { SurveyReportComponent } from './components/admin/survey-report/survey-report.component'; 
import { RecurverListComponent } from './components/admin/recurver-list/recurver-list.component';
import { CommonModule } from '@angular/common';
import { OfferLetterComponent } from './components/admin/offer-letter/offer-letter.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  declarations: [
    AppComponent,
    OfferLetterComponent,
    TabContentComponent,
    ContentContainerDirective,
    ToastsContainerComponent,
    LoginComponent,
    HomeComponent,
    LeaveViewComponent,
    LeaveAdminViewComponent,
    AttendanceAdminViewComponent,
    DailyAttendanceSummaryComponent,
    DailyAttendanceActivityComponent,
    AttendanceViewComponent,
    UserProfileComponent,
    EmployeeListComponent,
    RecurverListComponent,
    MasterAdminComponent,
    HolidayListComponent,
    EmployeeSalaryComponent,
    PayslipViewComponent,
    PolicyViewComponent,
    ContainsValidator,
    CompareValidator,
    AssetListComponent,
    AssetViewComponent,
    AssetRequestComponent,
    MessageTimePipe,
    MinutesToHours,
    NotificationComponent,
    SalaryComponentComponent,
    RelievingLetterComponent,
    PrintPageComponent,
    SanitizeHtmlPipe,
    LocationListComponent,
    ShiftListComponent,
    AttendancePunchComponent,
    AttendanceRegularizeAdminComponent,
    AttendanceRegularizeComponent,
    SalarySheetComponent,
    ChangePasswordComponent,
    HrDashboardComponent,
    SendNotificationComponent,
    EditEmployeeComponent,
    EditRecurverComponent,
    ToWordsPipe,
    ApplyLeaveComponent,
    RequestRegularizeComponent,
    PermissionViewComponent,
    CustomFieldsComponent,
    FilterPipe,
    LeaveBalanceComponent,
    DailyAttendanceReportComponent,
    DateRangeAttendanceReportComponent,
    ProfilePictureUploadComponent,
    PerformanceViewComponent,
    SurveyViewComponent,
    SurveyListComponent,
    SurveyReportComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    DxPieChartModule,
    DxChartModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    HttpClientModule,
    NgbModule,
    NgbRatingModule,
    DxButtonModule,
    DxDataGridModule,
    FontAwesomeModule,
    DxLoadPanelModule,
    FormsModule,
    DxAutocompleteModule,
    DxLoadIndicatorModule,
    DxTooltipModule,
    DxTextAreaModule,
    DxPopoverModule,
    DxCircularGaugeModule,
    AvatarModule,
    PerfectScrollbarModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBqrebIN5KQAtJeY5BHTRdg-SxNtoeXOdg'
    }),
    AgmDirectionModule
  ],
  providers:
    [
      DatePipe,
      Global,
      UpperCasePipe,
      httpInterceptorProviders,
      Title,
      {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
      },
      {
        provide: LocationStrategy,
        useClass: HashLocationStrategy
      }
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
