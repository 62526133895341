import { Component, Input, OnInit } from '@angular/core';
import { EmployeeDailyAttendance } from 'src/app/model/attendance';

@Component({
  selector: 'app-daily-attendance-summary',
  templateUrl: './daily-attendance-summary.component.html',
  styleUrls: ['./daily-attendance-summary.component.scss']
})
export class DailyAttendanceSummaryComponent implements OnInit {
  @Input() ngModel: EmployeeDailyAttendance;
  @Input() showPunchOut = true;
  constructor() { }

  ngOnInit(): void {
    if (this.ngModel.first_punch_in != null) {
      const ind: Date = new Date(this.ngModel.first_punch_in);
      const otd: Date = new Date(this.ngModel.last_punch_out);
      if (ind.getFullYear() === 1970) {
        const dt: Date = new Date(this.ngModel.report_date);
        const m: number = dt.getMonth();
        const y: number = dt.getFullYear();
        const d: number = dt.getDate();
        ind.setFullYear(y);
        ind.setMonth(m);
        ind.setDate(d);
        otd.setFullYear(y);
        otd.setMonth(m);
        otd.setDate(d);
        this.ngModel.first_punch_in = ind;
        this.ngModel.last_punch_out = otd;
      }
    }
  }

}
