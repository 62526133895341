<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Attendance Report</h3>
            </div>
            <div class="col-auto float-right ml-auto">
            </div>
        </div>
    </div>
    <div class="row filter-row">
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <dx-date-box [(value)]="reportFromDate" type="date" class="form-control filter-control"
                placeholder="Report From Date" (onValueChanged)="fromdateChanged($event)" [max]="reportToDate">
            </dx-date-box>

        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <dx-date-box [(value)]="reportToDate" type="date" class="form-control filter-control"
                placeholder="Report To Date" (onValueChanged)="todateChanged($event)" [min]="reportFromDate">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="status" name="status"
                [ngClass]="{'loading-control': (ddlocationloading$ | async) === true }" id="location" name="location"
                [(ngModel)]="locationId">
                <option [ngValue]="undefined" selected>All Location</option>
                <option *ngFor="let item of locationList; let i = index" [ngValue]="item.location_id">
                    {{item.location_name}}</option>
            </select>
        </div>
        <!-- <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="shift" name="shift"
                [ngClass]="{'loading-control': (ddshiftloading$ | async) === true }" id="shift" name="shift"
                [(ngModel)]="shiftId">
                <option [ngValue]="undefined" selected>All Shifts</option>
                <option *ngFor="let item of shiftList; let i = index" [ngValue]="item.shift_id">
                    {{item.shift_name}}</option>
            </select>
        </div> -->
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="department" name="department"
                [ngClass]="{'loading-control': (ddloading$ | async) === true }" id="department" name="department"
                [(ngModel)]="departmentId">
                <option [ngValue]="undefined" selected>All Department</option>
                <option *ngFor="let item of departmentList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="category" name="category"
                [ngClass]="{'loading-control': (ddloading$ | async) === true }"
                [(ngModel)]="categoryId">
                <option [ngValue]="undefined" selected>All Category</option>
                <option *ngFor="let item of categoryList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <button class="btn btn-secondary btn-block" (click)="loadReport()"> 
                Load
            </button>
        </div>
    </div>
    <div class="row  mt-5">
        <div class="col-md-12 mt-1 table-responsive" id="activity-report-grid">
            <dx-data-grid #gridreport id="gridContainer" [dataSource]="reportData" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false">
                <dxo-export [enabled]="true" fileName="employeeAttendanceReport"></dxo-export>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                <dxi-column dataField="full_name" [allowFiltering]="true" [allowSorting]="false"
                    [allowGrouping]="false" cellTemplate="imageTemplate" caption="EMPLOYEE" width="250" cssClass="cls">
                </dxi-column>
                <dxi-column dataField="employee_code" caption="Code" dataType="string" [allowGrouping]="false"
                    [allowFiltering]="true">
                </dxi-column>
                <dxi-column dataField="shift" caption="SHIFT" dataType="string" [allowGrouping]="true"
                    [allowFiltering]="true">
                </dxi-column>
                <dxi-column dataField="no_of_late_punches" caption="Lates" [allowFiltering]="true"></dxi-column>
                <dxi-column dataField="no_of_leave_days" caption="Leave days" [allowFiltering]="true"></dxi-column>
                <dxi-column dataField="no_of_missed_punches" caption="Missed Punches" [allowFiltering]="true"></dxi-column>
                <dxi-column dataField="no_of_permission_hours" caption="Permission hours" [allowFiltering]="true"></dxi-column>             
                <dxo-summary>
                    <dxi-total-item column="employee_code" summaryType="count" title="Employee Count"
                        displayFormat="Employee Count: {0}">
                    </dxi-total-item>
                </dxo-summary>
                <div *dxTemplate="let data of 'imageTemplate'">
                    <h2 class="table-avatar">
                        <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                            [name]="data.row.data.full_name">
                        </ngx-avatar>
                        <a class="ml-1 mt-1 gridlink" href="javascript:void(0);">
                            {{data.row.data.full_name}}<span>{{data.row.data.designation}} </span></a>
                    </h2>
                </div>               
            </dx-data-grid>
        </div>
    </div>
</div>
<div *ngIf="(activityReportloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#activity-report-grid' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#activity-report-grid">
    </dx-load-panel>
</div>