<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Survey Report</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="card profile-box flex-fill">
            <div class="card-body">
                <div class="row">
                    <div *ngFor="let item of surveyData;let i=index" class="col-md-4 mt-3">
                        <dx-pie-chart *ngIf="item" id="field_name" type="doughnut" [title]="item.field_name"
                            palette="Soft Pastel" [dataSource]="item.result">
                            <dxi-series argumentField="results">
                                <dxo-label [visible]="true">
                                    <dxo-connector [visible]="true"></dxo-connector>
                                </dxo-label>
                            </dxi-series>
                            <dxo-legend [margin]="0" horizontalAlignment="right" verticalAlignment="top"></dxo-legend>
                        </dx-pie-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>