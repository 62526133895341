
   <div class="content container-fluid">
    <!-- <div class="page-header">
      <div class="row align-items-center">
          <div class="col centered">
              <h3 class="page-title">Relieving Letter</h3>
          </div>
          <div class="col-auto float-right ml-auto">
              <div class="btn-group btn-group-sm">
                  <button class="btn btn-white" (click)="print()"><i class="fa fa-print fa-lg"></i> Print</button>
              </div>
          </div>
      </div>
  </div> -->
    <div class="offer-letter-container" id="offer-letter-content">
     <div class="headerh2">
        <h3>SCMCube Technologies Private Limited</h3>
        <p>Address: 135, Medavakkam Main Rd, Jeevan Nagar,<br>
          Adambakkam,Chennai,Tamil Nadu <br> Pin-600088</p>
        <p>Phone: 093634 97133  <br> Email: digital@scm.in.in</p>
      </div>

      <br>
      <br>
  
      <section class="offer-details">
        <div class="centered">
        <h3>Offer of Employment</h3>
        </div>
      </section>

      <br>

    

      <p> We are pleased to offer you the position of <strong>{{ offerLetter.position }}</strong> at {{ offerLetter.company_name }}.</p><br>
      <p> Your annual cost to company is <strong>{{ offerLetter.salary | currency:'INR':'symbol':'1.2-2':'en-IN' }}</strong>. The break down of your gross salary and information specific to employee benefits can be found in Annexure A.</p><br>
      <p> We would like you to start work on <strong>{{ offerLetter.join_date | date: 'dd/MM/yyyy' }}</strong> from the base location, <strong>{{ offerLetter.location }}</strong>. You will work with the [team's name - Development/Marketing/HR] team and report directly to (manager's name and designation].</p><br>
      <p> If you choose to accept this job offer, please sign and return this letter by <strong>{{ offerLetter.deadline| date: 'dd/MM/yyyy'}} </strong>. Once we receive your acceptance, we will provide information about onboarding and other asset details.</p><br>
      <p> We are confident that you will find this offer exciting, and I, on behalf of <strong>{{ offerLetter.company_name}}</strong>, assure you of a very rewarding career in our organization.</p><br>

      <p> We are confident that you will find this offer exciting, and I, on behalf of <strong>{{ offerLetter.company_name}}</strong>, assure you of a very rewarding career in our organization.</p><br>
  
      <!-- <section class="offer-details">
        <h5>Interview Date</h5>
        <p><strong>{{ offerLetter.interview_date }}</strong></p>

        <h5>Position</h5>
        <p><strong>{{ offerLetter.position }}</strong></p>
  
        <h5>Start Date</h5>
        <p>{{ offerLetter. join_date| date: 'MM/dd/yyyy'}}</p>
  
        <h5>Salary</h5>
        <p>{{ offerLetter.salary | currency:'INR':'symbol':'1.2-2':'en-IN' }}</p>
  
        <h5>Additional Information</h5>
        <p>{{ offerLetter.additionalInfo }}</p>
      </section>
         -->

      <footer class="footer">
        <p>Sincerely,</p>
        <p><strong>{{offerLetter.user_name}}</strong></p>
        <!-- <p><strong>{{ offerLetter.ecompany_id}}</strong></p> -->
      </footer>
    </div>
  </div>
  