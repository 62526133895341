import { UpperCasePipe } from '@angular/common';
import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[app-contains][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ContainsValidator,
      multi: true
    }
  ]
})
export class ContainsValidator implements Validator {
  // tslint:disable-next-line:no-input-rename
  @Input('app-contains') containsArr: string[];
  // tslint:disable-next-line:no-input-rename
  @Input('exclude') exclude: string;
  validator: ValidatorFn;
  constructor(private casePipe: UpperCasePipe) { }

  validate(c: FormControl) {
    // tslint:disable-next-line:max-line-length
    if (!this.containsArr || this.containsArr.find(a => this.casePipe.transform(a) === this.casePipe.transform(c.value)) === undefined || c.value === '' || this.casePipe.transform(c.value) === this.casePipe.transform(this.exclude)) {
      return null;
    } else {
      return { contains: 'The Value ' + c.value + ' is not valid.' };
    }
  }
}
