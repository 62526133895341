<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center" *ngIf="!global.isMobileApp && global.loggedUser">
            <div class="col" *ngIf="global.isMobile===false">
                <h3 class="page-title" *ngIf="role!=='approver'">Permission</h3>
                <h3 class="page-title" *ngIf="role==='approver'">Manage Permission</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
                <button class="btn add-btn" (click)="openModel()" *ngIf="role!=='approver'"><i class="fa fa-plus"></i>
                    Request Permission</button>
                <button class="btn add-btn ml-3" (click)="openApplyPermission()"
                    *ngIf="(role==='approver' && showAddPermissionFor===true) || (role==='approver' && hasHrRole==true) || (role==='approver' && hasAdminRole==true)"><i
                        class="fa fa-user-circle"></i> Apply
                    Permission For</button>
            </div>
        </div>
    </div>
    <ng-template #desktop>
        <div class="row mt-3 mb-3" *ngIf="role!=='approver'">
            <div class="col-md-3">
                <div class="stats-info bg-card bg-holder" id="permission-summary">
                    <h6>Balance</h6>
                    <h4>{{permissionSummary==null?0:permissionSummary?.no_of_hours_left}} <span>Hours </span></h4>
                </div>
            </div>
            <div class="col-md-3">
                <div class="stats-info bg-card bg-holder" id="permission-summary-1">
                    <h6>Last Permission</h6>
                    <div *ngIf="permissionSummary?.permission_date">
                        <h4><span>On {{permissionSummary.permission_date | date : "dd-MM-yyyy"}} for</span>
                            {{permissionSummary.duration}} <span>Hours</span></h4>
                    </div>
                    <div *ngIf="!permissionSummary?.permission_date">
                        <h4><span>No Permission taken for the month of {{currDate | date : "MMMM"}}</span></h4>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="stats-info bg-card bg-holder" id="permission-summary-2">
                    <h6>Permission taken this month</h6>
                    <h4>{{permissionSummary==null?0:permissionSummary?.no_of_hours_month}} <span>Hours </span></h4>
                </div>
            </div>
            <div class="col-md-3">
                <div class="stats-info bg-card bg-holder" id="permission-summary-3">
                    <h6>Permission taken this year</h6>
                    <h4>{{permissionSummary==null?0:permissionSummary?.no_of_hours_year}} <span>Hours </span></h4>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #mobile>
        <div class="row stats-info bg-card bg-mobile-holder align-left min-height-100" id="permission-summary"
            *ngIf="role!=='approver'">
            <div class="col-md-12 mt-3">
                <i class="fa fa-dot-circle-o text-warning mr-2"></i> Balance <div class="pull-right">
                    {{permissionSummary==null?0:permissionSummary?.no_of_hours_left}} <span>Hours</span></div>
            </div>
            <div class="col-md-12 mt-3">
                <i class="fa fa-dot-circle-o text-warning mr-2"></i> Last Permission Date<div class="pull-right">
                    <div *ngIf="permissionSummary?.permission_date">
                        <span>{{permissionSummary.permission_date | date : "dd-MM-yyyy"}}</span>
                    </div>
                    <div *ngIf="!permissionSummary?.permission_date">
                        <h4><span>NA</span></h4>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3">
                <i class="fa fa-dot-circle-o text-warning mr-2"></i> Last Permission Duration<div class="pull-right">
                    <div *ngIf="permissionSummary?.duration">
                        {{permissionSummary.duration}} <span>Hours</span>
                    </div>
                    <div *ngIf="!permissionSummary?.duration">
                        <h4><span>NA</span></h4>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3">
                <i class="fa fa-dot-circle-o text-warning mr-2"></i> Permission taken this month<div class="pull-right">
                    <div *ngIf="permissionSummary?.no_of_hours_month">
                        {{permissionSummary==null?0:permissionSummary?.no_of_hours_month}} <span>Hours</span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3">
                <i class="fa fa-dot-circle-o text-warning mr-2"></i> Permission taken this year<div class="pull-right">
                    <div *ngIf="permissionSummary?.no_of_hours_year">
                        {{permissionSummary==null?0:permissionSummary?.no_of_hours_year}} <span>Hours</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="global.isMobile===true?mobile:desktop">
    </ng-container>
    <div class="row" *ngIf="global.isMobileApp">
        <div class="col-md-12 mobile-filter mb-3">
            <button class="btn mobile-btn col-md-12" (click)="openModel()" *ngIf="role!=='approver'"><i
                    class="fa fa-plus"></i>
                Request Permission</button>
            <button class="btn mobile-btn col-md-12" (click)="openApplyPermission()"
                *ngIf="(role==='approver' && showAddPermissionFor===true) || (role==='approver' && hasHrRole==true) || (role==='approver' && hasAdminRole==true)"><i
                    class="fa fa-user-circle"></i> Apply
                Permission For</button>
        </div>
    </div>
    <div class="row filter-row">
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12" [ngClass]="{'mobile-filter': global.isMobile===true}"
            *ngIf="role==='approver'">
            <dx-autocomplete class="form-control filter-control" id="employeeName" placeholder="Type Employee Name...."
                (onSelectionChanged)="selectName($event)" [dataSource]="employeeNameList" [showClearButton]="true">
            </dx-autocomplete>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" [ngClass]="{'mobile-filter': global.isMobile===true}"
            *ngIf="role==='approver'">
            <select class="form-control filter-control" id="permissionStatus" name="permissionStatus"
                [(ngModel)]="permissionStatusId" [ngClass]="{'loading-control': (loading$ | async) === true }">
                <option [ngValue]="undefined" selected>All Status</option>
                <option *ngFor="let item of permissionStatusList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" [ngClass]="{'mobile-filter': global.isMobile===true}">
            <dx-date-box [(value)]="fromDate" type="date" class="form-control filter-control" placeholder="From Date"
                (onValueChanged)="fromdateChanged($event)">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" [ngClass]="{'mobile-filter': global.isMobile===true}">
            <dx-date-box [(value)]="toDate" type="date" class="form-control filter-control" placeholder="To Date"
                [min]="fromDate">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" [ngClass]="{'mobile-filter': global.isMobile===true}"
            *ngIf="global.loggedUser">
            <button [ngClass]="global.isMobileApp ? 'btn mobile-btn btn-block' : 'btn btn-secondary btn-block'"
                (click)="searchPermission()" [disabled]="!fromDate || !toDate">
                Search </button>
        </div>
    </div>
    <ng-template #desktopgrid>
        <div class="row">
            <div class="col-md-12 mt-3 table-responsive" id="permission-loading-div">
                <dx-data-grid #gridpermission id="gridContainer" [dataSource]="permissionList"
                    [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
                    [showBorders]="false" [showColumnLines]="false">
                    <dxo-export [enabled]="true" fileName="permissionReport"></dxo-export>
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxi-column dataField="employee_name" [allowFiltering]="true" [allowSorting]="false"
                        cellTemplate="imageTemplate" caption="Employee" width="250" [visible]="role==='approver'">
                    </dxi-column>

                    <dxi-column dataField="employee_code" caption="Code" dataType="string"
                        [allowFiltering]="false">
                    </dxi-column>


                    <dxi-column dataField="permission_date" caption="Permission Date" cellTemplate="dateTemplate"
                        [allowFiltering]="false">
                    </dxi-column>
                    <dxi-column dataField="permission_reason" caption="Reason" dataType="string"
                        [allowFiltering]="false">
                    </dxi-column>
                    <dxi-column dataField="permission_from_time" caption="From" cellTemplate="timeTemplate"
                        [allowFiltering]="false">
                    </dxi-column>
                    <dxi-column dataField="permission_to_time" caption="To" cellTemplate="timeTemplate"
                        [allowFiltering]="false">
                    </dxi-column>
                    <dxi-column dataField="permission_duration" caption="Duration">
                    </dxi-column>
                    <dxi-column dataField="permission_status" caption="Status" [allowFiltering]="false"
                        [allowSorting]="false" cellTemplate="statusTemplate" alignment="center"
                        [visible]="role!=='approver'">
                    </dxi-column>
                    <dxi-column dataField="permission_status" caption="Status" [allowFiltering]="false"
                        [allowSorting]="false" cellTemplate="statusTemplateApprover" alignment="center"
                        [visible]="role==='approver'">
                    </dxi-column>
                    <dxi-column dataField="permission_id" [allowFiltering]="false" width=50 caption=""
                        [allowSorting]="false" cellTemplate="actionTemplate" alignment="center"
                        [visible]="role!=='approver'">
                    </dxi-column>
                    <div *dxTemplate="let data of 'dateTemplate'">
                        {{data.value | date }}
                    </div>
                    <div *dxTemplate="let data of 'timeTemplate'">
                        {{data.value | date:"shortTime" }}
                    </div>
                    <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Approved'">
                            <i class="fa fa-dot-circle-o text-success"></i> Approved
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Pending'">
                            <i class="fa fa-dot-circle-o text-purple"></i> Pending
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Declined'">
                            <i class="fa fa-dot-circle-o text-danger"></i> Declined
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Cancelled'">
                            <i class="fa fa-dot-circle-o text-grey"></i> Cancelled
                        </a>
                    </div>
                    <div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
                        <div ngbDropdown container="body"
                            *ngIf="cellInfo.row.data.permission_status!=='Cancelled' && cellInfo.row.data.permission_status==='Pending'">
                            <a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle><i
                                    class="material-icons">more_vert</i></a>
                            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript:void(0);"
                                    (click)="editPermission(cellInfo.row.data)"><i class="fa fa-pencil m-r-5"></i>
                                    Edit</a>
                                <a class="dropdown-item" href="javascript:void(0);"
                                    (click)="changeStatus(cellInfo,'Cancelled')"><i class="fa fa-trash-o m-r-5"></i>
                                    Cancel</a>
                            </div>
                        </div>
                    </div>
                    <div *dxTemplate="let cellInfo of 'statusTemplateApprover'" class="text-center">
                        <div ngbDropdown container="body">
                            <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                                *ngIf="cellInfo.value=='Approved'" ngbDropdownToggle>
                                <i class="fa fa-dot-circle-o text-success"></i> Approved
                            </a>
                            <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                                *ngIf="cellInfo.value=='Manager Approved'" ngbDropdownToggle>
                                <i class="fa fa-dot-circle-o text-warning"></i> M Approved
                            </a>
                            <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                                *ngIf="cellInfo.value=='Pending'" ngbDropdownToggle>
                                <i class="fa fa-dot-circle-o text-purple"></i> Pending
                            </a>
                            <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                                *ngIf="cellInfo.value=='Declined'" ngbDropdownToggle>
                                <i class="fa fa-dot-circle-o text-danger"></i> Declined
                            </a>
                            <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                                *ngIf="cellInfo.value=='Cancelled'">
                                <i class="fa fa-dot-circle-o text-grey"></i> Cancelled
                            </a>
                            <div ngbDropdownMenu>
                                <a class="dropdown-item"
                                    *ngIf="cellInfo.value=='Declined' || cellInfo.value=='Pending' || (cellInfo.value=='Manager Approved' && hasHrRole===true)"
                                    href="javascript:void(0);" (click)="changeStatus(cellInfo.row.data, 'Approved')"><i
                                        class="fa fa-dot-circle-o text-success"></i> Approve</a>
                                <a class="dropdown-item"
                                    *ngIf="cellInfo.value=='Pending' || (cellInfo.value=='Manager Approved' && hasHrRole===true)"
                                    href="javascript:void(0);" (click)="changeStatus(cellInfo.row.data, 'Declined')"><i
                                        class="fa fa-dot-circle-o text-danger"></i> Decline</a>
                                <a class="dropdown-item"
                                    *ngIf="(cellInfo.value=='Approved' || cellInfo.value=='Declined') &&  hasHrRole===true"
                                    href="javascript:void(0);" (click)="changeStatus(cellInfo.row.data, 'Cancelled')"><i
                                        class="fa fa-dot-circle-o text-grey"></i> Cancel</a>
                            </div>
                        </div>
                    </div>
                    <div *dxTemplate="let data of 'imageTemplate'">
                        <h2 class="table-avatar">
                            <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                                [name]="data.row.data.employee_name">
                            </ngx-avatar>
                            <a class="ml-1 mt-1"> {{data.row.data.employee_name}}<span>{{data.row.data.designation}}
                                </span></a>
                        </h2>
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </ng-template>
    <ng-template #mobilegrid>
        <div class="row">
            <div class="col-md-12 mt-3 table-responsive" id="permission-loading-div">
                <dx-data-grid #gridpermission id="gridContainerMobile" [dataSource]="permissionList"
                    [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
                    [showBorders]="false" [showColumnLines]="false" [showColumnHeaders]="false"
                    rowTemplate="rowPermissionTemplate">
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxi-column dataField="employee_name" [width]="80+'%'"></dxi-column>
                    <dxi-column dataField="permission_status">
                    </dxi-column>
                    <tbody class="dx-row" [ngClass]="{'dx-row-alt': permission.rowIndex % 2}"
                        *dxTemplate="let permission of 'rowPermissionTemplate'">
                        <tr class="main-row">
                            <td class="mobile-column">
                                <h2 class="table-avatar">
                                    <ngx-avatar size="40" [src]="permission.data.profile_picture"
                                        [name]="permission.data.employee_name">
                                    </ngx-avatar>
                                    <a class="ml-1 mt-1"> {{permission.data.employee_name}} <span
                                            *ngIf="permission.data.permission_date!=undefined">{{permission.data.permission_date
                                            | date }}
                                        </span></a>
                                </h2>
                            </td>
                            <td class="action-column">
                                <div ngbDropdown container="body"
                                    *ngIf="permission.data.permission_status!='Cancelled'">
                                    <a href="javascript:void(0);" class="action-icon dropdown-toggle-no"
                                        ngbDropdownToggle><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                        <a class="dropdown-item" href="javascript:void(0);"
                                            (click)="editPermission(permission.data)" *ngIf="role!=='approver'"><i class="fa fa-pencil m-r-5"></i>
                                            Edit</a>
                                        <a class="dropdown-item" href="javascript:void(0);"
                                            (click)="changeStatus(permission.data,'Cancelled')" *ngIf="role!=='approver'"><i
                                                class="fa fa-trash-o m-r-5"></i>
                                            Cancel</a>
                                        <a class="dropdown-item"
                                            *ngIf="permission.data.permission_status=='Declined' || permission.data.permission_status=='Pending' || (permission.data.permission_status=='Manager Approved' && hasHrRole===true)"
                                            href="javascript:void(0);"
                                            (click)="changeStatus(permission.data, 'Approved')"><i
                                                class="fa fa-thumbs-o-up m-r-5"></i>Approve</a>
                                        <a class="dropdown-item"
                                            *ngIf="permission.data.permission_status=='Pending' || (permission.data.permission_status=='Manager Approved' && hasHrRole===true)"
                                            href="javascript:void(0);"
                                            (click)="changeStatus(permission.data, 'Declined')"><i
                                                class="fa fa-thumbs-o-down m-r-5"></i>Decline</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="alt-row">
                            <td colspan="" class="mobile-column">
                                <div>
                                    <span class="text-sm text-muted">Duration: {{permission.data.permission_duration}}
                                        Hour(s)</span>
                                </div>
                            </td>
                            <td class="status-column">
                                <span class="badge"
                                    [ngClass]="{'bg-inverse-danger': permission.data.permission_status=='Declined','bg-inverse-success': permission.data.permission_status=='Approved','bg-inverse-purple': permission.data.permission_status=='Pending','bg-inverse-warning': permission.data.permission_status=='Manager Approved','bg-inverse-grey': permission.data.permission_status=='Cancelled'}">{{permission.data.permission_status==='Manager
                                    Approved'?'M Approved':permission.data.permission_status}}</span>
                            </td>
                        </tr>
                    </tbody>
                </dx-data-grid>
            </div>
        </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="global.isMobile===true?mobilegrid:desktopgrid">
    </ng-container>
    <ng-template #permissioncontent let-c="close" let-d="dismiss">
        <form #permissionForm="ngForm">
            <div class="modal-header">
                <div *ngIf="isNew;else edit_content">
                    <h4 class="modal-title" id="modal-basic-title">Add Permission Request</h4>
                </div>
                <ng-template #edit_content>
                    <h4 class="modal-title" id="modal-basic-title">Edit Permission Request</h4>
                </ng-template>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-6"
                        *ngIf="(role==='approver' && showAddPermissionFor===true) || (role==='approver' && hasHrRole==true) || (role==='approver' && hasAdminRole==true)">
                        <label for="permissionFor">Permission For <span class="text-danger">*</span></label>
                        <dx-autocomplete class="form-control" id="permissionFor" name="permissionFor"
                            placeholder="Type Employee Name...." (onSelectionChanged)="selectPermissionFor($event)"
                            [dataSource]="employeeNameList" [value]="permission.permission_for_name"
                            [showClearButton]="true">
                        </dx-autocomplete>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="date">Date <span class="text-danger">*</span></label>
                        <dx-date-box [(ngModel)]="permission.permission_date" name="date" id="date" class="form-control"
                            #date="ngModel" type="date" required displayFormat="dd/MM/yyyy"
                            dateSerializationFormat="yyyy-MM-dd">
                        </dx-date-box>
                        <div *ngIf="date.invalid && (date.dirty || date.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="date.errors.required">
                                Date is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="hours">Total Hours</label>
                        <input class="form-control" readonly [(ngModel)]="permission.no_of_hours" type="number"
                            id="hours" name="hours" #hours="ngModel">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="starttime">From Time <span class="text-danger">*</span></label>
                        <dx-date-box [(ngModel)]="permission.permission_from_time" name="starttime" id="starttime" pickerType="list"
                        [interval]="15"   
                        #starttime="ngModel" required type="time" (onValueChanged)="fromtimeChanged($event)">
                        </dx-date-box>
                        <div *ngIf="starttime.invalid && (starttime.dirty || starttime.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="starttime.errors.required">
                                From Time is Required.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="endtime">To Time <span class="text-danger">*</span></label>
                        <dx-date-box [(ngModel)]="permission.permission_to_time" name="endtime" id="endtime"
                            #endtime="ngModel" required type="time" (onValueChanged)="totimeChanged($event)"  [interval] ="15" pickerType="list"
                            [min]="permission.permission_from_time" [disabled]="!permission.permission_from_time">
                        </dx-date-box>
                        <div *ngIf="endtime.invalid && (endtime.dirty || endtime.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="endtime.errors.required">
                                To Time is Required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <label for="reason">Reason <span class="text-danger">*</span></label>
                        <textarea rows="4" class="form-control" id="reason" name="reason"
                            [(ngModel)]="permission.permission_reason" required #reason></textarea>
                        <div *ngIf="reason.invalid && (reason.dirty || reason.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="reason.errors.required">
                                Reason is required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="savePermission(permissionForm)"
                    [disabled]="!permissionForm.valid">Save</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
    <div *ngIf="(loading$ | async)">
        <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#permission-loading-div' }"
            [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
            container="#permission-loading-div">
        </dx-load-panel>
    </div>
    <div *ngIf="(summaryloading$ | async)">
        <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#permission-summary' }"
            [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
            container="#permission-summary">
        </dx-load-panel>
        <dx-load-panel *ngIf="global.isMobile===false" shadingColor="rgba(0,0,0,0.4)" visible="true"
            [position]="{ of: '#permission-summary-1' }" [showIndicator]="true" [showPane]="false" [shading]="true"
            [closeOnOutsideClick]="false" container="#permission-summary-1">
        </dx-load-panel>
        <dx-load-panel *ngIf="global.isMobile===false" shadingColor="rgba(0,0,0,0.4)" visible="true"
            [position]="{ of: '#permission-summary-2' }" [showIndicator]="true" [showPane]="false" [shading]="true"
            [closeOnOutsideClick]="false" container="#permission-summary-2">
        </dx-load-panel>
        <dx-load-panel *ngIf="global.isMobile===false" shadingColor="rgba(0,0,0,0.4)" visible="true"
            [position]="{ of: '#permission-summary-3' }" [showIndicator]="true" [showPane]="false" [shading]="true"
            [closeOnOutsideClick]="false" container="#permission-summary-3">
        </dx-load-panel>
    </div>