import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {  LoginComponent } from './components/common/login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './components/common/home/home.component';
import { LeaveViewComponent } from './components/attendance/leave-view/leave-view.component';
import { PermissionViewComponent } from './components/attendance/permission-view/permission-view.component';
import { LeaveAdminViewComponent } from './components/attendance/leave-admin-view/leave-admin-view.component';
import { PayslipViewComponent } from './components/payroll/payslip-view/payslip-view.component';
const routes: Routes =
  [
    { path: 'home', component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'survey', component: LoginComponent },
    { path: 'userlogin',  component: LoginComponent },
    { path: 'leave',  component: LeaveViewComponent },
    { path: 'permission',  component: PermissionViewComponent },
    { path: 'leaveadmin',  component: LeaveAdminViewComponent },
    { path: 'payslip',  component: PayslipViewComponent },
    { path: 'permissionadmin',  component: PermissionViewComponent, data: { role: 'approver' }}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgbModule],
  exports: [RouterModule, NgbModule]
})
export class AppRoutingModule { }
