import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { Survey } from "src/app/model/admin";
import { AdminApiService } from "src/app/services/admin-api.service";
import { indicate } from "src/app/services/loading.service";
import { TabService } from "src/app/services/tab.service";
import { Tab } from "src/app/model/tab";
import { SurveyReportComponent } from "../survey-report/survey-report.component";
import { Global } from "src/app/shared/global";

@Component({
  selector: "app-survey-list",
  templateUrl: "./survey-list.component.html",
  styleUrls: ["./survey-list.component.scss"],
})
export class SurveyListComponent implements OnInit, OnDestroy {
  role = "";
  intializeData: any;
  survey: Survey = new Survey();
  surveyList: Survey[] = [];
  surveyloading$ = new Subject<boolean>();
  subscriptions: Subscription[] = [];
  constructor(
    private adminApiService: AdminApiService,
    private tabService: TabService,
    public global: Global
  ) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  ngOnInit(): void {
    this.initialize();
  }
  refresh() {
    this.ngOnInit();
  }
  initialize() {

    if (this.intializeData && this.intializeData.role) {
      this.role = this.intializeData.role;
    }

    if (this.role === "hr-manager") {
      this.subscriptions.push(
        this.adminApiService
          .getSurveyList()
          .pipe(indicate(this.surveyloading$))
          .subscribe((response) => {
            this.surveyList = response.data;
          }));
    }
  }
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${this.global.siteurl}/#/survey?surveyid=${val}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  surveyReport(e: any) {
    this.survey = e.row.data;
    this.tabService.addTab(
      new Tab(
        SurveyReportComponent,
        "Survey Report - " + this.survey.survey_id,
        { surveyId: this.survey.survey_id }
      )
    );
  }
}
