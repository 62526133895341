<div class="content container-fluid ml-2" id="salry-sheet-loading">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="float-left">
                <h4 class="page-title" *ngIf="type=='pf'">PF Sheet For the Month of {{ salaryDate | date : "MMMM, y" }}
                </h4>
                <h4 class="page-title" *ngIf="type=='salary'">Salary Details For the Month of {{ salaryDate | date :
                    "MMMM, y" }}</h4>
                <h4 class="page-title" *ngIf="type=='bank'">Bank Transfer for the Month of {{ salaryDate | date : "MMMM,
                    y" }}</h4>
                <h4 class="page-title" *ngIf="type=='cash'">Cash salary for the Month of {{ salaryDate | date : "MMMM,
                    y" }}</h4>
                <h4 class="page-title" *ngIf="type=='employee'">Employee contact details</h4>
            </div>
            <div class="col-auto float-right ml-auto">
                <div class="btn-group btn-group-sm">
                    <button class="btn btn-white" (click)="print()"><i class="fa fa-print fa-lg"></i> Print</button>
                </div>
            </div>
        </div>
        <div class="row p-3">
            <div class="col-md-12 table-responsive" #printContent>
                <dx-data-grid id="gridSalary" [dataSource]="reportData" wordWrapEnabled='true'>
                    <dxo-export [enabled]="true" fileName="employee_info"></dxo-export>
                    <dxo-paging [enabled]="false"></dxo-paging>
                </dx-data-grid>
            </div>
        </div>
    </div>
</div>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#salry-sheet-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#salry-sheet-loading">
    </dx-load-panel>
</div>