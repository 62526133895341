export class EmployeeLeaveBalance {
    public employee_leave_balance_id: number;
    public employee_id: number;
    public leave_type_id: number = null;
    public leave_type: string;
    public no_of_days_total: number;
    public no_of_days_left: number;
    public no_of_taken: number;
    constructor() {
    }
}
export class Holiday {
    public holiday_id: number;
    public holiday_calendar_id: number = null;
    public holiday_calendar: string;
    public holiday_type_id: number = null;
    public holiday_type: string;
    public holiday_name: string;
    public holiday_date: Date;
    constructor() {
    }
}

export class Leave {
    public employee_leave_id: number;
    public created_by_employee_id: number;
    public profile_picture: string;
    public leave_for_name: string;
    public employee_id: number;
    public employee_code: string;
    public leave_from_date: Date;
    public leave_to_date: Date;
    public leave_type_id: number;
    public leave_status_id: number = null;
    public leave_status: string;
    public leave_reason: string;
    public leave_decline_reason: string;
    public no_of_days: number;
    public half_day: boolean;
    constructor() {
    }
}
export class AttendanceActivity {
    public employee_attendance_activity_id: number;
    public punch_direction: string;
    public tooltipvisible = false;
    public lat: number;
    public long: number;
    public in_punch_time: Date;
    public out_punch_time: Date;
    public attendance_date: Date;
    public attendance_type_id: number;
    public attendance_type: string;
    public photo_path: string;
    public time: Date;
    constructor() {
    }
}
export class EmployeeAttendanceListPost {
    public employee_id: number;
    public present: string[] = [];
    public absent: string[] = [];
    constructor() {
    }
}
export class EmployeeDailyAttendance {
    public employee_id: number;
    public employee_name: string;
    public employee_code: number;
    public lat: number;
    public long: number;
    public report_date: Date;
    public first_punch_in: Date;
    public last_punch_out: Date;
    public total_hours: number;
    public break_hours: number;
    public over_time_hours: number;
    public punch_type: string;
    public punch_description: string;
    public activity: AttendanceActivity[];
    public waypoints: WayPoint[];
    public shift_name: string;
    public shift_start_time: Date;
    public shift_end_time: Date;
    constructor() {
    }
}
export class WayPoint {
    public time: Date;
    public lat: number;
    public lng: number;
    public location_time: number;
    constructor() {
    }
}

export class EmployeeMonthlyAttendance {
    public name: string;
    public total: number;
    public percentage: number;
    public style: string;
    public current: number;
    constructor() {
    }
}
export class MonthlyAttendanceActivity {
    public date: Date;
    public punch_in_time: Date;
    public punch_out_time: Date;
    public total_time: number;
    public break_time: number;
    public over_time: number;
    constructor() {
    }
}
export class EmployeeAttendance {
    public employee_attendance_id: number;
    public punch_direction: number;
    public punch_description: string;
    public punch_activity: string;
    public in_punch_description: string;
    public location_punch_description: string;
    public employee_id: number;
    public employee_name: string;
    public attendance_date: Date;
    public attendance_type_id: number;
    public attendance_type: string;
    public lat: number;
    public long: number;
    public in_punch_time: Date;
    public out_punch_time: Date;
    public punch_time: Date;
    public total_hours: number;
    constructor() {
    }
}
export class EmployeeCurrentDayAttendance {
    public employee_attendance_id: number;
    public punch_date: Date;
    public punch_in_description: string;
    public punch_out_description: string;
    public employee_id: number;
    public punch_in_time: Date;
    public punch_out_time: Date;
    public total_hours: number;
    public punch_direction: number;
    constructor() {
    }
}
export class AttendanceRegularization {
    public regularization_id: number;
    public employee_id: number;
    public employee_name: string;
    public regularization_for_name: string;
    public designation: string;
    public profile_picture: string;
    public regularization_from_time: Date;
    public regularization_to_time: Date;
    public regularization_date: Date;
    public regularization_reason_id: number;
    public regularization_reason: string;
    public regularization_status_id: number;
    public regularization_status: string;
    constructor() {
    }
}
export class PunchReport {
    public employee_id: number;
    public employee_name: string;
    public designation: string;
    public profile_picture: string;
    public shift_name: string;
    public punch_time: Date;
    public punch_status: string;
    public late_duration: number;
    public has_permission: number;
    constructor() {
    }
}
export class AttendanceDashboardReport {
    public number_of_leaves: number;
    public number_of_permissions: number;
    public number_of_lates: number;
    public number_of_present: number;
    constructor() {
    }
}
export class AttendancePermission {
    public permission_id: number;
    public employee_id: number;
    public employee_name: string;
    public designation: string;
    public profile_picture: string;
    public permission_from_time: Date;
    public permission_to_time: Date;
    public permission_date: Date;
    public employee_code: string;
    public permission_duration: Date;
    public permission_reason: string;
    public permission_status_id: number;
    public permission_status: string;
    public no_of_hours: number;
    public permission_for_name: string;
    public in_punch_time: string;
    constructor() {
    }
}
export class AttendanceHistoryReport {
    public month_name: string;
    public month_number: number;
    public noofdays: number;
    public noofhours: number;
    public leave_type: string;
    public leave_id: number;
    constructor() {
    }
}
export class MonthlyAttendanceReport {
    public employee_id: number;
    public full_name: string;
    public regularization_reason: string;
    public shift: string;
    public designation: string;
    public profile_picture: string;
    public attendance_date: Date;
    public in_punch_time: Date;
    public out_punch_time: Date;
    public attendance_type: string;
    public total_hours: number;
    public payable_hours: number;
    public over_time: number;
    constructor() {
    }
}
export class AttendanceActivityReport {
    public employee_id: number;
    public full_name: string;
    public employee_code: string;
    public shift: string;
    public designation: string;
    public department: string;
    public profile_picture: string;
    public no_of_late_punches: number;
    public no_of_missed_punches: number;
    public no_of_permission_hours: number;
    public no_of_leave_days: number;
    public no_of_lops: number;
    constructor() {
    }
}
