import { HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '../services/toast.service';
import { HttpRequestInterceptor } from './http-request-interceptor';
import { environment } from '../../environments/environment';
import { User } from '../model/common';
export let tService: ToastService;
@Injectable()
export class Global {
  getValidators(): any {
    throw new Error('Method not implemented.');
  }
  loading = false;
  isParentCompany = true;
  isAltLogin = false;
  companyLogo: string;
  companyId: number;
  commonApiEndPoint: string;
  attendanceApiEndPoint: string;
  adminApiEndPoint: string;
  payrollApiEndPoint: string;
  siteurl: string;
  isMobile = false;
  isMobileApp = false;
  alphaNumericValidator = '^[a-zA-Z0-9 ]+$';
  alphaNumericPeriodValidator = '^[a-zA-Z0-9. ]+$';
  numericValidator = '^[0-9]+$';
  decimalValidator = /^\d+(\.\d{1,2})?$/;
  phoneValidator = '^[0-9]{10}$';
  emailValidator = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$';
  masterValueValidator = '^[a-zA-Z0-9_]*$';
  dateValidator = '^[0-9]+$';
  maleAvatar = 'assets/images/male-avatar.jpg';
  femaleAvatar = 'assets/images/female-avatar.jpg';
  map_icon_in = 'assets/images/inpunch.png';
  map_icon_out = 'assets/images/outpunch.png';
  map_icon_location = 'assets/images/location.png';
  map_icon_clock = 'assets/images/clock.png';
  map_icon_user = 'assets/images/user.png';
  loggedUser: User;
  printContent: string;
  landingScreen: string;
  surveyId: string;
  constructor(private toastService: ToastService) {
    this.siteurl = window.location.origin;
    this.commonApiEndPoint = `${this.siteurl}/${environment.commonEndpoint}`;
    this.attendanceApiEndPoint = `${this.siteurl}/${environment.attendanceEndpoint}`;
    this.adminApiEndPoint = `${this.siteurl}/${environment.adminEndpoint}`;
    this.payrollApiEndPoint = `${this.siteurl}/${environment.payrollEndpoint}`;
    tService = toastService;
    this.isMobile = this.detectMobile();
  }
  detectMobile() {
    return ((window.innerWidth <= 800) && (window.innerHeight <= 850));
  }
  clear() {
    this.loggedUser = new User();
  }
  loadUserSession(user: string) {
    this.loggedUser = JSON.parse(atob(user)) as User;
    this.loggedUser.token = this.loggedUser.token;
  }
  showError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      msg = 'An error occurred: ' + error.error.message;
    } else if (error.error && error.error.err) {
      msg = 'An error occurred: ' + error.error.err;
    } else if (error.error && error.error.message) {
      msg = 'An error occurred: ' + error.error.message;
    } else if (error.message) {
      msg = error.message;
    } else {
      msg = 'Something bad happened; please try again later.';
    }
    this.toastService.error(msg);
  }
  print(html: string) {
    this.printContent = html;
    setTimeout(window.print, 100);
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }
];
