<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Attendance Regularization</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn" (click)="openModel()"><i class="fa fa-plus"></i>
                    Request Regularization</button>
            </div>
        </div>
    </div>
    <div class="row filter-row">
        <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-12">
            <dx-date-box [(value)]="fromDate" type="date" class="form-control filter-control" placeholder="From Date" (onValueChanged)="fromdateChanged($event)">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-12">
            <dx-date-box [(value)]="toDate" type="date" class="form-control filter-control" placeholder="To Date" [min]="fromDate">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-12">
            <button class="btn btn-secondary btn-block" (click)="searchRegularization()" [disabled]="!fromDate || !toDate"> Search </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-3 table-responsive" id="regulrize-loading-div">
            <dx-data-grid #gridregularization id="gridContainer" [dataSource]="regularizationList"
                [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
                [showBorders]="false" [showColumnLines]="false">
                <dxo-export [enabled]="true" fileName="regularizationReport"></dxo-export>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                <dxi-column dataField="regularization_date" caption="Regularization Date" cellTemplate="dateTemplate">
                </dxi-column>
                <dxi-column dataField="regularization_reason" caption="Reason" dataType="string"></dxi-column>
                <dxi-column dataField="regularization_status" caption="Satus" [allowFiltering]="false"
                    [allowSorting]="false" cellTemplate="statusTemplate" alignment="center">
                </dxi-column>
                <dxi-column dataField="regularization_id" [allowFiltering]="false" width=50 caption=""
                    [allowSorting]="false" cellTemplate="actionTemplate" alignment="center">
                </dxi-column>
                <div *dxTemplate="let data of 'dateTemplate'">
                    {{data.value | date }}
                </div>
                <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Approved'">
                        <i class="fa fa-dot-circle-o text-success"></i> Approved
                    </a>
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Pending'">
                        <i class="fa fa-dot-circle-o text-purple"></i> Pending
                    </a>
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Declined'">
                        <i class="fa fa-dot-circle-o text-danger"></i> Declined
                    </a>
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Cancelled'">
                        <i class="fa fa-dot-circle-o text-grey"></i> Cancelled
                    </a>
                </div>
                <div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
                    <div ngbDropdown container="body" *ngIf="cellInfo.row.data.regularization_status!='Cancelled'">
                        <a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle><i
                                class="material-icons">more_vert</i></a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                            <a *ngIf="cellInfo.row.data.regularization_status=='Pending'" class="dropdown-item"
                                href="javascript:void(0);" (click)="editRegularization(cellInfo)"><i
                                    class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="javascript:void(0);"
                                (click)="cancelRegularization(cellInfo)"><i class="fa fa-trash-o m-r-5"></i> Cancel</a>
                        </div>
                    </div>
                </div>
            </dx-data-grid>
        </div>
    </div>   
    <app-request-regularize #applyRegularization (modalClose)="searchRegularization()"></app-request-regularize>
</div>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#regulrize-loading-div' }" [showIndicator]="true"
        [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#regulrize-loading-div">
    </dx-load-panel>
</div>
