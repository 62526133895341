import { Type } from '@angular/core';
export class Tab {
    public id: number;
    public isNew = true;
    public componentid: number;
    public title: string;
    public tabData: any;
    public active: boolean;
    public component: Type<any>;
    constructor(component: Type<any>, title: string, tabData: any = null, id: any = null) {
        this.component = component;
        this.title = title;
        if (id != null) {
            this.id = id;
        }
        if (tabData != null) {
            this.tabData = tabData;
        }
    }
}
