import {
  Component,
  Input,
  ComponentFactoryResolver,
  ViewChild,
  OnInit
} from '@angular/core';
import { ContentContainerDirective } from 'src/app/directive/content-container.directive';
import { Tab } from 'src/app/model/tab';
@Component({
  selector: 'app-tab-content',
  template: '<ng-template appContentContainer></ng-template>'
})
export class TabContentComponent implements OnInit {
  @Input() tab;
  @ViewChild(ContentContainerDirective, { static: true })
  contentContainer: ContentContainerDirective;
  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    const tab: Tab = this.tab;
    this.tab.isNew = false;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(tab.component);
    const viewContainerRef = this.contentContainer.viewContainerRef;
    const componentRef = viewContainerRef.createComponent(componentFactory);
    if (this.tab.tabData != null) {
      componentRef.instance.intializeData = this.tab.tabData;
    }
  }
}
