<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title" *ngIf="role==='manager'">My Team</h3>
                <h3 class="page-title" *ngIf="role==='hr-manager'">Recruiter Details</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()" ><i class="fa fa-refresh"></i> Refresh</button>
                <button class="btn add-btn" (click)="addRecurver()" ><i class="fa fa-plus"></i> Add </button>
            </div>
        </div>
    </div> 
    <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
            <dx-autocomplete class="form-control filter-control" id="department" name="department" id="first_name"
                placeholder="Type a Name...." (onSelectionChanged)="selectName($event)"
                [dataSource]="recurverNameList" [showClearButton]="true">
            </dx-autocomplete>
        </div>

        <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-12">
            <select class="form-control filter-control"
                [ngClass]="{'loading-control': (locationloading$ | async) === true }" id="location" name="location"
                [(ngModel)]="locationId">
                <option [ngValue]="undefined" selected>All Location</option>
                <option *ngFor="let item of locationList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="designation" name="designation"
                [ngClass]="{'loading-control': (ddloading$ | async) === true }"
                [(ngModel)]="designationId">
                <option [ngValue]="undefined" selected>All Designation</option>
                <option *ngFor="let item of designationList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="department" name="department"
                [ngClass]="{'loading-control': (ddloading$ | async) === true }" 
                [(ngModel)]="departmentId">
                <option [ngValue]="undefined" selected>All Department</option>
                <option *ngFor="let item of departmentList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="status" name="status"
                [ngClass]="{'loading-control': (ddloading$ | async) === true }" 
                [(ngModel)]="statusId">
                <option [ngValue]="undefined" selected>All Status</option>
                <option *ngFor="let item of statusList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-1 col-12">
            <button class="btn btn-secondary btn-block" (click)="searchRecurver()">
                <i class="fa fa-search fa-lg"></i>
            </button>
        </div>
    </div>
 
    <div class="row mt-3">
        <div class="col-sm-12 col-md-12">
            <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="recurverExcelSheet()" class="btn btn-white">
                    <i class="fa fa-file-excel-o fa-lg"></i> Employee Data Download</button>
            </div>
            <!-- <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="recurverSheet()" class="btn btn-white" [disabled]="recurverList.length==0">
                    <i class="fa fa-file-o fa-lg"></i> Employee Info Sheet</button>
            </div> -->
        </div>
    <div class="col-md-12 mt-1 table-responsive" id="emp-grid">
    <dx-data-grid #gridEmployee id="gridContainer" [dataSource]="recurverList" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false">
    <dxo-export [enabled]="true" fileName="recurverReport"></dxo-export>
    <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"[showInfo]="true" 
    [showNavigationButtons]="true"></dxo-pager>
    
    <dxi-column dataField="recurver_name" [allowFiltering]="true" [allowSorting]="false"
        [allowGrouping]="false" cellTemplate="imageTemplate" caption="Name" width="250" cssClass="cls">
    </dxi-column>
    
    <dxi-column dataField="qualification" caption="Qualification" dataType="string"></dxi-column>
    <dxi-column dataField="personal_phone" caption="Phone" dataType="string"></dxi-column>
    <dxi-column dataField="email" caption="Email" dataType="string"></dxi-column>

    <dxi-column dataField="recurver_status" caption="STATUS" [allowFiltering]="false" [allowSorting]="false"
        cellTemplate="statusTemplate" alignment="center" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="recurver_id" [allowFiltering]="false" width="50" caption="" [allowSorting]="false"
        cellTemplate="actionTemplate" alignment="center" [allowGrouping]="false" [allowFiltering]="false">
    </dxi-column>

    <dxo-summary>
        <dxi-total-item column="first_name" summaryType="count" title="Employee Count"
            displayFormat="Employee Count: {0}">
        </dxi-total-item>
    </dxo-summary>
    <dxo-summary>
        <dxi-total-item column="recurver_name" summaryType="count" title="Recurver Count"
            displayFormat="Recurver Count: {0}">
        </dxi-total-item>
    </dxo-summary>
    <div *dxTemplate="let data of 'dateTemplate'">
        {{data.value | date}}
    </div>

    <!-- <div *dxTemplate="let data of 'imageTemplate'">
        <h2 class="table-avatar">
            <ngx-avatar size="40" [src]="data.row.data.personal_info?.profile_picture"
                [name]="data.row.data.first_name + ' ' + (data.row.data.last_name || '')">
            </ngx-avatar>
        </h2>
    </div> -->

    <div *dxTemplate="let data of 'imageTemplate'">
        <h2 class="table-avatar">
            <ngx-avatar size="40" [src]="data.row.data.personal_info?.profile_picture"
                [name]="data.row.data.first_name + ' ' + (data.row.data.last_name==null ? '' : data.row.data.last_name)">
            </ngx-avatar>
            <a class="ml-1 mt-1 gridlink" href="javascript:void(0);">
                {{data.row.data.first_name}}
                {{data.row.data.last_name}}<span>{{data.row.data.designation}} </span></a>
        </h2>
    </div>

    <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
        <a *ngIf="cellInfo.value === 'Yet to Start'" class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
            <i class="fa fa-dot-circle-o text-success"></i> Yet to Start
        </a>
        <a *ngIf="cellInfo.value === 'Scheduled'" class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);" (click)="activeRecurver(cellInfo)">
            <i class="fa fa-dot-circle-o text-muted"></i> Scheduled
        </a>
        <a *ngIf="cellInfo.value === 'Selected'" class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);" (click)="activeRecurver(cellInfo)">
            <i class="fa fa-dot-circle-o text-success"></i> Selected
        </a>
        <a *ngIf="cellInfo.value === 'Rejected'" class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
            <i class="fa fa-dot-circle-o text-danger"></i> Rejected
        </a>
        <a *ngIf="cellInfo.value === 'On Hold'" class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
            <i class="fa fa-dot-circle-o text-warning"></i> On Hold
        </a>
        <a *ngIf="cellInfo.value === 'Offer Released'" class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
            <i class="fa fa-dot-circle-o text-success"></i> Offer Released
        </a>
        <a *ngIf="cellInfo.value === 'Offer Rejected'" class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
            <i class="fa fa-dot-circle-o text-danger"></i> Offer Rejected
        </a>
        <a *ngIf="cellInfo.value === 'Joined'" class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
            <i class="fa fa-dot-circle-o text-success"></i> Joined
        </a>
        <a *ngIf="cellInfo.value === 'Offer Letter'" class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
            <i class="fa fa-dot-circle-o text-success"></i> Offer Letter
        </a>
        
    </div>

    <div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
        <div ngbDropdown container="body">
            <a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle>
                <i class="material-icons">more_vert</i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript:void(0);" (click)="editEmployee(cellInfo)" *ngIf="role === 'hr-manager'">
                    <i class="fa fa-pencil m-r-5"></i> Edit
                </a>
                <a class="dropdown-item"
                                href="javascript:void(0);" (click)="startRecurver(cellInfo)"><i
                                    class="fa fa-hourglass-start m-r-5"></i> Yet to start</a>
                <a class="dropdown-item"
                                href="javascript:void(0);" (click)="scheduledRecurver(cellInfo)"><i
                                    class="fa fa-calendar m-r-5"></i> Scheduled</a>
                <a class="dropdown-item"
                                 href="javascript:void(0);" (click)="selected(cellInfo)"><i
                                        class="fa fa-check-circle-o m-r-5"></i> Selected</a>
                <!-- <a *ngIf="cellInfo.row.data.recurver_status=='Resigned' && role==='hr-manager'" class="dropdown-item"
                href="javascript:void(0);" (click)="activeRecurver(cellInfo)"  ><i
                    class="fa fa-times m-r-5"></i> Cancel Resignation</a> -->
                <a class="dropdown-item" href="javascript:void(0);" (click)="rejected(cellInfo)" *ngIf="role === 'hr-manager'">
                    <i class="fa fa-ban m-r-5"></i> Rejected
                </a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="hold(cellInfo)" *ngIf="role === 'hr-manager'">
                    <i class="fa fa-pause m-r-5"></i> On Hold
                </a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="released(cellInfo)" *ngIf="role === 'hr-manager'">
                    <i class="fa fa-thumbs-up m-r-5"></i> Offer Released
                </a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="offerRejected(cellInfo)" *ngIf="role === 'hr-manager'">
                    <i class="fa fa-thumbs-down m-r-5"></i> Offer Rejected
                </a>
                <a class="dropdown-item" href="javascript:void(0);" (click)="joined(cellInfo)" *ngIf="role === 'hr-manager'">
                    <i class="fa fa-user-plus m-r-5"></i> Joined
                </a>

                <a class="dropdown-item" href="javascript:void(0);" (click)="viewOfferLetter(cellInfo)" *ngIf="role === 'hr-manager'">
                    <i class="fa fa-envelope m-r-5"></i> Offer Letter
                </a>
       
            </div>
        </div>
    </div>
</dx-data-grid>
        </div>
    </div>
</div>

<ng-template #terminatemodalcontent let-c="close" let-d="dismiss">
    <form #offerForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Offer Letter -
                {{recurver.first_name}}&nbsp;{{recurver.last_name}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
         <div class="modal-body modal-scroll">
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="position">Position <span class="text-danger">*</span></label>
                    <!-- <select class="form-control" id="terminationtype" name="terminationtype"
                        [(ngModel)]="recurverTermination.release_sub_type_id" #terminationtype="ngModel"
                        [ngClass]="{'is-invalid': terminationtype.invalid && terminationtype.touched}" required>
                        <option [ngValue]="null" selected>Select Termination Type</option>
                        <option *ngFor="let item of terminationTypeList; let i = index" [ngValue]="item.key">
                            {{item.value}}</option>
                    </select>
                    <div *ngIf="terminationtype.invalid && (terminationtype.dirty || terminationtype.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="terminationtype.errors.required">
                            Termination Type is required.
                        </div>
                    </div> -->
                    <input class="form-control txthight" type="text" [(ngModel)]="recurverTermination.position" name="position" id="position" /> 
                </div>
                <div class="col-sm-6">

                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="joiningdate">Joining Date <span class="text-danger">*</span></label>
                    <dx-date-box [(ngModel)]="recurverTermination.join_date" name="joiningdate"
                        id="joiningdate" #terminationdate="ngModel" required type="date" displayFormat="dd/MM/yyyy"
                        dateSerializationFormat="yyyy-MM-dd">
                    </dx-date-box>
                    <div *ngIf="terminationdate.invalid && (terminationdate.dirty || terminationdate.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="terminationdate.errors.required">
                            Offer Date is required.
                        </div>
                    </div>
                </div>
               
                <div class="col-sm-6"> 
                    <label for="salary">Salary</label> <br>
                    <input class="form-control txthight" type="number" [(ngModel)]="recurverTermination.salary" name="salary" id="salary" /> 
                </div>
            </div>

        
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="responseDeadline">Deadline <span class="text-danger">*</span></label>
                    <dx-date-box [(ngModel)]="recurverTermination.deadline" name="responseDeadline"
                        id="responseDeadline" #terminationdate="ngModel" required type="date" displayFormat="dd/MM/yyyy"
                        dateSerializationFormat="yyyy-MM-dd">
                    </dx-date-box>
                    <div *ngIf="terminationdate.invalid && (terminationdate.dirty || terminationdate.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="terminationdate.errors.required">
                            Offer Deadline Date is required.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6"> 
                    <label for="location">Location</label> <br>
                    <input class="form-control txthight" type="text" [(ngModel)]="recurverTermination.location" name="location" id="location" /> 
                </div>
                
            </div>

        </div> 
         <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="offerRecurver(offerForm)"
                [disabled]="!offerForm.valid">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>

<ng-template #scheduledmodalcontent let-c="close" let-d="dismiss">
    <form #offerForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">InterView Scheduled to -
                {{recurver.first_name}}&nbsp;{{recurver.last_name}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
         <div class="modal-body modal-scroll">
           
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="scheduledate">Interview Date <span class="text-danger">*</span></label>
                    <dx-date-box [(ngModel)]="recurverTermination.interview_date" name="scheduledate"
                        id="scheduledate" #terminationdate="ngModel" required type="date" displayFormat="dd/MM/yyyy"
                        dateSerializationFormat="yyyy-MM-dd">
                    </dx-date-box>
                    <div *ngIf="terminationdate.invalid && (terminationdate.dirty || terminationdate.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="terminationdate.errors.required">
                            Interview Date is required.
                        </div>
                    </div>
                </div>
            </div>

        </div> 
         <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="interviewRecurver(offerForm)"
                [disabled]="!offerForm.valid">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>
