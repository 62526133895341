import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Global } from 'src/app/shared/global';
import { Holiday } from 'src/app/model/attendance';
import { ToastService } from 'src/app/services/toast.service';
import { confirm } from 'devextreme/ui/dialog';
import { indicate } from 'src/app/services/loading.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-holiday-list',
  templateUrl: './holiday-list.component.html',
  styleUrls: ['./holiday-list.component.scss']
})
export class HolidayListComponent implements OnInit {
  isNew = true;
  holidayCalendarList: KeyValue<number, string>[];
  holidayTypeList: KeyValue<number, string>[];
  holidayYearList: KeyValue<number, number>[] = [];
  holiday: Holiday = new Holiday();
  holidayYear: number;
  holidayCalendarId: number;
  holidayList: Holiday[] = [];
  maxDate: Date;
  minDate: Date;
  intializeData: any = null;
  hrRole = true;
  loading$ = new Subject<boolean>();
  gridloading$ = new Subject<boolean>();
  @ViewChild('holidaymodalcontent', { static: true }) holidaymodalcontent: ElementRef;
  // tslint:disable-next-line:max-line-length
  constructor(private toastService: ToastService, private modalService: NgbModal, private commonApiService: CommonApiService, private attendanceApiService: AttendanceApiService, public global: Global) { }

  ngOnInit() {
    const currentDay = new Date();
    this.holidayYear = currentDay.getFullYear();
    if (this.intializeData && this.intializeData.hoidayId) {
      this.holidayCalendarId = this.intializeData.hoidayId;
      this.hrRole = false;
    } else {
      this.minDate = new Date(this.holidayYear - 1, 12, 1);
      this.maxDate = new Date(this.holidayYear, 12, 0);
      this.holidayYearList.push({ key: this.holidayYear - 1, value: this.holidayYear - 1 });
      this.holidayYearList.push({ key: this.holidayYear, value: this.holidayYear });
      this.holidayYearList.push({ key: this.holidayYear + 1, value: this.holidayYear + 1 });
      this.commonApiService
      .getMasterData('holiday_calendar,holiday_type')
        .pipe(indicate(this.loading$))
        .subscribe(apiresponse => {
          this.holidayCalendarList = apiresponse.data.filter((d: { type: string; }) => d.type === 'holiday_calendar');
          this.holidayTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'holiday_type');
          this.holidayCalendarId = this.holidayCalendarList.find(h => h.value === 'Tamilnadu').key;
        });

    }
    this.searchHoliday();
  }
  refresh() {
    this.ngOnInit();
  }
  onYearChange(e) {
    this.minDate = new Date(this.holidayYear - 1, 12, 1);
    this.maxDate = new Date(this.holidayYear, 12, 0);
  }
  openModel(content: any) {
    this.isNew = true;
    this.clearModel();
    this.modalService.open(content, { size: 'lg' });
  }
  saveHoliday(frm: any) {
    if (frm.valid) {
      this.attendanceApiService.postHoliday(this.holiday).subscribe(apiresponse => {
        if (this.isNew) {
          this.toastService.success('Holiday successfully created.');
        } else {
          this.toastService.success('Holiday successfully updated.');
        }
        this.searchHoliday();
        this.clearModel();
        this.modalService.dismissAll();
      });
    }
  }
  editHoliday(e: any) {
    this.isNew = false;
    this.holiday = e.row.data;
    this.modalService.open(this.holidaymodalcontent, { size: 'lg' });
  }
  deleteHoliday(e: any) {
    this.isNew = false;
    this.holiday = e.row.data;
    confirm('Are you sure, you want to delete ' + this.holiday.holiday_name + ' Holiday ?', 'Confirmation ')
      .then((dialogResult: any) => {
        if (dialogResult) {
          this.attendanceApiService.deleteHoliday(this.holiday.holiday_id).subscribe(apiresponse => {
            this.toastService.success('Holiday successfully deleted.');
            this.searchHoliday();
          });
        }
      });
  }
  searchHoliday() {
    this.attendanceApiService.getHoliday(this.holidayYear, this.holidayCalendarId)
      .pipe(indicate(this.gridloading$))
      .subscribe(apiresponse => {
        this.holidayList = apiresponse.data;
      });
  }
  clearModel() {
    this.holiday = new Holiday();
  }
}
