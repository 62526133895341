import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];
  success(textOrTpl: string | TemplateRef<any>) {
    const options: any =  { classname: 'bg-success text-light', delay: 10000 };
    this.toasts.push({ textOrTpl, ...options });
  }
  error(textOrTpl: string | TemplateRef<any>) {
    const options: any =  { classname: 'bg-danger text-light', delay: 15000 };
    this.toasts.push({ textOrTpl, ...options });
  }
  warning(textOrTpl: string | TemplateRef<any>) {
    const options: any =  { classname: 'bg-warning text-light', delay: 15000 };
    this.toasts.push({ textOrTpl, ...options });
  }
  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
