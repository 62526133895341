import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toWords'
})
export class ToWordsPipe implements PipeTransform {
  th: string[] = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
  dg: string[] = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
  tn: string[] = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
  tw: string[] = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

  transform(value: unknown, ...args: unknown[]): unknown {
    let s: string = value.toString();
    s = s.replace(/[\, ]/g, '');
    let x = s.indexOf('.');
    if (x === -1) {
      x = s.length;
    }
    if (x > 15) { return 'too big'; }
    const n: string[] = s.split('');
    let str = '';
    let sk = 0;
    for (let i = 0; i < x; i++) {
      if ((x - i) % 3 === 2) {
        if (Number(n[i]) === 1) {
          str += this.tn[Number(n[i + 1])] + ' ';
          i++;
          sk = 1;
        } else if (Number(n[i]) !== 0) {
          str += this.tw[Number(n[i]) - 2] + ' ';
          sk = 1;
        }
      } else if (Number(n[i]) !== 0) {
        str += this.dg[n[i]] + ' ';
        if ((x - i) % 3 === 0) { str += 'hundred '; }
        sk = 1;
      }
      if ((x - i) % 3 === 1) {
        if (sk) { str += this.th[(x - i - 1) / 3] + ' '; }
        sk = 0;
      }
    }
    if (x !== s.length) {
      const y = s.length;
      str += 'point ';
      for (let i = x + 1; i < y; i++) { str += this.dg[n[i]] + ' '; }
    }
    return str.replace(/\s+/g, ' ');
  }
}
