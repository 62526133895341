import { Component, OnInit } from '@angular/core';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { EmployeeAttendance, EmployeeCurrentDayAttendance } from 'src/app/model/attendance';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { indicate } from 'src/app/services/loading.service';
import { ToastService } from 'src/app/services/toast.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-attendance-punch',
  templateUrl: './attendance-punch.component.html',
  styleUrls: ['./attendance-punch.component.scss']
})
export class AttendancePunchComponent implements OnInit {
  employeeAttendance: EmployeeAttendance = new EmployeeAttendance();
  employeeCurrentDayAttendance: EmployeeCurrentDayAttendance[] = [];
  attendance_date: Date;
  current_date: Date = new Date();
  employee_name: string;
  loading$ = new Subject<boolean>();
  constructor(private attendanceApiService: AttendanceApiService, public global: Global, private toastService: ToastService) { }

  ngOnInit() {
    this.employee_name = this.global.loggedUser.name;
    this.loadEmployeeAttendance();
  }
  isShowActivity() {
    if (this.employeeCurrentDayAttendance.length > 1) {
      return true;
    }
    if (this.employeeCurrentDayAttendance.length === 0) {
      return false;
    }
    if (this.employeeCurrentDayAttendance.length === 1) {
      return this.employeeCurrentDayAttendance[0].punch_out_time;
    }
  }
  loadEmployeeAttendance() {
    this.attendanceApiService.getCurrentDayAttendance(this.global.loggedUser.user_type_internal_id)
      .pipe(indicate(this.loading$))
      .subscribe(apiresponse => {
        let addAttendance = true;
        this.employeeCurrentDayAttendance = apiresponse.data;
        this.employeeAttendance = new EmployeeAttendance();
        this.employeeCurrentDayAttendance.forEach(element => {
          if (!element.punch_out_time && element.punch_direction !== 181) {
            addAttendance = false;
            this.employeeAttendance.attendance_date = element.punch_date;
            this.employeeAttendance.in_punch_time = element.punch_in_time;
            this.employeeAttendance.in_punch_description = element.punch_in_description;
            this.employeeAttendance.total_hours = element.total_hours;
            this.employeeAttendance.punch_direction = element.punch_direction;
            this.employeeAttendance.employee_id = this.global.loggedUser.user_type_internal_id;
          }
        });
        if (addAttendance === true) {
          this.employeeAttendance.attendance_date = this.attendance_date;
          this.employeeAttendance.employee_id = this.global.loggedUser.user_type_internal_id;
          this.employeeAttendance.employee_name = this.employee_name;
        }
        if (this.employeeAttendance.attendance_date) {
          this.attendance_date = this.employeeAttendance.attendance_date;
        } else {
          this.employeeAttendance.attendance_date = this.attendance_date = new Date();
        }
      });
  }
  postAttendance(punchdirection: number) {
    if (punchdirection === 60) {
      this.employeeAttendance.in_punch_time = new Date();
    } else {
      this.employeeAttendance.out_punch_time = new Date();
    }
    this.employeeAttendance.attendance_type_id = 165;
    this.employeeAttendance.punch_direction = punchdirection;
    if (this.employeeAttendance.punch_activity) {
      // tslint:disable-next-line:max-line-length
      this.employeeAttendance.punch_description = `Location: ${this.employeeAttendance.punch_description}; Activity: ${this.employeeAttendance.punch_activity}`;
    }
    this.attendanceApiService.postAtttendance(this.employeeAttendance)
      .subscribe(apiresponse => {
        if (punchdirection === 60) {
          this.toastService.success('Attendance Punch In Successful');
        } else {
          this.toastService.success('Attendance Punch Out Successful');
        }
        this.loadEmployeeAttendance();
      });
  }

  punchIn() {
    if (this.global.loggedUser.attendance_type_id === 186) {
      navigator.geolocation.getCurrentPosition(resp => {
        this.employeeAttendance.lat = resp.coords.latitude;
        this.employeeAttendance.long = resp.coords.longitude;
        this.postAttendance(60);
      },
        err => {
          this.toastService.error(err.message);
        });
    } else {
      this.postAttendance(60);
    }
  }
  punchOut() {
    if (this.global.loggedUser.attendance_type_id === 186) {
      navigator.geolocation.getCurrentPosition(resp => {
        this.employeeAttendance.lat = resp.coords.latitude;
        this.employeeAttendance.long = resp.coords.longitude;
        this.employeeAttendance.attendance_type_id = 165;
        this.postAttendance(61);
      },
        err => {
          this.toastService.error(err.message);
        });
    } else {
      this.postAttendance(61);
    }
  }
  punchLocation() {
    navigator.geolocation.getCurrentPosition(resp => {
      this.employeeAttendance.lat = resp.coords.latitude;
      this.employeeAttendance.long = resp.coords.longitude;
      this.employeeAttendance.attendance_type_id = 165;
      this.employeeAttendance.punch_direction = 181;
      this.employeeAttendance.punch_description = this.employeeAttendance.location_punch_description;
      this.employeeAttendance.punch_time = new Date();
      this.attendanceApiService.postAtttendance(this.employeeAttendance)
        .subscribe(apiresponse => {
          this.toastService.success('Location Punch Successful');
          this.employeeAttendance.punch_description = null;
          this.loadEmployeeAttendance();
        });
    },
      err => {
        this.toastService.error(err.message);
      });

  }
  // formatTotalHours(decimalHours: number): string {
  //   if (decimalHours == null) return '00:00 hrs';

  //   const hours = Math.floor(decimalHours);
  //   const minutes = Math.round((decimalHours - hours) * 60);

  //   // Adjust for cases where minutes are exactly 60
  //   const formattedMinutes = minutes === 60 ? 0 : minutes;
  //   const formattedHours = minutes === 60 ? hours + 1 : hours;

  //   return `${formattedHours}:${formattedMinutes.toString().padStart(2, '0')} hrs`;
  // }
  formatTotalHours(decimalHours: number): string {
    if (decimalHours == null) return '00:00 hrs';
  
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
  
    // Adjust for cases where minutes are exactly 60
    const formattedMinutes = minutes === 60 ? 0 : minutes;
    const formattedHours = minutes === 60 ? hours + 1 : hours;
  
    return `${formattedHours}:${formattedMinutes.toString().padStart(2, '0')} hrs`;
  }
  
}
