<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Dashboard</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
            </div>
        </div>
    </div>
    <div class="row filter-row">
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-4 col-12">
            <select class="form-control filter-control" [ngClass]="{'loading-control': (loading$ | async) === true }"
                id="designation" name="designation" [(ngModel)]="designationId">
                <option [ngValue]="undefined" selected>All Designation</option>
                <option *ngFor="let item of designationList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-4 col-12">
            <select class="form-control filter-control" [ngClass]="{'loading-control': (loading$ | async) === true }"
                id="department" name="department" [(ngModel)]="departmentId">
                <option [ngValue]="undefined" selected>All Department</option>
                <option *ngFor="let item of departmentList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-12">
            <select class="form-control filter-control" [ngClass]="{'loading-control': (locationloading$ | async) === true }"
                id="location" name="location" [(ngModel)]="locationId">
                <option [ngValue]="undefined" selected>All Location</option>
                <option *ngFor="let item of locationList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-1 col-lg-1 col-xl-2 col-12">
            <button class="btn btn-secondary btn-block" (click)="loadDashboard()"> Load </button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-4">
            <div class="stats-info bg-card bg-holder">
                <h6>Employee Count</h6>
                <h4>{{reportData?.summary_report[0]?.employee_count}}</h4>
            </div>
        </div>
        <div class="col-md-4">
            <div class="stats-info bg-card bg-holder">
                <h6>Location Count</h6>
                <h4>{{reportData?.summary_report[0]?.location_count}}</h4>
            </div>
        </div>
        <div class="col-md-4">
            <div class="stats-info bg-card bg-holder">
                <h6>Department Count</h6>
                <h4>{{reportData?.summary_report[0]?.department_count}}</h4>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12 d-flex">
            <div class="card profile-box flex-fill">
                <div class="card-body" id="location-report">
                    <dx-chart *ngIf="reportData" id="location" [dataSource]="reportData.location_report"
                        title="Location wise employee count">
                        <dxi-value-axis name="val" position="left">
                        </dxi-value-axis>
                        <dxi-series argumentField="location" valueField="val" name="Location" type="bar">
                        </dxi-series>
                        <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="locationTooltip">
                        </dxo-tooltip>
                        <dxo-common-series-settings argumentField="location" type="bar" hoverMode="allArgumentPoints"
                            selectionMode="allArgumentPoints" [barPadding]="0.2">
                            <dxo-label [visible]="true" [format]="{ type: 'fixedPoint', precision: '0'}">
                            </dxo-label>
                        </dxo-common-series-settings>
                    </dx-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 d-flex">
            <div class="card profile-box flex-fill">
                <div class="card-body" id="gender-report">
                    <dx-pie-chart *ngIf="reportData" id="gender" type="doughnut" title="Gender wise employee count"
                        palette="Soft Pastel" [dataSource]="reportData.gender_report">
                        <dxi-series argumentField="gender">
                            <dxo-label [visible]="true">
                                <dxo-connector [visible]="true"></dxo-connector>
                            </dxo-label>
                        </dxi-series>
                        <dxo-export [enabled]="true"></dxo-export>
                        <dxo-legend [margin]="0" horizontalAlignment="right" verticalAlignment="top"></dxo-legend>
                    </dx-pie-chart>
                </div>
            </div>
        </div>
        <div class="col-md-6 d-flex">
            <div class="card profile-box flex-fill">
                <div class="card-body" id="age-report">
                    <dx-pie-chart *ngIf="reportData" id="age" type="doughnut" title="Age wise employee count"
                        palette="Soft Pastel" [dataSource]="reportData.age_report">
                        <dxi-series argumentField="age">
                            <dxo-label [visible]="true">
                                <dxo-connector [visible]="true"></dxo-connector>
                            </dxo-label>
                        </dxi-series>
                        <dxo-export [enabled]="true"></dxo-export>
                        <dxo-legend [margin]="0" horizontalAlignment="right" verticalAlignment="top"></dxo-legend>
                    </dx-pie-chart>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex">
            <div class="card profile-box flex-fill">
                <div class="card-body" id="salary-report">
                    <h3 class="card-title"></h3>
                    <div class="row filter-row">
                        <div class="col-md-3 d-flex offset-md-9">
                            <dx-date-box class="form-control filter-control" #dxSalaryDate id="calendar-container"
                                displayFormat="year" [(value)]="salaryReportYear" pickerType="calendar" type="date"
                                (onValueChanged)="saldateChanged($event)">
                            </dx-date-box>
                        </div>
                    </div>
                    <dx-chart *ngIf="reportData" id="salary" [dataSource]="reportData.salary_report"
                        title="Salary Report">
                        <dxi-value-axis name="salary" position="left">
                        </dxi-value-axis>
                        <dxi-value-axis name="count" position="right" [valueMarginsEnabled]="false">
                        </dxi-value-axis>
                        <dxi-series argumentField="month" valueField="salary" name="Salary" type="bar">
                        </dxi-series>
                        <dxi-series type="spline" valueField="employee_count" axis="count" name="Employee Count"
                            color="#6b71c3">
                        </dxi-series>
                        <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltip">
                        </dxo-tooltip>
                        <dxo-common-series-settings argumentField="month" type="bar" hoverMode="allArgumentPoints"
                            selectionMode="allArgumentPoints" [barPadding]="0.2">
                            <dxo-label [visible]="false">
                            </dxo-label>
                        </dxo-common-series-settings>
                    </dx-chart>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="(chartloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#location-report' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#location-report">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#gender-report' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#gender-report">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#age-report' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#age-report">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#salary-report' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#salary-report">
    </dx-load-panel>
</div>