import { Component, Input, OnInit } from '@angular/core';
import { EmployeeDailyAttendance } from 'src/app/model/attendance';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-daily-attendance-activity',
  templateUrl: './daily-attendance-activity.component.html',
  styleUrls: ['./daily-attendance-activity.component.scss']
})
export class DailyAttendanceActivityComponent implements OnInit {
  @Input() ngModel: EmployeeDailyAttendance;
  constructor(public global: Global, public adminApiService: AdminApiService) { }

  ngOnInit(): void {
  }

}
