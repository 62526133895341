import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/model/common';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @Input() user: User = new User();
  isAdmin = false;
  @ViewChild('passwordmodalcontent', { static: true }) passwordmodalcontent: ElementRef;
  // tslint:disable-next-line:max-line-length
  constructor(private modalService: NgbModal, private commonApiService: CommonApiService, private adminApiService: AdminApiService, private toastService: ToastService) {
  }
  ngOnInit(): void { }
  openModal(user: User = null) {
    if (user) {
      this.user = user;
      this.isAdmin = true;
    }
    this.user.password = null;
    this.user.newpassword = null;
    this.user.confirmpassword = null;
    this.modalService.open(this.passwordmodalcontent, { size: 'lg' });
  }
  changePassword(isvalid: boolean) {
    if (isvalid === true) {
      if (!this.isAdmin) {
        this.commonApiService.login(this.user).subscribe(apiresponse => {
          this.adminApiService.resetPassword(this.user).subscribe(apiresponse1 => {
            this.toastService.success('Password reset successful, please login with new credentials.');
            this.modalService.dismissAll();
          });
        });
      } else {
        this.adminApiService.resetPassword(this.user, true).subscribe(apiresponse1 => {
          this.toastService.success(`Password reset successful for ${this.user.user_name}, please ask user to rest the temerory password.`);
          this.modalService.dismissAll();
        });
      }
    }
  }
}
