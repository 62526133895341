<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Manage Attendance</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
            </div>
        </div>
    </div>
    <div class="row filter-row">
        <div class="col-sm-6 col-md-3">
            <dx-autocomplete class="form-control filter-control" id="employeeName" placeholder="Type Employee Name..."
                (onSelectionChanged)="selectName($event)" [dataSource]="employeeNameList" [showClearButton]="true">
            </dx-autocomplete>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="location" name="location" [(ngModel)]="locationId"
                [ngClass]="{'loading-control': (locationloading$ | async) === true }">
                <option [ngValue]="undefined" selected>All Location</option>
                <option *ngFor="let item of locationList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="designation" name="designation" [(ngModel)]="designationId"
                [ngClass]="{'loading-control': (loading$ | async) === true }">
                <option [ngValue]="undefined" selected>All Designation</option>
                <option *ngFor="let item of designationList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="department" name="department" [(ngModel)]="departmentId"
                [ngClass]="{'loading-control': (loading$ | async) === true }">
                <option [ngValue]="undefined" selected>All Department</option>
                <option *ngFor="let item of departmentList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-2">
            <dx-date-box #dxSalaryDate class="form-control filter-control" id="calendar-container"
                displayFormat="monthAndYear" [(value)]="salaryDate" pickerType="calendar" type="date">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-1">
            <button class="btn btn-secondary btn-block" (click)="searchAttendance()">
                <i class="fa fa-search fa-lg"></i>
            </button>
        </div>

    </div>
    <div class="row mt-5">
        <div class="col-md-7">
            <i class="fa fa-check text-success"></i> &nbsp; Present &nbsp;
            <i class="fa fa-check text-magenta"></i> &nbsp; Permission &nbsp;
            <i class="fa fa-close text-warning"></i> &nbsp; Holiday &nbsp;
            <i class="fa fa-close text-purple"></i> &nbsp; Half Leave &nbsp;
            <i class="fa fa-close text-info"></i> &nbsp; Leave &nbsp;
            <i class="fa fa-close text-danger"></i> &nbsp; Absent &nbsp;
            <i class="fa fa-close text-muted"></i> &nbsp; Weekend &nbsp; 
        </div>
        <div class="col-sm-5 col-md-5" *ngIf="hasHrRole==true">
            <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="editAttendanceMode()" class="btn btn-white ml-1" [disabled]="attendanceData.length===0"><i class="fa fa-pencil fa-lg"></i>
                    Edit </button>
                <button (click)="checkAllDays()" class="btn btn-white ml-1" [disabled]="isGridEditMode===false"><i
                        class="fa fa-lg" [ngClass]="{'fa-check-square': checkAll, 'fa-square-o': !checkAll}"></i>
                    Check All</button>
                <button (click)="saveAttendance()" class="btn btn-white ml-1" [disabled]="isGridEditMode===false"><i
                        class="fa fa-floppy-o fa-lg"></i> Save</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 table-responsive mt-1" id="attendance-grid">
            <div style="overflow-x: auto;">
            <dx-data-grid #gridattendance id="gridContainer" [dataSource]="attendanceData" [remoteOperations]="false" [scrolling]="{ mode: 'horizontal' }" [columnAutoWidth]="true"
                [allowColumnReordering]="true" [columns]="columns" [showBorders]="false" [showColumnLines]="false"
                (onExporting)="onExporting($event)">               
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-export [enabled]="true" fileName="employeeAttendanceReport"></dxo-export>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                <div *dxTemplate="let data of 'imageTemplate'">
                    <h2 class="table-avatar">
                        <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                            [name]="data.row.data.employee_name">
                        </ngx-avatar>
                        <a class="ml-1 mt-1"> {{data.row.data.employee_name}}<span>{{data.row.data.designation}}
                            </span></a>
                    </h2>
                </div>
                <dxo-summary>
                    <dxi-total-item column="employee_name" summaryType="count" title="Employee Count"
                        displayFormat="Employee Count: {0}">
                    </dxi-total-item>
                </dxo-summary>
                <dxi-column dataField="employee_code" caption="Code" dataType="string"
                        [allowFiltering]="false">
                    </dxi-column>
                
                <div *dxTemplate="let data of 'dayTemplate'">
                    <!-- Show Present icon -->
                    <a *ngIf="data.value && data.value === 'P'"
                       href="javascript:void(0);" (click)="openModel(data)">
                        <i class="fa fa-check text-success"></i>
                    </a>
                    
                    <!-- Show Permission icon -->
                    <a *ngIf="data.value && data.value === 'Pp'"
                       href="javascript:void(0);">
                        <i class="fa fa-check text-magenta"></i>
                    </a>
                    
                    <!-- Show Holiday icon -->
                    <a *ngIf="data.value && data.value === 'H'"
                       href="javascript:void(0);">
                        <i class="fa fa-close text-warning"></i>
                    </a>
                    
                    <!-- Show Leave icon -->
                    <a *ngIf="data.value && data.value === 'L'"
                       href="javascript:void(0);">
                        <i class="fa fa-close text-info"></i>
                    </a>
                    
                    <!-- Show Half Leave icon -->
                    <a *ngIf="data.value && data.value === 'HL'" href="javascript:void(0);" (click)="openModel(data)">
                        <i class="fa fa-close text-purple"></i>
                    </a>
                    
                    <!-- Show Weekend icon -->
                    <a *ngIf="data.value && data.value === 'W'" href="javascript:void(0);">
                        <i class="fa fa-close text-muted"></i>
                    </a>
                    
                    <!-- Show Absent icon -->
                    <i *ngIf="(!data.value || data.value === 'Ab') && isGridEditMode === false"
                       class="fa fa-close text-danger"></i>
                    
                    <!-- Show Checkbox for editing -->
                    <input *ngIf="isGridEditMode && !['L', 'HL', 'W', 'H', 'P','Pp'].includes(data.value)"
                           type="checkbox" [checked]="data.value === 'P'" (change)="editAttendance(data,$event)" [title]="data.column.dataField"/>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
</div>
<ng-template #attendanceModalcontent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Attendance Info</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <app-daily-attendance-summary [ngModel]="dailyAttendanceData" name="dailyAttendance" ngDefaultControl>
                </app-daily-attendance-summary>
            </div>
            <div class="col-md-6">
                <app-daily-attendance-activity [ngModel]="dailyAttendanceData" name="dailyActivity" ngDefaultControl>
                </app-daily-attendance-activity>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-12">
                <agm-map [zoom]="13" [latitude]="lat" [longitude]="lng" *ngIf="showMap">
                    <agm-marker [latitude]="p.latitude" [longitude]="p.longitude" *ngFor="let p of this.agmMarker"
                        [iconUrl]="p.iconUrl">
                        <agm-info-window [disableAutoPan]="false" #infoWindow>
                            <p [innerHTML]="p.message"></p>
                        </agm-info-window>
                    </agm-marker>
                    <agm-direction *ngIf="waypoints.length>0"  [renderOptions]="renderOptions" [origin]="origin" [destination]="destination" [waypoints]="waypoints"></agm-direction>
                </agm-map>
            </div>
        </div>
    </div>

</ng-template>
<div *ngIf="(gridloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#attendance-grid' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#attendance-grid">
    </dx-load-panel>
</div>