import { KeyValue } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { Employee } from 'src/app/model/admin';
import { CustomField, UserAccess, UserRole } from 'src/app/model/common';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { indicate } from 'src/app/services/loading.service';
import { ToastService } from 'src/app/services/toast.service';
import { Global } from 'src/app/shared/global';
import { switchMap, concatMap } from 'rxjs/operators';
import { CustomFieldsComponent } from '../../common/custom-fields/custom-fields.component';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.scss']
})

export class EditEmployeeComponent implements OnInit {
  employee: Employee = new Employee();
  intializeData: any = null;
  relationshipList: KeyValue<number, string>[];
  bloodGroupList: KeyValue<number, string>[];
  stateList: KeyValue<number, string>[];
  emergencyContactRelationList: KeyValue<number, string>[];
  holidayCalendarList: KeyValue<number, string>[];
  departmentList: KeyValue<number, string>[];
  categoryList: KeyValue<number, string>[];
  employeeTypeList: KeyValue<number, string>[];
  shiftList: KeyValue<number, string>[];
  locationList: KeyValue<number, string>[];
  customFieldList: CustomField[];
  attendanceTypeList: KeyValue<number, string>[];
  leavePolicyList: KeyValue<number, string>[];
  permissionPolicyList: KeyValue<number, string>[];
  earningList: KeyValue<number, string>[];
  prefixList: KeyValue<number, string>[];
  maritalstatusList: KeyValue<number, string>[];
  deductionList: KeyValue<number, string>[];
  salaryList: KeyValue<number, string>[];
  bonusList: KeyValue<number, string>[];
  employeeNames: KeyValue<number, string>[];
  designationList: KeyValue<number, string>[];
  employeeNameList: KeyValue<number, string>[];
  userNameList: string[] = [];
  roleList: KeyValue<number, string>[];
  genderList: KeyValue<number, string>[];
  ctcComponentList: KeyValue<number, string>[];
  togglePermanentAddress = false;
  ddloading$ = new Subject<boolean>();
  locationloading$ = new Subject<boolean>();
  shiftloading$ = new Subject<boolean>();
  usernameloading$ = new Subject<boolean>();
  empcodeloading$ = new Subject<boolean>();
  loading$ = new Subject<boolean>();
  customloading$ = new Subject<boolean>();
  formsubmitted = false;
  enableHrLocation = false;
  @ViewChild('customField', { read: CustomFieldsComponent }) customFieldComponent: CustomFieldsComponent;
  constructor(
    public global: Global,
    public commonApiService: CommonApiService,
    public adminApiService: AdminApiService,
    private toastService: ToastService) { }

  ngOnInit() {
    this.commonApiService.getUtilityData('employee_name_all')
      .pipe(indicate(this.empcodeloading$))
      .pipe(
        switchMap((apiresponse: any) => {
          this.employeeNames = apiresponse.data;
          this.employeeNameList = this.employeeNames.reduce((a, o) => (a.push(o.value), a), []);
          this.loadUserNames();
          if (this.intializeData && this.intializeData.employee && this.intializeData.employee.is_new === false) {
            return forkJoin(
              {
                emp: this.adminApiService.getEmployee(this.intializeData.employee.employee_id),
                empfield: this.commonApiService.getEmployeeCustomFields(this.intializeData.employee.employee_id),
              });
          } else {
            return this.commonApiService.getNextSequenceNumber('employee_code');
          }
        }))
      .pipe(
        concatMap((apiresponse: any) => {
          if (this.intializeData && this.intializeData.employee && this.intializeData.employee.is_new === false) {
            this.employee = apiresponse.emp.data[0];
            this.employee.is_new = false;
            let emp: KeyValue<number, string> = this.employeeNames.find(d => d.key === this.employee.reporting_to_id);
            if (emp) {
              this.employee.reporting_to_name = emp.value;
            }
            emp = this.employeeNames.find(d => d.key === this.employee.referred_by_id);
            if (emp) {
              this.employee.referred_by_name = emp.value;
            }
            try {
              // tslint:disable-next-line:max-line-length
              this.employee.personal_info.address = this.employee.personal_info.address === null ? this.employee.personal_info.address : atob(this.employee.personal_info.address);
              // tslint:disable-next-line:max-line-length
              this.employee.personal_info.permanent_address = this.employee.personal_info.permanent_address === null ? this.employee.personal_info.permanent_address : atob(this.employee.personal_info.permanent_address);
            } catch (e) {
            }
            if (apiresponse.empfield.data.length !== 0) {
              this.customFieldComponent.setCustomFieldList(apiresponse.empfield.data);
            }
          } else {
            this.employee.employee_code = apiresponse.data[0].next_sequence_code;
            this.employee.is_new = true;
          }
          this.enableHrLocation = this.hasHrRole();
          return this.commonApiService.getUserAccess(this.intializeData?.employee?.employee_id);
        }))
      .subscribe((apiresponse: any) => {
        this.employee.access = apiresponse.data;
      });
    // tslint:disable-next-line:max-line-length
    this.commonApiService.getMasterData('permission_policy_type,ctc_policy_type,user_role,gender,bonus_type,salary_type,payroll_deduction_policy_type,payroll_earning_policy_type,leave_policy_type,attendance_type,employee_type,employee_category,department,father_or_husband_relationship,emergency_contact_relation,blood_group,state,holiday_calendar,designation,marital_status,prefix')
      .pipe(indicate(this.ddloading$))
      .subscribe(apiresponse => {
        this.relationshipList = apiresponse.data.filter((d: { type: string; }) => d.type === 'father_or_husband_relationship');
        this.emergencyContactRelationList = apiresponse.data.filter((d: { type: string; }) => d.type === 'emergency_contact_relation');
        this.bloodGroupList = apiresponse.data.filter((d: { type: string; }) => d.type === 'blood_group');
        this.stateList = apiresponse.data.filter((d: { type: string; }) => d.type === 'state');
        this.holidayCalendarList = apiresponse.data.filter((d: { type: string; }) => d.type === 'holiday_calendar');
        this.designationList = apiresponse.data.filter((d: { type: string; }) => d.type === 'designation');
        this.departmentList = apiresponse.data.filter((d: { type: string; }) => d.type === 'department');
        this.categoryList = apiresponse.data.filter((d: { type: string; }) => d.type === 'employee_category');
        this.employeeTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'employee_type');
        this.attendanceTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'attendance_type');
        this.leavePolicyList = apiresponse.data.filter((d: { type: string; }) => d.type === 'leave_policy_type');
        this.earningList = apiresponse.data.filter((d: { type: string; }) => d.type === 'payroll_earning_policy_type');
        this.deductionList = apiresponse.data.filter((d: { type: string; }) => d.type === 'payroll_deduction_policy_type');
        this.salaryList = apiresponse.data.filter((d: { type: string; }) => d.type === 'salary_type');
        this.bonusList = apiresponse.data.filter((d: { type: string; }) => d.type === 'bonus_type');
        this.ctcComponentList = apiresponse.data.filter((d: { type: string; }) => d.type === 'ctc_policy_type');
        this.genderList = apiresponse.data.filter((d: { type: string; }) => d.type === 'gender');
        this.roleList = apiresponse.data.filter((d: { type: string; }) => d.type === 'user_role');
        this.permissionPolicyList = apiresponse.data.filter((d: { type: string; }) => d.type === 'permission_policy_type');
        this.maritalstatusList = apiresponse.data.filter((d: { type: string; }) => d.type === 'permission_policy_type');
        this.prefixList = apiresponse.data.filter((d: { type: string; }) => d.type === 'prefix');
        this.maritalstatusList = apiresponse.data.filter((d: { type: string; }) => d.type === 'marital_status');
      });
    this.commonApiService.getUtilityData('shift')
      .pipe(indicate(this.shiftloading$))
      .subscribe(apiresponse => {
        this.shiftList = apiresponse.data;
      });
    this.commonApiService.getUtilityData('location')
      .pipe(indicate(this.locationloading$))
      .subscribe(apiresponse => {
        this.locationList = apiresponse.data;
      });
    this.commonApiService.getCompanyField()
      .pipe(indicate(this.customloading$))
      .subscribe(apiresponse => {
        this.customFieldList = apiresponse.data;
      });
  }
  saveEmployee(frm: any) {
    if (frm.valid) {
      this.formsubmitted = false;
      const emp: Employee = (JSON.parse(JSON.stringify(this.employee)));
      emp.personal_info.address = this.employee.personal_info.address == null ? null : btoa(this.employee.personal_info.address);
      // tslint:disable-next-line:max-line-length
      emp.personal_info.permanent_address = this.employee.personal_info.permanent_address == null ? null : btoa(this.employee.personal_info.permanent_address);
      emp.custom_fields = this.customFieldComponent.getCustomFieldList();
      this.adminApiService.postEmployee(emp)
        .pipe(indicate(this.loading$))
        .pipe(
          concatMap(apiresponse => {
            if (this.employee.is_new) {
              this.toastService.success('Employee created successfully');
              this.employee.is_new = false;
              this.employee.employee_id = apiresponse.data[0].employee_id;
              this.employee.employee_code = apiresponse.data[0].employee_code;
            } else {
              this.toastService.success('Employee updated successfully');
            }
            this.loadUserNames();
            this.togglePermanentAddress = false;
            return this.commonApiService.getEmployeeCustomFields(this.employee.employee_id);
          })
        ).subscribe(
          apiresponse => {
            if (apiresponse.data.length !== 0) {
              this.customFieldComponent.setCustomFieldList(apiresponse.data);
            }
          }
        );
    } else {
      this.toastService.error('Fix the errors to proceed');
      this.formsubmitted = true;
    }
  }
  clearModel() {
    this.employee = new Employee();
    this.employee.personal_info.gender_id = 21;
    this.employee.personal_info.crime_record = 0;
    this.employee.personal_info.political_activity = 0;
    this.employee.is_new = true;
  }
  loadUserNames() {
    this.commonApiService.getUtilityData('user_name')
      .pipe(indicate(this.usernameloading$))
      .subscribe(apiresponse => {
        if (apiresponse.data) {
          this.userNameList = apiresponse.data.map((a: { key: any; }) => a.key);
        }
      });
  }
  copyPresentAddress() {
    if (this.togglePermanentAddress === false) {
      this.employee.personal_info.permanent_address = this.employee.personal_info.address;
      this.employee.personal_info.permanent_city = this.employee.personal_info.city;
      this.employee.personal_info.permanent_state_id = this.employee.personal_info.state_id;
      this.employee.personal_info.permanent_state = this.employee.personal_info.state;
      this.employee.personal_info.permanent_pincode = this.employee.personal_info.pincode;
    } else {
      this.employee.personal_info.permanent_address = null;
      this.employee.personal_info.permanent_city = null;
      this.employee.personal_info.permanent_state_id = null;
      this.employee.personal_info.permanent_state = null;
      this.employee.personal_info.permanent_pincode = null;
    }
  }
  updateRoles(event) {
    this.employee.restrict_location = null;
    if (this.employee.roles === undefined) {
      this.employee.roles = [];
    }
    if (event.target.checked) {
      if (this.employee.roles.filter(r => r.role_id === parseInt(event.target.name, 10)).length === 0) {
        const r: UserRole = new UserRole();
        r.role_id = parseInt(event.target.name, 10);
        this.employee.roles.push(r);

      }
    } else {
      if (this.employee.roles.filter(r => r.role_id === parseInt(event.target.name, 10)).length > 0) {
        this.employee.roles = this.employee.roles.filter((r) => {
          return r.role_id !== parseInt(event.target.name, 10);
        });
      }
    }
    this.enableHrLocation = this.hasHrRole();
  }
  selectReportingName(e) {
    if (this.employeeNames) {
      const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
      if (emp) {
        this.employee.reporting_to_id = emp.key;
      }
    }
  }
  selectReferredByName(e) {
    if (this.employeeNames) {
      const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
      if (emp) {
        this.employee.referred_by_id = emp.key;
      }
    }
  }
  checkRole(roleId) {
    return (this.employee.roles && (this.employee.roles.filter(r => r.role_id === roleId).length > 0) ? 'checked' : '');
  }
  hasHrRole() {
    return (this.employee.roles.filter(d => d.role_name === 'HR-Manager' || d.role_id === 3).length > 0);    
  }
  checkAccess(moduleId) {
    return (this.employee.access.filter(r => r.module_id === moduleId && r.has_access === 1).length > 0) ? 'checked' : '';
  }
  updateAccess(event) {
    if (this.employee.access === undefined) {
      this.employee.access = [];
    }
    const acc: UserAccess = this.employee.access.filter(a => a.module_id === parseInt(event.target.name, 10))[0];
    if (event.target.checked) {
      acc.has_access = 1;
    } else {
      acc.has_access = 0;
    }
  }
}
