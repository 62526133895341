import { Component, OnInit, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Tab } from 'src/app/model/tab';
import { TabService } from 'src/app/services/tab.service';
import { Global } from 'src/app/shared/global';
import { LeaveViewComponent } from '../../attendance/leave-view/leave-view.component';
import { LeaveAdminViewComponent } from '../../attendance/leave-admin-view/leave-admin-view.component';
import { AttendanceAdminViewComponent } from '../../attendance/attendance-admin-view/attendance-admin-view.component';
import { AttendanceViewComponent } from '../../attendance/attendance-view/attendance-view.component';
import { EmployeeListComponent } from '../../admin/employee-list/employee-list.component';

// import { RecurverList } from '../../admin/recurver-list/recurver-list';

import { HolidayListComponent } from '../../admin/holiday-list/holiday-list.component';
import { EmployeeSalaryComponent } from '../../payroll/employee-salary/employee-salary.component';
import { PayslipViewComponent } from '../../payroll/payslip-view/payslip-view.component';
import { MasterAdminComponent } from '../../admin/master-admin/master-admin.component';
import { PolicyViewComponent } from '../../admin/policy-view/policy-view.component';
import { AssetListComponent } from '../../admin/asset-list/asset-list.component';
import { AssetRequestComponent } from '../../admin/asset-request/asset-request.component';
import { LocationListComponent } from '../../admin/location-list/location-list.component';
import { ShiftListComponent } from '../../admin/shift-list/shift-list.component';
import { AttendancePunchComponent } from '../../attendance/attendance-punch/attendance-punch.component';
import { AttendanceRegularizeAdminComponent } from '../../attendance/attendance-regularize-admin/attendance-regularize-admin.component';
import { AttendanceRegularizeComponent } from '../../attendance/attendance-regularize/attendance-regularize.component';
import { HrDashboardComponent } from '../hr-dashboard/hr-dashboard.component';
import { UserProfileComponent } from '../../common/user-profile/user-profile.component';
import { SendNotificationComponent } from '../send-notification/send-notification.component';
import { Router } from '@angular/router';
import { PermissionViewComponent } from '../../attendance/permission-view/permission-view.component';
import { DailyAttendanceReportComponent } from '../../attendance/daily-attendance-report/daily-attendance-report.component';
import { DateRangeAttendanceReportComponent } from '../../attendance/date-range-attendance-report/date-range-attendance-report.component';
import { PerformanceViewComponent } from '../../admin/performance-view/performance-view.component';
import { SurveyListComponent } from '../../admin/survey-list/survey-list.component';
import { SurveyViewComponent } from '../../admin/survey-view/survey-view.component';
import { RecurverListComponent } from '../../admin/recurver-list/recurver-list.component';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewChecked {
  tabs = new Array<Tab>();
  active = 1;
  selectedTab: number;
  showMenu = true;
  isDashboardCollapsed = false;
  isEmployeeCollapsed = true;
  isPayrollCollapsed = true;
  isAssetCollapsed = true;
  hasHrRole = false;
  hasEmpRole = false;
  hasManagerRole = false;
  collapsed = true;
  constructor(private router: Router, private tabService: TabService, private cd: ChangeDetectorRef, public global: Global) {
    if (!global.loggedUser || global.loggedUser === null) {
      this.router.navigate(['/login']);
    } else {
      this.hasHrRole = global.loggedUser.roles.filter(d => d.role_name === 'HR-Manager').length > 0;
      this.hasEmpRole = global.loggedUser.roles.filter(d => d.role_name === 'Employee').length > 0;
      this.hasManagerRole = global.loggedUser.roles.filter(d => d.role_name === 'Manager').length > 0;
      if (global.landingScreen === 'survey') {
        this.tabService.addTab(new Tab(SurveyViewComponent, 'Survey', { surveyid: global.surveyId }));
      } else if (this.hasEmpRole === true &&
        (
          global.loggedUser.attendance_type_id === 144 ||
          global.loggedUser.attendance_type_id === 186 ||
          global.loggedUser.attendance_type_id === 220
        )) {
        this.tabService.addTab(new Tab(AttendancePunchComponent, 'Manual Attendance'));
      } else if (this.hasHrRole === true && this.global.isMobile === false) {
        this.tabService.addTab(new Tab(HrDashboardComponent, 'Dashboard'));
      } else if (this.global.isMobile === false) {
        this.tabService.addTab(new Tab(UserProfileComponent, this.global.loggedUser.name));
      }
      else {
        this.tabService.addTab(new Tab(LeaveViewComponent, 'Leave'));
      }
    }
  }
  collapseSubmenu(activeMenu) {
    switch (activeMenu) {
      case 'dashboard': {
        this.isAssetCollapsed = true;
        this.isDashboardCollapsed = !this.isDashboardCollapsed;
        this.isEmployeeCollapsed = true;
        this.isPayrollCollapsed = true;
        break;
      }
      case 'employee': {
        this.isAssetCollapsed = true;
        this.isDashboardCollapsed = true;
        this.isEmployeeCollapsed = !this.isEmployeeCollapsed;
        this.isPayrollCollapsed = true;
        break;
      }
      case 'payroll': {
        this.isAssetCollapsed = true;
        this.isDashboardCollapsed = true;
        this.isPayrollCollapsed = !this.isPayrollCollapsed;
        this.isEmployeeCollapsed = true;
        break;
      }
      case 'asset': {
        this.isDashboardCollapsed = true;
        this.isPayrollCollapsed = true;
        this.isEmployeeCollapsed = true;
        this.isAssetCollapsed = !this.isAssetCollapsed;

        break;
      }
      default: {
        break;
      }
    }
  }
  ngOnInit(): void {
    this.tabService.tabSub.subscribe(tabs => {
      this.tabs = tabs;
      this.selectedTab = tabs.findIndex(tab => tab.active);
      this.active = this.tabService.active;
    });
  }

  close(event: MouseEvent, toRemove: number) {
    this.tabService.removeTab(toRemove);
    event.preventDefault();
    event.stopImmediatePropagation();
  }
  addNewTab(menuName) {
    switch (menuName) {
      case 'leave': {
        this.tabService.addTab(new Tab(LeaveViewComponent, 'Leave'));
        break;
      }
      case 'leaveadmin': {
        this.tabService.addTab(new Tab(LeaveAdminViewComponent, 'Manage Leave'));
        break;
      }
      case 'attendanceadmin': {
        this.tabService.addTab(new Tab(AttendanceAdminViewComponent, 'Manage Attendance'));
        break;
      }
      case 'attendance': {
        this.tabService.addTab(new Tab(AttendanceViewComponent, 'Attendance'));
        break;
      }
      case 'employeelist': {
        this.tabService.addTab(new Tab(EmployeeListComponent, 'Employee', { role: 'hr-manager' }));
        break;
      }

      case 'recurverlist': {
        this.tabService.addTab(new Tab(RecurverListComponent, 'Recruiter', { role: 'hr-manager' }));
        break;
      }

      case 'employeelistManager': {
        this.tabService.addTab(new Tab(RecurverListComponent, 'My Team', { role: 'manager' }));
        break;
      }

      case 'employeelistManager': {
        this.tabService.addTab(new Tab(EmployeeListComponent, 'My Team', { role: 'manager' }));
        break;
      }
      case 'holiday': {
        this.tabService.addTab(new Tab(HolidayListComponent, 'Holiday'));
        break;
      }
      case 'holidaylist': {
        this.tabService.addTab(new Tab(HolidayListComponent, 'Holiday List', { hoidayId: this.global.loggedUser.holiday_calendar_id }));
        break;
      }
      case 'employeesalary': {
        this.tabService.addTab(new Tab(EmployeeSalaryComponent, 'Employee Salary'));
        break;
      }
      case 'payslip': {
        this.tabService.addTab(new Tab(PayslipViewComponent, 'Payslip'));
        break;
      }
      case 'master': {
        this.tabService.addTab(new Tab(MasterAdminComponent, 'Master Admin'));
        break;
      }
      case 'policy': {
        this.tabService.addTab(new Tab(PolicyViewComponent, 'Policy'));
        break;
      }
      case 'assetlist': {
        this.tabService.addTab(new Tab(AssetListComponent, 'Asset'));
        break;
      }
      case 'assetrequest': {
        this.tabService.addTab(new Tab(AssetRequestComponent, 'Asset Request'));
        break;
      }
      case 'approveassetrequest': {
        this.tabService.addTab(new Tab(AssetRequestComponent, 'Manage Asset Request', { role: 'approver' }));
        break;
      }
      case 'location': {
        this.tabService.addTab(new Tab(LocationListComponent, 'Locations'));
        break;
      }
      case 'shift': {
        this.tabService.addTab(new Tab(ShiftListComponent, 'Shifts'));
        break;
      }
      case 'manualattendance': {
        this.tabService.addTab(new Tab(AttendancePunchComponent, 'Manual Attendance'));
        break;
      }
      case 'regularizeadmin': {
        this.tabService.addTab(new Tab(AttendanceRegularizeAdminComponent, 'Manage Regularization'));
        break;
      }
      case 'regularize': {
        this.tabService.addTab(new Tab(AttendanceRegularizeComponent, 'Request Regularization'));
        break;
      }
      case 'permissionadmin': {
        this.tabService.addTab(new Tab(PermissionViewComponent, 'Manage Permission', { role: 'approver' }));
        break;
      }
      case 'permission': {
        this.tabService.addTab(new Tab(PermissionViewComponent, 'Request Permission'));
        break;
      }
      case 'hrdashboard': {
        this.tabService.addTab(new Tab(HrDashboardComponent, 'Dashboard'));
        break;
      }
      case 'sendnotification': {
        this.tabService.addTab(new Tab(SendNotificationComponent, 'Send Notification'));
        break;
      }
      case 'dailyattendancereport': {
        this.tabService.addTab(new Tab(DailyAttendanceReportComponent, 'Daily Attendance Report'));
        break;
      }
      case 'attendancereport': {
        this.tabService.addTab(new Tab(DateRangeAttendanceReportComponent, 'Attendance Report'));
        break;
      }
      case 'performanceView': {
        this.tabService.addTab(new Tab(PerformanceViewComponent, `Performance`, { role: 'employee' }));
        break;
      }
      case 'surveyView': {
        this.tabService.addTab(new Tab(SurveyListComponent, `Survey List`, { role: 'hr-manager' }));
        break;
      }
      default: {
        break;
      }
    }
  }
  hasAccess(module: string) {
    return this.global.loggedUser.access.filter(a => a.module_name === module).length > 0;
  }
  ngAfterViewChecked() {
    this.cd.detectChanges();
  }
}
