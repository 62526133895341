import { KeyValue } from '@angular/common';

export class EmployeeSalary {
    employee_salary_id: number;
    employee_id: number;
    employee_name: string;
    remarks: string;
    employee_code: string;
    employee_status: string;
    employee_status_id: number;
    last_date: Date;
    date_of_joining: Date;
    designation_id: number = null;
    designation: string;
    department_id: number = null;
    no_of_days_worked: number;
    pay_per_day: number;
    pay_month: number;
    pay_year: number;
    pf_number:string;
    department: string;
    manager_name: string;
    gross_salary: number;
    lop_days: number;
    net_salary: number;
    total_deduction: number;
    earning_components_new: SalaryComponent[] = [];
    deduction_components_new: SalaryComponent[] = [];
    has_salary: boolean;
    salary_components: SalaryComponent[];
    earning_components: SalaryComponent[];
    deduction_components: SalaryComponent[];
    employee_salary_status_id: number = null;
    employee_salary_status: string;
    uan_number: string;
    esic_no: string;
    total_salary: number;
    account_number: number;
    location_name: string;
    constructor() {
    }
}
export class SalaryComponent {
    employee_id: number;
    employee_salary_component_id: number = null;
    index: number;
    salary_component_type_id: number = null;
    salary_component_type: string;
    salary_component_sub_type_id: number = null;
    salary_component_sub_type: string;
    salary_component_value: number;
    salary_component_annual_value: number;
    salaryComponentList: KeyValue<number, string>[] = [];
    constructor() {
    }
}
