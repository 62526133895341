<!-- Sidebar -->
<div class="sidebar" id="sidebar" [ngClass]="{'d-none': showMenu === false}">
    <perfect-scrollbar>
        <div class="sidebar-inner scrollbar">
            <div id="sidebar-menu" class="sidebar-menu">
                <ul class="navbar-nav">
                    <li class="menu-title">
                        <span>Employee</span>
                    </li>

                    <!-- <li *ngIf="hasAccess('Admin')"><a href="javascript:void(0)"
                        (click)="addNewTab('recurverlist')"> <i class="fa fa-group"></i> <span>
                            Recruiter Type </span></a>
                    </li> -->
                    

                    <li *ngIf="hasAccess('Admin')"><a href="javascript:void(0)"
                            (click)="addNewTab('employeelist')"> <i class="fa fa-user"></i> <span>
                                All Employees </span></a>
                    </li>
                    <li *ngIf="hasManagerRole===true && hasHrRole===false"><a href="javascript:void(0)"
                        (click)="addNewTab('employeelistManager')"> <i class="fa fa-users"></i> <span>
                            My Team </span></a>
                    </li>
                    <li class="submenu" *ngIf="hasAccess('Leave & Attendance')">
                        <a href="javascript:void(0)" (click)="collapseSubmenu('employee')"
                            [ngClass]="{'subdrop': !isEmployeeCollapsed}" [attr.aria-expanded]="!isEmployeeCollapsed"
                            aria-controls="employeeCollapse">
                            <i class="fa fa-calendar"></i>
                            <span> Leave & Attendance</span> <span class="menu-arrow"></span>
                        </a>
                        <ul id="employeeCollapse" [ngbCollapse]="isEmployeeCollapsed">
                            <li *ngIf="(hasHrRole===true || hasManagerRole===true) && hasAccess('Leave & Attendance')">
                                <a href="javascript:void(0)" (click)="addNewTab('leaveadmin')">Leaves (Admin) <span
                                        *ngIf="global.loggedUser.pending_leave!=null"
                                        class="badge badge-pill bg-primary float-right">{{global.loggedUser.pending_leave}}</span></a>
                            </li>
                            <li *ngIf="hasEmpRole===true && hasAccess('Leave & Attendance')"><a
                                    href="javascript:void(0)" (click)="addNewTab('leave')">Leaves</a></li>
                            <li *ngIf="(hasHrRole===true || hasManagerRole===true) && hasAccess('Leave & Attendance')"><a href="javascript:void(0)"
                                    (click)="addNewTab('attendanceadmin')">Attendance (Admin)</a>
                            </li>
                            <li *ngIf="hasEmpRole===true && hasAccess('Leave & Attendance')"><a
                                    href="javascript:void(0)" (click)="addNewTab('attendance')">Attendance</a>
                            </li>
                            <li *ngIf="(hasHrRole===true || hasManagerRole===true) && hasAccess('Leave & Attendance')">
                                <a href="javascript:void(0)" (click)="addNewTab('permissionadmin')">Permission
                                    (Admin)</a>
                            </li>
                            <li *ngIf="hasEmpRole===true && hasAccess('Leave & Attendance')"><a
                                    href="javascript:void(0)" (click)="addNewTab('permission')">Permission</a>
                            </li>
                            <li *ngIf="(hasHrRole===true || hasManagerRole===true) && hasAccess('Leave & Attendance')">
                                <a href="javascript:void(0)" (click)="addNewTab('regularizeadmin')">Regularize
                                    (Admin)<span *ngIf="global.loggedUser.pending_regularization!=null"
                                        class="badge badge-pill bg-primary float-right">{{global.loggedUser.pending_regularization}}</span></a>
                            </li>
                            <li *ngIf="hasEmpRole===true && hasAccess('Leave & Attendance')"><a
                                    href="javascript:void(0)" (click)="addNewTab('regularize')">Regularize</a>
                            </li>
                            <li
                                *ngIf="hasEmpRole===true && (global.loggedUser.attendance_type_id==144 || global.loggedUser.attendance_type_id==186 || global.loggedUser.attendance_type_id==220) && hasAccess('Leave & Attendance')">
                                <a href="javascript:void(0)" (click)="addNewTab('manualattendance')">Manual
                                    Attendance</a>
                            </li>
                            <li *ngIf="hasEmpRole===true && hasAccess('Leave & Attendance')"><a
                                    href="javascript:void(0)" (click)="addNewTab('holidaylist')">
                                    Holiday List</a></li>
                        </ul>
                    </li>
                    <li class="submenu" *ngIf="hasAccess('Asset')">
                        <a href="javascript:void(0)" (click)="collapseSubmenu('asset')"
                            [ngClass]="{'subdrop': !isAssetCollapsed}" [attr.aria-expanded]="!isAssetCollapsed"
                            aria-controls="isAssetCollapsed">
                            <i class="fa fa-pencil-square-o"></i>
                            <span> Asset Requests</span> <span class="menu-arrow"></span>
                        </a>
                        <ul id="assetCollapse" [ngbCollapse]="isAssetCollapsed">
                            <li *ngIf="hasEmpRole===true && hasAccess('Asset')"><a href="javascript:void(0)"
                                    (click)="addNewTab('assetrequest')"> Asset Request </a>
                            </li>
                            <li *ngIf="(hasHrRole===true || hasManagerRole===true) && hasAccess('Asset')"><a
                                    href="javascript:void(0)" (click)="addNewTab('approveassetrequest')"> Asset Request
                                    (Admin)
                                </a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="addNewTab('performanceView')"
                            *ngIf="hasEmpRole===true ">
                            <i class="fa fa-trophy"></i> <span>
                                Performance</span> </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" (click)="addNewTab('payslip')"
                            *ngIf="hasEmpRole===true && hasAccess('Payroll')">
                            <i class="fa fa-inr"></i> <span>
                                Payslip</span> </a>
                    </li>
                    <li class="menu-title" *ngIf="hasHrRole===true">
                        <span>HR</span>
                    </li>
                    <li *ngIf="hasHrRole===true"><a href="javascript:void(0)" (click)="addNewTab('hrdashboard')"> <i
                                class="fa fa-tachometer"></i> <span>
                                Dashboard </span></a>
                    </li>
                    <li *ngIf="hasHrRole===true && hasAccess('Payroll')"><a href="javascript:void(0)"
                            (click)="addNewTab('employeesalary')"> <i class="fa fa-money"></i> <span>
                                Payroll </span></a>
                    </li>
                    <li *ngIf="hasHrRole===true && hasAccess('Admin')"><a href="javascript:void(0)"
                        (click)="addNewTab('surveyView')"> <i class="fa fa-bar-chart"></i> <span>
                            Survey</span></a>
                    </li>
                    <li *ngIf="(hasManagerRole===true || hasHrRole===true) && hasAccess('Leave & Attendance')">
                        <a href="javascript:void(0)" (click)="addNewTab('dailyattendancereport')"><i
                                class="fa fa-th-list"></i> <span>Daily Attendance Report</span>
                            </a>
                    </li>
                    <li *ngIf="(hasManagerRole===true || hasHrRole===true) && hasAccess('Leave & Attendance')">
                        <a href="javascript:void(0)" (click)="addNewTab('attendancereport')"><i
                                class="fa fa-table"></i> <span>Attendance Report</span>
                            </a>
                    </li>
                    <li class="menu-title"
                        *ngIf="global.isParentCompany===true && hasAccess('Admin') && (hasManagerRole===false || hasHrRole===true)">
                        <span>Administration</span>
                    </li>
                    <li *ngIf="(global.isParentCompany===true) && hasAccess('Admin') && (hasManagerRole===false || hasHrRole===true)"><a
                            href="javascript:void(0)" (click)="addNewTab('sendnotification')">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i> <span>
                                Notification </span></a>
                    </li>
                    <li *ngIf="global.isParentCompany===true && hasAccess('Admin') && (hasManagerRole===false || hasHrRole===true)"><a
                            href="javascript:void(0)" (click)="addNewTab('assetlist')">
                            <i class="fa fa-object-ungroup"></i> <span>Assets </span>
                        </a></li>
                    <li *ngIf="global.isParentCompany===true && hasAccess('Admin') && (hasManagerRole===false || hasHrRole===true)"><a
                            href="javascript:void(0)" (click)="addNewTab('holiday')">
                            <i class="fa fa-briefcase"></i> <span>Holidays </span>
                        </a></li>
                    <li *ngIf="global.isParentCompany===true && hasAccess('Admin') && (hasManagerRole===false || hasHrRole===true)"><a
                            href="javascript:void(0)" (click)="addNewTab('location')"> <i class="fa fa-map-marker"></i>
                            <span>Location </span></a>
                    <li *ngIf="global.isParentCompany===true && hasAccess('Admin') && (hasManagerRole===false || hasHrRole===true)"><a
                            href="javascript:void(0)" (click)="addNewTab('shift')"> <i class="fa fa-clock-o"></i>
                            <span>Shift </span></a>
                    <li *ngIf="global.isParentCompany===true && hasAccess('Admin') && (hasManagerRole===false || hasHrRole===true)"><a
                            href="javascript:void(0)" (click)="addNewTab('master')"> <i class="fa fa-gear"></i>
                            <span>Master
                                Admin </span></a>
                    <li *ngIf="global.isParentCompany===true && hasAccess('Admin') && (hasManagerRole===false || hasHrRole===true)"><a
                            href="javascript:void(0)" (click)="addNewTab('policy')"> <i class="fa fa-file"></i> <span>
                                Policies </span></a>
                    </li>
                    <li class="collapse-menu">
                        <a (click)="showMenu = !showMenu" class="cursor-pointer">
                            <div>
                                <span id="collapse-icon" class="fa fa-2x mr-3 fa-angle-double-left"></span>
                                <span id="collapse-text" class="menu-collapsed">Hide</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </perfect-scrollbar>
</div>
<!-- /Sidebar -->
<!-- Page Wrapper -->
<div [ngClass]="{'page-wrapper-full':  showMenu === false, 'page-wrapper': showMenu === true, 'mobile-background':  global.isMobileApp }">
    <!-- Page Content -->
    <div class="content container-fluid">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active"
            [ngClass]="{'nav nav-tabs nav-tabs-bottom fixed-menu': global.isMobile===false}" [destroyOnHide]="false">
            <li [ngClass]="{'d-none': showMenu === true , 'menu-bg ml-m-20':showMenu === false }">
                <a (click)="showMenu = !showMenu" class="cursor-pointer">
                    <div class="pt-1">
                        <span id="collapse-icon" class="fa fa-2x pl-2 mr-3 fa-angle-double-right"></span>
                    </div>
                </a>
            </li>
            <li *ngFor="let tab of tabs" [ngbNavItem]="tab.id" class="nav-item">
                <a ngbNavLink class="nav-link" *ngIf="global.isMobile===false">
                    {{ tab.title }}
                    <span class="fa fa-close fa-fw pl-2" (click)="close($event, tab.id)"></span>
                </a>
                <ng-template ngbNavContent>
                    <app-tab-content [tab]="tab" [ngClass]="{'no-margin': global.isMobile===true}"></app-tab-content>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-5"></div>
    </div>
</div>
<!--https://www.codeply.com/go/3e0RAjccRO/bootstrap-4-collapsing-sidebar-menu-->