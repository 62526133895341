<div class="form-group row">
    <div *ngFor="let field of customFields; let i = index" [ngClass]="(field.rating_report && reportMode)?'col-md-3':'col-md-6 mt-3'">
        <div *ngIf="field.rating_report && reportMode" class="margin-min-60">
            <dx-circular-gauge id="rating_custom_{{field.company_custom_field_id}}_{{i}}"
                [value]="field.rating_report.val">
                <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>
                <dxo-value-indicator [color]="field.rating_report.color"></dxo-value-indicator>
                <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">                    
                </dxo-scale>
                <dxo-range-container>
                    <dxi-range [startValue]="0" [endValue]="50" color="#FF7200"></dxi-range>
                    <dxi-range [startValue]="50" [endValue]="75" color="#b7f7dd"></dxi-range>
                    <dxi-range [startValue]="75" [endValue]="90" color="#76f2c0"></dxi-range>
                    <dxi-range [startValue]="90" [endValue]="100" color="#00A884"></dxi-range>
                </dxo-range-container>
            </dx-circular-gauge>
            <div class="chart-title margin-min-80">{{field.field_name}}<br>
                {{field.rating_report.rating}}</div>
        </div>
        <div *ngIf="!reportMode && field.field_source!='survey_rating_type'">
            <div *ngIf="field.field_type==='integer'">
                <div *ngIf="readOnly;else edit_content">
                    {{field.field_name}}:
                    <span class="read-only-text">{{(field?.field_value===null || field?.field_value===undefined ||
                        field?.field_value==='')?'NA':field?.field_value}}</span>
                </div>
                <ng-template #edit_content>
                    <label for="{{field.field_name}}">{{field.field_name}}</label>
                    <input class="form-control" [(ngModel)]="field.field_value" type="text" id="field_name_{{i}}"
                        name="field.field_name_{{i}}" #fld="ngModel" [pattern]="global.numericValidator">
                    <div *ngIf="fld.invalid && (fld.dirty || fld.touched)" class="badge badge-danger position-absolute">
                        <div *ngIf="fld.errors.pattern">
                            Invalid values.
                        </div>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="field.field_type==='string'">
                <div *ngIf="readOnly;else edit_content">
                    {{field.field_name}}:
                    <span class="read-only-text">{{(field?.field_value===null || field?.field_value===undefined ||
                        field?.field_value==='')?'NA':field?.field_value}}</span>
                </div>
                <ng-template #edit_content>
                    <label for="{{field.field_name}}_{{i}}">{{field.field_name}}</label>
                    <input class="form-control" [(ngModel)]="field.field_value" type="text" id="field_name_{{i}}"
                        name="field.field_name_{{i}}" #fld="ngModel" [pattern]="global.alphaNumericValidator">
                    <div *ngIf="fld.invalid && (fld.dirty || fld.touched)" class="badge badge-danger position-absolute">
                        <div *ngIf="fld.errors.pattern">
                            Invalid value.
                        </div>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="field.field_type==='decimal'">
                <div *ngIf="readOnly;else edit_content">
                    {{field.field_name}}:
                    <span class="read-only-text">{{(field?.field_value===null || field?.field_value===undefined ||
                        field?.field_value==='')?'NA':field?.field_value}}</span>
                </div>
                <ng-template #edit_content>
                    <label for="{{field.field_name}}_{{i}}">{{field.field_name}}</label>
                    <input class="form-control" [(ngModel)]="field.field_value" type="text" id="field_name_{{i}}"
                        name="field.field_name_{{i}}" #fld="ngModel" [pattern]="global.decimalValidator">
                    <div *ngIf="fld.invalid && (fld.dirty || fld.touched)" class="badge badge-danger position-absolute">
                        <div *ngIf="fld.errors.pattern">
                            Invalid value.
                        </div>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="field.field_type==='date'">
                <div *ngIf="readOnly;else edit_content">
                    {{field.field_name}}:
                    <span class="read-only-text">{{(field?.field_value===null || field?.field_value===undefined ||
                        field?.field_value==='')?'NA':field?.field_value | date : "dd-MM-yyyy"}}</span>
                </div>
                <ng-template #edit_content>
                    <label for="{{field.field_name}}_{{i}}">{{field.field_name}}</label>
                    <dx-date-box [(ngModel)]="field.field_value" id="field_name_{{i}}" name="field.field_name_{{i}}"
                        #fld="ngModel" required type="date" displayFormat="dd/MM/yyyy"
                        dateSerializationFormat="yyyy-MM-dd">
                    </dx-date-box>
                </ng-template>
            </div>
            <div *ngIf="field.field_type==='list'">

                <div *ngIf="readOnly;else edit_content">
                    {{field.field_name}}:
                    <span class="read-only-text">
                        {{(field?.field_value===null || field?.field_value===undefined ||
                        field?.field_value==='')?'NA':fieldDisplayName(field?.field_value)}}
                    </span>
                </div>
                <ng-template #edit_content>
                    <label for="{{field.field_name}}_{{i}}">{{field.field_name}}</label>
                    <select class="form-control " id="field_name_{{i}}" name="field.field_name_{{i}}"
                        [(ngModel)]="field.field_value"
                        [ngClass]="{'loading-control': (listloading$ | async) === true}">
                        <option [ngValue]="undefined" selected>Select</option>
                        <option *ngFor="let item of selectList | filter : field.field_source : 'type'; let i = index"
                            [ngValue]="item.key">
                            {{item.value}}</option>
                    </select>
                </ng-template>
            </div>
        </div>
        <div *ngIf="!reportMode && field.field_source==='survey_rating_type'">
            <br/>
            <label for="{{field.field_name}}_{{i}}">{{field.field_name}}</label>
            <br/>
            <ngb-rating [(rate)]="field.field_rating_value" (rateChange)="setFieldValue(field)" max="5"></ngb-rating>
        </div>
    </div>
</div>
<div *ngFor="let g of groups; let i = index">
    <div class="form-group mt-5 row">
        <h6 class="text-muted">{{g.field_name}}</h6>
    </div>
    <div class="form-group row table-responsive">
        <dx-data-grid #gridCustom id="grid_custom_{{i}}" [dataSource]="g.dataSource" [remoteOperations]="false"
            [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
            [showColumnLines]="false" (onRowInserted)="customInserted($event)" (onRowUpdated)="customUpdated($event)"
            (onRowRemoved)="customRemoved($event)" [columns]="g.columns">
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-editing *ngIf="!readOnly" mode="form" [allowUpdating]="true" [allowDeleting]="true"
                [allowAdding]="true">
            </dxo-editing>
            <dxo-paging [enabled]="false"></dxo-paging>
            <div *dxTemplate="let data of 'dateTemplate'">
                {{data.value | date: 'dd-MMM-yy'}}
            </div>
        </dx-data-grid>
    </div>
</div>