import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PayslipViewComponent } from '../payslip-view/payslip-view.component';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Global } from 'src/app/shared/global';
import { EmployeeSalary, SalaryComponent } from 'src/app/model/payroll';
import { Tab } from 'src/app/model/tab';
import { PayrollApiService } from 'src/app/services/payroll-api.service';
import { TabService } from 'src/app/services/tab.service';
import { ToastService } from 'src/app/services/toast.service';
import { DatePipe } from '@angular/common';
import { SalarySheetComponent } from '../salary-sheet/salary-sheet.component';
import { DxDateBoxComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { concatMap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';
@Component({
  selector: 'app-employee-salary',
  templateUrl: './employee-salary.component.html',
  styleUrls: ['./employee-salary.component.scss']
})
export class EmployeeSalaryComponent implements OnInit, OnDestroy {

  designationId: number;
  departmentId: number;
  statusId: number;
  employeeId: number;
  allEmployee = false;
  employeeNameList: KeyValue<number, string>[];
  designationList: KeyValue<number, string>[];
  departmentList: KeyValue<number, string>[];
  salaryData: EmployeeSalary[] = [];
  employeeSalary: EmployeeSalary = new EmployeeSalary();
  salaryDate: Date;
  earningTypeList: KeyValue<number, string>[];
  deductionTypeList: KeyValue<number, string>[];
  salaryComponentList: KeyValue<number, string>[];
  salaryStatusList: KeyValue<number, string>[];
  loading$ = new Subject<boolean>();
  gridloading$ = new Subject<boolean>();
  locationloading$ = new Subject<boolean>();
  locationList: KeyValue<number, string>[];
  locationId: number;
  subscriptions: Subscription[] = [];
  @ViewChild('salarymodalcontent', { static: true }) salarymodalcontent: ElementRef;
  @ViewChild('salaryremarkmodalcontent', { static: true }) salaryremarkmodalcontent: ElementRef;
  @ViewChild('dxSalaryDate', { static: true }) dxSalaryDate: DxDateBoxComponent;
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  // tslint:disable-next-line:max-line-length
  constructor(private datePipe: DatePipe, private tabService: TabService, private toastService: ToastService, private payrollApiService: PayrollApiService, private commonApiService: CommonApiService, public global: Global, private modalService: NgbModal) {
    this.salaryDate = new Date();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  ngOnInit() {
    this.dxSalaryDate.calendarOptions = { zoomLevel: 'year', maxZoomLevel: 'year', minZoomLevel: 'decade' };
    this.subscriptions.push(
      this.commonApiService
        .getUtilityData('employee_name', this.global.isAltLogin)
        .pipe(indicate(this.loading$))
        .subscribe(apiresponse => {
          const employeeNames = apiresponse.data;
          this.employeeNameList = employeeNames.map((a: { value: any; }) => a.value);
        }));
    // tslint:disable-next-line:max-line-length
    this.subscriptions.push(
      this.commonApiService.getMasterData('designation,department,salary_status,payroll_earnings,payroll_deduction,salary_component_type')
        .pipe(indicate(this.loading$))
        .subscribe(apiresponse => {
          this.salaryStatusList = apiresponse.data.filter((d: { type: string; }) => d.type === 'salary_status');
          this.designationList = apiresponse.data.filter((d: { type: string; }) => d.type === 'designation');
          this.departmentList = apiresponse.data.filter((d: { type: string; }) => d.type === 'department');
          this.earningTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'payroll_earnings');
          this.deductionTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'payroll_deduction');
          this.salaryComponentList = apiresponse.data.filter((d: { type: string; }) => d.type === 'salary_component_type');
        }));
    this.subscriptions.push(
      this.commonApiService.getUtilityData('location')
        .pipe(indicate(this.locationloading$))
        .subscribe(apiresponse => {
          this.locationList = apiresponse.data;
        }));
  }
  searchAllSalary() {
    this.allEmployee = !this.allEmployee;
    this.subscriptions.push(
      this.payrollApiService
        .getEmployeeSalary
        (
          this.salaryDate.getFullYear(),
          this.salaryDate.getMonth() + 1,
          null,
          false,
          this.global.isAltLogin,
          this.allEmployee
        )
        .pipe(indicate(this.gridloading$))
        .subscribe(
          apiresponse => {
            this.salaryData = apiresponse.data;
          }
        ));
  }
  searchSalary() {
    this.subscriptions.push(
      this.payrollApiService
        .getEmployeeSalary(
          this.salaryDate.getFullYear(),
          this.salaryDate.getMonth() + 1,
          null,
          false,
          this.global.isAltLogin,
          this.allEmployee,
          this.locationId,
          this.statusId
        )
        .pipe(indicate(this.gridloading$))
        .subscribe(
          apiresponse => {
            this.salaryData = apiresponse.data;
          }
        ));
  }
  generateSalary() {
    this.allEmployee = false;
    this.subscriptions.push(
      this.payrollApiService
        .createEmployeeMonthlySalary(
          this.salaryDate.getFullYear(),
          this.salaryDate.getMonth() + 1,
          false,
          null,
          this.locationId,
          this.statusId)
        .pipe(
          concatMap(apiresponse => {
            // tslint:disable-next-line:max-line-length
            return this.payrollApiService.getEmployeeSalary(this.salaryDate.getFullYear(), this.salaryDate.getMonth() + 1, null, false, this.global.isAltLogin, null, this.locationId, this.statusId);
          })
        )
        .pipe(indicate(this.gridloading$))
        .subscribe(
          apiresponse => {
            this.salaryData = apiresponse.data;
          }
        ));
  }
  recalculateSalary() {
    // tslint:disable-next-line:max-line-length
    confirm('Are you sure, you want to recalculate the salary for ' + this.monthNames[this.salaryDate.getMonth()] + '.<br/> This will delete all the changes done to the salary ?', 'Confirmation ')
      .then((dialogResult: any) => {
        if (dialogResult) {
          this.subscriptions.push(
            this.payrollApiService
              .createEmployeeMonthlySalary(this.salaryDate.getFullYear(), this.salaryDate.getMonth() + 1, true, null, this.locationId, this.statusId)
              .pipe(indicate(this.gridloading$))
              .subscribe(apiresponse => {
                // tslint:disable-next-line:max-line-length
                this.toastService.success('Salary for te month of ' + this.monthNames[this.salaryDate.getMonth()] + ' successfully recalculated');
                this.loadSalary();
              }));
        }
      });
  }
  downloadEpf() {
    this.subscriptions.push(
      this.payrollApiService
        .getSalaryForPF(this.salaryDate.getMonth() + 1, this.salaryDate.getFullYear())
        .subscribe(apiresponse => {
          const reportData = apiresponse.data;
          const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
          const header = Object.keys(reportData[0]);
          const csv = reportData.map((row) =>
            header
              .map((fieldName) => JSON.stringify(row[fieldName], replacer).split('\'').join(''))
              .join('#~#')
          );
          const csvArray = csv.join('\r\n');
          const a = document.createElement('a');
          const blob = new Blob([csvArray], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = 'EPF.txt';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }));

  }
  loadSalary() {
    this.subscriptions.push(
      this.payrollApiService
        .getEmployeeSalary(
          this.salaryDate.getFullYear(),
          this.salaryDate.getMonth() + 1,
          null,
          null,
          null,
          null,
          this.locationId)
        .pipe(indicate(this.gridloading$))
        .subscribe(apiresponse => {
          this.salaryData = apiresponse.data;
        }));
  }
  openEditSalary(e: any) {
    this.employeeSalary = e.row.data;
    this.employeeSalary.deduction_components = this.employeeSalary.salary_components.filter(d => d.salary_component_type === 'Deduction');
    this.employeeSalary.earning_components = this.employeeSalary.salary_components.filter(d => d.salary_component_type === 'Earning');
    this.employeeSalary.earning_components_new = [];
    this.employeeSalary.deduction_components_new = [];
    this.modalService.open(this.salarymodalcontent, { size: 'lg' });
  }
  openPayslip(e: any) {
    this.employeeSalary = e.row.data;
    // tslint:disable-next-line:max-line-length
    this.tabService.addTab(new Tab(PayslipViewComponent, 'Payslip - ' + this.datePipe.transform(this.salaryDate, 'MMM y'), { id: { employeeId: this.employeeSalary.employee_id, month: this.salaryDate.getMonth() + 1, year: this.salaryDate.getFullYear() } }));
  }

  addDeduction() {
    const newComponent: SalaryComponent = new SalaryComponent();
    this.deductionTypeList.forEach(element => {
      const c = this.employeeSalary.deduction_components.filter(co => co.salary_component_sub_type === element.value);
      if (c.length === 0) {
        newComponent.salaryComponentList.push(element);
      }
    });
    this.employeeSalary.deduction_components_new.push(newComponent);
  }
  addEarning() {
    const newComponent: SalaryComponent = new SalaryComponent();
    this.earningTypeList.forEach(element => {
      const c = this.employeeSalary.earning_components.filter(co => co.salary_component_sub_type === element.value);
      if (c.length === 0) {
        newComponent.salaryComponentList.push(element);
      }
    });
    this.employeeSalary.earning_components_new.push(newComponent);
  }
  clearModel() {
    this.employeeSalary = new EmployeeSalary();
  }
  releasePayslip() {
    const statid: number = this.salaryStatusList.filter(s => s.value === 'Payslip_Released')[0].key;
    this.subscriptions.push(
      this.payrollApiService
        .updateEmployeeSalary(this.salaryDate.getFullYear(), this.salaryDate.getMonth() + 1, statid)
        .subscribe(apiresponse => {
          this.toastService.success('Employee payslip released successfully');
          this.loadSalary();
          this.clearModel();
        }));
  }
  openBankSheet() {
    // tslint:disable-next-line:max-line-length
    this.tabService.addTab(new Tab(SalarySheetComponent, 'Bank Transfer Details - ' + this.datePipe.transform(this.salaryDate, 'MMM y'), { id: { type: 'bank', month: this.salaryDate.getMonth() + 1, year: this.salaryDate.getFullYear() } }));
  }
  openPFSheet() {
    // tslint:disable-next-line:max-line-length
    this.tabService.addTab(new Tab(SalarySheetComponent, 'PF Details - ' + this.datePipe.transform(this.salaryDate, 'MMM y'), { id: { type: 'pf', month: this.salaryDate.getMonth() + 1, year: this.salaryDate.getFullYear() } }));
  }
  openPreview() {
    // tslint:disable-next-line:max-line-length
    this.tabService.addTab(new Tab(SalarySheetComponent, 'Salary Details - ' + this.datePipe.transform(this.salaryDate, 'MMM y'), { id: { type: 'salary', month: this.salaryDate.getMonth() + 1, year: this.salaryDate.getFullYear() } }));
  }
  openCash() {
    // tslint:disable-next-line:max-line-length
    this.tabService.addTab(new Tab(SalarySheetComponent, 'Cash Salary Details - ' + this.datePipe.transform(this.salaryDate, 'MMM y'), { id: { type: 'cash', month: this.salaryDate.getMonth() + 1, year: this.salaryDate.getFullYear() } }));
  }
  changeSalaryStatus(e: any, status: string) {
    this.employeeSalary = e.row.data;
    const statid: number = this.salaryStatusList.filter(s => s.value === status)[0].key;
    this.subscriptions.push(
      this.payrollApiService
        // tslint:disable-next-line:max-line-length
        .updateEmployeeSalary(this.salaryDate.getFullYear(), this.salaryDate.getMonth() + 1, statid, this.employeeSalary.employee_id)
        .subscribe(apiresponse => {
          if (status === 'Payslip_Released') {
            this.toastService.success('Employee payslip released successfully');
          } else if (status === 'Pending') {
            this.toastService.success('Employee payslip blocked successfully');
          }
          this.loadSalary();
          this.clearModel();
        }));
  }
  recalculateEmployeeSalary(e: any) {
    this.employeeSalary = e.row.data;
    // tslint:disable-next-line:max-line-length
    confirm('Are you sure, you want to recalculate the salary for ' + this.employeeSalary.employee_name + ' for the month of ' + this.monthNames[this.salaryDate.getMonth()] + '.<br/> This will delete all the changes done to the salary ?', 'Confirmation ')
      .then((dialogResult: any) => {
        if (dialogResult) {
          this.subscriptions.push(
            this.payrollApiService
              // tslint:disable-next-line:max-line-length
              .createEmployeeMonthlySalary(this.salaryDate.getFullYear(), this.salaryDate.getMonth() + 1, true, this.employeeSalary.employee_id)
              .pipe(indicate(this.gridloading$))
              .subscribe(apiresponse => {
                // tslint:disable-next-line:max-line-length
                this.toastService.success('Salary for the month of ' + this.monthNames[this.salaryDate.getMonth()] + ' successfully recalculated for ' + this.employeeSalary.employee_name);
                this.loadSalary();
              }));
        }
      });
  }
  calculateEmployeeSalary(e: any) {
    this.employeeSalary = e.row.data;
    this.subscriptions.push(
      // tslint:disable-next-line:max-line-length
      this.payrollApiService.createEmployeeMonthlySalary(this.salaryDate.getFullYear(), this.salaryDate.getMonth() + 1, true, this.employeeSalary.employee_id)
        .pipe(indicate(this.gridloading$))
        .subscribe(apiresponse => {
          // tslint:disable-next-line:max-line-length
          this.toastService.success('Salary for the month of ' + this.monthNames[this.salaryDate.getMonth()] + ' successfully calculated for ' + this.employeeSalary.employee_name);
          this.loadSalary();
        }));
  }
  saveSalary(frm: any) {
    if (frm.valid) {
      const esal: EmployeeSalary = new EmployeeSalary();
      let comp: SalaryComponent[] = [];
      comp = comp.concat(this.employeeSalary.deduction_components);
      comp = comp.concat(this.employeeSalary.deduction_components_new);
      comp = comp.concat(this.employeeSalary.earning_components);
      comp = comp.concat(this.employeeSalary.earning_components_new);
      esal.employee_salary_id = this.employeeSalary.employee_salary_id;
      esal.no_of_days_worked = this.employeeSalary.no_of_days_worked;
      esal.remarks = this.employeeSalary.remarks;
      esal.salary_components = comp;
      this.subscriptions.push(
        this.payrollApiService
          .postEmployeeSalary(esal).subscribe(apiresponse => {
            this.toastService.success('Employee salary updated successfully');
            this.loadSalary();
            this.clearModel();
            this.modalService.dismissAll();
          }));
    }
  }
  selectName(e) {
    const emp: KeyValue<number, string> = this.employeeNameList.find(d => d === e.selectedItem);
    if (emp) {
      this.employeeId = emp.key;
    } else {
      this.employeeId = null;
    }
  }
  saveRemark(frm: any) {
    if (frm.valid) {
      const esal: EmployeeSalary = new EmployeeSalary();
      esal.remarks = this.employeeSalary.remarks;
      esal.employee_salary_id = this.employeeSalary.employee_salary_id;
      this.subscriptions.push(
        this.payrollApiService
          .postEmployeeSalary(esal)
          .subscribe(apiresponse => {
            this.toastService.success('Employee salary Remarks updated successfully');
            this.loadSalary();
            this.clearModel();
            this.modalService.dismissAll();
          }));
    }
  }
  remarksEmployeeSalary(e: any) {
    this.employeeSalary = e.row.data;
    this.modalService.open(this.salaryremarkmodalcontent, { size: 'lg' });
  }
  calculateCustom(options: any) {
    if (options.summaryProcess === 'start') {
      options.totalValue = 0;
    }
    if (options.name === 'Pending') {
      options.totalValue = options.totalValue + (options?.value?.employee_salary_status === null ? 1 : 0);
    } else if (options.name === 'PendingRelease') {
      options.totalValue = options.totalValue + (options?.value?.employee_salary_status === 'Pending_Release' ? 1 : 0);
    } else if (options.name === 'Released') {
      options.totalValue = options.totalValue + (options?.value?.employee_salary_status === 'Payslip_Released' ? 1 : 0);
    }
  }
}
