<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 *ngIf="role!=='approver'" class="page-title">Asset Request</h3>
                <h3 *ngIf="role==='approver'" class="page-title">Manage Asset Request</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
                <button *ngIf="role!=='approver'" class="btn add-btn" (click)="openModel(requestmodalcontent)"><i
                        class="fa fa-plus"></i> Create
                    New Request</button>
            </div>
        </div>
    </div>
    <div *ngIf="role==='approver'" class="row filter-row">
        <div class="col-sm-6 col-md-4">
            <dx-autocomplete class="form-control filter-control" id="employeeName" placeholder="Type Employee Name..."
                (onSelectionChanged)="selectName($event)" [dataSource]="employeeNameList" [showClearButton]="true">
            </dx-autocomplete>
        </div>
        <div class="col-sm-6 col-md-3">
            <button class="btn btn-secondary btn-block" (click)="searchRequests()" [disabled]="!employeeId"> Search
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-3 table-responsive" id="{{ role==='approver' ? 'asset-request-grid': 'asset-request-grid-approver'}}">
            <dx-data-grid id="gridRequest" [dataSource]="assetReqList" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false">
                <dxo-export [enabled]="true" fileName="assetRequestReport"></dxo-export>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                <dxi-column dataField="employee_name" [allowFiltering]="false" [allowSorting]="false"
                    cellTemplate="imageTemplate" caption="Employee" width="250"></dxi-column>
                <dxi-column dataField="employee_code" caption="Code" dataType="string"></dxi-column>
                <dxi-column dataField="asset_type" caption="Type" dataType="string"></dxi-column>
                <dxi-column dataField="request_date" caption="Request Date" cellTemplate="dateTemplate"></dxi-column>
                <dxi-column dataField="description" caption="Description" dataType="string"></dxi-column>
                <dxi-column dataField="asset_request_status" caption="Satus" [allowFiltering]="false"
                    [allowSorting]="false" cellTemplate="statusTemplate" alignment="center"></dxi-column>
                <dxi-column dataField="asset_request_id" [allowFiltering]="false" width=50 caption=""
                    [allowSorting]="false" cellTemplate="actionTemplate" alignment="center" [allowGrouping]="false">
                </dxi-column>
                <div *dxTemplate="let data of 'imageTemplate'">
                    <h2 class="table-avatar">
                        <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                            [name]="data.row.data.employee_name">
                        </ngx-avatar>
                        <a class="ml-1 mt-1 gridlink" href="javascript:void(0);">
                            {{data.row.data.employee_name}}<span>{{data.row.data.designation}} </span></a>
                    </h2>
                </div>
                <div *dxTemplate="let data of 'dateTemplate'">
                    {{data.value | date}}
                </div>
                <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                    <div *ngIf="role!=='approver'">
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Approved'">
                            <i class="fa fa-dot-circle-o text-success"></i> Approved
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Pending'">
                            <i class="fa fa-dot-circle-o text-warning"></i> Pending
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Rejected'">
                            <i class="fa fa-dot-circle-o text-danger"></i> Rejected
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Assigned'">
                            <i class="fa fa-dot-circle-o text-info"></i> Assigned
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Manager Approved'">
                            <i class="fa fa-dot-circle-o text-purple"></i> M Approved
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Cancelled'">
                            <i class="fa fa-dot-circle-o text-grey"></i> Cancelled
                        </a>
                    </div>
                    <div ngbDropdown container="body" *ngIf="role==='approver'">
                        <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Approved'" ngbDropdownToggle>
                            <i class="fa fa-dot-circle-o text-success"></i> Approved
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Pending'" ngbDropdownToggle>
                            <i class="fa fa-dot-circle-o text-warning"></i> Pending
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Rejected'" ngbDropdownToggle>
                            <i class="fa fa-dot-circle-o text-danger"></i> Rejected
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Assigned'" ngbDropdownToggle>
                            <i class="fa fa-dot-circle-o text-info"></i> Assigned
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Manager Approved'" ngbDropdownToggle>
                            <i class="fa fa-dot-circle-o text-purple"></i> M Approved
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Cancelled'" ngbDropdownToggle>
                            <i class="fa fa-dot-circle-o text-grey"></i> Cancelled
                        </a>
                        <div ngbDropdownMenu>
                            <a class="dropdown-item"
                                *ngIf="cellInfo.value=='Pending' || cellInfo.value=='Manager Approved'"
                                href="javascript:void(0);" (click)="changeStatus(cellInfo, 'Approved')"><i
                                    class="fa fa-dot-circle-o text-success"></i> Approve</a>
                            <a class="dropdown-item"
                                *ngIf="cellInfo.value=='Pending' || cellInfo.value=='Manager Approved'"
                                href="javascript:void(0);" (click)="changeStatus(cellInfo, 'Rejected')"><i
                                    class="fa fa-dot-circle-o text-danger"></i> Reject</a>
                            <a class="dropdown-item" href="javascript:void(0);"
                                *ngIf="cellInfo.value!='Cancelled' && cellInfo.value!='Assigned'"
                                (click)="changeStatus(cellInfo, 'Cancelled')"><i
                                    class="fa fa-dot-circle-o text-grey"></i> Cancel</a>
                        </div>
                    </div>
                </div>
                <div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
                    <div ngbDropdown container="body"
                        *ngIf="role==='approver' && cellInfo.data.asset_request_status==='Approved'">
                        <a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle><i
                                class="material-icons">more_vert</i></a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="assignAsset(cellInfo)"><i
                                    class="fa fa-pencil m-r-5"></i> Assign</a>
                        </div>
                    </div>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
<!-- Modal window contents -->
<ng-template #requestmodalcontent let-c="close" let-d="dismiss">
    <form #reqForm="ngForm">
        <div class="modal-header">
            <div *ngIf="isNew;else edit_content">
                <h4 class="modal-title" id="modal-basic-title">Add Asset Request</h4>
            </div>
            <ng-template #edit_content>
                <h4 class="modal-title" id="modal-basic-title">Edit Asset Request</h4>
            </ng-template>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="type">Asset Type <span class="text-danger">*</span></label>
                    <select class="form-control" id="type" name="type" [(ngModel)]="assetReq.asset_type_id" required
                        #type="ngModel">
                        <option [ngValue]="undefined" selected>Select Asset Type</option>
                        <option *ngFor="let item of typeList; let i = index" [ngValue]="item.key">
                            {{item.value}}</option>
                    </select>
                    <div *ngIf="type.invalid && (type.dirty || type.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="type.errors.required">
                            Asset Type is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="desc">Description</label>
                    <textarea rows="4" class="form-control" id="desc" name="desc"
                        [(ngModel)]="assetReq.description"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="saveRequest(reqForm)"
                [disabled]="!reqForm.valid">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>
<ng-template #assignmodalcontent let-c="close" let-d="dismiss">
    <form #reqForm="ngForm">
        <div class="modal-header">
            <div>
                <h4 class="modal-title" id="modal-basic-title">Assign Asset</h4>
            </div>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="type">Asset Name/Code</label>
                    <dx-autocomplete class="form-control" id="assetCode" placeholder="Type Asset Code..."
                        (onSelectionChanged)="selectAsset($event)" [dataSource]="assetCodeList"
                        [showClearButton]="true">
                    </dx-autocomplete>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="desc">Description</label>
                    <textarea rows="4" class="form-control" id="desc" name="desc" [(ngModel)]="description"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="saveAssignAsset(reqForm)"
                [disabled]="!asset">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>
<div *ngIf="(gridloading$ | async) && role==='approver'">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#asset-request-grid' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#asset-request-grid">
    </dx-load-panel>
</div>
<div *ngIf="(gridloading$ | async) && role!=='approver'">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#asset-request-grid-approver' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#asset-request-grid-approver">
    </dx-load-panel>
</div>