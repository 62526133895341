import { identifierModuleUrl } from '@angular/compiler';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { EmployeeSalary } from 'src/app/model/payroll';
import { indicate } from 'src/app/services/loading.service';
import { PayrollApiService } from 'src/app/services/payroll-api.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-salary-sheet',
  templateUrl: './salary-sheet.component.html',
  styleUrls: ['./salary-sheet.component.scss']
})
export class SalarySheetComponent implements OnInit {
  intializeData: any = null;
  type: string = null;
  month: number = null;
  year: number = null;
  salaryDate: Date = null;
  reportData: any[] = [];
  loading$ = new Subject<boolean>();
  @ViewChild('printContent', { static: true }) printContent: ElementRef;
  constructor(private payrollApiService: PayrollApiService, private global: Global) { }
  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    if (this.intializeData && this.intializeData.id && this.intializeData.id.month && this.intializeData.id.year && this.intializeData.id.type) {
      this.month = this.intializeData.id.month;
      this.year = this.intializeData.id.year;
      this.type = this.intializeData.id.type;
      this.salaryDate = new Date(this.year + '-' + this.month + '-01');
      if (this.type === 'pf') {
        this.payrollApiService.getSalaryForPF(this.month, this.year)
          .pipe(indicate(this.loading$))
          .subscribe(apiresponse => {
            this.reportData = apiresponse.data;
          });
      }
      if (this.type === 'cash') {
        this.payrollApiService.getCashSalary(this.month, this.year)
          .pipe(indicate(this.loading$))
          .subscribe(apiresponse => {
            this.reportData = apiresponse.data;
          });
      } else if (this.type === 'salary') {
        this.payrollApiService.getEmployeeSalary(this.year, this.month)
          .pipe(indicate(this.loading$))
          .subscribe(apiresponse => {
            const rdata: EmployeeSalary[] = apiresponse.data;
            this.reportData = [];
            rdata.forEach(element => {
              const d: any = {};
              d.Employee_Code = element.employee_code;
              d.Employe_Name = element.employee_name;
              d.No_Of_Days_Worked = element.no_of_days_worked;
              d.Gross_Salary = element.gross_salary;
              d.Total_Deduction = element.total_deduction;
              d.Net_Salary = element.net_salary;
              element.salary_components.forEach(comp => {
                d[comp.salary_component_sub_type] = comp.salary_component_value;
              });
              this.reportData.push(d);
            });
          });
      } else if (this.type === 'bank') {
        this.payrollApiService.getSalaryForBank(this.month, this.year)
          .pipe(indicate(this.loading$))
          .subscribe(apiresponse => {
            this.reportData = apiresponse.data;
          });
      }
    } else {
      if (this.intializeData && this.intializeData.id && this.intializeData.id.data) {
        if (this.intializeData.id.type === 'employee') {
          const d = this.intializeData.id.data;
          this.reportData = d;
        }
      }
    }
  }
  print() {
    this.global.print(this.printContent.nativeElement.innerHTML);
  }
}
