<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center" *ngIf="!global.isMobileApp && global.loggedUser">
            <div class="col" *ngIf="global.isMobile===false">
                <h3 class="page-title">Manage Leaves</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
                <button class="btn add-btn ml-3" (click)="openApplyLeave()"
                    *ngIf="(hasManagerRole===true && showAddLeaveFor===true) || hasHrRole==true || hasAdminRole==true"><i
                        class="fa fa-user-circle"></i> Apply
                    Leave For</button>
            </div>
        </div>
    </div>
    <ng-template #mobile>
        <div class="row stats-info bg-card bg-mobile-holder align-left min-height-100" id="leave-stat-div">
            <div *ngFor="let l of leaveStat; let i = index" class="col-md-12 mt-3">
                <i class="fa fa-dot-circle-o text-warning mr-2"></i> {{l.key}} <div class="pull-right">
                    {{l.value===null?0:l.value}} <span>Today</span></div>
            </div>
        </div>
    </ng-template>    
    <ng-template #desktop>
        <div class="row">
            <div *ngFor="let l of leaveStat" class="col-md-4">
                <div class="stats-info bg-card bg-holder">
                    <h6>{{l.key}}</h6>
                    <h4>{{l.value===null?0:l.value}} <span>Today</span></h4>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="global.isMobile===true?mobile:desktop">
    </ng-container>
    <div class="row" *ngIf="global.isMobileApp">
        <div class="col-md-12 mobile-filter mb-3">
            <button class="btn mobile-btn col-md-12" (click)="openApplyLeave()"
                *ngIf="(hasManagerRole===true && showAddLeaveFor===true) || hasHrRole==true || hasAdminRole==true"><i
                    class="fa fa-user-circle"></i> Apply
                Leave For</button>
        </div>
    </div>
    <div class="row filter-row mt-3">
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12" [ngClass]="{'mobile-filter': global.isMobile===true}">
            <dx-autocomplete class="form-control filter-control" id="employeeName" placeholder="Type Employee Name...."
                (onSelectionChanged)="selectName($event)" [dataSource]="employeeNameList" [showClearButton]="true">
            </dx-autocomplete>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" [ngClass]="{'mobile-filter': global.isMobile===true}">
            <select class="form-control filter-control" id="leaveType" name="leaveType" [(ngModel)]="leaveTypeId"
                [ngClass]="{'loading-control': (loading$ | async) === true }">
                <option [ngValue]="undefined" selected>Select Leave Type</option>
                <option *ngFor="let item of leaveTypeList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" [ngClass]="{'mobile-filter': global.isMobile===true}">
            <select class="form-control filter-control" id="leaveStatus" name="leaveStatus" [(ngModel)]="leaveStatusId"
                [ngClass]="{'loading-control': (loading$ | async) === true }">
                <option [ngValue]="undefined" selected>Select Leave Status</option>
                <option *ngFor="let item of leaveStatusList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" [ngClass]="{'mobile-filter': global.isMobile===true}">
            <dx-date-box [(value)]="fromDate" type="date" class="form-control filter-control" placeholder="From Date"
                (onValueChanged)="fromdateChanged($event)">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" [ngClass]="{'mobile-filter': global.isMobile===true}">
            <dx-date-box [(value)]="toDate" type="date" class="form-control filter-control" placeholder="To Date"
                [min]="fromDate">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-1 col-12" *ngIf="global.loggedUser"
            [ngClass]="{'mobile-filter': global.isMobile===true}">
            <button [ngClass]="global.isMobileApp ? 'btn mobile-btn btn-block' : 'btn btn-secondary btn-block'"
                (click)="searchLeave()"><i class="fa fa-search fa-lg"></i>
            </button>
        </div>
    </div>
    <ng-template #desktopgrid>
        <div class="row">
            <div class="col-md-12 mt-3 table-responsive" id="leave-admin-grid">
                <dx-data-grid #gridLeave id="gridContainer" [dataSource]="leaveData" [remoteOperations]="false"
                    [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                    [showColumnLines]="false">
                    <dxo-export [enabled]="true" fileName="leaveReport"></dxo-export>
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                    
                    <dxi-column dataField="employee_name" [allowFiltering]="false" [allowSorting]="false"
                        cellTemplate="imageTemplate" caption="Employee" width="250"></dxi-column>
                    <dxi-column dataField="employee_code" caption="Code" dataType="string"></dxi-column>
                    <dxi-column dataField="leave_type" caption="Leave Type" dataType="string"></dxi-column>
                    <dxi-column dataField="leave_from_date" caption="From" cellTemplate="dateTemplate"></dxi-column>
                    <dxi-column dataField="leave_to_date" caption="To" cellTemplate="dateTemplate"></dxi-column>
                    <dxi-column dataField="no_of_days" caption="No Of Days" dataType="number"></dxi-column>
                    <dxi-column dataField="leave_reason" caption="Reason" dataType="string"></dxi-column>
                    <dxi-column dataField="leave_status" caption="Satus" [allowFiltering]="false" [allowSorting]="false"
                        cellTemplate="statusTemplate" alignment="center">
                    </dxi-column>
                    <div *dxTemplate="let data of 'dateTemplate'">
                        {{data.value | date}}
                    </div>
                    <div *dxTemplate="let data of 'imageTemplate'">
                        <h2 class="table-avatar">
                            <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                                [name]="data.row.data.employee_name">
                            </ngx-avatar>
                            <a class="ml-1 mt-1"> {{data.row.data.employee_name}} <span
                                    *ngIf="data.row.data.designation!=undefined">{{data.row.data.designation}}
                                </span></a>
                        </h2>
                    </div>
                    <div *dxTemplate="let cellInfo of 'statusTemplate'">
                        <div ngbDropdown container="body">
                            <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                                *ngIf="cellInfo.value=='Approved'" ngbDropdownToggle>
                                <i class="fa fa-dot-circle-o text-success"></i> Approved
                            </a>
                            <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                                *ngIf="cellInfo.value=='Manager Approved'" ngbDropdownToggle>
                                <i class="fa fa-dot-circle-o text-warning"></i> M Approved
                            </a>
                            <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                                *ngIf="cellInfo.value=='Pending'" ngbDropdownToggle>
                                <i class="fa fa-dot-circle-o text-purple"></i> Pending
                            </a>
                            <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                                *ngIf="cellInfo.value=='Declined'" ngbDropdownToggle>
                                <i class="fa fa-dot-circle-o text-danger"></i> Declined
                            </a>
                            <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                                *ngIf="cellInfo.value=='Cancelled'">
                                <i class="fa fa-dot-circle-o text-grey"></i> Cancelled
                            </a>
                            <div ngbDropdownMenu>
                                <a class="dropdown-item"
                                    *ngIf="cellInfo.value=='Declined' || cellInfo.value=='Pending' || (cellInfo.value=='Manager Approved' && hasHrRole)"
                                    href="javascript:void(0);"
                                    (click)="changeLeaveStatus(cellInfo.row.data, 'Approved')"><i
                                        class="fa fa-dot-circle-o text-success"></i> Approve</a>
                                <a class="dropdown-item"
                                    *ngIf="cellInfo.value=='Pending' || (cellInfo.value=='Manager Approved' && hasHrRole)"
                                    href="javascript:void(0);"
                                    (click)="changeLeaveStatus(cellInfo.row.data, 'Declined')"><i
                                        class="fa fa-dot-circle-o text-danger"></i> Decline</a>
                                <a class="dropdown-item"
                                    *ngIf="cellInfo.value=='Approved' || (cellInfo.value=='Manager Approved' && !hasHrRole)"
                                    href="javascript:void(0);"
                                    (click)="changeLeaveStatus(cellInfo.row.data, 'Cancelled')"><i
                                        class="fa fa-dot-circle-o text-warning"></i> Cancel</a>
                            </div>
                        </div>
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </ng-template>
    <ng-template #mobilegrid>
        <div class="row">
            <div class="col-md-12 mt-3 table-responsive" id="leave-grid">
                <dx-data-grid #gridLeave id="gridContainerMobile" [dataSource]="leaveData" [remoteOperations]="false"
                    [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                    [showColumnLines]="false" [showColumnHeaders]="false" rowTemplate="rowLeaveTemplate">
                    <dxo-paging [pageSize]="10"></dxo-paging>
                    <dxi-column dataField="employee_name" [width]="80+'%'"></dxi-column>
                    <dxi-column dataField="leave_status">
                    </dxi-column>
                    <tbody class="leave dx-row" [ngClass]="{'dx-row-alt': leave.rowIndex % 2}"
                        *dxTemplate="let leave of 'rowLeaveTemplate'">
                        <tr class="main-row">
                            <td class="mobile-column">
                                <h2 class="table-avatar">
                                    <ngx-avatar size="40" [src]="leave.data.profile_picture"
                                        [name]="leave.data.employee_name">
                                    </ngx-avatar>
                                    <a class="ml-1 mt-1"> {{leave.data.employee_name}} <span
                                            *ngIf="leave.data.leave_type!=undefined">{{leave.data.leave_type}}
                                        </span></a>
                                </h2>
                            </td>
                            <td class="action-column">
                                <div ngbDropdown container="body" *ngIf="leave.data.leave_status!='Cancelled'">
                                    <a href="javascript:void(0);" class="action-icon dropdown-toggle-no"
                                        ngbDropdownToggle><i class="material-icons">more_vert</i></a>
                                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                        <a *ngIf="leave.data.leave_status =='Declined' || leave.data.leave_status=='Pending' || (leave.data.leave_status=='Manager Approved' && hasHrRole)"
                                            class="dropdown-item" href="javascript:void(0);"
                                            (click)="changeLeaveStatus(leave.data, 'Approved')"><i
                                                class="fa fa-check m-r-5"></i> Approve</a>
                                        <a class="dropdown-item" href="javascript:void(0);"
                                            (click)="changeLeaveStatus(leave.data, 'Declined')"
                                            *ngIf="leave.data.leave_status=='Pending' || (leave.data.leave_status=='Manager Approved' && hasHrRole)">
                                            <i class="fa fa-trash-o m-r-5"></i> Decline</a>
                                        <a class="dropdown-item" href="javascript:void(0);"
                                            (click)="changeLeaveStatus(leave.data, 'Cancelled')"
                                            *ngIf="leave.data.leave_status=='Approved' || (leave.data.leave_status=='Manager Approved' && !hasHrRole)">
                                            <i class="fa fa-times-circle m-r-5"></i> Cancel</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="alt-row">
                            <td colspan="" class="mobile-column">
                                <div>
                                    <span class="text-sm text-muted">{{leave.data.leave_from_date | date:
                                        "M/d/yyyy"}} -
                                        {{leave.data.no_of_days}} {{leave.data.no_of_days === 1 ? 'Day' : 'Days'
                                        }}</span>
                                </div>
                            </td>
                            <td class="status-column">
                                <span class="badge"
                                    [ngClass]="{'bg-inverse-danger': leave.data.leave_status=='Declined','bg-inverse-success': leave.data.leave_status=='Approved','bg-inverse-purple': leave.data.leave_status=='Pending','bg-inverse-warning': leave.data.leave_status=='Manager Approved','bg-inverse-grey': leave.data.leave_status=='Cancelled'}">{{leave.data.leave_status==='Manager
                                    Approved'?'M Approved':leave.data.leave_status}}</span>
                            </td>
                        </tr>
                    </tbody>
                </dx-data-grid>
            </div>
        </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="global.isMobile===true?mobilegrid:desktopgrid">
    </ng-container>
    <app-apply-leave #applyLeave (modalClose)="reloadGrid()"></app-apply-leave>
    <ng-template #declineleavemodalcontent let-c="close" let-d="dismiss">
        <form #declineleaveForm="ngForm">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Decline Leave </h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-scroll">
                <div class="form-group row">
                    <div class="col-sm-12">
                        <label for="reason">Reason <span class="text-danger">*</span></label>
                        <textarea rows="4" class="form-control" id="reason" name="reason"
                            [(ngModel)]="leave.leave_decline_reason" #declinereason="ngModel" required></textarea>
                    </div>
                    <div *ngIf="declinereason.invalid && (declinereason.dirty || declinereason.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="declinereason.errors.required">
                            Decline Reason is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="declineLeave(declineleaveForm)"
                    [disabled]="!declineleaveForm.valid">Save</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
</div>
<div *ngIf="(gridloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#leave-admin-grid' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#leave-admin-grid">
    </dx-load-panel>
</div>