import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutestToHour'
})
export class MinutesToHours implements PipeTransform {
  transform(value: number): string {
    if (value > 0) {
      const hours = Math.floor(value / 60);
      const minutes = Math.floor(value % 60);
      return hours + ' Hrs ' + minutes + ' Mins';
    } else {
      return '';
    }
  }
}
