<ng-template #passwordmodalcontent let-c="close" let-d="dismiss">
    <form #passForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <div class="form-group">
                <label>User Name</label>
                <input class="form-control" type="text" [(ngModel)]="user.user_name" name ="user_name" readonly>
            </div>
            <div class="form-group" *ngIf="!isAdmin">
                <div class="row">
                    <div class="col">
                        <label>Password</label>
                    </div>
                </div>
                <input class="form-control" type="password" [(ngModel)]="user.password" id="password"
                    name="password" #password="ngModel" required>
                <div *ngIf="password.invalid && (password.dirty || password.touched)"
                    class="badge badge-danger position-absolute">
                    <div *ngIf="password.errors.required">
                        Password is required.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col">
                        <label *ngIf="!isAdmin">New Password</label>
                        <label *ngIf="isAdmin">Temporary Password</label>
                    </div>
                </div>
                <input class="form-control" type="newpassword" [(ngModel)]="user.newpassword" id="newpassword"
                    name="newpassword" #newpassword="ngModel" required type="password">
                <div *ngIf="newpassword.invalid && (newpassword.dirty || newpassword.touched)"
                    class="badge badge-danger position-absolute">
                    <div *ngIf="newpassword.errors.required">
                        New Password is required.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col">
                        <label *ngIf="!isAdmin">Confirm Password</label>
                        <label *ngIf="isAdmin">Confirm Temporary Password</label>
                    </div>
                </div>
                <input class="form-control" type="cpassword" [(ngModel)]="user.confirmpassword" id="cpassword"
                    name="cpassword" #cpassword="ngModel" required [app-compare]="user.newpassword" type="password">
                <div *ngIf="cpassword.invalid && (cpassword.dirty || cpassword.touched)"
                    class="badge badge-danger position-absolute">
                    <div *ngIf="cpassword.errors.required">
                        Confirm Password is required.
                    </div>
                    <div *ngIf="cpassword.errors.compare">
                        Password Mismatch.
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="changePassword(passForm.valid)"
                [disabled]="!passForm.valid">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>