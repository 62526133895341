<div class="card punch-status min-height-100" *ngIf="isShowActivity()">
    <div class="card-body">
        <div *ngFor="let item of employeeCurrentDayAttendance; let i = index">
            <div class="punch-det" *ngIf="item.punch_out_time">
                <div class="row ml-1">
                    <h6>Punch In:</h6>&nbsp;<small class="text-muted">{{ item.punch_in_time | date : "EEEE, MMMM d, y,
                        h:mm:ss a" }}<span
                            *ngIf="item.punch_in_description">&nbsp;({{item.punch_in_description}})</span></small>
                </div>
                <div class="row ml-1">
                    <h6>Punch Out: </h6>&nbsp;<small class="text-muted">{{ item.punch_out_time | date : "EEEE, MMMM d,
                        y,
                        h:mm:ss
                        a" }}<span
                            *ngIf="item.punch_out_description">&nbsp;({{item.punch_out_description}})</span></small>
                </div>
                <div class="row ml-1">
                    <h6>Total Time: </h6>&nbsp;<small class="text-muted">{{ item.total_hours | number :
                        '1.2-2'}}&nbsp;Hrs</small>
                </div>
            </div>
            <div class="punch-det" *ngIf="item.punch_direction===181">
                <div class="row ml-1">
                    <h6>Activity:</h6>&nbsp;<small class="text-muted">{{ item.punch_in_time | date : "EEEE,
                        MMMM d, y,
                        h:mm:ss a" }}<span
                            *ngIf="item.punch_in_description">&nbsp;({{item.punch_in_description}})</span></small>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card punch-status">
    <div class="card-body" id="attendance-punch-loading">
        <h5 class="card-title">{{employee_name}} <small class="text-muted">
                {{ attendance_date | date : "mediumDate" }}</small></h5>
        <div class="punch-det" *ngIf="employeeAttendance.in_punch_time">
            <h6>Punch In</h6>
            <p>{{ employeeAttendance.in_punch_time | date : "EEEE, MMMM d, y, h:mm:ss a" }}<span
                    *ngIf="employeeAttendance.in_punch_description">&nbsp;({{employeeAttendance.in_punch_description}})</span>
            </p>
        </div>
        <div class="mt-3 mb-3" *ngIf="!employeeAttendance.in_punch_time">
            <h5 class="card-title"> <small class="text-muted">
                    Location {{ current_date | date : "medium" }}</small></h5>
            <input class="form-control" [(ngModel)]="employeeAttendance.punch_description" type="text"
                id="inDescription" name="inDescription">
        </div>
        <div class="mt-3 mb-3" *ngIf="!employeeAttendance.in_punch_time">
            <h5 class="card-title"> <small class="text-muted">
                    Activity (Purpose of visit or Notes)</small></h5>
            <input class="form-control" [(ngModel)]="employeeAttendance.punch_activity" type="text" id="inActivity"
                name="inActivity">
        </div>
        <button *ngIf="!employeeAttendance.in_punch_time" class="btn btn-secondary btn-block" (click)="punchIn()">
            Punch In </button>
        <div class="punch-info mt-5 mb-5">
            <div class="punch-hours">
                <span *ngIf="employeeAttendance.total_hours">
                    {{ formatTotalHours(employeeAttendance.total_hours) }}
                    </span>
                <span *ngIf="!employeeAttendance.total_hours">0 hrs</span>
            </div>
        </div>
        <div class="punch-det" *ngIf="employeeAttendance.out_punch_time">
            <h6>Punch Out</h6>
            <p>{{ employeeAttendance.out_punch_time | date : "EEEE, MMMM d, y,
                h:mm:ss a" }}</p>
        </div>
        <div class="mt-3 mb-3" *ngIf="employeeAttendance.in_punch_time">
            <h5 class="card-title"><small class="text-muted">
                    Location {{ current_date | date : "medium" }}</small></h5>
            <input class="form-control" [(ngModel)]="employeeAttendance.punch_description" type="text"
                id="outDescription" name="outDescription">
        </div>
        <div class="mt-3 mb-3" *ngIf="employeeAttendance.in_punch_time">
            <h5 class="card-title"> <small class="text-muted">
                    Activity (Purpose of visit or Notes)</small></h5>
            <input class="form-control" [(ngModel)]="employeeAttendance.punch_activity" type="text" id="outActivity"
                name="outActivity">
        </div>
        <button *ngIf="!employeeAttendance.out_punch_time" [disabled]="!employeeAttendance.in_punch_time"
            class="btn btn-secondary btn-block" (click)="punchOut()">
            Punch Out </button>
    </div>
</div>
<div class="card punch-status" *ngIf="global.loggedUser.attendance_type_id==186"  [hidden]="true">
    <div class="card-body" id="attendance-status-loading">
        <h5 class="card-title">Activity <small class="text-muted">
                {{ current_date | date : "medium" }} (Purpose of visit, Location or Notes)</small></h5>
        <div class="mt-3 mb-3">
            <input class="form-control" [(ngModel)]="employeeAttendance.location_punch_description" type="text"
                id="location" name="location">
        </div>
        <button [disabled]="!employeeAttendance.location_punch_description" class="btn btn-secondary btn-block"
            (click)="punchLocation()">
            Punch Activity </button>
    </div>
</div>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#attendance-punch-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#attendance-punch-loading">
    </dx-load-panel>
</div>