<div class="mr-2 ml-2">
    <form #salaryForm="ngForm">
        <div class="form-group row">
            <div class="col-sm-6">
                <label for="name">Name</label><br>
                <input class="form-control" [(ngModel)]="employeeSalary.employee_name" readonly
                    type="text" id="name" name="name">
            </div>
            <div class="col-sm-6">
                <label for="name">Employee Code</label><br>
                <input class="form-control" [(ngModel)]="employeeSalary.employee_code" readonly
                    type="text" id="month" name="month">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <h4 class="text-primary">Earnings</h4>
                <div *ngFor="let l of employeeSalary.earning_components; let i = index" class="form-group">
                    <label>{{l.salary_component_sub_type}}</label>
                    <input class="form-control" type="text" [(ngModel)]="l.salary_component_value"
                        id="sal_earning_components{{i}}" name="sal_earning_components{{i}}">
                </div>
                <div *ngFor="let l of employeeSalary.earning_components_new; let i = index" class="mt-3">
                    <div class="form-group row ml-0">
                        <select class="form-control col-sm-8" id="earning_components{{i}}"
                            name="earning_components{{i}}" [(ngModel)]="l.salary_component_sub_type_id">
                            <option [ngValue]="null" selected>Select Component</option>
                            <option *ngFor="let item of l.salaryComponentList; let i = index" [ngValue]="item.key">
                                {{item.value}}</option>
                        </select>
                        <div class="add-more col-sm-4 mt-2">
                            <a href="javascript:void(0);" (click)="removeEarning(l)"><i class="fa fa-minus-circle"></i>
                                remove</a>
                        </div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" [(ngModel)]="l.salary_component_value" type="number"
                            id="earningcomponentvalue{{i}}" name="earningcomponentvalue{{i}}">
                    </div>
                </div>
                <div class="add-more mt-2">
                    <a href="javascript:void(0);" (click)="addEarning()"><i class="fa fa-plus-circle"></i> add</a>
                </div>
            </div>
            <div class="col-sm-6">
                <h4 class="text-primary">Deductions</h4>
                <div *ngFor="let l of employeeSalary.deduction_components; let i = index" class="form-group">
                    <label>{{l.salary_component_sub_type}}</label>
                    <input class="form-control" type="text" [(ngModel)]="l.salary_component_value"
                        id="sal_deduction_components{{i}}" name="sal_deduction_components{{i}}">
                </div>
                <div *ngFor="let l of employeeSalary.deduction_components_new; let i = index" class="mt-3">
                    <div class="form-group row ml-0">
                        <select class="form-control col-sm-8" id="deduction_components{{i}}"
                            name="deduction_components{{i}}" [(ngModel)]="l.salary_component_sub_type_id">
                            <option [ngValue]="null" selected>Select Component</option>
                            <option *ngFor="let item of l.salaryComponentList; let i = index" [ngValue]="item.key">
                                {{item.value}}</option>
                        </select>
                        <div class="add-more col-sm-4 mt-2">
                            <a href="javascript:void(0);" (click)="removeDeduction(l)"><i
                                    class="fa fa-minus-circle"></i> remove</a>
                        </div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" [(ngModel)]="l.salary_component_value" type="number"
                            id="deductioncomponentvalue{{i}}" name="deductioncomponentvalue{{i}}">
                    </div>
                </div>
                <div class="add-more mt-2">
                    <a href="javascript:void(0);" (click)="addDeduction()"><i class="fa fa-plus-circle"></i> add</a>
                </div>
            </div>
        </div>
    </form>
</div>