import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { OfficeLocation } from 'src/app/model/admin';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { indicate } from 'src/app/services/loading.service';
import { ToastService } from 'src/app/services/toast.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit {
  isNew = true;
  locationList: OfficeLocation[] = [];
  location: OfficeLocation = new OfficeLocation();
  stateList: KeyValue<number, string>[];
  loading$ = new Subject<boolean>();
  @ViewChild('locationmodalcontent', { static: true }) locationmodalcontent: ElementRef;
  // tslint:disable-next-line:max-line-length
  constructor(public global: Global, private modalService: NgbModal, private adminApiService: AdminApiService, private commonApiService: CommonApiService, private toastService: ToastService) { }

  ngOnInit() {
    this.searchLocation();
    this.commonApiService.getMasterData('state')
    .subscribe(apiresponse => {
      this.stateList = apiresponse.data;
    });
  }
  editLocation(e: any) {
    this.isNew = false;
    this.location = e.row.data;
    this.modalService.open(this.locationmodalcontent, { size: 'lg' });
  }
  saveLocation(frm: any) {
    if (frm.valid) {
      this.location.address1 = this.location.address1 == null ? this.location.address1 : btoa(this.location.address1);
      this.location.address2 = this.location.address2 == null ? this.location.address2 : btoa(this.location.address2);
      this.adminApiService.postLocation(this.location)
      .subscribe(apiresponse => {
        if (this.isNew) {
          this.toastService.success('Location successfully created.');
        } else {
          this.toastService.success('Location successfully updated.');
        }
        this.searchLocation();
        this.clearModel();
        this.modalService.dismissAll();
      });
    }
  }
  searchLocation() {
    this.adminApiService.getLocation()
    .pipe(indicate(this.loading$))
    .subscribe(apiresponse => {
      this.locationList = apiresponse.data;
      this.locationList.forEach(element => {
        element.address1 = element.address1 == null ? element.address1 : atob(element.address1);
        element.address2 = element.address2 == null ? element.address2 : atob(element.address2);
      });
    });
  }
  openModel(content: any) {
    this.isNew = true;
    this.clearModel();
    this.modalService.open(content, { size: 'lg' });
  }
  clearModel() {
    this.location = new OfficeLocation();
    this.location.state_id = null;
  }
}
