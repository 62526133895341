<div class="content container-fluid" id="employee-salary-loading">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Employee - Salary</h3>
            </div>
        </div>
    </div>
    <div class="row filter-row">
        <div class="col-sm-6 col-md-2">
            <dx-date-box class="form-control filter-control" #dxSalaryDate id="calendar-container"
                displayFormat="monthAndYear" [(value)]="salaryDate" pickerType="calendar" type="date">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-2">
            <select class="form-control filter-control"
                [ngClass]="{'loading-control': (locationloading$ | async) === true }" id="location" name="location"
                [(ngModel)]="locationId">
                <option [ngValue]="undefined" selected>All Location</option>
                <option *ngFor="let item of locationList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="status" name="status"
                [ngClass]="{'loading-control': (loading$ | async) === true }" [(ngModel)]="statusId">
                <option [ngValue]="undefined" selected>All Status</option>
                <option *ngFor="let item of salaryStatusList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-2">
            <button class="btn btn-secondary btn-block" (click)="searchSalary()"> Search </button>
        </div>
        <div class="col-sm-6 col-md-2">
            <button class="btn btn-secondary btn-block" (click)="generateSalary()"> Calculate </button>
        </div>
        <div class="col-sm-6 col-md-2">
            <button class="btn btn-secondary btn-block" (click)="recalculateSalary()"
                [disabled]="salaryData.length===0">
                Recalculate </button>
        </div>

    </div>
    <div class="row mt-5">
        <div class="col-sm-12 col-md-12">
            <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="downloadEpf()" class="btn btn-white" [disabled]="salaryData.length==0"><i
                        class="fa fa-download fa-lg"></i> EPF</button>
            </div>
            <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="releasePayslip()" class="btn btn-white" [disabled]="salaryData.length==0"><i
                        class="fa fa-check fa-lg"></i> Release Payslip</button>
            </div>
            <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="openBankSheet()" class="btn btn-white" [disabled]="salaryData.length==0"><i
                        class="fa fa-university fa-lg"></i> Bank Sheet</button>
            </div>
            <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="openPFSheet()" class="btn btn-white" [disabled]="salaryData.length==0"><i
                        class="fa fa-file-o fa-lg"></i> PF Sheet</button>
            </div>
            <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="openCash()" class="btn btn-white" [disabled]="salaryData.length==0"><i
                        class="fa fa-money fa-lg"></i> Cash Salary</button>
            </div>
            <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="openPreview()" class="btn btn-white" [disabled]="salaryData.length==0"><i
                        class="fa fa-search fa-lg"></i> Preview</button>
            </div>
            <!-- <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="searchAllSalary()" class="btn btn-white" [disabled]="salaryData.length==0"><i
                        class="fa fa-lg" [ngClass]="{'fa-check-square': allEmployee, 'fa-square-o': !allEmployee}"></i>
                    All Employee</button>
            </div> -->
        </div>
        <div class="col-md-12 table-responsive mt-1" id="employee-grid-loading">
            <dx-data-grid #gridEmployee id="gridContainer" [dataSource]="salaryData" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false">
                <dxo-export [enabled]="true" fileName="employeeReport"></dxo-export>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                <dxi-column dataField="employee_name" [allowFiltering]="true" [allowSorting]="false"
                    cellTemplate="imageTemplate" caption="Employee" width="250"></dxi-column>
                <dxi-column dataField="employee_code" caption="Code" dataType="string"></dxi-column>
                <dxi-column dataField="location_name" caption="Location" dataType="string"></dxi-column>
                <dxi-column dataField="department" caption="Department" dataType="string"></dxi-column>
                <dxi-column dataField="no_of_days_worked" caption="Days Worked" dataType="number"
                    [allowFiltering]="false"></dxi-column>
                <dxi-column dataField="gross_salary" caption="Gross Salary" dataType="number" [allowFiltering]="false">
                </dxi-column>
                <dxi-column dataField="total_deduction" caption="Deductions" dataType="number" [allowFiltering]="false">
                </dxi-column>
                <dxi-column dataField="net_salary" caption="Net Salary" dataType="number" [allowFiltering]="false">
                </dxi-column>
                <dxi-column dataField="employee_salary_status" caption="Satus" [allowFiltering]="false"
                    [allowSorting]="false" cellTemplate="statusTemplate" alignment="center" width=150></dxi-column>
                <dxi-column dataField="employee_id" [allowFiltering]="false" width=50 caption="" [allowSorting]="false"
                    cellTemplate="actionTemplate" alignment="center">
                </dxi-column>
                <dxo-summary [calculateCustomSummary]="calculateCustom">
                    
                    <dxi-total-item name="Pending" summaryType="custom" displayFormat="Pending&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {0}"
                        showInColumn="employee_name">
                    </dxi-total-item>
                    <dxi-total-item name="PendingRelease" summaryType="custom" displayFormat="Pending Release : {0}"
                        showInColumn="employee_name">
                    </dxi-total-item>
                    <dxi-total-item name="Released" summaryType="custom" displayFormat="Released&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {0}"
                        showInColumn="employee_name">
                    </dxi-total-item>
                    <dxi-total-item column="net_salary" summaryType="sum" title="Total Salary"
                        displayFormat="Total Salary&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {0}" showInColumn="Employee">
                    </dxi-total-item>
                    <dxi-total-item column="employee_name" summaryType="count" title="Employee Count"
                        displayFormat="Employee Count : {0}">
                    </dxi-total-item>
                </dxo-summary>
                <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Pending_Release'">
                        <i class="fa fa-dot-circle-o text-warning"></i> Pending Release
                    </a>
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Payslip_Released'">
                        <i class="fa fa-dot-circle-o text-success"></i> Released
                    </a>
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);" *ngIf="cellInfo.value==null">
                        <i class="fa fa-dot-circle-o text-muted"></i> Pending
                    </a>
                </div>
                <div *dxTemplate="let data of 'imageTemplate'">
                    <h2 class="table-avatar">
                        <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                            [name]="data.row.data.employee_name">
                        </ngx-avatar>
                        <a class="ml-1 mt-1"> {{data.row.data.employee_name}}
                            <span *ngIf="data.row.data.employee_status==='Active'">{{data.row.data.designation}}
                            </span>
                            <span *ngIf="data.row.data.employee_status==='Resigned'" class="text-warning">Resigned
                                (last date:{{data.row.data.last_date| date : "dd-MM-yyyy" }})</span>
                            <span
                                *ngIf="data.row.data.employee_status==='Terminated' || data.row.data.employee_status==='Relieved'"
                                class="text-danger">{{data.row.data.employee_status}}</span>
                        </a>
                    </h2>
                </div>

                <div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
                    <div ngbDropdown container="body">
                        <a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle><i
                                class="material-icons">more_vert</i></a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                            <a *ngIf="cellInfo.row.data.employee_salary_status!=null" class="dropdown-item"
                                href="javascript:void(0);" (click)="openEditSalary(cellInfo)"><i
                                    class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a *ngIf="cellInfo.row.data.employee_salary_status=='Payslip_Released'"
                                class="dropdown-item" href="javascript:void(0);" (click)="openPayslip(cellInfo)"><i
                                    class="fa fa-file-o m-r-5"></i> Payslip</a>
                            <a *ngIf="cellInfo.row.data.employee_salary_status=='Payslip_Released'"
                                class="dropdown-item" href="javascript:void(0);"
                                (click)="changeSalaryStatus(cellInfo,'Pending_Release')"><i class="fa fa-ban m-r-5"></i>
                                Block
                                Payslip</a>
                            <a *ngIf="cellInfo.row.data.employee_salary_status=='Pending_Release'" class="dropdown-item"
                                href="javascript:void(0);" (click)="changeSalaryStatus(cellInfo,'Payslip_Released')"><i
                                    class="fa fa-check m-r-5"></i> Release Payslip</a>
                            <a class="dropdown-item" href="javascript:void(0);"
                                *ngIf="cellInfo.row.data.employee_salary_status!=null"
                                (click)="recalculateEmployeeSalary(cellInfo)"><i class="fa fa-calculator m-r-5"></i>
                                Recalculate</a>
                            <a class="dropdown-item" href="javascript:void(0);"
                                *ngIf="cellInfo.row.data.employee_salary_status==null"
                                (click)="calculateEmployeeSalary(cellInfo)"><i class="fa fa-calculator m-r-5"></i>
                                Calculate</a>
                            <a class="dropdown-item" href="javascript:void(0);"
                                *ngIf="cellInfo.row.data.employee_salary_status!=null"
                                (click)="remarksEmployeeSalary(cellInfo)"><i class="fa fa-pencil-square-o m-r-5"></i>
                                Remarks</a>
                        </div>
                    </div>
                </div>
            </dx-data-grid>

        </div>
    </div>
</div>
<!-- Modal window contents -->
<ng-template #salarymodalcontent let-c="close" let-d="dismiss">
    <form #salaryForm="ngForm">
        <div class="modal-header">
            <div *ngIf="employeeSalary.has_salary;else edit_content">
                <h4 class="modal-title" id="modal-basic-title">Edit Salary</h4>
            </div>
            <ng-template #edit_content>
                <h4 class="modal-title" id="modal-basic-title">Add Salary</h4>
            </ng-template>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <app-salary-component #salaryComponent [(employeeSalary)]="employeeSalary"
                [(earningTypeList)]="earningTypeList" [(deductionTypeList)]="deductionTypeList"></app-salary-component>
            <div class="form-group row pt-3">
                <div class="col-sm-6">
                    <label for="days">No Of Working Days <span class="text-danger">*</span></label>
                    <input class="form-control" [(ngModel)]="employeeSalary.no_of_days_worked" type="text" id="days"
                        name="days" #days="ngModel" [pattern]="global.numericValidator">
                    <div *ngIf="days.invalid && (days.dirty || days.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="days.errors.required">
                            No Of Days is required.
                        </div>
                        <div *ngIf="days.errors.pattern">
                            No Of Days is invalid.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="saveSalary(salaryForm)"
                [disabled]="!salaryForm.valid">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>
<ng-template #salaryremarkmodalcontent let-c="close" let-d="dismiss">
    <form #salaryremarkForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Salary Remark </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="remark">Remarks <span class="text-danger">*</span></label>
                    <textarea rows="4" class="form-control" id="remark" name="remark"
                        [(ngModel)]="employeeSalary.remarks" #remark="ngModel" required></textarea>
                </div>
                <div *ngIf="remark.invalid && (remark.dirty || remark.touched)"
                    class="badge badge-danger position-absolute">
                    <div *ngIf="remark.errors.required">
                        Salary Remark is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="saveRemark(salaryremarkForm)"
                [disabled]="!salaryremarkForm.valid">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#employee-salary-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#employee-salary-loading"></dx-load-panel>
</div>
<div *ngIf="(gridloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#employee-grid-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#employee-grid-loading"></dx-load-panel>
</div>