import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { CustomField, UserAccess, UserRole } from './common';

export class EmployeeRelease {
    employee_release_id: number;
    employee_id: number;
    release_type_id: number = null;
    release_type: string;
    release_sub_type_id: number = null;
    release_sub_type: string;
    release_date: Date;
    last_date: Date;
    reason: string;
    updated_date: Date;
    updated_by_name: string;
    constructor() {
    }
}

export class RecurverRelease {
    recurver_release_id: number;
    recurver_status_id:number;
    recurver_id: number;
    company_id: number;
    release_type_id: number = null;
    release_type: string;
    release_sub_type_id: number = null;
    release_sub_type: string;
    join_date: Date;
    deadline:Date;
    location:string;
    last_date: Date;
    salary:number;
    user_name:string;
    display_name:string;
    position: string;
    updated_date: Date;
    interview_date:Date;
    updated_by_name: string;
    resume_upload:string;
    constructor() {
    }
}

export class Resumeupload {
   resume: string;
   file: File;
   name:string;
   user_id:number;
   recurver_id:number;

    constructor() {
    }
}



export class EmployeeBankDetails {
    public employee_id: number;
    public account_number: number;
    public bank_branch: string;
    public pan_number: string;
    public ifsc_code: string;
    public bank_name: string;
    constructor() {
    }
}
export class EmployeeWorkHistory {
    public employee_work_history_id: number;
    public employee_id: number;
    public company_name: string;
    public from_date: Date;
    public to_date: Date;
    public designation: string;
    public last_drawn_salary: number;
    public duration: string;
    public reason: string;
    constructor() { }
}
export class EmployeeKpi {
    public employee_kpi_id: number;
    public kpi_date: Date;
    public employee_id: number;
    public kra_id: number;
    public kpi_name: string;
    public kra: string;
    public kpi_description: string;
    public weightage: number;
    public kpi_performance_id: number;
    public performance_cycle_id: number;
    public rating: string;
    public rating_description: string;
    public employee_performance_id: number;
    constructor() { }
}
export class EmployeeComment {
    public employee_comment_id: number;
    public employee_id: number;
    public employee_comment_type_id: number;
    public employee_comment_type: string;
    public employee_comment: string;
    public comment_date: Date;
    constructor() { }
}
export class EmployeeSalaryIncrement {
    public increment_id: number;
    public employee_id: number;
    public financial_year: string;
    public comments: string;
    public increment_date: Date;
    public effective_from: Date;
    public percentage_hike: number;
    constructor() { }
}
export class EmployeePersonalInfo {
    public employee_id: number;
    public crime_record: number;
    public political_activity: number;
    public aadhar: string;
    public pan_number: string;
    public father_or_husband_name: string;
    public father_or_husband_relationship_id: number = null;
    public father_or_husband_relationship: string;
    public prefix_id: number = null;
    public prefix: string;
    public marital_status_id: number = null;
    public marital_status: string;
    public date_of_birth: Date;
    public phone: number;
    public official_phone: number;
    public email: string;
    public official_email: string;
    public address: string;
    public city: string;
    public state_id: number = null;
    public state: string;
    public permanent_address: string;
    public permanent_city: string;
    public permanent_state_id: number = null;
    public permanent_state: string;
    public permanent_pincode: string;
    public pincode: string;
    public gender_id: number = null;
    public gender: string;
    public profile_picture: string;
    public emergency_contact: string;
    public emergency_contact_relation_id: number = null;
    public emergency_contact_relation: string;
    public blood_group_id: number = null;
    public blood_group: string;
    public qualification: string;
    constructor() {
    }
}

export class RecurverPersonalInfo {
    public recurver_id: number;
    public date_of_birth: Date;
    public phone: number;
    public official_phone: number;
    public email: string;
    public official_email: string;
    public address: string;
    public city: string;
    public state_id: number = null;
    public state: string;
    public permanent_address: string;
    public permanent_city: string;
    public permanent_state_id: number = null;
    public permanent_state: string;
    public permanent_pincode: string;
    public pincode: string;
    public gender_id: number = null;
    public gender: string;
    public blood_group_id: number = null;
    public blood_group: string;
    public qualification: string;
    constructor() {
    }
}

export class Employee {
    public employee_id: number;
    public is_new: boolean;
    public restrict_location: boolean;
    public full_name: string;
    public last_date: Date;
    public first_name: string;
    public user_name: string;
    public user_id: number;
    public last_name: string;
    public employee_code: string;
    public designation_id: number = null;
    public designation: string;
    public department_id: number = null;
    public department: string;
    public referred_by_id: number = null;
    public referred_by_name: string;
    public reporting_to_id: number = null;
    public reporting_to_name: string;
    public reporting_to_profile_picture: string;
    public shift_id: number = null;
    public shift_name: string;
    public shift_start_time: Date;
    public shift_end_time: Date;
    public salary_type_id: number = null;
    public salary_type: string;
    public category_id: number = null;
    public category: string;
    public location_id: number = null;
    public location: string;
    public bonus_type_id: number = null;
    public overall_experience: number = null;
    public bonus_type: string;
    public ctc_policy_id: number = null;
    public ctc_policy: string;
    public date_of_joining: Date;
    public employee_status_id: number = null;
    public employee_status: string;
    public personal_info: EmployeePersonalInfo = new EmployeePersonalInfo();
    public bank_details: EmployeeBankDetails = new EmployeeBankDetails();
    public pf_number: string;
    public uan_number: string;
    public esic_no: string;
    public gross_salary: number;
    public holiday_calendar_id: number = null;
    public holiday_calendar: string;
    public employee_type_id: number = null;
    public employee_type: string;
    public leave_policy_id: number = null;
    public leave_policy: string;
    public permission_policy_id: number = null;
    public permission_policy: string;
    public payroll_earning_policy_id: number = null;
    public payroll_earning_policy: string;
    public payroll_deduction_policy_id: number = null;
    public payroll_deduction_policy: string;
    public attendance_type_id: number = null;
    public attendance_type: string;
    public device_employee_id: string;
    public display_name: string;
    public create_erp_user_flag: number;
    public roles: UserRole[] = [];
    public access: UserAccess[] = [];
    public custom_fields: CustomField[] = [];
    constructor() {
    }
}

export class Employeereliev {
       public employee_code:string;
       public first_name:string;
       public last_name:string;
       public designation_id:number; 
       public department_id:number;
       public date_of_joining:Date;
       public gross_salary:number;
       public employee_status_id:number;
       public company_name:string;
       public company_logo:string;
       public address:string;
       public address1:string;
       public city:string;
       public state:string;
       public pin:number;
       public phone:number;
       public official_email:string;
       public eaddress:string;
       public estate:string;
       public ecity:string;
       public epincode:number;
       public company_email:string;
       public employee_id:number;
       public user_id:number;
       public user_name:string;
       public display_name:string;
       public company_id:number;
       public release_type_id:number;
       public release_date:Date;
       public last_date:Date;
    constructor() {
    }
}

export class Recurver {
  find(arg0: (d: any) => boolean): import("@angular/common").KeyValue<number, string> {
    throw new Error('Method not implemented.');
  }
  access: any;
    public recurver_id: number;
    public company_id: number;
    public first_name: string;
    public last_name: string;
    public full_name: string;
    public last_date: Date;
    public user_name: string;
    public user_id: number;
    public qualification: string;
    public date_of_birth: Date; // Adjusted to use Date type
    public gender: string;
    public personal_info: RecurverPersonalInfo = new RecurverPersonalInfo();
    public gender_id: number = null;
    public personal_phone: string;
    public phone: number;
    public email: string;
    public alt_phone: string;
    public address: string;
    public city: string;
    public state: string;
    public state_id: number = null;
    public pincode: string;
    public location_id: number = null;
    public location: string;
    public permanent_address: string;
    public permanent_city: string;
    public permanent_state_id: number = null;
    public permanent_state: string;
    public permanent_pincode: string;
    public blood_group_id: number = null;
    public blood_group: string;
    public updated_by: number;
    public recurver_status_id: number=null;
    public recurver_status: string;
    public position: string;
    public roles: UserRole[] = [];
    public release_date : Date;
    public  salary:number;
    public  resume_upload:string;
    // public personal_info: Recurver = new Recurver();
    public emergency_contact: string;
    public emergency_contact_relation_id: number = null;
    public emergency_contact_relation: string;
    // Additional properties not present in the SQL schema
    public is_new: boolean;
  employee_code: string;
  static address: string;

    constructor() {

    }
}

export class RecurverAsset {
    public recurver_name: string;
    public assigned_date: Date;
    public return_date: Date;
    public employee_asset_status_id: number = null;
    public employee_asset_status: string;
    public description: string;
    constructor() {
    }
}

export class EmployeeAsset {
    public employee_name: string;
    public assigned_date: Date;
    public return_date: Date;
    public employee_asset_status_id: number = null;
    public employee_asset_status: string;
    public description: string;
    constructor() {
    }
}
export class AssetService {
    public asset_service_id: number;
    public asset_id: number;
    public service_date: Date;
    public service_type_id: number = null;
    public service_type: string;
    public service_amount: number;
    public description: string;
    constructor() {
    }
}
export class AssetRequest {
    public asset_request_id: number;
    public employee_id: number;
    public employee_code: Variable;
    public employee_name: string;
    public request_date: Date;
    public asset_type_id: number;
    public asset_type: string;
    public asset_request_status_id: number = null;
    public asset_request_status: string;
    public description: string;
    constructor() {
    }
}
export class Asset {
    public asset_id: number;
    public employee_id: number;
    public asset_code: string;
    public employee_name: string;
    public asset_name: string;
    public purchase_date: Date;
    public manufacturer: string;
    public model: string;
    public serial_number: string;
    public asset_condition_id: number = null;
    public asset_condition: string;
    public warranty_till_date: Date;
    public asset_value: number;
    public description: string;
    public asset_type_id: number = null;
    public asset_type: string;
    public asset_ownership_type_id: number = null;
    public asset_ownership_type: string;
    public asset_status_id: number = null;
    public asset_status: string;
    public replace_asset_id: number = null;
    public updated_date: Date;
    public updated_by: number;
    public employee_assets: EmployeeAsset[];
    public services: AssetService[];
    constructor() {
    }
}

export class OfficeLocation {
    public location_id: number;
    public location_name: string;
    public location_code: string;
    public latitude: string;
    public longitude: string;
    public address1: string;
    public address2: string;
    public city: string;
    public state: string;
    public state_id: number;
    public pincode: string;
    public phone: string;
    constructor() {
    }
}
export class Shift {
    public shift_id: number;
    public shift_name: string;
    public shift_start_time: Date;
    public shift_end_time: Date;
    public shift_duration: number;
    public break_duration: number;
    constructor() {
    }
}
export class EmployeeDocument {
    public document_name: string;
    public document_path: string;
    public document_type_id: number;
    public document_type: string;
    constructor() {
    }
}
export class HrDashboardReportData {
    public gender_report: any[] = [];
    public age_report: any[] = [];
    public location_report: any[] = [];
    public salary_report: any[] = [];
    public summary_report: any[] = [];
    constructor() {
    }
}
export class SurveyReportData{
    public company_custom_field_id:number;
    public field_name:string;
    public result:any[]=[];
}
export class Survey {
    public survey_id: number;
    public company_id: number;
    public survey_name: string;
    public survey_description: string;
    public updated_by: number;
    public updated_date: Date;
    public survey_report:SurveyReportData[];
    constructor(){
        
    }
}
