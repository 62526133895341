<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Survey</h3>
            </div>
            <div class="col-auto float-right ml-auto" >
                <button class="btn add-btn ml-3" (click)="saveSurvey()" *ngIf="showSurvey"> Save </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex">
            <div class="card profile-box flex-fill">
                <div class="card-body p-5" id="survey-panel">
                    <div class="form-group row">
                        <div class="col-sm-12" *ngIf="showSurvey">
                            <app-custom-fields #customField *ngIf="customFieldList"
                                [customFieldList]="customFieldList"></app-custom-fields>
                        </div>
                        <div class="col-sm-12" *ngIf="!showSurvey">
                            Survey already submitted. Thanks for your response.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="(surveyCycleLoading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#survey-panel' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#survey-panel">
    </dx-load-panel>
</div>