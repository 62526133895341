import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DxDateBoxComponent } from 'devextreme-angular';
import { of, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ApiResponse, Company } from 'src/app/model/common';
import { EmployeeSalary } from 'src/app/model/payroll';
import { CommonApiService } from 'src/app/services/common-api.service';
import { indicate } from 'src/app/services/loading.service';
import { PayrollApiService } from 'src/app/services/payroll-api.service';
import { Global } from 'src/app/shared/global';
import { jsPDF } from 'jspdf';
// import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { Location } from '@angular/common';

@Component({
  selector: 'app-payslip-view',
  templateUrl: './payslip-view.component.html',
  styleUrls: ['./payslip-view.component.scss']
})
export class PayslipViewComponent implements AfterViewInit, OnInit, OnDestroy {
  intializeData: any = null;
  employeeId: number = null;
  month: number = null;
  year: number = null;
  payslipDate: Date = new Date();
  showFilter = false;
  employeeSalary: EmployeeSalary = new EmployeeSalary();
  company: Company = new Company();
  loading$ = new Subject<boolean>();
  subscriptions: Subscription[] = [];
  @ViewChild('printContent', { static: true }) printContent: ElementRef;
  @ViewChild('dxSalaryDate', { static: false }) dxSalaryDate: DxDateBoxComponent;
  constructor(
    private payrollApiService: PayrollApiService,
    private commonApiService: CommonApiService,
    public global: Global,
    private location: Location) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  ngOnInit() {
    const url = this.location.path();
    if (url === '/payslip') {
      this.subscriptions.push(
        this.commonApiService.getRequestHeader()
          .subscribe(apiresponse => {
            this.global.loadUserSession(apiresponse.data.user);
            this.commonApiService.setToken(this.global.loggedUser.token);
            this.payrollApiService.setToken(this.global.loggedUser.token);
            this.global.isMobileApp = true;
            this.initialize();
          }));
    } else {
      this.initialize();
    }

  }
  initialize() {
    // tslint:disable-next-line:max-line-length
    const payslipCall: boolean = this.intializeData && this.intializeData.id && this.intializeData.id.month && this.intializeData.id.year && this.intializeData.id.employeeId;
    if (payslipCall) {
      this.month = this.intializeData.id.month;
      this.year = this.intializeData.id.year;
      this.employeeId = this.intializeData.id.employeeId;
    } else {
      this.showFilter = true;
    }
    this.subscriptions.push(
      this.commonApiService.getCompany()
      .pipe(
        switchMap((apiresponse: ApiResponse) => {
          this.company = apiresponse.data[0];
          if (payslipCall) {
            return this.payrollApiService.getEmployeeSalary(this.year, this.month, this.employeeId, true);
          } else {
            return of(null);
          }
        }))
      .subscribe((apiresponse: ApiResponse) => {
        if (payslipCall) {
          this.setSalary(apiresponse);
        }
      }));
  }
  ngAfterViewInit() {
    this.dxSalaryDate.calendarOptions = { zoomLevel: 'year', maxZoomLevel: 'year', minZoomLevel: 'decade' };
  }
  setSalary(apiresponse) {
    this.employeeSalary = apiresponse.data[0];
    if (this.employeeSalary) {
      // tslint:disable-next-line:max-line-length
      this.employeeSalary.deduction_components = this.employeeSalary.salary_components.filter(d => d.salary_component_type === 'Deduction');
      this.employeeSalary.earning_components = this.employeeSalary.salary_components.filter(d => d.salary_component_type === 'Earning');
    }
    this.showFilter = false;
  }
  getPayslip() {
    this.subscriptions.push(
      this.payrollApiService
    .getEmployeeSalary(this.payslipDate.getFullYear(), (this.payslipDate.getMonth() + 1), this.global.loggedUser.user_type_internal_id, true)
      .pipe(indicate(this.loading$))
      .subscribe(apiresponse => {
        this.setSalary(apiresponse);
      }));
  }
  getPdf() {
    const data = document.getElementById('payslipContent');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save(`payslip_${this.employeeSalary.employee_code}_${this.employeeSalary.employee_salary_id}.pdf`); // Generated PDF
    });
  }
  back(d: DxDateBoxComponent) {
    this.showFilter = true;
    d.calendarOptions = { zoomLevel: 'year', maxZoomLevel: 'year', minZoomLevel: 'decade' };
  }
  print() {
    this.global.print(this.printContent.nativeElement.innerHTML);
  }
  getTotalEarning(): number {
    return this.employeeSalary.earning_components.reduce((acc, current) => acc + current.salary_component_value, 0);
  }
  
  getTotalDeduction(): number {
    return this.employeeSalary.deduction_components.reduce((acc, current) => acc + current.salary_component_value, 0);
  }
}
