<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Locations</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn" (click)="openModel(locationmodalcontent)"><i class="fa fa-plus"></i> Add
                    Location</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-3 table-responsive" id="location-loading">
            <dx-data-grid #gridlocation id="gridContainer" [dataSource]="locationList" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false">
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxi-column dataField="location_name" caption="Name" dataType="string"></dxi-column>
                <dxi-column dataField="phone" caption="Phone" dataType="string"></dxi-column>
                <dxi-column dataField="address1" caption="Address" cellTemplate="addressTemplate"></dxi-column>
                <div *dxTemplate="let data of 'addressTemplate'">
                    <ul class="list-unstyled mb-0">
                        <li>{{data.row.data.address1}},</li>
                        <li>{{data.row.data.address2}}</li>
                        <li>{{data.row.data.state}}, {{data.row.data.city}}, {{data.row.data.pincode}}</li>
                    </ul>
                </div>
                <dxi-column dataField="location_id" [allowFiltering]="false" width=50 caption="" [allowSorting]="false"
                    cellTemplate="actionTemplate" alignment="center">
                    <div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
                        <div ngbDropdown container="body">
                            <a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle><i
                                    class="material-icons">more_vert</i></a>
                            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript:void(0);" (click)="editLocation(cellInfo)"><i
                                        class="fa fa-pencil m-r-5"></i> Edit</a>
                            </div>
                        </div>
                    </div>
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>
    <ng-template #locationmodalcontent let-c="close" let-d="dismiss">
        <form #locationForm="ngForm">
            <div class="modal-header">
                <div *ngIf="isNew;else edit_content">
                    <h4 class="modal-title" id="modal-basic-title">Add Location</h4>
                </div>
                <ng-template #edit_content>
                    <h4 class="modal-title" id="modal-basic-title">Edit Location</h4>
                </ng-template>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="locationname">Location Name <span class="text-danger">*</span></label>
                        <input class="form-control" [(ngModel)]="location.location_name" type="text" id="locationname"
                            name="locationname" #locationname="ngModel" [pattern]="global.alphaNumericValidator"
                            required>
                        <div *ngIf="locationname.invalid && (locationname.dirty || locationname.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="locationname.errors.pattern">
                                Location Name is invalid.
                            </div>
                            <div *ngIf="locationname.errors.required">
                                Location Name is Required.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="locationcode">Location Code</label>
                        <input class="form-control" [(ngModel)]="location.location_code" type="text" id="locationcode"
                            name="locationcode" #locationcode="ngModel" [pattern]="global.alphaNumericValidator"
                            required>
                        <div *ngIf="locationcode.invalid && (locationcode.dirty || locationcode.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="locationcode.errors.pattern">
                                Location Code is invalid.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="address1">Address 1 </label>
                        <input class="form-control" [(ngModel)]="location.address1" type="text" id="address1"
                            name="address1">
                    </div>
                    <div class="col-sm-6">
                        <label for="address2">Address 2 </label>
                        <input class="form-control" [(ngModel)]="location.address2" type="text" id="address2"
                            name="address2">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="state">State</label>
                        <select class="form-control" id="state" name="state" [(ngModel)]="location.state_id"
                            #state="ngModel" [ngClass]="{'is-invalid': state.invalid && state.touched}">
                            <option [ngValue]="null" selected>Select State</option>
                            <option *ngFor="let item of stateList; let i = index" [ngValue]="item.key">
                                {{item.value}}</option>
                        </select>
                    </div>
                    <div class="col-sm-6">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="city">City </label>
                        <input class="form-control" [(ngModel)]="location.city" type="text" id="city" name="city">
                    </div>
                    <div class="col-sm-6">
                        <label for="pin">Pincode </label>
                        <input class="form-control" [(ngModel)]="location.pincode" type="text" id="pin" name="pin">
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="saveLocation(locationForm)"
                    [disabled]="!locationForm.valid">Save</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
</div>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#location-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#location-loading">
    </dx-load-panel>
</div>