import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Survey, SurveyReportData } from "src/app/model/admin";
import { AdminApiService } from "src/app/services/admin-api.service";
import { Global } from "src/app/shared/global";

@Component({
  selector: "app-survey-report",
  templateUrl: "./survey-report.component.html",
  styleUrls: ["./survey-report.component.scss"],
})
export class SurveyReportComponent implements OnInit, OnDestroy {
  survey_Id: number;
  intializeData: any = null;
  surveyData: SurveyReportData[];
  subscriptions: Subscription[] = [];
  constructor(private adminService: AdminApiService, public global: Global) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  ngOnInit(): void {
    if (this.intializeData && this.intializeData.surveyId) {
      this.survey_Id = this.intializeData.surveyId;
    }
    this.subscriptions.push(
      this.adminService
        .getSurveyReport(this.survey_Id)
        .subscribe((apiresponse) => {
          this.surveyData = apiresponse.data.survey_report;
          if (this.surveyData) {
            this.surveyData = [];
            apiresponse.data.survey_report.forEach((e) => {
              let result: any[] = [];
              result.push({ results: "1", val: e.results[1] });
              result.push({ results: "2", val: e.results[2] });
              result.push({ results: "3", val: e.results[3] });
              result.push({ results: "4", val: e.results[4] });
              result.push({ results: "5", val: e.results[5] });
              result.push({ results: "Not Available", val: e.results.NA });
              this.surveyData.push({
                field_name: e.field_name,
                result: result,
                company_custom_field_id: e.company_custom_field_id,
              });
            });
          }
        })
    );
  }
}
