<div class="content container-fluid" #printContent>
    <div class="page-header">
        <div class="row">
            <div class="col">
                <h3 class="page-title">Profile</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
            </div>
        </div>
    </div>
    <div class="card mb-0">
        <div class="card-body" id="user-emp-loading">
            <div class="row">
                <div class="col-md-12">
                    <div class="profile-view">
                        <!-- <div class="profile-img-wrap">
                            <div class="profile-img">
                                <h2 class="table-avatar">
                                    <ngx-avatar size="250" [src]="employee.personal_info.profile_picture"
                                        [name]="employee.first_name + ' ' + employee.last_name">
                                    </ngx-avatar>
                                </h2>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-2">
                                <img [src]="employee.personal_info.profile_picture" width="150px">
                            </div>
                            <div class="col-md-5">
                                <div class="profile-info-left">
                                    <h3 class="user-name m-t-0 mb-0">{{employee.first_name}} {{employee.last_name}}
                                    </h3>
                                    <small class="text-muted">Designation: {{employee.designation}}</small>
                                    <h6 class="text-muted">Department: {{employee.department}}</h6>
                                    <h6 class="text-muted" *ngIf="employee.shift_name">Shift: {{ employee.shift_name
                                        }} - ( {{employee.shift_start_time | date : "shortTime" }} to
                                        {{employee.shift_end_time | date :
                                        "shortTime"}})</h6>
                                    <div class="staff-id">Employee ID : {{employee.employee_code}}</div>
                                    <div class="small  doj text-muted">Date of Join :
                                        {{ employee.date_of_joining | date : "dd-MM-yyyy" }}
                                    </div>
                                    <div class="small text-muted">Experience :
                                        {{experienceDuration}}
                                    </div>
                                    <div class="small text-muted" *ngIf="employee.reporting_to_name!==null &&
                                        employee.reporting_to_name!=='null' &&
                                        employee.reporting_to_name!==''">Reporting To :
                                        {{employee.reporting_to_name}}
                                    </div>
                                    <div class="small text-muted">Status :
                                        <span *ngIf="employee.employee_status=='Active'"
                                            class="badge bg-inverse-success">Active </span>
                                        <span *ngIf="employee.employee_status=='Resigned'"
                                            class="badge bg-inverse-warning">Resigned (last
                                            date:{{employee.last_date| date : "dd-MM-yyyy" }})</span>
                                        <span *ngIf="employee.employee_status=='InActive'"
                                            class="badge bg-inverse-secondary">InActive</span>
                                        <span *ngIf="employee.employee_status=='Terminated'"
                                            class="badge bg-inverse-danger">Terminated</span>
                                        <span *ngIf="employee.employee_status=='Relieved'"
                                            class="badge bg-inverse-danger">Relieved</span>
                                    </div>
                                    <div class="small text-muted"
                                        *ngIf="employee.employee_status=='Relieved' || employee.employee_status=='Terminated'">
                                        Date Releived :
                                        {{employee.last_date | date : "dd-MM-yyyy" }}
                                    </div>
                                    <div class="staff-msg">
                                        <button *ngIf="hasHrRole" class="btn btn-custom"
                                            (click)="openModel(messagemodalcontent)"> Send Message </button>
                                        <button
                                            *ngIf="hasEmpRole && employee.employee_id==global.loggedUser.user_type_internal_id"
                                            class="btn btn-custom ml-3" (click)="openModel(profilepicmodalcontent)">
                                            Update Profile Picture
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="title mt-3">Present Address:</div>
                                <div class="text">
                                    <ul class="list-unstyled mb-0 text-muted">
                                        <li> {{(employee.personal_info.address===null ||
                                            employee.personal_info.address==='null'||
                                            employee.personal_info.address==='')?'&nbsp;':employee.personal_info.address}}
                                        </li>
                                        <li>{{(employee.personal_info.city===null ||
                                            employee.personal_info.city==='null'||
                                            employee.personal_info.city==='')?'&nbsp;':employee.personal_info.city +
                                            ','}}
                                            {{(employee.personal_info.state===null ||
                                            employee.personal_info.state==='null'||
                                            employee.personal_info.state==='')?'&nbsp;':employee.personal_info.state
                                            + '-'}}
                                            {{(employee.personal_info.pincode===null ||
                                            employee.personal_info.pincode==='null'||
                                            employee.personal_info.pincode==='')?'&nbsp;':employee.personal_info.pincode}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="title mt-3">Permanent Address:</div>
                                <div class="text">
                                    <ul class="list-unstyled mb-0 text-muted">
                                        <li> {{(employee.personal_info.permanent_address===null ||
                                            employee.personal_info.permanent_address==='null'||
                                            employee.personal_info.permanent_address==='')?'&nbsp;':employee.personal_info.permanent_address}}
                                        </li>
                                        <li>{{(employee.personal_info.permanent_city===null ||
                                            employee.personal_info.permanent_city==='null'||
                                            employee.personal_info.permanent_city==='')?'&nbsp;':employee.personal_info.permanent_city+
                                            ','}}
                                            {{(employee.personal_info.permanent_state===null ||
                                            employee.personal_info.permanent_state==='null'||
                                            employee.personal_info.permanent_state==='')?'&nbsp;':employee.personal_info.permanent_state+
                                            '-'}}
                                            {{(employee.personal_info.permanent_pincode===null ||
                                            employee.personal_info.permanent_pincode==='null'||
                                            employee.personal_info.permanent_pincode==='')?'&nbsp;':employee.personal_info.permanent_pincode}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="pro-edit"><a href="javascript:void(0);" class="edit-icon" (click)="print()"><i
                                    class="fa fa-print"></i></a></div>
                        <div class="pro-edit" *ngIf="showEdit"><a href="javascript:void(0);" class="edit-icon"
                                (click)="openXlModel(employeemodalcontent)"><i class="fa fa-pencil"></i></a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card tab-box">
        <div class="row user-tabs">
            <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul ngbNav #navTab="ngbNav" (activeId)="1" class="nav nav-tabs nav-tabs-bottom" [destroyOnHide]="false"
                    (navChange)="onNavChange($event)">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink class="nav-link hidePrint">
                            Profile
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-6 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body" id="user-emp-loading-1">
                                            <div class="pro-edit" *ngIf="showEdit"><a href="javascript:void(0);"
                                                    class="edit-icon" (click)="openModel(personalmodalcontent)"><i
                                                        class="fa fa-pencil"></i></a></div>
                                            <h3 class="card-title">Contact information</h3>
                                            <ul class="personal-info">
                                                <li>
                                                    <div class="title">Personal Phone</div>
                                                    <div class="text">
                                                        {{employee.personal_info.phone===null?'NA':employee.personal_info.phone}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Personal Email</div>
                                                    <div class="text">
                                                        {{employee.personal_info.email===null?'NA':employee.personal_info.email}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Official Phone</div>
                                                    <div class="text">
                                                        {{employee.personal_info.official_phone===null?'NA':employee.personal_info.official_phone}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Official Email</div>
                                                    <div class="text">
                                                        {{employee.personal_info.official_email===null?'NA':employee.personal_info.official_email}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Emergency Number
                                                        <span
                                                            *ngIf="employee.personal_info.emergency_contact_relation!==null && employee.personal_info.emergency_contact_relation !==''">({{employee.personal_info.emergency_contact_relation}})</span>
                                                    </div>
                                                    <div class="text">
                                                        {{employee.personal_info.emergency_contact===null?'NA':employee.personal_info.emergency_contact}}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body" id="user-emp-loading-2">
                                            <h3 class="card-title">General information</h3>
                                            <ul class="personal-info">
                                                <li>
                                                    <div class="title">Job Location</div>
                                                    <div class="text">
                                                        {{employee.location===null?'22222222222222':employee.location}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Holiday Calendar</div>
                                                    <div class="text">
                                                        {{employee.holiday_calendar===null?'NA':employee.holiday_calendar}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Bonus</div>
                                                    <div class="text">
                                                        {{employee.bonus_type===null?'NA':employee.bonus_type}}</div>
                                                </li>
                                                <li>
                                                    <div class="title">Salary Type</div>
                                                    <div class="text">
                                                        {{employee.salary_type===null?'NA':employee.salary_type}}</div>
                                                </li>
                                                <li>
                                                    <div class="title">UAN No</div>
                                                    <div class="text">
                                                        {{employee.uan_number===null||employee.uan_number===''?'NA':employee.uan_number}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">ESCI No</div>
                                                    <div class="text">
                                                        {{employee.esic_no===null||employee.esic_no===''?'NA':employee.esic_no}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">PF No</div>
                                                    <div class="text">
                                                        {{employee.pf_number===null||employee.pf_number===''?'ywtettte6667373737':employee.pf_number}}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body" id="user-emp-loading-3">
                                            <h3 class="card-title">Personal information</h3>
                                            <ul class="personal-info">

                                                <li>
                                                    <div class="title">
                                                        Date Of Birth
                                                    </div>
                                                    <div class="text">
                                                        {{ employee.personal_info.date_of_birth===null?'NA':
                                                        employee.personal_info.date_of_birth
                                                        | date : "dd-MM-yyyy" }}
                                                    </div>
                                                </li>
                                                <li *ngIf="employee.personal_info.father_or_husband_name!==null">
                                                    <div class="title">
                                                        {{employee.personal_info.father_or_husband_relationship}} Name
                                                    </div>
                                                    <div class="text">
                                                        {{employee.personal_info.father_or_husband_name===null?'NA':employee.personal_info.father_or_husband_name}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Aadhar No</div>
                                                    <div class="text">
                                                        {{employee.personal_info.aadhar===null?'NA':employee.personal_info.aadhar}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Gender</div>
                                                    <div class="text">
                                                        {{employee.personal_info.gender===null?'NA':employee.personal_info.gender}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Qualification</div>
                                                    <div class="text">
                                                        {{employee.personal_info.qualification===null?'NA':employee.personal_info.qualification}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Blood Group</div>
                                                    <div class="text">
                                                        {{employee.personal_info.blood_group===null?'NA':employee.personal_info.blood_group}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">PAN No</div>
                                                    <div class="text">
                                                        {{employee.bank_details.pan_number===null||employee.bank_details.pan_number===''?'NA':employee.bank_details.pan_number}}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body" id="user-emp-loading-4">
                                            <!-- <div class="pro-edit" *ngIf="showEdit"><a href="javascript:void(0);"
                                                    class="edit-icon" (click)="openModel(bankmodalcontent)"><i
                                                        class="fa fa-pencil"></i></a></div> -->
                                            <h3 class="card-title">Bank information</h3>
                                            <ul class="personal-info">
                                                <li>
                                                    <div class="title">Bank name</div>
                                                    <div class="text">
                                                        {{employee.bank_details.bank_name===null||employee.bank_details.bank_name===''?'NA':employee.bank_details.bank_name}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Bank Branch</div>
                                                    <div class="text">
                                                        {{employee.bank_details.bank_branch===null||employee.bank_details.bank_branch===''?'NA':employee.bank_details.bank_branch}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">Bank account No</div>
                                                    <div class="text">
                                                        {{employee.bank_details.account_number===null?'NA':employee.bank_details.account_number}}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="title">IFSC Code</div>
                                                    <div class="text">
                                                        {{employee.bank_details.ifsc_code===null||employee.bank_details.ifsc_code===''?'NA':employee.bank_details.ifsc_code}}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink class="nav-link hidePrint">
                            Salary
                        </a>
                        <ng-template ngbNavContent class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="stats-info bg-card bg-holder" id="user-emp-monthly">
                                        <h6>Monthly Compensation</h6>
                                        <h4>{{(employee.gross_salary) | number : '1.2-2'}}</h4>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="stats-info bg-card bg-holder" id="user-emp-ctc">
                                        <h6>Current Annual CTC</h6>
                                        <h4>{{annualCtc | number : '1.2-2'}}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col">
                                    <h4 class="section-title">Earnings</h4>
                                </div>
                            </div>
                            <div class="row p-3 min-height-100" id="user-emp-earning">
                                <div class="col-md-12 table-responsive no-header" *ngIf="employeeSalary">
                                    <dx-data-grid id="gridSalary" [dataSource]="employeeSalary.earning_components">
                                        <dxo-paging [enabled]="false"></dxo-paging>
                                        <dxo-summary>
                                            <dxi-total-item column="salary_component_value" summaryType="sum"
                                                displayFormat="Net Monthly Salary: {0}">
                                            </dxi-total-item>
                                            <dxi-total-item column="salary_component_annual_value" summaryType="sum"
                                                displayFormat="Annual CTC: {0}">
                                            </dxi-total-item>
                                        </dxo-summary>
                                        <dxi-column dataField="salary_component_sub_type" caption="Pay Component"
                                            dataType="string">
                                        </dxi-column>
                                        <dxi-column dataField="salary_component_annual_value" caption="Annual Amount"
                                            dataType="number" cellTemplate="amountTemplate">
                                        </dxi-column>
                                        <dxi-column dataField="salary_component_value" caption="Monthly Amount"
                                            dataType="number" cellTemplate="amountTemplate">
                                        </dxi-column>
                                        <div *dxTemplate="let cellInfo of 'amountTemplate'" class="text-right">
                                            {{cellInfo.value | number : '1.2-2'}}
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h4 class="section-title">Deductions</h4>
                                </div>
                            </div>
                            <div class="row p-3 min-height-100" id="user-emp-deduction">
                                <div class="col-md-12 table-responsive no-header" *ngIf="employeeSalary">
                                    <dx-data-grid id="gridSalary" [dataSource]="employeeSalary.deduction_components">
                                        <dxo-paging [enabled]="false"></dxo-paging>
                                        <dxo-summary>
                                            <dxi-total-item column="Mothly Amount" summaryType="sum"
                                                displayFormat="Total Monthly Deduction: {0}">
                                            </dxi-total-item>
                                        </dxo-summary>
                                        <dxi-column dataField="salary_component_sub_type" caption="Pay Component"
                                            dataType="string">
                                        </dxi-column>
                                        <dxi-column dataField="salary_component_value" caption="Annual Amount"
                                            dataType="number" cellTemplate="annualTemplate">
                                        </dxi-column>
                                        <dxi-column dataField="salary_component_value" caption="Mothly Amount"
                                            dataType="number" cellTemplate="monthlyTemplate">
                                        </dxi-column>
                                        <div *dxTemplate="let cellInfo of 'monthlyTemplate'" class="text-right">
                                            {{cellInfo.value | number : '1.2-2'}}
                                        </div>
                                        <div *dxTemplate="let cellInfo of 'annualTemplate'" class="text-right">
                                            {{(cellInfo.value *12) | number : '1.2-2'}}
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body" id="user-emp-comparision">
                                            <dx-chart [dataSource]="salaryComparisionData"
                                                (onInitialized)="onInitialized($event)">
                                                <dxi-series *ngFor="let y of months" [valueField]="y.key"
                                                    [name]="y.value">
                                                </dxi-series>
                                                <dxo-title text="Salary Comparision">
                                                    <dxo-subtitle text="(in rupees)">
                                                    </dxo-subtitle>
                                                </dxo-title>
                                                <dxo-common-series-settings #seriesSettings argumentField="month"
                                                    type="bar" hoverMode="allArgumentPoints"
                                                    selectionMode="allArgumentPoints" [barPadding]="0.1">
                                                    <dxo-label [visible]="true"
                                                        [format]="{ type: 'fixedPoint', precision: '0' }">
                                                    </dxo-label>
                                                </dxo-common-series-settings>
                                                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                                                </dxo-legend>
                                                <dxo-export [enabled]="true"></dxo-export>
                                            </dx-chart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="col-md-12 d-flex">
                                        <div class="card profile-box flex-fill">
                                            <div class="card-body" id="user-emp-hike">
                                                <dx-chart id="hike" title="Hike History" [dataSource]="hikeData"
                                                    (onInitialized)="onHikeInitialized($event)">
                                                    <dxi-value-axis name="percentage_hike" position="left">
                                                    </dxi-value-axis>
                                                    <dxo-legend [visible]="false"></dxo-legend>
                                                    <dxi-series argumentField="hike_date" valueField="percentage_hike"
                                                        name="Hike Percentage" type="bar">
                                                    </dxi-series>
                                                    <dxo-argument-axis [tickInterval]="1">
                                                        <dxo-label>
                                                            <dxo-format type="decimal"></dxo-format>
                                                        </dxo-label>
                                                    </dxo-argument-axis>
                                                    <dxo-common-series-settings argumentField="hike_date" type="bar"
                                                        hoverMode="allArgumentPoints" selectionMode="allArgumentPoints"
                                                        [ignoreEmptyPoints]="true">
                                                        <dxo-label [visible]="true"
                                                            [format]="{ type: 'fixedPoint', precision: '0'}">
                                                        </dxo-label>
                                                    </dxo-common-series-settings>
                                                </dx-chart>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="col-md-12 d-flex">
                                        <div class="card profile-box flex-fill">
                                            <div class="card-body" id="user-emp-bonus">
                                                <dx-chart id="bonus" title="Bonus History" [dataSource]="bonusData"
                                                    (onInitialized)="onBonusInitialized($event)">
                                                    <dxi-value-axis name="bonus_amount" position="left">
                                                    </dxi-value-axis>
                                                    <dxo-legend [visible]="false"></dxo-legend>
                                                    <dxi-series argumentField="bonus_date" valueField="bonus_amount"
                                                        name="Bonus Amount" type="bar">
                                                    </dxi-series>
                                                    <dxo-argument-axis [tickInterval]="1">
                                                        <dxo-label>
                                                            <dxo-format type="decimal"></dxo-format>
                                                        </dxo-label>
                                                    </dxo-argument-axis>
                                                    <dxo-common-series-settings argumentField="bonus_date" type="bar"
                                                        hoverMode="allArgumentPoints" selectionMode="allArgumentPoints"
                                                        [ignoreEmptyPoints]="true">
                                                        <dxo-label [visible]="true"
                                                            [format]="{ type: 'fixedPoint', precision: '0'}">
                                                        </dxo-label>
                                                    </dxo-common-series-settings>
                                                </dx-chart>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink class="nav-link hidePrint">
                            Job Profile
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="col-md-12 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body p-5" id="user-emp-kra">
                                            <div class="pro-edit" *ngIf="showEdit"><a href="javascript:void(0);"
                                                    class="edit-icon" (click)="openModel(empkramodalcontent)"><i
                                                        class="fa fa-pencil"></i></a></div>
                                            <div class="form-group row table-responsive">
                                                <dx-data-grid id="gridKra" [dataSource]="employeeKpi">
                                                    <dxo-paging [enabled]="false"></dxo-paging>
                                                    <dxo-load-panel [enabled]="false"></dxo-load-panel>
                                                    <dxi-column dataField="kra_name" caption="KRA" dataType="string"
                                                        width="20%">
                                                    </dxi-column>
                                                    <dxi-column dataField="kpi_description" caption="Description"
                                                        cellTemplate="textTemplate">
                                                    </dxi-column>
                                                    <div *dxTemplate="let data of 'textTemplate'">
                                                        <p class="WrappedColumnClass">{{data.value}}</p>
                                                    </div>
                                                </dx-data-grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink class="nav-link hidePrint">
                            Attendance
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="offset-md-8 col-md-4">
                                    <dx-date-box #dxAttendanceDate id="calendar-container" displayFormat="monthAndYear"
                                        [(value)]="attendanceDate" class="form-control filter-control"
                                        pickerType="calendar" type="date"
                                        (onValueChanged)="attendanceDateChanged($event)">
                                    </dx-date-box>
                                </div>
                            </div>
                            <div class="row p-3" id="user-emp-earning">
                                <div class="col-md-12 table-responsive" id="attendance-grid">
                                    <dx-data-grid #gridAttendance id="gridContainer" [dataSource]="attendanceList"
                                        [remoteOperations]="false" [allowColumnReordering]="true"
                                        [rowAlternationEnabled]="true" [showBorders]="false" [showColumnLines]="false">
                                        <dxo-export [enabled]="true" fileName="attendanceReport"></dxo-export>
                                        <dxo-paging [enabled]="false"></dxo-paging>
                                        <dxi-column dataField="attendance_date" [allowFiltering]="false"
                                            [allowSorting]="false" [allowGrouping]="false" cellTemplate="dateTemplate"
                                            caption="DATE" width="250" cssClass="cls">
                                        </dxi-column>
                                        <dxi-column dataField="in_punch_time" caption="FIRST IN"
                                            cellTemplate="timeTemplate"> >
                                        </dxi-column>
                                        <dxi-column dataField="out_punch_time" caption="LAST OUT"
                                            cellTemplate="timeTemplate">
                                        </dxi-column>
                                        <dxi-column dataField="regularization_reason" caption="REASON"
                                            dataType="string">
                                        </dxi-column>
                                        <dxi-column dataField="attendance_type" caption="TYPE" dataType="string">
                                        </dxi-column>
                                        <dxi-column dataField="total_hours" caption="TOTAL HOURS" dataType="number">
                                        </dxi-column>
                                        <dxi-column dataField="payable_hours" caption="PAYABLE HOURS" dataType="number">
                                        </dxi-column>
                                        <dxi-column dataField="over_time" caption="OVER TIME" dataType="number">
                                        </dxi-column>
                                        <div *dxTemplate="let data of 'dateTemplate'">
                                            {{data.value | date: 'EEEE, MMM d, y' }}
                                        </div>
                                        <div *dxTemplate="let data of 'timeTemplate'">
                                            {{data.value | date:'shortTime'}}
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5">
                        <a ngbNavLink class="nav-link hidePrint">
                            Leave
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row filter-row">
                                <div class="col-md-3 d-flex offset-md-9">
                                    <select class="form-control filter-control" id="leaveyear" name="leaveyear"
                                        [(ngModel)]="leaveYear" #lYear (change)="leaveYearChanged()">
                                        <option [ngValue]="undefined" selected>Select Year</option>
                                        <option *ngFor="let item of yearList; let i = index" [ngValue]="item.key">
                                            {{item.value}}</option>
                                    </select>
                                </div>
                            </div>
                            <app-leave-balance #leaveBalance></app-leave-balance>
                            <div class="row">
                                <div class="col-md-12 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body" id="user-emp-leave">
                                            <dx-chart [dataSource]="leaveHistoryData"
                                                (onInitialized)="onLeaveInitialized($event)">
                                                <dxi-series *ngFor="let y of leaves" [valueField]="y.name"
                                                    [name]="y.name">
                                                </dxi-series>
                                                <dxo-title text="Leave History">
                                                </dxo-title>
                                                <dxi-value-axis position="right">
                                                    <dxo-title text="days"></dxo-title>
                                                </dxi-value-axis>
                                                <dxo-common-series-settings argumentField="monthname" type="stackedBar">
                                                </dxo-common-series-settings>
                                                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center"
                                                    itemTextPosition="top">
                                                </dxo-legend>
                                                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                                                </dxo-legend>
                                                <dxo-export [enabled]="true"></dxo-export>
                                                <dxo-tooltip [enabled]="true" location="edge"
                                                    [customizeTooltip]="customizeTooltip">
                                                </dxo-tooltip>
                                            </dx-chart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body" id="user-emp-permission">
                                            <dx-chart id="permission" title="Permission History"
                                                [dataSource]="permissionHistoryData"
                                                (onInitialized)="onPermissionInitialized($event)">
                                                <dxi-value-axis name="noofhours" position="left">
                                                </dxi-value-axis>
                                                <dxo-legend [visible]="false"></dxo-legend>
                                                <dxi-series argumentField="monthname" valueField="noofhours"
                                                    name="Permission Hours" type="bar">
                                                </dxi-series>
                                                <dxo-argument-axis>
                                                    <dxo-label>
                                                        <dxo-format type="decimal"></dxo-format>
                                                    </dxo-label>
                                                </dxo-argument-axis>
                                                <dxo-tooltip [enabled]="true" location="edge"
                                                    [customizeTooltip]="customizeTooltip">
                                                </dxo-tooltip>
                                            </dx-chart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="6">
                        <a ngbNavLink class="nav-link hidePrint">
                            Permission
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="offset-md-8 col-md-4">
                                    <dx-date-box #dxPermissionDate id="permission-calendar-container"
                                        displayFormat="monthAndYear" [(value)]="permissionDate"
                                        class="form-control filter-control" pickerType="calendar" type="date"
                                        (onValueChanged)="permissionDateChanged($event)">
                                    </dx-date-box>
                                </div>
                            </div>
                            <div class="row p-3" id="user-emp-permission">
                                <div class="col-md-12 table-responsive" id="permission-grid">
                                    <dx-data-grid #gridPermission id="gridContainer" [dataSource]="permissionList"
                                        [remoteOperations]="false" [allowColumnReordering]="true"
                                        [rowAlternationEnabled]="true" [showBorders]="false" [showColumnLines]="false">
                                        <dxo-export [enabled]="true" fileName="permissionReport"></dxo-export>
                                        <dxo-paging [enabled]="false"></dxo-paging>
                                        <dxi-column dataField="permission_date" [allowFiltering]="false"
                                            [allowSorting]="false" [allowGrouping]="false" cellTemplate="dateTemplate"
                                            caption="DATE" width="250" cssClass="cls">
                                        </dxi-column>
                                        <dxi-column dataField="permission_reason" caption="Reason" dataType="string"
                                            [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="permission_from_time" caption="From"
                                            cellTemplate="timeTemplate" [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="permission_to_time" caption="To"
                                            cellTemplate="timeTemplate" [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="in_punch_time" caption="In Time"
                                            cellTemplate="timeTemplate" [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="permission_duration" caption="Duration">
                                        </dxi-column>
                                        <div *dxTemplate="let data of 'dateTemplate'">
                                            {{data.value | date: 'EEEE, MMM d, y' }}
                                        </div>
                                        <div *dxTemplate="let data of 'timeTemplate'">
                                            {{data.value | date:'shortTime'}}
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="7">
                        <a ngbNavLink class="nav-link hidePrint">
                            Late Punch
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row">
                                <div class="offset-md-8 col-md-4">
                                    <dx-date-box #dxLateDate id="late-calendar-container" displayFormat="monthAndYear"
                                        [(value)]="lateDate" class="form-control filter-control" pickerType="calendar"
                                        type="date" (onValueChanged)="lateDateChanged($event)">
                                    </dx-date-box>
                                </div>
                            </div>
                            <div class="row p-3" id="user-emp-late">
                                <div class="col-md-12 table-responsive" id="late-grid">
                                    <dx-data-grid #gridLate id="gridContainer" [dataSource]="lateList"
                                        [remoteOperations]="false" [allowColumnReordering]="true"
                                        [rowAlternationEnabled]="true" [showBorders]="false" [showColumnLines]="false">
                                        <dxo-export [enabled]="true" fileName="lateReport"></dxo-export>
                                        <dxo-paging [enabled]="false"></dxo-paging>
                                        <dxi-column dataField="punch_time" [allowFiltering]="false"
                                            [allowSorting]="false" [allowGrouping]="false" cellTemplate="dateTemplate"
                                            caption="DATE" width="350" cssClass="cls">
                                        </dxi-column>
                                        <dxi-column dataField="punch_time" caption="In Time" cellTemplate="timeTemplate"
                                            [allowGrouping]="false" [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="late_duration" caption="Late By"
                                            cellTemplate="durationTemplate" [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="punch_status" caption="Status"
                                            cellTemplate="statusTemplate" alignment="center" width="200"
                                            [allowFiltering]="false">
                                        </dxi-column>
                                        <div *dxTemplate="let data of 'dateTemplate'">
                                            {{data.value | date: 'EEEE, MMM d, y' }}
                                            <span *ngIf="data.row.data.has_permission===1"
                                                class="badge bg-inverse-warning">Permission</span>
                                        </div>
                                        <div *dxTemplate="let data of 'timeTemplate'">
                                            {{data.value | date:'shortTime'}}
                                        </div>
                                        <div *dxTemplate="let data of 'durationTemplate'">
                                            {{ data.value | minutestToHour }} <span *ngIf="data.value"></span>
                                        </div>
                                        <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Not Available'">
                                                <i class="fa fa-dot-circle-o text-warning"></i> Not Available
                                            </a>
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Late'">
                                                <i class="fa fa-dot-circle-o text-danger"></i> Late
                                            </a>
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="8">
                        <a ngbNavLink class="nav-link hidePrint">
                            Aditional Information
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row" *ngIf="!customFieldList || customFieldList?.length!==0">
                                <div class="col-md-12 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body p-5" id="user-other-loading">
                                            <h3 class="card-title text-muted bottom-border">Additional Information</h3>
                                            <div class="form-group row">
                                                <div class="col-sm-12">
                                                    <app-custom-fields #customField *ngIf="customFieldList"
                                                        [customFieldList]="customFieldList" [readOnly]="true">
                                                    </app-custom-fields>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body p-5" id="user-emp-asset">
                                            <h3 class="card-title text-muted bottom-border">Assets</h3>
                                            <div class="form-group row table-responsive">
                                                <dx-data-grid id="gridAsset" [dataSource]="assetList">
                                                    <dxo-paging [enabled]="false"></dxo-paging>
                                                    <dxo-load-panel [enabled]="false"></dxo-load-panel>
                                                    <dxi-column dataField="asset_code" caption="Asset Code"
                                                        dataType="string">
                                                    </dxi-column>
                                                    <dxi-column dataField="asset_name" caption="Name" dataType="number"
                                                        width="10%">
                                                    </dxi-column>
                                                    <dxi-column dataField="asset_type" caption="Type" dataType="string">
                                                    </dxi-column>
                                                    <dxi-column dataField="asset_status" caption="Status"
                                                        dataType="string" cellTemplate="statusTemplate"
                                                        alignment="center">
                                                    </dxi-column>
                                                    <div *dxTemplate="let cellInfo of 'statusTemplate'"
                                                        class="text-center">
                                                        <a class="btn btn-white btn-sm btn-rounded"
                                                            href="javascript:void(0);" *ngIf="cellInfo.value=='Active'">
                                                            <i class="fa fa-dot-circle-o text-success"></i> Active
                                                        </a>
                                                        <a class="btn btn-white btn-sm btn-rounded"
                                                            href="javascript:void(0);" *ngIf="cellInfo.value=='Scrap'">
                                                            <i class="fa fa-dot-circle-o text-warning"></i> Scrap
                                                        </a>
                                                        <a class="btn btn-white btn-sm btn-rounded"
                                                            href="javascript:void(0);" *ngIf="cellInfo.value=='Resale'">
                                                            <i class="fa fa-dot-circle-o text-danger"></i> Resale
                                                        </a>
                                                    </div>
                                                </dx-data-grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body p-5" id="user-emp-history">
                                            <h3 class="card-title text-muted bottom-border">Work History</h3>
                                            <div class="form-group row">
                                                <span class="nodata"
                                                    *ngIf="!workHistoryList || workHistoryList?.length===0">No
                                                    data</span>
                                                <div class="activity col-md-12" *ngIf="workHistoryList?.length>0">
                                                    <div class="activity-box">
                                                        <ul class="activity-list">
                                                            <li *ngFor="let item of workHistoryList; let i = index">
                                                                <div class="activity-content pl-3">
                                                                    <div class="timeline-content">
                                                                        {{item.company_name}} <span
                                                                            *ngIf='item.designation'>as
                                                                            {{item.designation}}</span> from
                                                                        {{item.from_date| date
                                                                        : "dd-MM-yyyy" }} to {{item.to_date| date :
                                                                        "dd-MM-yyyy" }}
                                                                        <span class="time"> {{item.duration}}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body p-5" id="user-emp-remarks">
                                            <h3 class="card-title text-muted bottom-border">Remarks</h3>
                                            <div class="form-group row">
                                                <span class="nodata"
                                                    *ngIf="!employeeRemarkList || employeeRemarkList?.length===0">No
                                                    data</span>
                                                <div class="activity col-md-12" *ngIf="employeeRemarkList?.length>0">
                                                    <div class="activity-box">
                                                        <ul class="activity-list">
                                                            <li *ngFor="let item of employeeRemarkList; let i = index">
                                                                <div class="activity-content pl-3">
                                                                    <div class="timeline-content">
                                                                        {{item.employee_comment_type}} on
                                                                        {{item.comment_date| date : "dd-MM-yyyy" }}
                                                                        <br>
                                                                        {{item.employee_comment }}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div [ngbNavOutlet]="navTab" id="tabcontentPanel" class="tab-content"></div>

    <!-- Modal window contents -->
    <ng-template #empkramodalcontent let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add KRA - {{employee.first_name}}&nbsp;{{employee.last_name}}
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <div class="form-group row">
                <div class="col-sm-12">
                    <dx-data-grid #gridKra id="gridempKra" [dataSource]="employeeKpi" [remoteOperations]="false"
                        [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                        [showColumnLines]="false" (onRowInserted)="empKraInserted($event)"
                        (onRowUpdated)="empKraUpdated($event)" (onRowRemoved)="empKraRemoved($event)">
                        <dxo-editing mode="form" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
                        </dxo-editing>
                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxi-column dataField="kra_name" caption="Name" dataType="string" [allowEditing]="true">
                            <dxo-form-item [colSpan]="2">
                            </dxo-form-item>
                        </dxi-column>
                        <dxi-column dataField="kpi_description" caption="Description" dataType="string"
                            [allowEditing]="true">
                            <!-- <dxo-form-item [colSpan]="2">
                            </dxo-form-item> -->
                            <dxo-form-item [colSpan]="2" editorType="dxTextArea"
                                [editorOptions]="{ height: 100 }"></dxo-form-item>
                        </dxi-column>

                        <!-- <dxi-column dataField="kra_id" caption="Type" dataType="string" [allowEditing]="true">
                            <dxo-lookup [dataSource]="kraList" displayExpr="value" valueExpr="key">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column> -->
                        <div *dxTemplate="let data of 'dateTemplate'">
                            {{data.value | date: 'dd-MMM-yy'}}
                        </div>
                        <div *dxTemplate="let cellInfo of 'dateEditor'">
                            <dx-date-box [(ngModel)]="cellInfo.value" [value]="cellInfo.value"
                                displayFormat="dd/MM/yyyy" dateSerializationFormat="yyyy-MM-dd" type="date"
                                (onValueChanged)="onValueChanged($event, cellInfo)">
                            </dx-date-box>
                        </div>
                    </dx-data-grid>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </ng-template>
    <ng-template #employeemodalcontent let-c="close" let-d="dismiss">
        <form #employeeForm="ngForm">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Edit Personal Info</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-4">
                        <label for="name">Name</label><br>
                        <input class="form-control " [(ngModel)]="employee.full_name" readonly type="text" id="name"
                            name="name">
                    </div>
                    <div class="col-sm-4">
                        <label for="phone">Phone</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.phone" type="text" id="phone"
                            name="phone" #phone="ngModel" [pattern]="global.phoneValidator">
                        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="phone.errors.pattern">
                                Phone Number is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label for="email">Email</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.email" type="text" id="email"
                            name="email" #email="ngModel" [pattern]="global.emailValidator">
                        <div *ngIf="email.invalid && (email.dirty || email.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="email.errors.pattern">
                                Email is invalid.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">

                    <div class="col-sm-4">
                        <label for="dob">Date Of Birth</label>
                        <dx-date-box [(ngModel)]="employee.personal_info.date_of_birth" name="dob" id="dob" type="date">
                        </dx-date-box>
                    </div>
                    <div class="col-sm-4">
                        <label for="gender">Gender</label><br>
                        <div *ngFor="let item of genderList" class="d-inline">
                            <input id="gender_{{item.key}}" [value]='item.key' type="radio" name="gender"
                                class="radio-inline" [(ngModel)]="employee.personal_info.gender_id">
                            {{item.value}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <label for="address">Present Address</label>
                        <textarea rows="3" class="form-control" id="address" name="address"
                            [(ngModel)]="employee.personal_info.address"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-4">
                        <label for="city">City</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.city" type="text" id="city"
                            name="city" #city="ngModel" [pattern]="global.alphaNumericValidator">
                        <div *ngIf="city.invalid && (city.dirty || city.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="city.errors.pattern">
                                City is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label for="state">State</label>
                        <select class="form-control " id="state" name="state"
                            [(ngModel)]="employee.personal_info.state_id" #state="ngModel"
                            [ngClass]="{'is-invalid': state.invalid && state.touched}">
                            <option [ngValue]="null" selected>Select State</option>
                            <option *ngFor="let item of stateList; let i = index" [ngValue]="item.key">
                                {{item.value}}</option>
                        </select>
                    </div>
                    <div class="col-sm-4">
                        <label for="pincode">Pincode</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.pincode" type="text"
                            id="pincode" name="pincode" #pincode="ngModel" [pattern]="global.numericValidator">
                        <div *ngIf="pincode.invalid && (pincode.dirty || pincode.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="pincode.errors.pattern">
                                Pincode is invalid.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <label for="permanentaddress">Permanent Address</label>
                        <textarea rows="3" class="form-control" id="permanentaddress" name="permanentaddress"
                            [(ngModel)]="employee.personal_info.permanent_address"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-4">
                        <label for="permanentcity">Permanent Address City</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.permanent_city" type="text"
                            id="permanentcity" name="permanentcity" #permanentcity="ngModel"
                            [pattern]="global.alphaNumericValidator">
                        <div *ngIf="permanentcity.invalid && (permanentcity.dirty || permanentcity.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="permanentcity.errors.pattern">
                                Permanent City is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label for="permanentstate">Permanent Address State</label>
                        <select class="form-control" id="permanentstate" name="permanentstate"
                            [(ngModel)]="employee.personal_info.permanent_state_id" #permanentstate="ngModel"
                            [ngClass]="{'is-invalid': permanentstate.invalid && permanentstate.touched}">
                            <option [ngValue]="null" selected>Select State</option>
                            <option *ngFor="let item of stateList; let i = index" [ngValue]="item.key">
                                {{item.value}}</option>
                        </select>
                    </div>
                    <div class="col-sm-4">
                        <label for="permanentpincode">Permanent Address Pincode</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.permanent_pincode" type="text"
                            id="permanentpincode" name="permanentpincode" #permanentpincode="ngModel"
                            [pattern]="global.numericValidator">
                        <div *ngIf="permanentpincode.invalid && (permanentpincode.dirty || permanentpincode.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="permanentpincode.errors.pattern">
                                Permanent Pincode is invalid.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="saveEmployee(employeeForm)"
                    [disabled]="!employeeForm.valid">Save</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
    <ng-template #bankmodalcontent let-c="close" let-d="dismiss">
        <form #employeebankForm="ngForm">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Edit Bank</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="bankname">Bank Name <span class="text-danger">*</span></label>
                        <input class="form-control " [(ngModel)]="employee.bank_details.bank_name" required type="text"
                            id="bankname" name="bankname" #bankname="ngModel" [pattern]="global.alphaNumericValidator">
                        <div *ngIf="bankname.invalid && (bankname.dirty || bankname.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="bankname.errors.required">
                                Bank name is required.
                            </div>
                            <div *ngIf="bankname.errors.pattern">
                                Bank Name is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="branchname">Branch Name</label>
                        <input class="form-control " [(ngModel)]="employee.bank_details.bank_branch" type="text"
                            id="branchname" name="branchname" #branchname="ngModel"
                            [pattern]="global.alphaNumericValidator">
                        <div *ngIf="branchname.invalid && (branchname.dirty || branchname.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="branchname.errors.pattern">
                                Branch Name is invalid.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="accountnumber">Account Number <span class="text-danger">*</span></label>
                        <input class="form-control " [(ngModel)]="employee.bank_details.account_number" required
                            type="text" id="accountnumber" name="accountnumber" #accountnumber="ngModel"
                            [pattern]="global.numericValidator">
                        <div *ngIf="accountnumber.invalid && (accountnumber.dirty || accountnumber.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="accountnumber.errors.required">
                                Account Number is required.
                            </div>
                            <div *ngIf="accountnumber.errors.pattern">
                                Account Number is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="ifsccode">IFSC Code <span class="text-danger">*</span></label>
                        <input class="form-control " [(ngModel)]="employee.bank_details.ifsc_code" required type="text"
                            id="ifsccode" name="ifsccode" #ifsccode="ngModel" [pattern]="global.alphaNumericValidator">
                        <div *ngIf="ifsccode.invalid && (ifsccode.dirty || ifsccode.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="ifsccode.errors.required">
                                IFSC Code is required.
                            </div>
                            <div *ngIf="ifsccode.errors.pattern">
                                IFSC Code is invalid.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="pannumber">PAN Number <span class="text-danger">*</span></label>
                        <input class="form-control " [(ngModel)]="employee.bank_details.pan_number" required type="text"
                            id="pannumber" name="pannumber" #pannumber="ngModel"
                            [pattern]="global.alphaNumericValidator">
                        <div *ngIf="pannumber.invalid && (pannumber.dirty || pannumber.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="pannumber.errors.required">
                                PAN Number is required.
                            </div>
                            <div *ngIf="pannumber.errors.pattern">
                                PAN Number is invalid.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="saveBankInfo(employeebankForm)"
                    [disabled]="!employeebankForm.valid">Save</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
    <ng-template #personalmodalcontent let-c="close" let-d="dismiss">
        <form #employeepersonalForm="ngForm">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Edit Personal Information</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="phone">Personal Phone</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.phone" type="text" id="phone"
                            name="phone" #phone="ngModel" [pattern]="global.numericValidator">
                        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="phone.errors.pattern">
                                Personal Phone Number is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="email">Personal Email</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.email" type="text" id="email"
                            name="email" #email="ngModel" [pattern]="global.emailValidator">
                        <div *ngIf="email.invalid && (email.dirty || email.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="email.errors.pattern">
                                Personal Email is invalid.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="officephone">Official Phone</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.official_phone" type="text"
                            id="officephone" name="officephone" #officephone="ngModel"
                            [pattern]="global.numericValidator">
                        <div *ngIf="officephone.invalid && (officephone.dirty || officephone.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="officephone.errors.pattern">
                                Official Phone Number is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="officeemail">Official Email</label>
                        <input class="form-control" [(ngModel)]="employee.personal_info.official_email" type="text"
                            id="officeemail" name="officeemail" #officeemail="ngModel"
                            [pattern]="global.emailValidator">
                        <div *ngIf="officeemail.invalid && (officeemail.dirty || officeemail.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="officeemail.errors.pattern">
                                Official Email is invalid.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="econtact">Emergency Contact</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.emergency_contact" type="text"
                            id="econtact" name="econtact" #econtact="ngModel" [pattern]="global.numericValidator">
                        <div *ngIf="econtact.invalid && (econtact.dirty || econtact.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="econtact.errors.pattern">
                                Emergency Contact is invalid.
                            </div>
                            <div *ngIf="econtact.errors.required">
                                Emergency Contact is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="erelation">Emergency Contact Relation </label>
                        <select class="form-control " id="erelation" name="erelation"
                            [(ngModel)]="employee.personal_info.emergency_contact_relation_id" #erelation="ngModel"
                            [ngClass]="{'is-invalid': erelation.invalid && erelation.touched}">
                            <option [ngValue]="null" selected>Select Relationship</option>
                            <option *ngFor="let item of emergencyContactRelationList; let i = index"
                                [ngValue]="item.key">
                                {{item.value}}</option>
                        </select>
                        <div *ngIf="erelation.invalid && (erelation.dirty || erelation.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="erelation.errors.required">
                                Emergency Contact relation is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="dob">Date of Birth <span class="text-danger">*</span></label>
                        <dx-date-box [(ngModel)]="employee.personal_info.date_of_birth" name="dob" id="dob"
                            #dob="ngModel" required type="date" displayFormat="dd/MM/yyyy"
                            dateSerializationFormat="yyyy-MM-dd">
                        </dx-date-box>
                        <div *ngIf="dob.invalid && (dob.dirty || dob.touched )"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="dob.errors.required">
                                Date of Birth is required.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="gender">Gender</label><br>
                        <div *ngFor="let item of genderList" class="d-inline">
                            <input id="gender_{{item.key}}" [value]='item.key' type="radio" name="gender"
                                class="radio-inline" [(ngModel)]="employee.personal_info.gender_id">
                            {{item.value}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="aadhar">Aadhar</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.aadhar" type="text" id="aadhar"
                            name="aadhar" #aadhar="ngModel" [pattern]="global.numericValidator">
                        <div *ngIf="aadhar.invalid && (aadhar.dirty || aadhar.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="aadhar.errors.pattern">
                                Aadhar is invalid.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="pan">PAN</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.pan_number" type="text"
                            id="pan" name="pan" #pan="ngModel" [pattern]="global.alphaNumericValidator">
                        <div *ngIf="pan.invalid && (pan.dirty || pan.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="pan.errors.pattern">
                                PAN is invalid.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="qualification">Qualification</label>
                        <input class="form-control " [(ngModel)]="employee.personal_info.qualification" type="text"
                            id="qualification" name="qualification" #qualification="ngModel">
                    </div>
                    <div class="col-sm-6">
                        <label for="bgroup">Blood Group</label>
                        <select class="form-control " id="bgroup" name="bgroup"
                            [(ngModel)]="employee.personal_info.blood_group_id" #bgroup="ngModel"
                            [ngClass]="{'is-invalid': bgroup.invalid && bgroup.touched}">
                            <option [ngValue]="null" selected>Select Blood Group</option>
                            <option *ngFor="let item of bloodGroupList; let i = index" [ngValue]="item.key">
                                {{item.value}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="saveEmployee(employeepersonalForm)"
                    [disabled]="!employeepersonalForm.valid">Save</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
    <ng-template #messagemodalcontent let-c="close" let-d="dismiss">
        <form #messageForm="ngForm">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Send Message</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-12">
                        <label for="message">Message <span class="text-danger">*</span></label>
                        <textarea rows="4" class="form-control" id="message" name="message"
                            [(ngModel)]="notification.notification_message" #message="ngModel" required></textarea>
                        <div *ngIf="message.invalid && (message.dirty || message.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="message.errors.required">
                                Message is required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="sendMessage(messageForm)"
                    [disabled]="!messageForm.valid">Send</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
    <ng-template #profilepicmodalcontent let-c="close" let-d="dismiss">
        <form #uploadForm="ngForm">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Upload Profile Picture</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-12">
                        <label for="upload">Select a File to upload <span class="text-danger">*</span></label>
                        <input type="file" id="picFile" class="form-control" (change)="onFileChange($event)" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <label>Preview</label>
                        <br>
                        <h2 class="table-avatar">
                            <ngx-avatar size="100" [src]="imageSrc"
                                [name]="employee.first_name + ' ' + employee.last_name">
                            </ngx-avatar>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="uploadDocument(uploadForm)"
                    [disabled]="!uploadForm.valid || !file">Upload</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
</div>
<div *ngIf="(emploading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-loading">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-loading-1' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-loading-1">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-loading-2' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-loading-2">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-loading-3' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-loading-3">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-loading-4' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-loading-4">
    </dx-load-panel>
</div>
<div *ngIf="(payloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-earning' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-earning">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-deduction' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-deduction">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-ctc' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-ctc">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-monthly' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-monthly">
    </dx-load-panel>
</div>
<div *ngIf="(hikeloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-hike' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-hike">
    </dx-load-panel>
</div>
<div *ngIf="(bonusloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-bonus' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-bonus">
    </dx-load-panel>
</div>
<div *ngIf="(comparisionloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-comparision' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-comparision">
    </dx-load-panel>
</div>
<div *ngIf="(historyloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-history' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-history">
    </dx-load-panel>
</div>
<div *ngIf="(kraloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-kra' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-kra">
    </dx-load-panel>
</div>
<div *ngIf="(remarksloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-remarks' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-remarks">
    </dx-load-panel>
</div>
<div *ngIf="(customloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-other-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-other-loading">
    </dx-load-panel>
</div>
<div *ngIf="(leavehistoryloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-leave' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-leave">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-permission' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-permission">
    </dx-load-panel>
</div>
<div *ngIf="(assetloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-asset' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#user-emp-asset">
    </dx-load-panel>
</div>
<div *ngIf="(attendanceloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-asset' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#attendance-grid">
    </dx-load-panel>
</div>
<div *ngIf="(permissionLoading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-asset' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#permission-grid">
    </dx-load-panel>
</div>
<div *ngIf="(lateLoading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#user-emp-asset' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#late-grid">
    </dx-load-panel>
</div>