<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Shifts</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn" (click)="openModel(shiftmodalcontent)"><i class="fa fa-plus"></i> Add
                    Shift</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-3 table-responsive" id="shift-loading">
            <dx-data-grid #gridshift id="gridContainer" [dataSource]="shiftList" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false">
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxi-column dataField="shift_name" caption="Name" dataType="string"></dxi-column>
                <dxi-column dataField="shift_start_time" caption="Start Time" cellTemplate="timeTemplate"></dxi-column>
                <dxi-column dataField="shift_end_time" caption="End Time" cellTemplate="timeTemplate"></dxi-column>
                <dxi-column dataField="shift_duration" caption="Duration"></dxi-column>
                <dxi-column dataField="break_duration" caption="Break"></dxi-column>
                <div *dxTemplate="let data of 'timeTemplate'">
                    {{data.value | date:"shortTime" }}
                </div>
                <dxi-column dataField="shift_id" [allowFiltering]="false" width=50 caption="" [allowSorting]="false"
                    cellTemplate="actionTemplate" alignment="center">
                    <div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
                        <div ngbDropdown container="body">
                            <a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle><i
                                    class="material-icons">more_vert</i></a>
                            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript:void(0);" (click)="editShift(cellInfo)"><i
                                        class="fa fa-pencil m-r-5"></i> Edit</a>
                            </div>
                        </div>
                    </div>
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>
    <ng-template #shiftmodalcontent let-c="close" let-d="dismiss">
        <form #shiftForm="ngForm">
            <div class="modal-header">
                <div *ngIf="isNew;else edit_content">
                    <h4 class="modal-title" id="modal-basic-title">Add Shift</h4>
                </div>
                <ng-template #edit_content>
                    <h4 class="modal-title" id="modal-basic-title">Edit Shift</h4>
                </ng-template>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="shiftname">Shift Name <span class="text-danger">*</span></label>
                        <input class="form-control" [(ngModel)]="shift.shift_name" type="text" id="shiftname"
                            name="shiftname" #shiftname="ngModel" [pattern]="global.alphaNumericValidator" required>
                        <div *ngIf="shiftname.invalid && (shiftname.dirty || shiftname.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="shiftname.errors.pattern">
                                Location Name is invalid.
                            </div>
                            <div *ngIf="shiftname.errors.required">
                                Location Name is Required.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">

                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="starttime">Shift Start Time <span class="text-danger">*</span></label>
                        <dx-date-box [(ngModel)]="shift.shift_start_time" name="starttime" id="starttime"
                            #starttime="ngModel" required type="time">
                        </dx-date-box>
                        <div *ngIf="starttime.invalid && (starttime.dirty || starttime.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="starttime.errors.required">
                                Start Time is Required.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="endtime">Shift End Time <span class="text-danger">*</span></label>
                        <dx-date-box [(ngModel)]="shift.shift_end_time" name="endtime" id="endtime" #endtime="ngModel"
                            required type="time">
                        </dx-date-box>
                        <div *ngIf="endtime.invalid && (endtime.dirty || endtime.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="endtime.errors.required">
                                End Time is Required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="shiftduration">Shift Duration <span class="text-danger">*</span></label>
                        <input class="form-control" [(ngModel)]="shift.shift_duration" type="number" step=".01"
                            id="shiftduration" name="shiftduration" #shiftduration="ngModel" required>
                        <div *ngIf="shiftduration.invalid && (shiftduration.dirty || shiftduration.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="shiftduration.errors.required">
                                Shift Duration is Required.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="breakduration">Break Duration<span class="text-danger">*</span></label>
                        <input class="form-control" [(ngModel)]="shift.break_duration" type="number" step=".01"
                            id="breakduration" name="breakduration" #breakduration="ngModel" required>
                        <div *ngIf="breakduration.invalid && (breakduration.dirty || breakduration.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="breakduration.errors.required">
                                Break Duration is Required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="saveShift(shiftForm)"
                    [disabled]="!shiftForm.valid">Save</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
</div>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#shift-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#shift-loading">
    </dx-load-panel>
</div>