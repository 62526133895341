import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Leave } from 'src/app/model/attendance';
import { ToastService } from 'src/app/services/toast.service';
import { confirm } from 'devextreme/ui/dialog';
import { Global } from 'src/app/shared/global';
import { KeyValue, Location } from '@angular/common';
import { ApplyLeaveComponent } from '../apply-leave/apply-leave.component';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';
import { LeaveBalanceComponent } from '../leave-balance/leave-balance.component';

@Component({
  selector: 'app-leave-view',
  templateUrl: './leave-view.component.html',
  styleUrls: ['./leave-view.component.scss']
})
export class LeaveViewComponent implements OnInit, OnDestroy {
  isNew = true;
  leave: Leave = new Leave();
  leaveData: Leave[] = [];
  leaveStatusList: KeyValue<number, string>[] = [];
  gridloading$ = new Subject<boolean>();
  loading$ = new Subject<boolean>();
  subscriptions: Subscription[] = [];
  @ViewChild('applyLeave', { read: ApplyLeaveComponent }) applyLeave: ApplyLeaveComponent;
  @ViewChild('leaveBalance', { read: LeaveBalanceComponent }) leaveBalance: LeaveBalanceComponent;
  constructor(public global: Global,
    private toastService: ToastService,
    public attendanceApiService: AttendanceApiService,
    private commonApiService: CommonApiService,
    private location: Location) {

  }
  ngOnInit() {
    const url = this.location.path();
    if (url === '/leave') {
      this.subscriptions.push(
        this.commonApiService.getRequestHeader()
          .subscribe(apiresponse => {
            this.global.loadUserSession(apiresponse.data.user);
            this.commonApiService.setToken(this.global.loggedUser.token);
            this.attendanceApiService.setToken(this.global.loggedUser.token);
            this.global.isMobileApp = true;
            this.initialize();
          }));
    } else {
      this.initialize();
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  initialize() {
    this.subscriptions.push(
      forkJoin(
        {
          master: this.commonApiService.getMasterData('leave_status'),
          leaves: this.attendanceApiService.getLeave(this.global.loggedUser.user_type_internal_id)
        })
        .pipe(indicate(this.gridloading$))
        .subscribe(apiresponse => {
          this.leaveStatusList = apiresponse.master.data.filter((d: { type: string; }) => d.type === 'leave_status');
          this.leaveData = apiresponse.leaves.data;
          this.leaveBalance.loadStats();
        }));
  }
  refresh() {
    this.ngOnInit();
  }
  clearModel() {
    this.leave = new Leave();
  }
  reloadGrid() {
    this.subscriptions.push(
      this.attendanceApiService
        .getLeave(this.global.loggedUser.user_type_internal_id)
        .pipe(indicate(this.gridloading$))
        .subscribe(apiresponse => {
          this.leaveData = apiresponse.data;
          this.leaveBalance.loadStats();
        }));
  }
  openModel() {
    this.isNew = true;
    this.clearModel();
    this.leave.employee_id = this.global.loggedUser.user_type_internal_id;
    this.applyLeave.openModal(this.isNew, this.leave);
  }
  editLeave(d: Leave) {
    this.isNew = false;
    this.leave.employee_id = this.global.loggedUser.user_type_internal_id;
    this.leave = { ...d };
    this.applyLeave.openModal(this.isNew, this.leave);
  }
  viewLeave(d: Leave) {
    this.isNew = false;
    this.leave.employee_id = this.global.loggedUser.user_type_internal_id;
    this.leave = { ...d };
    this.applyLeave.openModal(this.isNew, this.leave, false, true);
  }
  cancelLeave(l: Leave) {
    this.leave = l;
    confirm('Are you sure, you want to cancel ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        const stat: KeyValue<number, string> = this.leaveStatusList.find(d => d.value === 'Cancelled');
        if (stat) {
          this.leave.leave_status_id = stat.key;
          this.subscriptions.push(
            this.attendanceApiService
              .postLeaveStatus(this.leave)
              .subscribe(apiresponse => {
                this.toastService.success('Leave cancelled successfully');
                this.reloadGrid();
              }));
        }
      }
    });
  }
}
