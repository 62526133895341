<div class="content container-fluid" id="payslip-loading-div">

    <div class="page-header">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="page-title">Payslip</h4>
          </div>
          <div class="col-auto float-right ml-auto" *ngIf="!showFilter">
            <div class="btn-group btn-group-sm">
              <button class="btn btn-white ml-1" (click)="back(dxSalaryDate)"><i class="fa fa-undo fa-lg"></i> Back</button>
              <button class="btn btn-white ml-1" *ngIf="global.isMobile===false" (click)="print()"><i class="fa fa-print fa-lg" *ngIf="!global.isMobile"></i> Print</button>
              <button class="btn btn-white ml-1" (click)="getPdf()"><i class="fa fa-download fa-lg"></i> Download</button>
            </div>
          </div>
        </div>
      </div>

    <div class="row filter-row pb-5" [hidden]="!showFilter">
        <div class="col-sm-6 col-md-4" [ngClass]="{'mobile-filter': global.isMobile===true}">
          <dx-date-box #dxSalaryDate id="calendar-container" displayFormat="monthAndYear" [(value)]="payslipDate" class="form-control filter-control" pickerType="calendar" type="date"></dx-date-box>
        </div>
        <div class="col-sm-6 col-md-4" [ngClass]="{'mobile-filter': global.isMobile===true}">
          <button [ngClass]="global.isMobileApp ? 'btn mobile-btn btn-block' : 'btn btn-secondary btn-block'" (click)="getPayslip()"> Open </button>
        </div>
      </div>

    <div *ngIf="!showFilter && (!employeeSalary || employeeSalary.salary_components.length==0)" class="row ml-3">
        <h5>Payslip Not available for the selected date</h5>
      </div>

    <div #printContent id="payslipContent" [ngClass]="{'mobile-payslip': global.isMobile===true}">
        <div *ngIf="!showFilter && (employeeSalary && employeeSalary.salary_components.length>0)" class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body ml-3 mr-3 mt-5">
                <div class="row">
                  <div class="col-2">
                    <img src="assets/images/logo/logo_scm.png" alt="" onerror="this.src='logo_scm.png';">
                  </div>
                  <div class="col-8 text-center">
                    <span>
                      <h3 class="text-uppercase"><b>{{company.company_name}}</b></h3>
                      {{company.address}}, {{company.address1}}, {{company.city}} - {{company.pin}}
                    </span>
                  </div>
                  <div class="col-2 invoice-details">
                    Payslip #{{employeeSalary.employee_salary_id}}
                  </div>
                </div>
                <br>
                <h4 class="payslip-title mb-4">Payslip for the month of {{ payslipDate | date : "MMMM, y" }}</h4>

                <div class="row mt-4">
                    <div class="col-10">
                <table class="table table-bordered">
                    <tr>
                      <th colspan="2">Employee Details</th>
                      <th colspan="2">Salary Details</th>
                    </tr>
                    <tr>
                      <td>Emp Code</td>
                      <td>{{ employeeSalary.employee_code }}</td>
                      <td>Month</td>
                      <td>{{ payslipDate | date : "MMMM, y" }}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{{ employeeSalary.employee_name }}</td>
                      <td>LOP Days</td>
                      <td>{{ employeeSalary.lop_days }}</td>
                    </tr>
                    <tr>
                      <td>Department</td>
                      <td>{{ employeeSalary.department }}</td>
                      <td>Days Worked</td>
                      <td>{{ employeeSalary.no_of_days_worked }}</td>
                    </tr>
                    <tr>
                      <td>Designation</td>
                      <td>{{ employeeSalary.designation }}</td>
                      <td>UAN Number</td>
                      <td>{{ employeeSalary.uan_number }}</td>
                    </tr>
                    <tr>
                      <td>Pf Number</td>
                      <td>{{  employeeSalary.pf_number }}</td>
                      <td>Esic Number</td>
                      <td>{{ employeeSalary.esic_no }}</td>
                    </tr>
                  </table>
                  </div>
                  <div class="col-2 Padtop">
                    <table class="Netpay">
                        <thead>
                          <tr>
                            <th class="th txtalign">
                              EMPLOYEE NET SALARY
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="txtalign" >
                              {{ employeeSalary.net_salary }} /-
                            </td>
                          </tr>
                          <!-- <tr>
                            <td class="txtalg">
                              PAID DAYS : {{ employeeSalary.no_of_days_worked }}
                            </td>
                          </tr> -->
                        </tbody>
                      </table>
                  </div>
                  
                  </div>

                <div class="row mt-4">
                    <div class="col-12">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="Earn"><b>Earning</b></th>
                            <th class="Amt"><b>Amount</b></th>
                            <th class="Earn"><b>Deduction</b></th>
                            <th class="Amt"><b>Amount</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let earning of employeeSalary.earning_components; let i = index">
                            <td>{{earning.salary_component_sub_type}}</td>
                            <td class="Amt">{{earning.salary_component_value}}</td>
                            <td>{{employeeSalary.deduction_components[i] ? employeeSalary.deduction_components[i].salary_component_sub_type : ''}}</td>
                            <td class="Amt">{{employeeSalary.deduction_components[i] ? employeeSalary.deduction_components[i].salary_component_value : ''}}</td>
                          </tr>
                          <tr>
                            <td><b>Total Earning</b></td>
                            <td class="Amt"><b>{{ getTotalEarning() | number: '.2-2' }}</b></td>
                            <td><b>Total Deduction</b></td>
                            <td class="Amt"><b>{{ getTotalDeduction() | number: '.2-2' }}</b></td>
                          </tr>
                          <br>
                          <tr>
                            <td colspan="4"><b>Net Salary :</b>&nbsp;&nbsp;{{employeeSalary.net_salary}}</td>
                          </tr>
                          <tr><td colspan="4"><b>In Words</b>&nbsp; Rupees {{ employeeSalary.net_salary | toWords }} Only.</td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div> 
                <div class="row mt-4">
                    <div class="col-12 text-muted text-center">
                        This is system generated payslip and does not require signature.
                    </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(loading$ | async)">
        <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#payslip-loading-div' }"
          [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#payslip-loading-div">
        </dx-load-panel>
      </div>
    </div>