<div class="content container-fluid" id="performance-cycle-loading">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title" *ngIf="role!=='employee'">Manage Employee Performance</h3>
                <h3 class="page-title" *ngIf="role==='employee'">My Performance</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-12">
            <select class="form-control filter-control" id="rating" name="year"
                [(ngModel)]="performanceYear" #lYear (change)="performanceYearChanged()">
                <option [ngValue]="undefined" selected>Select Year</option>
                <option *ngFor="let item of yearList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-12" *ngIf="role!=='employee' && activeStatus !== 230">
            <button class="btn btn-secondary btn-block" (click)="savePerformance()"> Save </button>
        </div>
        <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-12"
            *ngIf="role!=='employee' && activeStatus !== undefined && (activeStatus===228 || activeStatus===231)">
            <button class="btn btn-secondary btn-block" (click)="sendToEmployee()"> Send To Employee </button>
        </div>
        <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-12" *ngIf="role==='employee' && activeStatus === 229">
            <button class="btn btn-secondary btn-block" (click)="accept()"> Accept </button>
        </div>
        <!-- <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-12" *ngIf="role==='employee' && activeStatus === 229">
            <button class="btn btn-secondary btn-block" (click)="reject()"> Reject </button>
        </div> -->
    </div>
    <div class="row table-responsive ml-1 mt-2">
        <div class="row">
            <div class="col-4">Employee Name: {{employee?.first_name}} {{employee?.last_name}}</div>
            <div class="col-4">Employee Code: {{employee?.employee_code}}</div>
            <div class="col-4">Designation: {{employee?.designation}}</div>
        </div>
        <div class="row mt-2">
            <div class="col-4">Department: {{employee?.department}}</div>
            <div class="col-4">Reporting To: {{employee?.reporting_to_name}}</div>
        </div>
    </div>
    <div class="row user-tabs">
        <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
            <ul ngbNav #navTab="ngbNav" [activeId]="tabActiveId" class="nav nav-tabs nav-tabs-bottom"
                [destroyOnHide]="false" (navChange)="onNavChange($event)">
                <li [ngbNavItem]="performanceCycle.performance_cycle_id"
                    *ngFor="let performanceCycle of performanceCycles;let i=index">
                    <a ngbNavLink class="nav-link hidePrint">{{performanceCycle.performance_cycle_name}}
                        {{role ==='employee'?performanceCycle.overall_status_description?' -
                        ('+performanceCycle.overall_status_description+')':'':performanceCycle.review_status_description?'
                        - ('+performanceCycle.review_status_description+')':''}}</a>
                    <ng-template ngbNavContent>
                        <div class="card profile-box flex-fill"
                            *ngIf="role ==='employee' && (activeStatus ===undefined || activeStatus === 228) ">
                            <div class="form-group row">
                                <div class="col-sm-12 chart-title mt-5">
                                    <p>No performance review of the selected period</p>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="role!=='employee' || (role==='employee' && activeStatus !==undefined && activeStatus !==228) ">
                            <div class="row">
                                <div class="col-md-12 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body p-5">
                                            <h3 class="card-title text-muted bottom-border">Organization Goals</h3>
                                            <div class="form-group row">
                                                <div class="col-sm-12">
                                                    <app-custom-fields #customField *ngIf="customFieldList"
                                                        [customFieldList]="customFieldList"
                                                        [reportMode]="role==='employee' || activeStatus===230"></app-custom-fields>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body p-5">
                                            <h3 class="card-title text-muted bottom-border">Result Based Goals
                                            </h3>
                                            <div *ngIf="role!=='employee'  && activeStatus!==230">
                                                <div class="form-group row"
                                                    *ngFor="let kpi of performanceCycle.employee_kpi_performance">
                                                    <div class="col-sm-12">
                                                        <div class="col" *ngIf="kpi.performance_cycle_id ===null">
                                                            <h1>Goal is not set</h1>
                                                        </div>
                                                        <div *ngIf="kpi.performance_cycle_id !==null">
                                                            <div class="col-sm-12 mb-3">
                                                                <div>
                                                                    KPI: {{kpi.kpi_name}}
                                                                </div>
                                                                <div class="mt-1">
                                                                    <label for="rating">Rating</label>
                                                                    <select class="form-control"
                                                                        id="rating_{{kpi.kpi_performance_id}}"
                                                                        name="rating_{{kpi.kpi_performance_id}}"
                                                                        [(ngModel)]="kpi.rating" #rating="ngModel"
                                                                        [ngClass]="{'is-invalid': rating.invalid && rating.touched}">
                                                                        <option [ngValue]="undefined" selected>select
                                                                        </option>
                                                                        <option
                                                                            *ngFor="let per of performanceRating;let i=index"
                                                                            [ngValue]="per.key">
                                                                            {{per.value}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="role==='employee' || activeStatus===230">
                                                <div class="row">
                                                    <div class="col-md-3 mt-3 p-3"
                                                        [ngClass]="{'offset-md-3': addOffset(i,performanceCycle.employee_kpi_performance)}"
                                                        *ngFor="let kpi of performanceCycle.employee_kpi_performance; let i = index">
                                                        <div *ngIf="kpi.rating_report" class="margin-min-80">
                                                            <dx-circular-gauge
                                                                id="rating_{{kpi.performance_cycle_id}}_{{i}}"
                                                                [value]="kpi.rating_report.val">
                                                                <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>
                                                                <dxo-value-indicator [color]="kpi.rating_report.color"></dxo-value-indicator>
                                                                <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">                    
                                                                </dxo-scale>
                                                                <dxo-range-container>
                                                                    <dxi-range [startValue]="0" [endValue]="50" color="#FF7200"></dxi-range>
                                                                    <dxi-range [startValue]="50" [endValue]="75" color="#b7f7dd"></dxi-range>
                                                                    <dxi-range [startValue]="75" [endValue]="90" color="#76f2c0"></dxi-range>
                                                                    <dxi-range [startValue]="90" [endValue]="100" color="#00A884"></dxi-range>
                                                                </dxo-range-container>
                                                            </dx-circular-gauge>
                                                            <div class="chart-title margin-min-80">{{kpi.kpi_name}} <br>
                                                                {{kpi.rating_report.rating}}</div>
                                                            <dx-popover
                                                                target="#rating_{{kpi.performance_cycle_id}}_{{i}}"
                                                                showEvent="mouseenter" position="top"
                                                                hideEvent="mouseleave">
                                                                <div *dxTemplate="let data = model; of: 'content'"
                                                                    style="align-items: center; text-align: center;">
                                                                    {{kpi.kpi_description}}
                                                                </div>
                                                            </dx-popover>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="role!=='employee' || (role==='employee' && activeStatus!==228)">
                                <div class="col-md-12 d-flex">
                                    <div class="card profile-box flex-fill">
                                        <div class="card-body p-5">
                                            <h3 class="card-title text-muted bottom-border">Rewards</h3>
                                            <div class="form-group row">
                                                <div class="col-sm-6"
                                                    *ngIf="role!=='employee' || performanceCycle.recommended_promotion_description">
                                                    <div
                                                        *ngIf="(role==='employee' || activeStatus===230);else edit_content">
                                                        Recommended Promotion: <br>
                                                        <span
                                                            class="read-only-text">{{performanceCycle.recommended_promotion_description}}</span>
                                                    </div>
                                                    <ng-template #edit_content>
                                                        <label for="recommended_promotion">Recommended Promotion</label>
                                                        <select class="form-control " id="rating"
                                                            name="recommended_promotion"
                                                            [(ngModel)]="performanceCycle.recommended_promotion"
                                                            #promotionlist="ngModel">
                                                            <option [ngValue]="performanceCycle[0]" selected>Select
                                                            </option>
                                                            <option
                                                                *ngFor="let item of performanceRatingList; let i = index"
                                                                [ngValue]="item.key">
                                                                {{item.value}}</option>
                                                        </select>
                                                    </ng-template>
                                                </div>
                                                <div class="col-sm-6"
                                                    *ngIf="role!=='employee' || performanceCycle.hike_percentage_description">
                                                    <div
                                                        *ngIf="(role==='employee' || activeStatus===230);else edit_content">
                                                        Recommended Increment Percentage: <br>
                                                        <span
                                                            class="read-only-text">{{performanceCycle.hike_percentage_description}}</span>
                                                    </div>
                                                    <ng-template #edit_content>
                                                        <label for="hike_percentage">Recommended Increment
                                                            Percentage</label>
                                                        <select class="form-control " id="rating" name="hike_percentage"
                                                            [(ngModel)]="performanceCycle.hike_percentage"
                                                            #percentagelist="ngModel">
                                                            <option [ngValue]="performanceCycle[0]" selected>Select
                                                            </option>
                                                            <option
                                                                *ngFor="let item of performancePercentage; let i = index"
                                                                [ngValue]="item.key">
                                                                {{item.value}}</option>
                                                        </select>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-6"
                                                    *ngIf="role!=='employee' || performanceCycle.overall_status_description">
                                                    <div
                                                        *ngIf="(role==='employee' || activeStatus===230);else edit_content">
                                                        Overall Rating: <br>
                                                        <span
                                                            class="read-only-text">{{performanceCycle.overall_status_description}}</span>
                                                    </div>
                                                    <ng-template #edit_content>
                                                        <label for="overall_status">Overall Rating</label>
                                                        <select class="form-control" id="rating" name="overall_status"
                                                            [(ngModel)]="performanceCycle.overall_status"
                                                            #overallRating="ngModel">
                                                            <option [ngValue]="performanceCycle[0]" selected>select
                                                            </option>
                                                            <option *ngFor="let item of performanceRating;let i=index"
                                                                [ngValue]="item.key">
                                                                {{item.value}}</option>
                                                        </select>
                                                    </ng-template>
                                                </div>
                                                <div class="col-sm-6" *ngIf="role!=='employee' || performanceCycle.review_remarks">
                                                    <div
                                                        *ngIf="(role==='employee' || activeStatus===230);else edit_content">
                                                        Review Remarks: <br>
                                                        <span
                                                            class="read-only-text">{{performanceCycle.review_remarks}}</span>
                                                    </div>
                                                    <ng-template #edit_content>
                                                        <label for="review_remarks">Review Remarks</label><br>
                                                        <textarea class="form-control" id="rating" name="review_remarks"
                                                            [(ngModel)]="performanceCycle.review_remarks"></textarea>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>
    <div [ngbNavOutlet]="navTab" id="tabcontentPanel" class="tab-content"></div>
</div>
<div *ngIf="(performanceCycleLoading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#performance-cycle-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#performance-cycle-loading">
    </dx-load-panel>
</div>
<div *ngIf="(performanceFieldsLoading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#tabcontentPanel' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#tabcontentPanel">
    </dx-load-panel>
</div>