import { KeyValue } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Subscription } from 'rxjs';
import { AttendanceRegularization } from 'src/app/model/attendance';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-request-regularize',
  templateUrl: './request-regularize.component.html',
  styleUrls: ['./request-regularize.component.scss']
})
export class RequestRegularizeComponent implements OnInit, OnDestroy {
  isNew = false;
  isAdmin = false;
  regularization: AttendanceRegularization = new AttendanceRegularization();
  regularizationReasonList: KeyValue<number, string>[] = [];
  employeeNames: KeyValue<number, string>[];
  employeeNameList: KeyValue<number, string>[];
  maxDate: Date = new Date();
  @ViewChild('regularizationcontent', { static: true }) regularizationcontent: ElementRef;
  @Output() modalClose: EventEmitter<any> = new EventEmitter();
  subscriptions: Subscription[] = [];
  constructor(
    private toastService: ToastService,
    private commonApiService: CommonApiService,
    private modalService: NgbModal,
    private attendanceService: AttendanceApiService) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  ngOnInit() {
    this.subscriptions.push(
      forkJoin(
        {
          master: this.commonApiService.getMasterData('regularization_status,regularization_reason'),
          utility: this.commonApiService.getUtilityData('reporting_employee_name'),
        })
        .subscribe(apiresponse => {
          const masterData: any = apiresponse.master.data;
          this.regularizationReasonList = masterData.filter(d => d.type === 'regularization_reason');
          this.employeeNames = apiresponse.utility.data;
          this.employeeNameList = this.employeeNames.reduce((a, o) => (a.push(o.value), a), []);
        }));
  }
  openModal(isNew: boolean, regularization: AttendanceRegularization, isAdmin: boolean = false) {
    this.regularization = regularization;
    this.isNew = isNew;
    this.isAdmin = isAdmin;
    this.modalService.open(this.regularizationcontent, { size: 'lg' });
  }
  selectRegularizationFor(e) {
    if (this.employeeNames) {
      const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
      if (emp) {
        this.regularization.employee_id = emp.key;
      }
    }
  }
  saveRegularization(frm: { valid: any; reset: () => void; }) {
    if (frm.valid) {
      this.regularization.regularization_date = this.regularization.regularization_from_time;
      const cdt: Date = new Date(this.regularization.regularization_from_time);
      const edt: Date = new Date(this.regularization.regularization_to_time);
      const sdt: Date = new Date(this.regularization.regularization_from_time);
      edt.setFullYear(cdt.getFullYear());
      edt.setMonth(cdt.getMonth());
      edt.setDate(cdt.getDate());
      sdt.setFullYear(cdt.getFullYear());
      sdt.setMonth(cdt.getMonth());
      sdt.setDate(cdt.getDate());
      this.regularization.regularization_from_time = sdt;
      this.regularization.regularization_to_time = edt;
      this.subscriptions.push(
        this.attendanceService.postRegularizationRequest(this.regularization)
          .subscribe(apiresponse => {
            this.regularization = new AttendanceRegularization();
            if (this.isNew) {
              this.toastService.success('Regularization Request submitted successfully');
            } else {
              this.toastService.success('Regularization Request updated successfully');
            }
            frm.reset();
            this.modalService.dismissAll();
            this.modalClose.emit();
          }));
    }
  }
}
