import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Global } from 'src/app/shared/global';
import { MasterData } from 'src/app/model/common';
import { ToastService } from 'src/app/services/toast.service';
import { Subject } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';

@Component({
  selector: 'app-master-admin',
  templateUrl: './master-admin.component.html',
  styleUrls: ['./master-admin.component.scss']
})
export class MasterAdminComponent implements OnInit {
  isNew = false;
  masterType: string;
  masterList: KeyValue<string, string>[] = [];
  masterDataList: MasterData[];
  masterData: MasterData;
  masterGridData: MasterData[];
  loading$ = new Subject<boolean>();
  @ViewChild('mastermodalcontent', { static: true }) mastermodalcontent: ElementRef;
  // tslint:disable-next-line:max-line-length
  constructor(private toastService: ToastService, private modalService: NgbModal, private commonApiService: CommonApiService, public global: Global) { }
  ngOnInit() {
    this.masterList.push({ key: 'payroll_earnings', value: 'payroll_earnings' });
    this.masterList.push({ key: 'payroll_deduction', value: 'payroll_deduction' });
    this.masterList.push({ key: 'holiday_type', value: 'holiday_type' });
    this.masterList.push({ key: 'designation', value: 'designation' });
    this.masterList.push({ key: 'department', value: 'department' });
    this.masterList.push({ key: 'employee_category', value: 'employee_category' });
    this.masterList.push({ key: 'bonus_type', value: 'bonus_type' });
    this.masterList.push({ key: 'holiday_calendar', value: 'holiday_calendar' });
    this.masterList.push({ key: 'leave_type', value: 'leave_type' });
    this.masterList.push({ key: 'asset_type', value: 'asset_type' });
    this.masterList.push({ key: 'asset_service_type', value: 'asset_service_type' });
    this.masterList.push({ key: 'leave_policy_type', value: 'leave_policy_type' });
    this.masterList.push({ key: 'payroll_earning_policy_type', value: 'payroll_earning_policy_type' });
    this.masterList.push({ key: 'payroll_deduction_policy_type', value: 'payroll_deduction_policy_type' });
    this.masterList.push({ key: 'employee_remark_type', value: 'employee_remark_type' });
    this.masterList.push({ key: 'kra', value: 'kra' });
    this.masterList.push({ key: 'regularization_reason', value: 'regularization_reason' });
    this.searchMaster(true);
  }
  searchMaster(updateList: boolean = false) {
    if (updateList) {
      // tslint:disable-next-line:max-line-length
      this.commonApiService.getMasterData('regularization_reason,state,employee_type,payroll_earning_policy_type,payroll_deduction_policy_type,leave_policy_type,asset_service_type,asset_type,payroll_earnings,payroll_deduction,holiday_type,designation,department,employee_category,bonus_type,holiday_calendar,leave_type,kra')
      .pipe(indicate(this.loading$))
      .subscribe(apiresponse => {
        this.masterDataList  = apiresponse.data;
      });
    }
    if (this.masterType) {
      this.masterGridData = this.masterDataList.filter(d => d.type === this.masterType);
    }
  }
  saveMaster(frm: any) {
    if (frm.valid) {
      this.commonApiService.postMaster(this.masterData).subscribe(apiresponse => {
        if (this.isNew) {
          this.toastService.success(this.masterData.type + ' Value successfully created.');
        } else {
          this.toastService.success(this.masterData.type + ' Value successfully updated.');
        }
        this.searchMaster(true);
        this.clearModel();
        this.modalService.dismissAll();
      });
    }
  }
  clearModel() {
    this.masterData = new MasterData();
  }
  openModel(content: any) {
    this.isNew = true;
    this.clearModel();
    this.modalService.open(content, { size: 'lg' });
  }
  editMaster(e: any) {
    this.isNew = false;
    this.masterData = e.row.data;
    this.modalService.open(this.mastermodalcontent, { size: 'lg' });
  }
}
