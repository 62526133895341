import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Subject } from 'rxjs';
import { NotificationMessage } from 'src/app/model/common';
import { Tab } from 'src/app/model/tab';
import { CommonApiService } from 'src/app/services/common-api.service';
import { indicate } from 'src/app/services/loading.service';
import { TabService } from 'src/app/services/tab.service';
import { ToastService } from 'src/app/services/toast.service';
import { Global } from 'src/app/shared/global';
import { AssetRequestComponent } from '../../admin/asset-request/asset-request.component';
import { LeaveAdminViewComponent } from '../../attendance/leave-admin-view/leave-admin-view.component';
import { LeaveViewComponent } from '../../attendance/leave-view/leave-view.component';
import { PermissionViewComponent } from '../../attendance/permission-view/permission-view.component';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  employeeNames: KeyValue<number, string>[];
  employeeNameList: string[];
  notificationTypeList: KeyValue<number, string>[] = [];
  notification: NotificationMessage = new NotificationMessage();
  notificationList: NotificationMessage[] = [];
  loading$ = new Subject<boolean>();
  // tslint:disable-next-line:max-line-length
  constructor(public global: Global, private commonApiService: CommonApiService, private toastService: ToastService, private modalService: NgbModal, private tabService: TabService) { }

  ngOnInit() {
    forkJoin(
      {
        employee: this.commonApiService.getUtilityData('employee_name', this.global.isAltLogin),
        notification: this.commonApiService.getMasterData('notification_type')
      })
      .subscribe(apiresponse => {
        this.employeeNames = apiresponse.employee.data;
        this.employeeNameList = this.employeeNames.map(a => a.value);
        this.notificationTypeList = apiresponse.notification.data;
        this.loadNotification();
      });
  }
  loadNotification() {
    this.commonApiService.getUnreadNotification(this.global.loggedUser.user_id)
      .pipe(indicate(this.loading$))
      .subscribe(apiresponse => {
        this.notificationList = apiresponse.data;
      });
  }
  refresh() {
    this.ngOnInit();
  }
  clearModel() {
    this.notification = new NotificationMessage();
  }
  openModel(content) {
    this.clearModel();
    this.modalService.open(content, { size: 'md' });
  }
  openWindow(notification: NotificationMessage) {
    this.markAsRead(notification);
    switch (notification.notification_type_id) {
      case 193: {
        this.tabService.addTab(new Tab(LeaveAdminViewComponent, 'Manage Leave'));
        break;
      }
      case 194: {
        this.tabService.addTab(new Tab(LeaveViewComponent, 'Leave'));
        break;
      }
      case 195: {
        this.tabService.addTab(new Tab(AssetRequestComponent, 'Manage Asset Request', { role: 'approver' }));
        break;
      }
      case 199: {
        this.tabService.addTab(new Tab(AssetRequestComponent, 'Asset Request'));
        break;
      }
      case 200: {
        this.tabService.addTab(new Tab(PermissionViewComponent, 'Manage Permission', { role: 'approver' }));
        break;
      }
      case 201: {
        this.tabService.addTab(new Tab(PermissionViewComponent, 'Request Permission'));
        break;
      }
    }
  }
  selectName(e) {
    const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
    if (emp) {
      this.notification.employee_id = emp.key;
    } else {
      this.notification.employee_id = null;
    }
  }
  markAsRead(notification: NotificationMessage) {
    const n: NotificationMessage = new NotificationMessage();
    n.is_read = true;
    n.notification_id = notification.notification_id;
    this.commonApiService.postNotification(n)
      .subscribe(apiresponse => {
        this.loadNotification();
      });
  }
  openDocument(id: number) {
    const win = window.open(this.commonApiService.getNotificationDocumentUrl(id), '_blank');
    win.focus();
  }
  sendMessage(frm: any) {
    if (frm.valid) {
      this.notification.created_by = this.global.loggedUser.user_type_internal_id;
      this.notification.created_date = new Date();
      this.notification.notification_type_id = this.notificationTypeList
        .filter(res => res.value === 'Message')
        .map((a) => a.key)[0];
      this.commonApiService.postNotification(this.notification)
        .subscribe(apiresponse => {
          this.clearModel();
          this.toastService.success('Message sent successfully');
          this.modalService.dismissAll();
          frm.reset();
        });
    }
  }
}
