import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { Shift } from 'src/app/model/admin';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { indicate } from 'src/app/services/loading.service';
import { ToastService } from 'src/app/services/toast.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-shift-list',
  templateUrl: './shift-list.component.html',
  styleUrls: ['./shift-list.component.scss']
})
export class ShiftListComponent implements OnInit {
  isNew = true;
  shiftList: Shift[] = [];
  shift: Shift = new Shift();
  loading$ = new Subject<boolean>();
  @ViewChild('shiftmodalcontent', { static: true }) shiftnmodalcontent: ElementRef;
  // tslint:disable-next-line:max-line-length
  constructor(public global: Global, private adminApiService: AdminApiService, private modalService: NgbModal, private toastService: ToastService) { }

  ngOnInit() {
    this.searchShift();
  }
  searchShift() {
    this.adminApiService.getShift()
      .pipe(indicate(this.loading$))
      .subscribe(apiresponse => {
        this.shiftList = apiresponse.data;
      });
  }
  editShift(e: any) {
    this.isNew = false;
    this.shift = e.row.data;
    this.modalService.open(this.shiftnmodalcontent, { size: 'lg' });
  }
  saveShift(frm: any) {
    if (frm.valid) {
      const cdt: Date = new Date();
      const edt: Date = new Date(this.shift.shift_end_time);
      const sdt: Date = new Date(this.shift.shift_start_time);
      edt.setFullYear(cdt.getFullYear());
      edt.setMonth(cdt.getMonth());
      edt.setDate(cdt.getDate());
      sdt.setFullYear(cdt.getFullYear());
      sdt.setMonth(cdt.getMonth());
      sdt.setDate(cdt.getDate());
      this.shift.shift_start_time = sdt;
      this.shift.shift_end_time = edt;
      this.adminApiService.postShift(this.shift).subscribe(apiresponse => {
        if (this.isNew) {
          this.toastService.success('Shift successfully created.');
        } else {
          this.toastService.success('Shift successfully updated.');
        }
        this.searchShift();
        this.clearModel();
        this.modalService.dismissAll();
      });
    }
  }
  openModel(content: any) {
    this.isNew = true;
    this.clearModel();
    this.modalService.open(content, { size: 'lg' });
  }
  clearModel() {
    this.shift = new Shift();
  }
}
