import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Global } from 'src/app/shared/global';
// tslint:disable-next-line:max-line-length
import { Employee, EmployeeComment, EmployeeDocument, EmployeeKpi, EmployeeRelease, Employeereliev, EmployeeSalaryIncrement, EmployeeWorkHistory } from 'src/app/model/admin';
import { Tab } from 'src/app/model/tab';
import { TabService } from 'src/app/services/tab.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserProfileComponent } from '../../common/user-profile/user-profile.component';
import { EmployeeSalary, SalaryComponent } from 'src/app/model/payroll';
import { PayrollApiService } from 'src/app/services/payroll-api.service';
import { SalarySheetComponent } from '../../payroll/salary-sheet/salary-sheet.component';
import { EditEmployeeComponent } from '../edit-employee/edit-employee.component';
import { confirm } from 'devextreme/ui/dialog';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { EmployeeLeaveBalance } from 'src/app/model/attendance';
import { indicate } from 'src/app/services/loading.service';
import { Subject, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ChangePasswordComponent } from '../../common/change-password/change-password.component';
import { User } from 'src/app/model/common';
import { ProfilePictureUploadComponent } from '../../common/profile-picture-upload/profile-picture-upload.component';
import { PerformanceViewComponent } from '../performance-view/performance-view.component';
import { RelievingLetterComponent } from '../relieving-letter/relieving-letter.component';


@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit, OnDestroy {
print() {
throw new Error('Method not implemented.');
}
loading$: null;
d(arg0: string) {
throw new Error('Method not implemented.');
}
  role = '';
  intializeData: any;
  employeeResignation: EmployeeRelease = new EmployeeRelease();
  employeeTermination: EmployeeRelease = new EmployeeRelease();
  employee: Employee = new Employee();
  employeereliev: Employeereliev = new Employeereliev();
  employeeSalary: EmployeeSalary = new EmployeeSalary();
  employeeList: Employee[] = [];
  statusList: KeyValue<number, string>[];
  terminationTypeList: KeyValue<number, string>[];
  releaseTypeList: KeyValue<number, string>[];
  earningTypeList: KeyValue<number, string>[];
  deductionTypeList: KeyValue<number, string>[];
  documentTypeList: KeyValue<number, string>[];
  designationList: KeyValue<number, string>[];
  leaveTypeList: KeyValue<number, string>[];
  file: File = null;
  locationId: number;
  designationId: number;
  departmentId: number;
  statusId: number;
  employeeId: number;
  isNew = false;
  documentTypeId: number;
  employeeDocumentList: EmployeeDocument[] = [];
  employeeWorkHistory: EmployeeWorkHistory[] = [];
  employeeLeaveList: EmployeeLeaveBalance[] = [];
  employeeHike: EmployeeSalaryIncrement[] = [];
  employeeComment: EmployeeComment[] = [];
  employeeKpi: EmployeeKpi[] = [];
  commentTypeList: KeyValue<number, string>[];
  kraList: KeyValue<number, string>[];
  today: Date = new Date();
  empCodeList: string[] = [];
  employeeNames: KeyValue<number, string>[];
  employeeNameList: KeyValue<number, string>[];
  departmentList: KeyValue<number, string>[];
  locationList: KeyValue<number, string>[];
  ddloading$ = new Subject<boolean>();
  empcodeloading$ = new Subject<boolean>();
  empgridloading$ = new Subject<boolean>();
  locationloading$ = new Subject<boolean>();
  docloading$ = new Subject<boolean>();
  subscriptions: Subscription[] = [];
  profilefile: File = null;
  imageSrc: string | ArrayBuffer;
  dateSearchType: string;
  searchFromDate: Date;
  searchToDate: Date;
  @ViewChild('terminatemodalcontent', { static: true }) terminatemodalcontent: ElementRef;
  @ViewChild('resignmodalcontent', { static: true }) resignmodalcontent: ElementRef;
  @ViewChild('empsalmodalcontent', { static: true }) empsalmodalcontent: ElementRef;
  @ViewChild('documentmodalcontent', { static: true }) documentmodalcontent: ElementRef;
  @ViewChild('empworkhistmodalcontent', { static: true }) empworkhistmodalcontent: ElementRef;
  @ViewChild('emphikemodalcontent', { static: true }) emphikemodalcontent: ElementRef;
  @ViewChild('empcommentmodalcontent', { static: true }) empcommentmodalcontent: ElementRef;
  @ViewChild('empkramodalcontent', { static: true }) empkramodalcontent: ElementRef;
  @ViewChild('empleavemodalcontent', { static: true }) empleavemodalcontent: ElementRef;
  @ViewChild('RelievingLetterComponent', { static: true }) RelievingLetterComponent: ElementRef;
  @ViewChild('relievingLetterTemplate', { static: true }) relievingLetterTemplate: ElementRef;
  @ViewChild('profilepicturemodalcontent', { static: true }) profilepicturemodalcontent: ElementRef;
  @ViewChild('changePassword', { read: ChangePasswordComponent }) changePassword: ChangePasswordComponent;
  user: User;
  constructor(
    public attendanceApiService: AttendanceApiService,
    private tabService: TabService,
    private modalService: NgbModal,
    public global: Global,
    public payrollApiService: PayrollApiService,
    public commonApiService: CommonApiService,
    public adminApiService: AdminApiService,
    private toastService: ToastService) {
    this.leavetypeValidation = this.leavetypeValidation.bind(this);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  ngOnInit() {
    this.initialize();
    this.statusId = 17;
    this.subscriptions.push(
      // tslint:disable-next-line:max-line-length
      this.commonApiService.getMasterData('department,designation,kra,employee_remark_type,employee_status,termination_type,release_type,document_type,payroll_earnings,payroll_deduction,leave_type')
        .pipe(indicate(this.ddloading$))
        .subscribe(apiresponse => {
          this.statusList = apiresponse.data.filter((d: { type: string; }) => d.type === 'employee_status');
          this.terminationTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'termination_type');
          this.releaseTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'release_type');
          this.designationList = apiresponse.data.filter((d: { type: string; }) => d.type === 'designation');
          this.earningTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'payroll_earnings');
          this.deductionTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'payroll_deduction');
          this.documentTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'document_type');
          this.commentTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'employee_remark_type');
          this.kraList = apiresponse.data.filter((d: { type: string; }) => d.type === 'kra');
          this.departmentList = apiresponse.data.filter((d: { type: string; }) => d.type === 'department');
          this.leaveTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'leave_type');
        }));
    this.subscriptions.push(
      this.commonApiService.getUtilityData('employee_code')
        .subscribe(apiresponse => {
          if (apiresponse.data) {
            this.empCodeList = apiresponse.data.map((a: { value: string; }) => a.value);
          }
        }));
    this.subscriptions.push(
      this.commonApiService.getUtilityData('employee_name', this.global.isAltLogin)
        .pipe(indicate(this.empcodeloading$))
        .subscribe(apiresponse => {
          this.employeeNames = apiresponse.data;
          this.employeeNameList = this.employeeNames.reduce((a, o) => (a.push(o.value), a), []);
        }));
    this.subscriptions.push(
      this.commonApiService.getUtilityData('location')
        .pipe(indicate(this.locationloading$))
        .subscribe(apiresponse => {
          this.locationList = apiresponse.data;
        }));
  }
  initialize() {
    if (this.intializeData && this.intializeData.role) {
      this.role = this.intializeData.role;
    }
    if (this.role === 'manager') {
      let employeeid;
      employeeid = this.global.loggedUser.user_type_internal_id;
      this.adminApiService.getEmployeeList(this.employeeId, this.designationId,
        this.departmentId,
        this.statusId,
        this.global.isAltLogin,
        this.locationId,
        this.dateSearchType,
        this.searchFromDate,
        this.searchToDate).subscribe((response) => {
          this.employeeList = response.data;
        })
    }
  }
  searchEmployee() {
    this.subscriptions.push(
      this.adminApiService.getEmployeeList
        (
          this.employeeId,
          this.designationId,
          this.departmentId,
          this.statusId,
          this.global.isAltLogin,
          this.locationId,
          this.dateSearchType,
          this.searchFromDate,
          this.searchToDate
        )
        .pipe(indicate(this.empgridloading$))
        .subscribe(apiresponse => {
          this.employeeList = apiresponse.data;
        }));
  }
  refresh() {
    this.ngOnInit();
    this.searchEmployee();
  }
  onFileChange(e) {
    this.file = e.target.files[0];
  }
  uploadDocument(frm: { valid: boolean; }) {
    if (frm.valid) {
      this.subscriptions.push(
        this.adminApiService
          .uploadEmployeeDocument(this.file, this.documentTypeId, this.employee.employee_id)
          .pipe(indicate(this.docloading$))
          .pipe(
            concatMap(apiresponse => {
              this.toastService.success('Document uploaded successfully');
              this.documentTypeId = undefined;
              return this.adminApiService.getEmployeeDocuments(this.employee.employee_id);
            })
          ).subscribe(
            apiresponse => {
              this.employeeDocumentList = apiresponse.data;
              this.employeeDocumentList.forEach(element => {
                element.document_name = element.document_path.replace(/^.*[\\\/]/, '');
              });
            }
          ));
    }
  }
  saveEmployeeSalary(isValid: boolean) {
    if (isValid) {
      let comp: SalaryComponent[] = [];
      comp = comp.concat(this.employeeSalary.deduction_components);
      comp = comp.concat(this.employeeSalary.deduction_components_new);
      comp = comp.concat(this.employeeSalary.earning_components);
      comp = comp.concat(this.employeeSalary.earning_components_new);
      comp.forEach(element => {
        element.employee_id = this.employee.employee_id;
      });
      this.subscriptions.push(
        this.payrollApiService
          .postEmployeeSalaryMaster(this.employee.employee_id, comp).subscribe(apiresponse => {
            this.toastService.success('Employee salary successfully saved.');
            this.modalService.dismissAll();
          }));
    }
  }
  loadEmployeeWorkHistory() {
    this.subscriptions.push(
      this.adminApiService
        .getEmployeeWorkHistory(this.employee.employee_id)
        .subscribe(apiresponse => {
          this.employeeWorkHistory = apiresponse.data;
          this.modalService.open(this.empworkhistmodalcontent, { size: 'lg' });
        }));
  }
  editWorkEmployee(e: any) {
    this.isNew = false;
    this.employee = e.row.data;
    this.loadEmployeeWorkHistory();
  }
  editEmployee(e: any) {
    this.isNew = false;
    this.employee = e.row.data;
    this.employee.is_new = false;
    // tslint:disable-next-line:max-line-length
    this.tabService.addTab(new Tab(EditEmployeeComponent, 'Employee Edit - ' + this.employee.employee_code, { employee: this.employee }));
  }
  addEmployee() {
    const emp = new Employee();
    emp.is_new = true;
    this.tabService.addTab(new Tab(EditEmployeeComponent, 'Employee New', { employee: emp }));
  }
  editEmployeeSalary(e: any) {
    this.employee = e.row.data;
    this.subscriptions.push(
      this.payrollApiService
        .getEmployeeSalaryMaster(this.employee.employee_id)
        .subscribe(apiresponse => {
          const salarycomponents = apiresponse.data;
          this.employeeSalary.employee_code = this.employee.employee_code;
          this.employeeSalary.employee_name = this.employee.first_name + ' ' + this.employee.last_name;
          this.employeeSalary.deduction_components = salarycomponents.filter(d => d.salary_component_type === 'Deduction');
          this.employeeSalary.earning_components = salarycomponents.filter(d => d.salary_component_type === 'Earning');
          this.employeeSalary.earning_components_new = [];
          this.employeeSalary.deduction_components_new = [];
          this.modalService.open(this.empsalmodalcontent, { size: 'lg' });
        }));
  }

  clearModel() {
    this.employee = new Employee();
    this.employeeTermination = new EmployeeRelease();
    this.employeeResignation = new EmployeeRelease();
  }

  async resignEmployeeModal(e: any) {
    this.employee = e.row.data;
    this.employeeResignation = new EmployeeRelease();
    this.modalService.open(this.resignmodalcontent, { size: 'lg' });
  }
  async terminateEmployeeModal(e: any) {
    this.employee = e.row.data;
    this.employeeTermination = new EmployeeRelease();
    this.modalService.open(this.terminatemodalcontent, { size: 'lg' });
  }
  openDocuments(e: any) {
    this.employee = e.row.data;
    this.documentTypeId = undefined;
    this.subscriptions.push(
      this.adminApiService
        .getEmployeeDocuments(this.employee.employee_id)
        .subscribe(apiresponse => {
          this.employeeDocumentList = apiresponse.data;
          this.employeeDocumentList.forEach(element => {
            element.document_name = element.document_path.replace(/^.*[\\\/]/, '');
          });
          this.modalService.open(this.documentmodalcontent, { size: 'lg' });
        }));
  }
  openResignation(e: any) {
    this.employee = e.row.data;
    this.subscriptions.push(
      this.adminApiService
        .getRelease(this.employee.employee_id)
        .subscribe(apiresponse => {
          this.employeeResignation = apiresponse.data[0];
          this.modalService.open(this.resignmodalcontent, { size: 'lg' });
        }));
  }
  openTermination(e: any) {
    this.employee = e.row.data;
    this.subscriptions.push(
      this.adminApiService
        .getRelease(this.employee.employee_id)
        .subscribe(apiresponse => {
          this.employeeTermination = apiresponse.data[0];
          this.modalService.open(this.terminatemodalcontent, { size: 'lg' });
        }));
  }
  inactiveEmployee(e: any) {
    confirm('Are you sure, you want to change the employee status to inactive ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.employee = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'InActive').key;
        this.subscriptions.push(
          this.adminApiService
            .changeStatusEmployee(satusId, this.employee.employee_id)
            .subscribe(apiresponse => {
              this.toastService.success('Employee status successfully changed to Inactive.');
              this.searchEmployee();
            }));
      }
    });
  }



  openProfile(e: any) {
    this.employee = e.row.data;
    // tslint:disable-next-line:max-line-length
    this.tabService.addTab(new Tab(UserProfileComponent, this.employee.employee_code, { id: this.employee.employee_id }));
  }
  openProfilePerformance(e: any) {
    this.employee = e.row.data;
    // tslint:disable-next-line:max-line-length
    this.tabService.addTab(new Tab(PerformanceViewComponent, `Performance-${this.employee.employee_code}`, { employee: this.employee }));
  }

  // openRelievingLetter(e: any) {
  //   this.employee = e.row.data;
  //   this.subscriptions.push(
  //     this.adminApiService
  //       .getEmployeeDetails(this.employee.employee_id)
  //       .subscribe(apiresponse => {
  //         //this.employee = apiresponse.data;
  //         this.modalService.open(this.RelievingLetterComponent, { size: 'lg' });
  //       }));
  // }

  // relieving(e: any) {
  //   confirm('Are you sure, you want to give a offer letter?', 'Confirmation ').then((dialogResult: any) => {
  //     if (dialogResult) {
  //       this.employee = e.row.data;
  //       const satusId: number = this.statusList.find(d => d.value === 'Offer Released').key;
  //        this.subscriptions.push(
  //         this.adminApiService
  //            .changeStatusEmployee(satusId, this.employee.employee_id)
  //            .subscribe(apiresponse => {
  //             this.modalService.open(this.terminatemodalcontent, { size: 'lg' });
    
  //            })
  //           );
  //     }
  //   });
  // }

  // openRelievingLetter(e: any) {
  //   this.employee = e.row.data;
  //   this.subscriptions.push(
  //     this.adminApiService
  //       .getEmployeeDetails(this.employee.employee_id)
  //       .subscribe(apiresponse => {
  //         this.employeereliev = apiresponse.data[0];
  //         this.modalService.open(this.relievingLetterTemplate, { size: 'xl' });
  //       }));
  // }

  openRelievingLetter(e: any) {
    this.employee = e.row.data;
    this.subscriptions.push(
      this.adminApiService
        .getEmployeeDetails(this.employee.employee_id)
        .subscribe(apiresponse => {
          this.employeereliev = apiresponse.data[0];
          this.subscriptions.push(
            this.adminApiService
              .getEmployee(this.employee.user_id)
              .subscribe(apiresponse => {
                this.employee = apiresponse.data[0];
                // You can now access the user_name and display_name
                console.log(this.employee.user_name);
                console.log(this.employee.display_name);
              })
          );
          this.modalService.open(this.relievingLetterTemplate, { size: 'xl' });
        })
    );
  }



  // openRelievingLetter(cellInfo: any)  {
  //   this.employeereliev = cellInfo.row.data;
  //   console.log('Recurver data from cellInfo:', this.employeereliev);
  //   this.subscriptions.push(
  //     this.adminApiService.getEmployeeDetails(this.employeereliev.employee_id).subscribe(apiresponse => {
  //       console.log('API Response:', apiresponse);

  //       if (apiresponse.data && apiresponse.data.length > 0) {
  //         const releaseData = apiresponse.data[0];
  //         console.log('Release Data:', releaseData);
  //         const modalRef = this.modalService.open(this.relievingLetterTemplate, { size: 'lg' });

  //         modalRef.componentInstance.employeereliev = {
  //           first_name: releaseData.first_name,
  //           last_name: releaseData.last_name,
  //           company_name: releaseData.company_name,
  //           //address: releaseData.address,
  //           address1: releaseData.address1,
  //           city: releaseData.city,
  //           state: releaseData.state,
  //           pin: releaseData.pin,
  //           release_date: releaseData.release_date,
  //           last_date: releaseData.last_date,
  //           eaddress: releaseData.eaddress,
  //           // deadline: releaseData.deadline,
  //           // location: releaseData.location,
  //           // designation_id: releaseData.designation_id,
  //           address: releaseData.address || 'Address not available',
  //           // position: releaseData.position || 'Position not available',
  //           // salary: releaseData.salary || 'Salary not available',
  //           // date: new Date().toLocaleDateString(),
  //           // startDate: cellInfo.row.data.joining_date || 'Start date not available',
  //           additionalInfo: 'As discussed, the joining formalities and other details will be shared.',
  //           responseDeadline: new Date(new Date().setDate(new Date().getDate() + 7))
  //         };
  
  //         // Log the offer letter data passed to the modal
  //         console.log('Offer Letter Data:', modalRef.componentInstance.employeereliev);
  //       } else {
  //         console.error('No data returned from API');
  //       }
  //     })
  //   );
  // }

  activeEmployee(e: any) {
    confirm('Are you sure, you want to change the employee status to active ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.employee = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'Active').key;
        this.subscriptions.push(
          this.adminApiService
            .changeStatusEmployee(satusId, this.employee.employee_id)
            .subscribe(apiresponse => {
              this.toastService.success('Employee status successfully changed to Active.');
              this.searchEmployee();
            }));
      }
    });
  }
  relieveEmployee(e: any) {
    confirm('Are you sure, you want to change the employee status to releived ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.employee = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'Relieved').key;
        this.subscriptions.push(
          this.adminApiService
            .changeStatusEmployee(satusId, this.employee.employee_id)
            .subscribe(apiresponse => {
              this.toastService.success('Employee status successfully changed to Relieved.');
              this.searchEmployee();
            }));
      }
    });
  }
  resignEmployee(frm: any) {
    if (frm.valid) {
      this.employeeResignation.employee_id = this.employee.employee_id;
      const releaseTypeId: number = this.releaseTypeList.find(d => d.value === 'Resignation').key;
      this.employeeResignation.release_type_id = releaseTypeId;
      this.subscriptions.push(
        this.adminApiService
          .releaseEmployee(this.employeeResignation)
          .subscribe(apiresponse => {
            this.toastService.success('Employee resignation saved successfully');
            this.searchEmployee();
            this.clearModel();
            this.modalService.dismissAll();
          }));
    }
  }
  // relieveingLetter(e: any) {
  //   this.employee = e.row.data;
  //   this.employee.full_name = this.employee.first_name + ' ' + this.employee.last_name;
  //   this.tabService.addTab(new Tab(RelievingLetterComponent, 'Relieving Letter - ' + this.employee.employee_code, { id: this.employee }));

  // }
  terminateEmployee(frm: any) {
    if (frm.valid) {
      this.employeeTermination.employee_id = this.employee.employee_id;
      const releaseTypeId: number = this.releaseTypeList.find(d => d.value === 'Termination').key;
      this.employeeTermination.release_type_id = releaseTypeId;
      this.subscriptions.push(
        this.adminApiService
          .releaseEmployee(this.employeeTermination)
          .subscribe(apiresponse => {
            this.toastService.success('Employee termination saved successfully');
            this.searchEmployee();
            this.clearModel();
            this.modalService.dismissAll();
          }));
    }
  }
  selectName(e) {
    if (e.selectedItem === null) {
      this.employeeId = null;
    } else {
      const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
      if (emp) {
        this.employeeId = emp.key;
      }
    }
  }
  openDocumentWindow(id: number) {
    const win = window.open(this.adminApiService.getEmployeeDocumentUrl(id), '_blank');
    win.focus();
  }
  workHistInserted(e) {
    const h: EmployeeWorkHistory = e.data;
    h.employee_id = this.employee.employee_id;
    this.subscriptions.push(
      this.adminApiService
        .postWorkHistory(h)
        .subscribe(apiresponse => {
          this.toastService.success('Work History successfully created.');
          e.data.employee_work_history_id = apiresponse.data[0].id;
        }));
  }
  workHistUpdated(e) {
    const h: EmployeeWorkHistory = e.data;
    this.subscriptions.push(
      this.adminApiService
        .postWorkHistory(h)
        .subscribe(apiresponse => {
          this.toastService.success('Work History successfully updated.');
        }));
  }
  workHistRemoved(e) {
    this.subscriptions.push(
      this.adminApiService
        .deleteWorkHistory(e.data.employee_work_history_id)
        .subscribe(apiresponse => {
          this.toastService.success('Work History removed successfully');
        }));
  }
  deleteDocument(id: number) {
    confirm('Are you sure, you want to delete the document ?', 'Confirmation ')
      .then((dialogResult: any) => {
        if (dialogResult) {
          this.subscriptions.push(
            this.adminApiService
              .deleteEmployeeDocument(id)
              .pipe(indicate(this.docloading$))
              .pipe(
                concatMap(apiresponse => {
                  this.toastService.success('Document deleted successfully');
                  this.documentTypeId = undefined;
                  return this.adminApiService.getEmployeeDocuments(this.employee.employee_id);
                })
              ).subscribe(
                apiresponse => {
                  this.employeeDocumentList = apiresponse.data;
                  this.employeeDocumentList.forEach(element => {
                    element.document_name = element.document_path.replace(/^.*[\\\/]/, '');
                  });
                }
              ));
        }
      });
  }
  employeeExcelSheet() {
    const win = window.open(this.commonApiService.geDocumentUrl(), '_blank');
    win.focus();
  }

  employeeSheet() {
    const reportData: any[] = [];
    this.employeeList.forEach(element => {
      const r: any = {};
      r.Employee_Code = element.employee_code;
      r.Name = element.first_name + ' ' + element.last_name;
      r.Phone_Number = element.personal_info.phone;
      r.Email = element.personal_info.email;
      r.Address = element.personal_info.address == null ? '' : atob(element.personal_info.address);
      r.City = element.personal_info.city;
      r.State = element.personal_info.state;
      reportData.push(r);
    });
    this.tabService.addTab(new Tab(SalarySheetComponent, 'Employee Information', { id: { type: 'employee', data: reportData } }));
  }
  OpenLeaves(e: any) {
    this.employee = e.row.data;
    this.subscriptions.push(
      this.attendanceApiService
        .getLeaveBalance(this.employee.employee_id)
        .subscribe(apiresponse => {
          const l: EmployeeLeaveBalance[] = apiresponse.data;
          this.employeeLeaveList = l.filter(x => x.leave_type_id !== 171 && x.leave_type_id !== 0);
          this.modalService.open(this.empleavemodalcontent, { size: 'lg' });
        }));
  }
  OpenSalaryHike(e: any) {
    this.employee = e.row.data;
    this.subscriptions.push(
      this.payrollApiService
        .getEmployeeSalaryIncrement(this.employee.employee_id)
        .subscribe(apiresponse => {
          this.employeeHike = apiresponse.data;
          this.modalService.open(this.emphikemodalcontent, { size: 'lg' });
        }));
  }
  openComments(e: any) {
    this.employee = e.row.data;
    this.subscriptions.push(
      this.adminApiService
        .getEmployeeComments(this.employee.employee_id)
        .subscribe(apiresponse => {
          this.employeeComment = apiresponse.data;
          this.modalService.open(this.empcommentmodalcontent, { size: 'lg' });
        }));
  }
  openKra(e: any) {
    this.employee = e.row.data;
    this.subscriptions.push(
      this.adminApiService
        .getEmployeeKpi(this.employee.employee_id)
        .subscribe(apiresponse => {
          this.employeeKpi = apiresponse.data;
          this.modalService.open(this.empkramodalcontent, { size: 'lg' });
        }));
  }
  updateDateType(event) {

  }
  openChangePassword(e: any) {
    this.employee = e.row.data;
    this.subscriptions.push(
      this.commonApiService
        .getUser(this.global.isParentCompany, this.employee.user_id)
        .subscribe(apiresponse => {
          this.user = apiresponse.data;
          this.changePassword.openModal(this.user);
        }));
  }
  openProfilePicture(e: any) {
    this.employee = e.row.data;
    this.imageSrc = null;
    this.modalService.open(this.profilepicturemodalcontent, { size: 'lg' });
  }
  salIncrementInserted(e) {
    const s: EmployeeSalaryIncrement = e.data;
    s.employee_id = this.employee.employee_id;
    s.increment_date = new Date();
    this.subscriptions.push(
      this.payrollApiService
        .postSalaryIncrement(s)
        .subscribe(apiresponse => {
          this.toastService.success('Salary Increment successfully created.');
          e.data.increment_id = apiresponse.data[0].id;
          this.searchEmployee();
        }));
  }

  salIncrementUpdated(e) {
    const s: EmployeeSalaryIncrement = e.data;
    this.subscriptions.push(
      this.payrollApiService
        .postSalaryIncrement(s)
        .subscribe(apiresponse => {
          this.toastService.success('Salary Increment successfully updated.');
        }));
  }
  salIncrementRemoved(e) {
    this.subscriptions.push(
      this.payrollApiService
        .deleteSalaryIncrement(e.data.increment_id)
        .subscribe(apiresponse => {
          this.toastService.success('Salary Increment removed successfully');
        }));
  }
  empCommentInserted(e) {
    const s: EmployeeComment = e.data;
    if (s.employee_comment && s.employee_comment !== '') {
      s.employee_id = this.employee.employee_id;
      this.subscriptions.push(
        this.adminApiService
          .postEmployeeComment(s)
          .subscribe(apiresponse => {
            this.toastService.success('Employee comment successfully created.');
            e.data.employee_comment_id = apiresponse.data[0].id;
          }));
    } else {
      this.toastService.warning('Employee comment required.');
    }
  }
  empCommentUpdated(e) {
    const s: EmployeeComment = e.data;
    if (s.employee_comment && s.employee_comment !== '') {
      this.subscriptions.push(
        this.adminApiService
          .postEmployeeComment(s)
          .subscribe(apiresponse => {
            this.toastService.success('Employee comment successfully updated.');
          }));
    } else {
      this.toastService.warning('Employee comment required.');
    }
  }
  empCommentValidating(e: any) {
    const s: EmployeeComment = e.newData;
    if (!s.employee_comment || s.employee_comment === '') {
      e.errorText = 'Employee comment required.';
      e.isValid = false;
    } else {
      e.isValid = true;
    }
  }
  kpiDescriptionValidating(e: any) {
    const s: EmployeeKpi = e.newData;
    if (!s.kpi_description || s.kpi_description === '') {
      e.errorText = 'KPI description is required.';
      e.isValid = false;
    } else {
      e.isValid = true;
    }
  }

  empCommentRemoved(e) {
    this.subscriptions.push(
      this.adminApiService
        .deleteEmployeeComment(e.data.employee_comment_id)
        .subscribe(apiresponse => {
          this.toastService.success('Employee comment removed successfully');
        }));
  }
  empKraInserted(e) {
    const s: EmployeeKpi = e.data;
    if (s.kpi_description && s.kpi_description !== null) {
      s.employee_id = this.employee.employee_id;
      this.subscriptions.push(
        this.adminApiService
          .postEmployeeKpi(s)
          .subscribe(apiresponse => {
            this.toastService.success('Employee KPI successfully created.');
            e.data.employee_kpi_id = apiresponse.data[0].id;
          }));
    } else {
      this.toastService.warning('Employee KPI description required.');
    }
  }
  empKraUpdated(e) {
    const s: EmployeeKpi = e.data;
    if (s.kpi_description && s.kpi_description !== null) {
      this.subscriptions.push(
        this.adminApiService
          .postEmployeeKpi(s)
          .subscribe(apiresponse => {
            this.toastService.success('Employee KPI successfully updated.');
          }));
    } else {
      this.toastService.warning('Employee KPI description required.');
    }
  }
  empKraRemoved(e) {
    this.subscriptions.push(
      this.adminApiService
        .deleteEmployeeKpi(e.data.employee_kpi_id)
        .subscribe(apiresponse => {
          this.toastService.success('Employee KPI removed successfully');
        }));
  }
  empLeaveInserted(e) {
    const s: EmployeeLeaveBalance = e.data;
    s.employee_id = this.employee.employee_id;
    this.subscriptions.push(
      this.attendanceApiService.postEmployeeLeave(s)
        .subscribe(apiresponse => {
          this.toastService.success('Employee Leave successfully Added.');
          e.data.employee_leave_balance_id = apiresponse.data[0].id;
        }));
  }
  empLeaveUpdated(e) {
    const s: EmployeeLeaveBalance = e.data;
    this.subscriptions.push(
      this.attendanceApiService
        .postEmployeeLeave(s)
        .subscribe(apiresponse => {
          this.toastService.success('Employee Leave successfully updated.');
        }));
  }
  empLeaveRemoved(e) {
    this.subscriptions.push(
      this.attendanceApiService
        .deleteEmployeeLeave(e.data.employee_leave_balance_id)
        .subscribe(apiresponse => {
          this.toastService.success('Employee Leave removed successfully');
        }));
  }
  leavetypeValidation(params) {
    // tslint:disable-next-line:max-line-length
    const l: EmployeeLeaveBalance = this.employeeLeaveList.find(x => x.leave_type_id === params.data.leave_type_id && x.employee_leave_balance_id !== params.data.employee_leave_balance_id);
    if (l) {
      return new Promise((resolve, reject) => {
        resolve(false);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }
  }
  onValueChanged(evt: any, data: any): void {
    data.setValue(evt.value);
  }
  onProfileFileChange(e) {
    if (e.target.files && e.target.files[0]) {
      this.profilefile = e.target.files[0];
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = ea => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }
  uploadProfileDocument(frm) {
    if (frm.valid) {
      this.adminApiService.uploadProfilePicture(this.profilefile, this.employee.user_id.toString())
        .subscribe(apiresponse => {
          this.imageSrc = null;
          this.toastService.success('Profile Picture uploaded successfully');
          this.modalService.dismissAll();
        });
    }
  }
  fromdateChanged(e: any) {
    if (e.event !== undefined) {
      this.searchFromDate = e.value;
      this.searchToDate = null;
    }
  }
  todateChanged(e: any) {
    if (e.event !== undefined) {
      this.searchToDate = e.value;
    }
  }
  resetDate(e: any) {
    if (!this.dateSearchType) {
      this.searchToDate = null;
      this.searchFromDate = null;
    }
  }
}
