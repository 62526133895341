<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title" *ngIf="role==='hr-manager'">Survey List</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12 mt-1 table-responsive" id="survey-grid">
            <dx-data-grid #gridSurvey id="gridContainer" [dataSource]="surveyList" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false">
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxi-column dataField="survey_name" [allowFiltering]="false" [allowSorting]="false"
                    [allowGrouping]="false" cellTemplate="surveyTemplate" caption="SURVEY NAME">
                </dxi-column>
                <dxi-column dataField="survey_description" caption="SURVEY DESCRIPTIOn" dataType="string"
                    [allowGrouping]="false" [allowFiltering]="true">
                </dxi-column>
                <dxi-column caption="Copy Link" [allowFiltering]="false" [allowSorting]="false"
                    cellTemplate="copyLinkTemplate" alignment="center" [allowFiltering]="false"></dxi-column>
                <dxo-summary>
                    <dxi-total-item column="survey_name" dataField="survey_link" summaryType="count" title="Survey Count"
                        displayFormat="Survey Count: {0}">
                    </dxi-total-item>
                </dxo-summary>
                <div *dxTemplate="let data of 'copyLinkTemplate'">
                    <h2 class="table-avatar">
                        <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);" (click)="copyMessage(data.data.survey_id)">
                            <i class="fa fa-copy text-success"></i> Copy
                        </a>
                    </h2>
                </div>
                <div *dxTemplate="let data of 'surveyTemplate'">
                    <h2 class="table-avatar">
                        <a class="ml-1 mt-1 gridlink" href="javascript:void(0);" (click)="surveyReport(data)">
                            {{data.row.data.survey_name}}</a>
                    </h2>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
<div *ngIf="(surveyloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#survey-grid' }" [showIndicator]="true"
        [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#survey-grid">
    </dx-load-panel>
</div>