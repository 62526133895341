<div class="content container-fluid" id="notification-loading">
    <form #notificationForm="ngForm">
        <div class="page-header">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="page-title">Send Notification</h3>
                </div>
                <div class="col-auto float-right ml-auto">
                    <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
                </div>
            </div>
        </div>
        <div class="row filter-row">
            <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
                <select class="form-control" id="department" name="department" [(ngModel)]="notification.department_id"
                    [ngClass]="{'loading-control': (controlloading$ | async) === true }">
                    <option [ngValue]="undefined" selected>All Department</option>
                    <option *ngFor="let item of departmentList; let i = index" [ngValue]="item.key">
                        {{item.value}}</option>
                </select>
            </div>
            <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
                <select class="form-control" id="designation" name="designation"
                    [(ngModel)]="notification.designation_id"
                    [ngClass]="{'loading-control': (controlloading$ | async) === true }">
                    <option [ngValue]="undefined" selected>All Designation</option>
                    <option *ngFor="let item of designationList; let i = index" [ngValue]="item.key">
                        {{item.value}}</option>
                </select>
            </div>
            <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
                <select class="form-control" id="location" name="location" [(ngModel)]="notification.location_id"
                    [ngClass]="{'loading-control': (locationloading$ | async) === true }">
                    <option [ngValue]="undefined" selected>All Location</option>
                    <option *ngFor="let item of locationList; let i = index" [ngValue]="item.key">
                        {{item.value}}</option>
                </select>
            </div>
            <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
                <select class="form-control" id="type" name="type" [(ngModel)]="notification.notification_type_id"
                    [ngClass]="{'loading-control': (controlloading$ | async) === true }">
                    <option *ngFor="let item of notificationTypeList; let i = index" [ngValue]="item.key">
                        {{item.value}}</option>
                </select>
            </div>
        </div>
        <div class="row filter-row mt-3">
            <div class="col-sm-6 col-md-3 col-lg-3 col-xl-12 col-12">
                <input type="file" #fileupload id="empFile" class="form-control" (change)="onFileChange($event)" />
            </div>
        </div>
        <div class="row  mt-5">
            <div class="col-sm-12 mt-2">
                <label for="message">Message <span class="text-danger">*</span></label>
                <textarea rows="4" class="form-control" id="message" name="message"
                    [(ngModel)]="notification.notification_message" #message="ngModel" required></textarea>
                <div *ngIf="message.invalid && (message.dirty || message.touched)"
                    class="badge badge-danger position-absolute">
                    <div *ngIf="message.errors.required">
                        Message is required.
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 mt-5">
                <div class="btn-group btn-group-sm float-right ml-1">
                    <button class="btn add-btn" (click)="sendNotification(notificationForm)"
                        [disabled]="!notification.notification_message"><i class="fa fa-paper-plane"></i>
                        Send</button>
                </div>
            </div>
        </div>
    </form>
</div>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#notification-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#notification-loading">
    </dx-load-panel>
</div>