<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title" *ngIf="role==='manager'">My Team</h3>
                <h3 class="page-title" *ngIf="role==='hr-manager'">Manage Employee</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()" ><i class="fa fa-refresh"></i> Refresh</button>
                <button class="btn add-btn" (click)="addEmployee()" *ngIf="role==='hr-manager'"><i class="fa fa-plus"></i> Add
                    Employee</button>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="role==='hr-manager'">
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
            <dx-autocomplete class="form-control filter-control" id="department" name="department" id="employeeName"
                placeholder="Type Employee Name...." (onSelectionChanged)="selectName($event)"
                [dataSource]="employeeNameList" [showClearButton]="true">
            </dx-autocomplete>
        </div>
        <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-12">
            <select class="form-control filter-control"
                [ngClass]="{'loading-control': (locationloading$ | async) === true }" id="location" name="location"
                [(ngModel)]="locationId">
                <option [ngValue]="undefined" selected>All Location</option>
                <option *ngFor="let item of locationList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="designation" name="designation"
                [ngClass]="{'loading-control': (ddloading$ | async) === true }"
                [(ngModel)]="designationId">
                <option [ngValue]="undefined" selected>All Designation</option>
                <option *ngFor="let item of designationList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="department" name="department"
                [ngClass]="{'loading-control': (ddloading$ | async) === true }" 
                [(ngModel)]="departmentId">
                <option [ngValue]="undefined" selected>All Department</option>
                <option *ngFor="let item of departmentList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="status" name="status"
                [ngClass]="{'loading-control': (ddloading$ | async) === true }" 
                [(ngModel)]="statusId">
                <option [ngValue]="undefined" selected>All Status</option>
                <option *ngFor="let item of statusList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
    </div>
    <div class="row mt-2" *ngIf="role==='hr-manager'">
        <div class="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-12 offset-md-5">
            <div class="d-inline">
                <select class="form-control filter-control"
                id="dateSearchType" name="dateSearchType"
                [(ngModel)]="dateSearchType" (change)="resetDate($event)">
                <option [ngValue]="undefined" selected>All Date type</option>
                <option value="resignation">Resignation Date</option>
                <option value="joining">Joining Date</option>
            </select>
            </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <dx-date-box [(value)]="searchFromDate" type="date" class="form-control filter-control"  [disabled]="!dateSearchType"
                placeholder="Search From Date" (onValueChanged)="fromdateChanged($event)" [max]="searchToDate">
            </dx-date-box>

        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <dx-date-box [(value)]="searchToDate" type="date" class="form-control filter-control" [disabled]="!dateSearchType"
                placeholder="Search To Date" (onValueChanged)="todateChanged($event)" [min]="searchFromDate">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-1 col-12">
            <button class="btn btn-secondary btn-block" (click)="searchEmployee()">
                <i class="fa fa-search fa-lg"></i>
            </button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-12 col-md-12">
            <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="employeeExcelSheet()" class="btn btn-white">
                    <i class="fa fa-file-excel-o fa-lg"></i> Employee Data Download</button>
            </div>
            <div class="btn-group btn-group-sm float-right ml-1">
                <button (click)="employeeSheet()" class="btn btn-white" [disabled]="employeeList.length==0">
                    <i class="fa fa-file-o fa-lg"></i> Employee Info Sheet</button>
            </div>
        </div>
        <div class="col-md-12 mt-1 table-responsive" id="emp-grid">
            <dx-data-grid #gridEmployee id="gridContainer" [dataSource]="employeeList" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false">
                <dxo-export [enabled]="true" fileName="employeeReport"></dxo-export>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                <dxi-column dataField="employee_name" [allowFiltering]="true" [allowSorting]="false"
                    [allowGrouping]="false" cellTemplate="imageTemplate" caption="EMPLOYEE" width="250" cssClass="cls">
                </dxi-column>
                <dxi-column dataField="employee_code" caption="Code" dataType="string" [allowGrouping]="false"
                    [allowFiltering]="true">
                </dxi-column>
                <dxi-column dataField="shift_name" caption="SHIFT" dataType="string" [allowGrouping]="true"
                    [allowFiltering]="true">
                </dxi-column>
                <dxi-column dataField="employee_type" caption="EMPLOYEE TYPE" dataType="string" [allowGrouping]="true"
                    [allowFiltering]="true">
                </dxi-column>
                <dxi-column dataField="location" caption="Location" [allowFiltering]="true"></dxi-column>
                <dxi-column dataField="department" caption="DEPARTMENT" dataType="string"></dxi-column>
                <dxi-column dataField="bank_details.account_number" caption="ACCOUNT NUMBER" dataType="string"
                    [allowGrouping]="false" [allowFiltering]="false" [visible]="false">
                </dxi-column>
                <dxi-column dataField="employee_status" caption="STATUS" [allowFiltering]="false" [allowSorting]="false"
                    cellTemplate="statusTemplate" alignment="center" [allowFiltering]="false"></dxi-column>
                <dxi-column dataField="employee_id" [allowFiltering]="false" width=50 caption="" [allowSorting]="false"
                    cellTemplate="actionTemplate" alignment="center" [allowGrouping]="false" [allowFiltering]="false">
                </dxi-column>
                <dxo-summary>
                    <dxi-total-item column="employee_name" summaryType="count" title="Employee Count"
                        displayFormat="Employee Count: {0}">
                    </dxi-total-item>
                </dxo-summary>
                <div *dxTemplate="let data of 'dateTemplate'">
                    {{data.value | date}}
                </div>
                <div *dxTemplate="let data of 'imageTemplate'">
                    <h2 class="table-avatar">
                        <ngx-avatar size="40" [src]="data.row.data.personal_info.profile_picture"
                            [name]="data.row.data.first_name + ' ' + (data.row.data.last_name==null ? '' : data.row.data.last_name)">
                        </ngx-avatar>
                        <a class="ml-1 mt-1 gridlink" href="javascript:void(0);" (click)="openProfile(data)">
                            {{data.row.data.first_name}}
                            {{data.row.data.last_name}}<span>{{data.row.data.designation}} </span></a>
                    </h2>
                </div>
                <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Active'">
                        <i class="fa fa-dot-circle-o text-success"></i> Active
                    </a>
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='InActive'">
                        <i class="fa fa-dot-circle-o text-muted"></i> InActive
                    </a>
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Terminated'">
                        <i class="fa fa-dot-circle-o text-danger"></i> Terminated
                    </a>
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Relieved'">
                        <i class="fa fa-dot-circle-o text-danger"></i> Relieved
                    </a>
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Resigned'">
                        <i class="fa fa-dot-circle-o text-warning"></i> Resigned
                    </a>

                </div>
                <div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
                    <div ngbDropdown container="body">
                        <a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle><i
                                class="material-icons">more_vert</i></a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="editEmployee(cellInfo)" *ngIf="role==='hr-manager'"><i
                                    class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="javascript:void(0);"
                                (click)="editEmployeeSalary(cellInfo)" *ngIf="role==='hr-manager'"><i class="fa fa-inr m-r-5"></i> Edit Salary</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="editWorkEmployee(cellInfo)" *ngIf="role==='hr-manager'"><i
                                    class="fa fa-history m-r-5"></i> Edit Work History</a>
                            <a *ngIf="cellInfo.row.data.employee_status=='Resigned' && role==='hr-manager'" class="dropdown-item"
                                href="javascript:void(0);" (click)="activeEmployee(cellInfo)"  ><i
                                    class="fa fa-times m-r-5"></i> Cancel Resignation</a>
                            <a *ngIf="cellInfo.row.data.employee_status=='Resigned' && role==='hr-manager'" class="dropdown-item"
                                href="javascript:void(0);" (click)="relieveEmployee(cellInfo)" ><i
                                    class="fa fa-external-link-square m-r-5"></i> Relieve</a>
                            <a *ngIf="cellInfo.row.data.employee_status=='Resigned' && role==='hr-manager'" class="dropdown-item"
                                href="javascript:void(0);" (click)="openResignation(cellInfo)"><i
                                    class="fa fa-edit m-r-5"></i> Edit Resignation</a>
                           
                            <a *ngIf="cellInfo.row.data.employee_status=='Terminated' && role==='hr-manager'" class="dropdown-item"
                                href="javascript:void(0);" (click)="openTermination(cellInfo)" ><i
                                    class="fa fa-edit m-r-5"></i> Edit Termination</a>
                            <a *ngIf="role==='hr-manager' && cellInfo.row.data.employee_status=='Active' || cellInfo.row.data.employee_status=='InActive'"
                                class="dropdown-item" href="javascript:void(0);"
                                (click)="resignEmployeeModal(cellInfo)"><i class="fa fa-window-minimize m-r-5"></i>
                                Add Resignation</a>
                            <a *ngIf="role==='hr-manager' && cellInfo.row.data.employee_status=='Active' || cellInfo.row.data.employee_status=='InActive'"
                                class="dropdown-item" href="javascript:void(0);"
                                (click)="terminateEmployeeModal(cellInfo)"><i class="fa fa-times-circle m-r-5"></i>
                                Add Termination</a>
                            <a *ngIf="role==='hr-manager' && cellInfo.row.data.employee_status=='Active'" class="dropdown-item"
                                href="javascript:void(0);" (click)="inactiveEmployee(cellInfo)"><i
                                    class="fa fa-dot-circle-o m-r-5"></i> Deactivate</a>
                            <a *ngIf="cellInfo.row.data.employee_status=='InActive' && role==='hr-manager'" class="dropdown-item"
                                href="javascript:void(0);" (click)="activeEmployee(cellInfo)"><i
                                    class="fa fa-check-circle m-r-5"></i> Activate</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="OpenLeaves(cellInfo)"><i
                                    class="fa fa-calendar m-r-5"></i> Leaves</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="openDocuments(cellInfo)" *ngIf="role==='hr-manager'"><i
                                    class="fa fa-upload m-r-5"></i> Documents</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="OpenSalaryHike(cellInfo)" *ngIf="role==='hr-manager'"><i
                                    class="fa fa-line-chart m-r-5"></i> Salary Increment</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="openComments(cellInfo)" *ngIf="role==='hr-manager'"><i
                                    class="fa fa-comment m-r-5"></i> Remarks</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="openKra(cellInfo)"><i
                                    class="fa fa-list-ol m-r-5"></i> KRA</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="openProfile(cellInfo)"><i
                                    class="fa fa-user m-r-5"></i> Profile</a>
                            <a class="dropdown-item" href="javascript:void(0);"
                                (click)="openChangePassword(cellInfo)" *ngIf="role==='hr-manager'"><i class="fa fa-key m-r-5"></i> Change
                                Password</a>
                            <a class="dropdown-item" href="javascript:void(0);"
                                (click)="openProfilePicture(cellInfo)" *ngIf="role==='hr-manager'"><i class="fa fa-camera m-r-5"></i> Profile Picture</a>
                            <a class="dropdown-item" href="javascript:void(0);"
                                (click)="openProfilePerformance(cellInfo)"><i class="fa fa-id-card m-r-5"></i> Performance</a>    
                            <a *ngIf="role==='hr-manager' && cellInfo.row.data.employee_status=='Resigned'" class="dropdown-item"
                                href="javascript:void(0);" (click)="openRelievingLetter(cellInfo)"><i
                                    class="fa fa-dot-circle-o m-r-5"></i> Relieving Letter</a>

                    
                        </div>
                    </div>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
<!-- Modal window contents -->
<ng-template #resignmodalcontent let-c="close" let-d="dismiss">
    <form #resignForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Resignation -
                {{employee.first_name}}&nbsp;{{employee.last_name}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="resignationdate">Resignation Date <span class="text-danger">*</span></label>
                    <dx-date-box [(ngModel)]="employeeResignation.release_date" name="resignationdate"
                        id="resignationdate" #resignationdate="ngModel" required type="date" displayFormat="dd/MM/yyyy"
                        dateSerializationFormat="yyyy-MM-dd">
                    </dx-date-box>
                    <div *ngIf="resignationdate.invalid && (resignationdate.dirty || resignationdate.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="resignationdate.errors.required">
                            Resignation Date is required.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="rlastdate">Last Date</label>
                    <dx-date-box [(ngModel)]="employeeResignation.last_date" name="rlastdate" id="rlastdate" type="date"
                        displayFormat="dd/MM/yyyy" dateSerializationFormat="yyyy-MM-dd">
                    </dx-date-box>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="rreason">Reason</label>
                    <textarea rows="4" class="form-control" id="rreason" name="rreason"
                        [(ngModel)]="employeeResignation.reason"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="resignEmployee(resignForm)"
                [disabled]="!resignForm.valid">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>
<ng-template #terminatemodalcontent let-c="close" let-d="dismiss">
    <form #terminateForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Terminate Employee -
                {{employee.first_name}}&nbsp;{{employee.last_name}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="terminationtype">Termination Type <span class="text-danger">*</span></label>
                    <select class="form-control" id="terminationtype" name="terminationtype"
                        [(ngModel)]="employeeTermination.release_sub_type_id" #terminationtype="ngModel"
                        [ngClass]="{'is-invalid': terminationtype.invalid && terminationtype.touched}" required>
                        <option [ngValue]="null" selected>Select Termination Type</option>
                        <option *ngFor="let item of terminationTypeList; let i = index" [ngValue]="item.key">
                            {{item.value}}</option>
                    </select>
                    <div *ngIf="terminationtype.invalid && (terminationtype.dirty || terminationtype.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="terminationtype.errors.required">
                            Termination Type is required.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">

                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="terminationdate">Termination Date <span class="text-danger">*</span></label>
                    <dx-date-box [(ngModel)]="employeeTermination.release_date" name="terminationdate"
                        id="terminationdate" #terminationdate="ngModel" required type="date" displayFormat="dd/MM/yyyy"
                        dateSerializationFormat="yyyy-MM-dd">
                    </dx-date-box>
                    <div *ngIf="terminationdate.invalid && (terminationdate.dirty || terminationdate.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="terminationdate.errors.required">
                            Termination Date is required.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="lastdate">Last Date</label>
                    <dx-date-box [(ngModel)]="employeeTermination.last_date" name="lastdate" id="lastdate" type="date"
                        displayFormat="dd/MM/yyyy" dateSerializationFormat="yyyy-MM-dd">
                    </dx-date-box>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="reason">Reason</label>
                    <textarea rows="4" class="form-control" id="reason" name="reason"
                        [(ngModel)]="employeeTermination.reason"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="terminateEmployee(terminateForm)"
                [disabled]="!terminateForm.valid">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>
<ng-template #empsalmodalcontent let-c="close" let-d="dismiss">
    <form #salaryForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edit Salary -
                {{employee.first_name}}&nbsp;{{employee.last_name}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <app-salary-component #salaryComponent [(employeeSalary)]="employeeSalary"
                [(earningTypeList)]="earningTypeList" [(deductionTypeList)]="deductionTypeList"></app-salary-component>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="saveEmployeeSalary(salaryComponent.isValid())"
                [disabled]="!salaryForm.valid">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>
<ng-template #documentmodalcontent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Documents - {{employee.first_name}}&nbsp;{{employee.last_name}}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-scroll">
        <form #uploadForm="ngForm">
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="documenttype">Document Type <span class="text-danger">*</span></label>
                    <select class="form-control" id="documenttype" name="documenttype" [(ngModel)]="documentTypeId"
                        #documenttype="ngModel" [ngClass]="{'is-invalid': documenttype.invalid && documenttype.touched}"
                        required>
                        <option [ngValue]="undefined" selected>Select Document Type</option>
                        <option *ngFor="let item of documentTypeList; let i = index" [ngValue]="item.key">
                            {{item.value}}</option>
                    </select>
                    <div *ngIf="documenttype.invalid && (documenttype.dirty || documenttype.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="documenttype.errors.required">
                            Document Type is required.
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <label for="upload">Select a File to upload <span class="text-danger">*</span></label>
                    <input type="file" id="empFile" (change)="onFileChange($event)" />
                </div>
                <div class="col-sm-2 pull-right mt-2">
                    <br />
                    <button type="button" class="btn btn-dark btn-sm" (click)="uploadDocument(uploadForm)"
                        [disabled]="!uploadForm.valid || !file">Upload</button>
                </div>
            </div>
            <div class="form-group row mt-5">
                <div class="col-sm-12" id="emp-doc-loading">
                    <dx-data-grid #gridDocuments id="gridDocs" [dataSource]="employeeDocumentList"
                        [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
                        [showBorders]="false" [showColumnLines]="false">
                        <dxo-paging [pageSize]="10"></dxo-paging>
                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                        <dxi-column dataField="document_name" caption="Document Name" dataType="string"
                            [allowGrouping]="false" cellTemplate="documentTemplate"  width="450">
                        </dxi-column>
                        <dxi-column dataField="document_type" caption="Document Type" dataType="string"
                            [allowGrouping]="false">
                        </dxi-column>
                        <dxi-column dataField="document_type" caption="Delete" dataType="string" 
                            [allowGrouping]="false" cellTemplate="deleteTemplate" alignment="center">
                        </dxi-column>
                        <div *dxTemplate="let data of 'documentTemplate'">
                            <a href="javascript:void(0);"
                                (click)="openDocumentWindow(data.data.document_id)">{{data.value}}</a>
                        </div>
                        <div *dxTemplate="let data of 'deleteTemplate'">
                            <a class="dropdown-item" href="javascript:void(0);" (click)="deleteDocument(data.data.document_id)"><i
                                class="fa fa-trash m-r-5"></i></a>                            
                        </div>
                    </dx-data-grid>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer pt-3">
        <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
    </div>
</ng-template>
<ng-template #empworkhistmodalcontent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Work History -
            {{employee.first_name}}&nbsp;{{employee.last_name}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-scroll">
        <div class="form-group row">
            <div class="col-sm-12">
                <dx-data-grid #gridHistory id="gridWorkHist" [dataSource]="employeeWorkHistory"
                    [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
                    [showBorders]="false" [showColumnLines]="false" (onRowInserted)="workHistInserted($event)"
                    (onRowUpdated)="workHistUpdated($event)" (onRowRemoved)="workHistRemoved($event)">
                    <dxo-editing mode="form" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
                    </dxo-editing>
                    <dxo-paging [enabled]="false"></dxo-paging>
                    <dxi-column dataField="company_name" caption="Company Name" dataType="string" [allowEditing]="true">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="designation" caption="Designation" dataType="string" [allowEditing]="true">
                    </dxi-column>
                    <dxi-column dataField="from_date" caption="From Date" dataType="date" [allowEditing]="true"
                        editCellTemplate="dateEditor">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="to_date" caption="To Date" dataType="date" editCellTemplate="dateEditor"
                        [allowEditing]="true">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="last_drawn_salary" caption="Monthly Salary" dataType="number"
                        [allowEditing]="true">
                    </dxi-column>
                    <dxi-column dataField="reason" caption="Reason" dataType="string" [allowEditing]="true">
                    </dxi-column>
                    <div *dxTemplate="let cellInfo of 'dateEditor'">
                        <dx-date-box [(ngModel)]="cellInfo.value" [value]="cellInfo.value" displayFormat="dd/MM/yyyy"
                            dateSerializationFormat="yyyy-MM-dd" type="date"
                            (onValueChanged)="onValueChanged($event, cellInfo)">
                        </dx-date-box>
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </div>
    <div class="modal-footer pt-3">
        <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
    </div>
</ng-template>
<ng-template #emphikemodalcontent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Salary Increment -
            {{employee.first_name}}&nbsp;{{employee.last_name}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-scroll">
        <div class="form-group row">
            <div class="col-sm-12">
                <dx-data-grid #gridHike id="gridsalHike" [dataSource]="employeeHike" [remoteOperations]="false"
                    [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                    [showColumnLines]="false" (onRowInserted)="salIncrementInserted($event)"
                    (onRowUpdated)="salIncrementUpdated($event)" (onRowRemoved)="salIncrementRemoved($event)">
                    <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
                    </dxo-editing>
                    <dxo-paging [enabled]="false"></dxo-paging>
                    <dxi-column dataField="increment_date" caption="Date" dataType="date" editCellTemplate="dateEditor"
                        [allowEditing]="false">
                    </dxi-column>
                    <dxi-column dataField="percentage_hike" caption="Percentage" dataType="number"
                        valueFormat="#,##0.##" [allowEditing]="true">
                    </dxi-column>
                    <div *dxTemplate="let cellInfo of 'dateEditor'">
                        <dx-date-box [(ngModel)]="cellInfo.value" [value]="cellInfo.value" displayFormat="dd/MM/yyyy"
                            dateSerializationFormat="yyyy-MM-dd" type="date"
                            (onValueChanged)="onValueChanged($event, cellInfo)">
                        </dx-date-box>
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </div>
    <div class="modal-footer pt-3">
        <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
    </div>
</ng-template>
<ng-template #empcommentmodalcontent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Remarks - {{employee.first_name}}&nbsp;{{employee.last_name}}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-scroll">
        <div class="form-group row">
            <div class="col-sm-12">
                <dx-data-grid #gridComment id="gridempComments" [dataSource]="employeeComment"
                    [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
                    [showBorders]="false" [showColumnLines]="false" (onRowInserted)="empCommentInserted($event)"
                    (onRowUpdated)="empCommentUpdated($event)" (onRowRemoved)="empCommentRemoved($event)">
                    <dxo-editing mode="form" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
                    </dxo-editing>
                    <dxo-paging [enabled]="false"></dxo-paging>
                    <dxi-column dataField="comment_date" caption="Date" dataType="date" editCellTemplate="dateEditor"
                        [allowEditing]="true">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="employee_comment_type_id" caption="Type" dataType="string"
                        [allowEditing]="true">
                        <dxo-lookup [dataSource]="commentTypeList" displayExpr="value" valueExpr="key">
                        </dxo-lookup>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="employee_comment" caption="Description" dataType="string"
                        [allowEditing]="true">
                        <dxo-form-item [colSpan]="2"
                            [editorOptions]="{height: 90, width: '100%', autoResizeEnabled: true, visible: true, disabled:false }">
                        </dxo-form-item>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    <div *dxTemplate="let cellInfo of 'dateEditor'">
                        <dx-date-box [(ngModel)]="cellInfo.value" [value]="cellInfo.value" displayFormat="dd/MM/yyyy"
                            dateSerializationFormat="yyyy-MM-dd" type="date"
                            (onValueChanged)="onValueChanged($event, cellInfo)">
                        </dx-date-box>
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </div>
    <div class="modal-footer pt-3">
        <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
    </div>
</ng-template>
<ng-template #empkramodalcontent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add KRA - {{employee.first_name}}&nbsp;{{employee.last_name}}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-scroll">
        <div class="form-group row">
            <div class="col-sm-12">
                <dx-data-grid #gridKra id="gridempKra" [dataSource]="employeeKpi" [remoteOperations]="false"
                    [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                    [showColumnLines]="false" (onRowInserted)="empKraInserted($event)"
                    (onRowUpdated)="empKraUpdated($event)" (onRowRemoved)="empKraRemoved($event)">
                    <dxo-editing mode="form" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true" >
                    </dxo-editing>
                    <dxo-paging [enabled]="false"></dxo-paging>
                    <dxi-column dataField="kra_name" caption="Name" dataType="string" [allowEditing]="true">
                        <dxo-form-item [colSpan]="2">
                        </dxo-form-item>
                    </dxi-column>
                    <dxi-column dataField="kpi_description" caption="Description" dataType="string"
                        [allowEditing]="true">
                        <!-- <dxo-form-item [colSpan]="2">
                        </dxo-form-item> -->
                        <dxo-form-item [colSpan]="2" editorType="dxTextArea"
                            [editorOptions]="{ height: 100 }"></dxo-form-item>
                    </dxi-column>
                    <div *dxTemplate="let data of 'dateTemplate'">
                        {{data.value | date: 'dd-MMM-yy'}}
                    </div>
                    <div *dxTemplate="let cellInfo of 'dateEditor'">
                        <dx-date-box [(ngModel)]="cellInfo.value" [value]="cellInfo.value" displayFormat="dd/MM/yyyy"
                            dateSerializationFormat="yyyy-MM-dd" type="date"
                            (onValueChanged)="onValueChanged($event, cellInfo)">
                        </dx-date-box>
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </div>
    <div class="modal-footer pt-3">
        <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
    </div>
</ng-template>
<ng-template #empleavemodalcontent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Leaves - {{employee.first_name}}&nbsp;{{employee.last_name}}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-scroll">
        <div class="form-group row">
            <div class="col-sm-12">
                <dx-data-grid #gridLeave id="gridempLeave" [dataSource]="employeeLeaveList" [remoteOperations]="false"
                    [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                    [showColumnLines]="false" (onRowInserted)="empLeaveInserted($event)"
                    (onRowUpdated)="empLeaveUpdated($event)" (onRowRemoved)="empLeaveRemoved($event)">
                    <dxo-editing mode="form" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true" *ngIf="role==='hr-manager'">
                    </dxo-editing>
                    <dxo-paging [enabled]="false"></dxo-paging>
                    <dxi-column dataField="leave_type_id" caption="Type" dataType="string" [allowEditing]="true">
                        <dxo-lookup [dataSource]="leaveTypeList" displayExpr="value" valueExpr="key">
                        </dxo-lookup>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                        <dxi-validation-rule type="async" message="Leave Type already exist."
                            [validationCallback]="leavetypeValidation"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="no_of_days_total" caption="No Of Days" dataType="number"
                        [allowEditing]="true">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                </dx-data-grid>
            </div>
        </div>
    </div>
    <div class="modal-footer pt-3">
        <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
    </div>
</ng-template>
<ng-template #profilepicturemodalcontent let-c="close" let-d="dismiss">
    <form #profileuploadForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Upload Profile Picture - {{employee.first_name}}&nbsp;{{employee.last_name}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="upload">Select a File to upload <span class="text-danger">*</span></label>
                    <input type="file" id="picFile" class="form-control" (change)="onProfileFileChange($event)" />
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <label>Preview</label>
                    <br>
                    <h2 class="table-avatar">
                        <ngx-avatar size="100" [src]="imageSrc"
                            [name]="employee.first_name + ' ' + employee.last_name">
                        </ngx-avatar>
                    </h2>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="uploadProfileDocument(profileuploadForm)"
                [disabled]="!profileuploadForm.valid || !profilefile">Upload</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>

<ng-template #relievingLetterTemplate let-c="close" let-d="dismiss">
    <div class="content container-fluid">

        <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="page-title">Relieving Letter</h4>
              </div>
            </div>
          </div>
       
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col centered">
            <h3 class="page-title">Relieving Letter</h3>
          </div>
        </div>
      </div>
      <div class="row" #printContent>
        <div class="col-md-12 mt-5">
          <div class="card">
            <div class="card-body ml-2">
                <div class="row">
                    <!-- <div class="col-2">
                      <img src="assets/images/logo/logo_scm.png" alt="" onerror="this.src='logo_scm.png';">
                    </div> -->
                    <div class="col-8 btnleft">
                      <span>
                        <h4 class="text"><b>{{employeereliev.company_name}}</b></h4>
                        <h5>{{employeereliev.address}}</h5>
                        <h5>{{employeereliev.address1}},</h5><h5> {{employeereliev.city}},</h5> <h5>{{employeereliev.state}}</h5><h5>PIN - {{employeereliev.pin}}</h5>
                      </span>
                    </div>
                  </div>
                 <p> [Date]</p>
                 <p>
                    {{employeereliev.first_name}} {{employeereliev.last_name}}</p>
                   <p> {{employeereliev.eaddress}}</p>
                  <p>{{employeereliev.ecity}},{{employeereliev.estate}} {{employeereliev.epincode}}</p>
                    <h3>Subject: Relieving Letter</h3>
                    <p>Dear {{employeereliev.first_name}} {{employeereliev.last_name}}, </p>
                  <p>  We are writing to confirm that {{employeereliev.first_name}} {{employeereliev.last_name}}, employed with <b>{{employeereliev.company_name}}</b> since [Employee's Joining Date], has been relieved from their duties effective <b>{{employeereliev.release_date | date: 'dd/MM/yyyy'}}</b>.
                    During their tenure with us, <b>{{employeereliev.first_name}} {{employeereliev.last_name}}</b> performed their duties diligently and responsibly. We appreciate their contributions to the company and wish them all the best in their future endeavors.
                    Please feel free to contact us at {{employeereliev.phone}} or {{employeereliev.official_email}} if you require any further information.
                    Thank you for your cooperation.</p>

                 <p>   
                    Sincerely,<br>
                   {{employeereliev.user_name}}<br>
                    {{employeereliev.display_name}}<br>
                    [Your Contact Number]
                    [Your Email Address]
                    </p>
              <div>   
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(loading$ | async)">
      <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#relieving-loading' }"
          [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#relieving-loading">
      </dx-load-panel>
    </div>
  </ng-template>



<div *ngIf="(empgridloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-grid' }" [showIndicator]="true"
        [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#emp-grid">
    </dx-load-panel>
</div>
<div *ngIf="(docloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#emp-doc-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#emp-doc-loading">
    </dx-load-panel>
</div>
<app-change-password [user]="user" name="changepass" #changePassword></app-change-password>
