import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subject, Subscription, forkJoin } from "rxjs";
import { Employee } from "src/app/model/admin";
import { CustomField } from "src/app/model/common";
import { AdminApiService } from "src/app/services/admin-api.service";
import { CommonApiService } from "src/app/services/common-api.service";
import { CustomFieldsComponent } from "../../common/custom-fields/custom-fields.component";
import { indicate } from "src/app/services/loading.service";
import { concatMap } from "rxjs/operators";
import { ToastService } from "src/app/services/toast.service";
import { Global } from "src/app/shared/global";

@Component({
  selector: "app-survey-view",
  templateUrl: "./survey-view.component.html",
  styleUrls: ["./survey-view.component.scss"],
})
export class SurveyViewComponent implements OnInit, OnDestroy {
  surveyid: string = "";
  customFieldList: CustomField[];
  intializeData: any = null;
  surveyCycleLoading$ = new Subject<boolean>();
  surveyFieldsLoading$ = new Subject<boolean>();
  employee: Employee;
  showSurvey: boolean = true;
  subscriptions: Subscription[] = [];
  @ViewChild("customField", { read: CustomFieldsComponent })
  customFieldComponent: CustomFieldsComponent;
  constructor(
    private commonApiservice: CommonApiService,
    public adminApiService: AdminApiService,
    private toastService: ToastService,
    public global: Global
  ) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  ngOnInit(): void {
    if (this.intializeData && this.intializeData.surveyid) {
      this.surveyid = this.intializeData.surveyid;
      this.subscriptions.push(
        this.adminApiService
          .getEmployee(this.global.loggedUser.user_type_internal_id)
          .subscribe((apiresponse) => {
            if (apiresponse.data.length !== 0) {
              this.employee = apiresponse.data[0];
              this.initialize();
            }
          })
      );
    }
  }
  initialize() {
    this.subscriptions.push(
      forkJoin({
        companyfield: this.commonApiservice.getCompanyField(243, this.surveyid),
      })
        .pipe(indicate(this.surveyCycleLoading$))
        .pipe(
          concatMap((apiresponse: any) => {
            this.customFieldList = apiresponse.companyfield.data;
            return this.commonApiservice.getEmployeeCustomFields(
              this.employee.employee_id,
              243
            );
          })
        )
        .subscribe((apiresponse) => {
          if (apiresponse.data.length !== 0) {
            this.showSurvey = false;
          }
        })
    );
  }
  refresh() {
    this.ngOnInit();
  }
  saveSurvey() {
    this.customFieldList = this.customFieldComponent.getCustomFieldList();
    this.customFieldList.forEach((f) => {
      f.employee_id = this.employee.employee_id;
      f.survey_id = 1;
    });
    this.subscriptions.push(
      forkJoin({
        companyfield: this.adminApiService.postEmployeeCustomFields({
          custom_fields: this.customFieldList,
        }),
        survey: this.commonApiservice.getCompanyField(243),
      })
        .pipe(indicate(this.surveyFieldsLoading$))
        .pipe(
          concatMap(() => {
            this.toastService.success("survey fields updated successfully");
            return forkJoin({
              customFields: this.commonApiservice.getEmployeeCustomFields(
                this.employee.employee_id,
                243
              ),
            });
          })
        )
        .subscribe((apiresponse) => {
          if (apiresponse.customFields.data.length !== 0) {
            this.showSurvey = false;
          }
        })
    );
  }
}
