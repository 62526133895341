<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Daily Attendance Report</h3>
            </div>
            <div class="col-auto float-right ml-auto">
            </div>
        </div>
    </div>
    <div class="row filter-row">
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <dx-date-box [(value)]="reportDate" type="date" class="form-control filter-control"
                placeholder="Report Date" (onValueChanged)="reportdateChanged($event)" [max]="today_date">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="status" name="status"
                [ngClass]="{'loading-control': (ddlocationloading$ | async) === true }" id="location" name="location"
                [(ngModel)]="locationId">
                <option [ngValue]="undefined" selected>All Location</option>
                <option *ngFor="let item of locationList; let i = index" [ngValue]="item.location_id">
                    {{item.location_name}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="shift" name="shift"
                [ngClass]="{'loading-control': (ddshiftloading$ | async) === true }" id="shift" name="shift"
                [(ngModel)]="shiftId">
                <option [ngValue]="undefined" selected>All Shifts</option>
                <option *ngFor="let item of shiftList; let i = index" [ngValue]="item.shift_id">
                    {{item.shift_name}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="department" name="department"
                [ngClass]="{'loading-control': (ddloading$ | async) === true }" id="department" name="department"
                [(ngModel)]="departmentId">
                <option [ngValue]="undefined" selected>All Department</option>
                <option *ngFor="let item of departmentList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <select class="form-control filter-control" id="category" name="category"
                [ngClass]="{'loading-control': (ddloading$ | async) === true }"
                [(ngModel)]="categoryId">
                <option [ngValue]="undefined" selected>All Category</option>
                <option *ngFor="let item of categoryList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-1 col-12">
            <button class="btn btn-secondary btn-block" (click)="loadReport()"> 
                <i class="fa fa-search fa-lg"></i>
            </button>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-1 col-12">
            <button class="btn btn-secondary btn-block" (click)="employeeAttendanceExcelSheet()"> <i
                    class="fa fa-download"></i></button>
        </div>
    </div>
    <div class="card tab-box mt-3">
        <div class="row user-tabs">
            <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul ngbNav #navTab="ngbNav" (activeId)="1" class="nav nav-tabs nav-tabs-bottom" [destroyOnHide]="false">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink class="nav-link hidePrint">
                            Dashboard
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mr-1 ml-1">
                                <div class="col-md-3">
                                    <div class="stats-info bg-card bg-holder">
                                        <h6>No Of Approved Leaves</h6>
                                        <h4>{{dashboardReportData.number_of_leaves}}</h4>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="stats-info bg-card bg-holder">
                                        <h6>No Of Approved Permissions</h6>
                                        <h4>{{dashboardReportData.number_of_permissions}}</h4>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="stats-info bg-card bg-holder">
                                        <h6>No Of Late Punches</h6>
                                        <h4>{{dashboardReportData.number_of_lates}}</h4>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="stats-info bg-card bg-holder">
                                        <h6>Total Present</h6>
                                        <h4>{{dashboardReportData.number_of_present}}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row p-3" id="emp_punch">
                                <div class="col-md-12 table-responsive" id="dashboard-report-grid">
                                    <dx-data-grid #gridAttendance id="gridContainer" [dataSource]="dailyPunchData"
                                        [remoteOperations]="false" [allowColumnReordering]="true"
                                        [rowAlternationEnabled]="true" [showBorders]="false" [showColumnLines]="false">
                                        <dxo-export [enabled]="true" fileName="attendancePunchReport"></dxo-export>
                                        <dxo-paging [enabled]="false"></dxo-paging>
                                        <dxi-column dataField="full_name" caption="EMPLOYEE"
                                            cellTemplate="imageTemplate" width="350">
                                        </dxi-column>
                                        <dxi-column dataField="attendance_date" [allowFiltering]="false"
                                            [allowSorting]="false" [allowGrouping]="false" cellTemplate="dateTemplate"
                                            caption="DATE" cssClass="cls">
                                        </dxi-column>
                                        <dxi-column dataField="shift" caption="Shift">
                                        </dxi-column>
                                        <dxi-column dataField="in_punch_time" caption="FIRST IN"
                                            cellTemplate="timeTemplate"> >
                                        </dxi-column>
                                        <dxi-column dataField="out_punch_time" caption="LAST OUT"
                                            cellTemplate="timeTemplate">
                                        </dxi-column>
                                        <dxi-column dataField="attendance_type" caption="TYPE" dataType="string">
                                        </dxi-column>
                                        <dxi-column dataField="total_hours" caption="TOTAL HOURS" dataType="number">
                                        </dxi-column>
                                        <div *dxTemplate="let data of 'dateTemplate'">
                                            {{data.value | date}}
                                        </div>
                                        <div *dxTemplate="let data of 'timeTemplate'">
                                            {{data.value | date:'shortTime'}}
                                        </div>
                                        <div *dxTemplate="let data of 'imageTemplate'">
                                            <h2 class="table-avatar">
                                                <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                                                    [name]="data.row.data.full_name">
                                                </ngx-avatar>
                                                <a class="ml-1 mt-1">
                                                    {{data.row.data.full_name}}<span>{{data.row.data.designation}}
                                                    </span></a>
                                            </h2>
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>

                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink class="nav-link hidePrint">
                            Leaves
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mr-1 ml-1">
                                <div class="col-md-12 mt-1 table-responsive" id="leave-report-grid">
                                    <dx-data-grid #gridLeave id="gridContainer" [dataSource]="leaveData"
                                        [remoteOperations]="false" [allowColumnReordering]="true"
                                        [rowAlternationEnabled]="true" [showBorders]="false" [showColumnLines]="false">
                                        <dxo-export [enabled]="true" fileName="leaveReport"></dxo-export>
                                        <dxo-paging [pageSize]="10"></dxo-paging>
                                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]">
                                        </dxo-pager>
                                        <dxi-column dataField="employee_name" [allowFiltering]="false"
                                            [allowSorting]="false" cellTemplate="imageTemplate" caption="Employee"
                                            width="350"></dxi-column>
                                        <dxi-column dataField="leave_type" caption="Leave Type" dataType="string">
                                        </dxi-column>
                                        <dxi-column dataField="no_of_days" caption="Full/Half" dataType="string"
                                            cellTemplate="noofdaysTemplate"></dxi-column>
                                        <dxi-column dataField="leave_reason" caption="Reason" dataType="string"
                                            [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="leave_status" caption="Satus" [allowFiltering]="false"
                                            [allowSorting]="false" cellTemplate="statusTemplate" alignment="center"
                                            width="200" [allowFiltering]="false">
                                        </dxi-column>
                                        <dxo-summary>
                                            <dxi-total-item column="employee_name" summaryType="count"
                                                title="Employee Count" displayFormat="Employee Count: {0}">
                                            </dxi-total-item>
                                        </dxo-summary>
                                        <div *dxTemplate="let data of 'noofdaysTemplate'">
                                            {{data.value === 0.5 ? 'Half' : 'Full'}}
                                        </div>
                                        <div *dxTemplate="let data of 'imageTemplate'">
                                            <h2 class="table-avatar">
                                                <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                                                    [name]="data.row.data.employee_name">
                                                </ngx-avatar>
                                                <a class="ml-1 mt-1"> {{data.row.data.employee_name}} <span
                                                        *ngIf="data.row.data.designation!=undefined">{{data.row.data.designation}}
                                                    </span></a>
                                            </h2>
                                        </div>
                                        <div *dxTemplate="let cellInfo of 'statusTemplate'">
                                            <div ngbDropdown container="body">
                                                <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                    *ngIf="cellInfo.value=='Approved'">
                                                    <i class="fa fa-dot-circle-o text-success"></i> Approved
                                                </a>
                                                <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                    *ngIf="cellInfo.value=='Manager Approved'">
                                                    <i class="fa fa-dot-circle-o text-warning"></i> M Approved
                                                </a>
                                                <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                    *ngIf="cellInfo.value=='Pending'">
                                                    <i class="fa fa-dot-circle-o text-purple"></i> Pending
                                                </a>
                                                <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                    *ngIf="cellInfo.value=='Declined'">
                                                    <i class="fa fa-dot-circle-o text-danger"></i> Declined
                                                </a>
                                                <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                    *ngIf="cellInfo.value=='Cancelled'">
                                                    <i class="fa fa-dot-circle-o text-grey"></i> Cancelled
                                                </a>
                                            </div>
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                        </ng-template>
                    </li>

                    <li [ngbNavItem]="3">
                        <a ngbNavLink class="nav-link hidePrint">
                            Permissions
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mr-1 ml-1">
                                <div class="col-md-12 mt-1 table-responsive" id="permission-report-grid">
                                    <dx-data-grid #gridpermission id="gridContainer" [dataSource]="permissionList"
                                        [remoteOperations]="false" [allowColumnReordering]="true"
                                        [rowAlternationEnabled]="true" [showBorders]="false" [showColumnLines]="false">
                                        <dxo-export [enabled]="true" fileName="permissionReport"></dxo-export>
                                        <dxo-paging [pageSize]="10"></dxo-paging>
                                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]">
                                        </dxo-pager>
                                        <dxi-column dataField="employee_name" [allowSorting]="false"
                                            cellTemplate="imageTemplate" caption="Employee" width="350">
                                        </dxi-column>
                                        <dxi-column dataField="permission_reason" caption="Reason" dataType="string"
                                            [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="permission_from_time" caption="From"
                                            cellTemplate="timeTemplate" [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="permission_to_time" caption="To"
                                            cellTemplate="timeTemplate" [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="permission_duration" caption="Duration">
                                        </dxi-column>
                                        <dxi-column dataField="in_punch_time" caption="In Time"
                                            cellTemplate="timeTemplate" [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="permission_status" caption="Status"
                                            [allowSorting]="false" cellTemplate="statusTemplate" alignment="center"
                                            width="200" [allowFiltering]="false">
                                        </dxi-column>

                                        <dxo-summary>
                                            <dxi-total-item column="employee_name" summaryType="count"
                                                title="Employee Count" displayFormat="Employee Count: {0}">
                                            </dxi-total-item>
                                        </dxo-summary>
                                        <div *dxTemplate="let data of 'timeTemplate'">
                                            {{data.value | date:"shortTime" }}
                                        </div>
                                        <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Approved'">
                                                <i class="fa fa-dot-circle-o text-success"></i> Approved
                                            </a>
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Pending'">
                                                <i class="fa fa-dot-circle-o text-purple"></i> Pending
                                            </a>
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Declined'">
                                                <i class="fa fa-dot-circle-o text-danger"></i> Declined
                                            </a>
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Cancelled'">
                                                <i class="fa fa-dot-circle-o text-grey"></i> Cancelled
                                            </a>
                                        </div>
                                        <div *dxTemplate="let data of 'imageTemplate'">
                                            <h2 class="table-avatar">
                                                <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                                                    [name]="data.row.data.employee_name">
                                                </ngx-avatar>
                                                <a class="ml-1 mt-1">
                                                    {{data.row.data.employee_name}}<span>{{data.row.data.designation}}
                                                    </span></a>
                                            </h2>
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink class="nav-link hidePrint">
                            Late Punch
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mr-1 ml-1">
                                <div class="col-md-12 mt-1 table-responsive" id="punch-report-grid">
                                    <dx-data-grid #gridpunchin id="gridContainer" [dataSource]="punchReportData"
                                        [remoteOperations]="false" [allowColumnReordering]="true"
                                        [rowAlternationEnabled]="true" [showBorders]="false" [showColumnLines]="false">
                                        <dxo-export [enabled]="true" fileName="punchInReport"></dxo-export>
                                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                                        <dxo-group-panel [visible]="true"></dxo-group-panel>
                                        <dxo-paging [pageSize]="10"></dxo-paging>
                                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]">
                                        </dxo-pager>
                                        <dxi-column dataField="employee_name" [allowSorting]="false"
                                            cellTemplate="imageTemplate" caption="Employee" width="350"
                                            [allowGrouping]="false" [allowFiltering]="true">
                                        </dxi-column>
                                        <dxi-column dataField="shift_name" caption="Shift" dataType="string"
                                            [allowFiltering]="true">
                                        </dxi-column>
                                        <dxi-column dataField="punch_time" caption="In Time" cellTemplate="timeTemplate"
                                            [allowGrouping]="false" [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="late_duration" caption="Late By"
                                            cellTemplate="durationTemplate" [allowFiltering]="false">
                                        </dxi-column>
                                        <dxi-column dataField="punch_status" caption="Status"
                                            cellTemplate="statusTemplate" alignment="center" width="200"
                                            [allowFiltering]="false">
                                        </dxi-column>
                                        <dxo-summary>
                                            <dxi-total-item column="employee_name" summaryType="count"
                                                title="Employee Count" displayFormat="Employee Count: {0}">
                                            </dxi-total-item>
                                        </dxo-summary>
                                        <div *dxTemplate="let data of 'timeTemplate'">
                                            {{data.value | date:"shortTime" }}
                                        </div>
                                        <div *dxTemplate="let data of 'durationTemplate'">
                                            {{ data.value | minutestToHour }} <span *ngIf="data.value"></span>
                                        </div>
                                        <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Not Available'">
                                                <i class="fa fa-dot-circle-o text-warning"></i> Not Available
                                            </a>
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Late'">
                                                <i class="fa fa-dot-circle-o text-danger"></i> Late
                                            </a>
                                        </div>
                                        <div *dxTemplate="let data of 'imageTemplate'">
                                            <h2 class="table-avatar">
                                                <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                                                    [name]="data.row.data.employee_name">
                                                </ngx-avatar>
                                                <a class="ml-1 mt-1">
                                                    {{data.row.data.employee_name}}<span>{{data.row.data.designation}}
                                                    </span></a>
                                            </h2>
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5">
                        <a ngbNavLink class="nav-link hidePrint">
                            Manual Attendance
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mr-1 ml-1">
                                <div class="col-md-12 mt-1 table-responsive" id="activity-report-grid">
                                    <dx-data-grid #gridactivity id="gridContainer" [dataSource]="activityReportData"
                                        [remoteOperations]="false" [allowColumnReordering]="true"
                                        [rowAlternationEnabled]="true" [showBorders]="false" [showColumnLines]="false">
                                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                                        <dxo-group-panel [visible]="true"></dxo-group-panel>
                                        <dxo-paging [pageSize]="10"></dxo-paging>
                                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]">
                                        </dxo-pager>
                                        <dxi-column dataField="employee_name" [allowSorting]="false"
                                            cellTemplate="imageTemplate" caption="Employee" width="500"
                                            [allowGrouping]="false" [allowFiltering]="true">
                                        </dxi-column>
                                        <dxi-column dataField="activity" caption="Activity"
                                            cellTemplate="activityTemplate" [allowGrouping]="false"
                                            [allowFiltering]="false">
                                        </dxi-column>
                                        <dxo-summary>
                                            <dxi-total-item column="employee_name" summaryType="count"
                                                title="Employee Count" displayFormat="Employee Count: {0}">
                                            </dxi-total-item>
                                        </dxo-summary>
                                        <div *dxTemplate="let data of 'activityTemplate'">
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr *ngFor="let p of data.row.data.activity">
                                                        <td>{{p.punch_direction}}</td>
                                                        <td>{{ p.time | date : "hh:mm a" }}</td>
                                                        <td>{{p.punch_description}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div *dxTemplate="let data of 'imageTemplate'">
                                            <h2 class="table-avatar">
                                                <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                                                    [name]="data.row.data.employee_name">
                                                </ngx-avatar>
                                                <a class="ml-1 mt-1">
                                                    {{data.row.data.employee_name}}<span>{{data.row.data.designation}}
                                                    </span></a>
                                            </h2>
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="6">
                        <a ngbNavLink class="nav-link hidePrint">
                            Missed Punch
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row mr-1 ml-1">
                                <div class="col-md-12 mt-1 table-responsive" id="missed-punch-report-grid">
                                    <dx-data-grid #gridpunchin id="gridContainer" [dataSource]="missedPunchReportData"
                                        [remoteOperations]="false" [allowColumnReordering]="true"
                                        [rowAlternationEnabled]="true" [showBorders]="false" [showColumnLines]="false">
                                        <dxo-export [enabled]="true" fileName="missedPunchInReport"></dxo-export>
                                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                                        <dxo-group-panel [visible]="true"></dxo-group-panel>
                                        <dxo-paging [pageSize]="10"></dxo-paging>
                                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]">
                                        </dxo-pager>
                                        <dxi-column dataField="employee_name" [allowSorting]="false"
                                            cellTemplate="imageTemplate" caption="Employee" width="350"
                                            [allowGrouping]="false" [allowFiltering]="true">
                                        </dxi-column>
                                        <dxi-column dataField="shift_name" caption="Shift" dataType="string"
                                            [allowFiltering]="true">
                                        </dxi-column>
                                        <dxi-column dataField="punch_status" caption="Status"
                                            cellTemplate="statusTemplate" alignment="center" width="200"
                                            [allowFiltering]="false">
                                        </dxi-column>
                                        <dxo-load-panel [enabled]="true">
                                        </dxo-load-panel>
                                        <dxo-summary>
                                            <dxi-total-item column="employee_name" summaryType="count"
                                                title="Employee Count" displayFormat="Employee Count: {0}">
                                            </dxi-total-item>
                                        </dxo-summary>
                                        <div *dxTemplate="let data of 'timeTemplate'">
                                            {{data.value | date:"shortTime" }}
                                        </div>
                                        <div *dxTemplate="let data of 'durationTemplate'">
                                            {{ data.value | minutestToHour }} <span *ngIf="data.value"></span>
                                        </div>
                                        <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Not Available'">
                                                <i class="fa fa-dot-circle-o text-warning"></i> Not Available
                                            </a>
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Late'">
                                                <i class="fa fa-dot-circle-o text-danger"></i> Late
                                            </a>
                                        </div>
                                        <div *dxTemplate="let data of 'imageTemplate'">
                                            <h2 class="table-avatar">
                                                <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                                                    [name]="data.row.data.employee_name">
                                                </ngx-avatar>
                                                <a class="ml-1 mt-1">
                                                    {{data.row.data.employee_name}}<span>{{data.row.data.designation}}
                                                    </span></a>
                                            </h2>
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div [ngbNavOutlet]="navTab" id="tabcontentPanel" class="tab-content"></div>
</div>
<div *ngIf="(leaveloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#leave-report-grid' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#leave-report-grid">
    </dx-load-panel>
</div>
<div *ngIf="(permissionloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#permission-report-grid' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#permission-report-grid">
    </dx-load-panel>
</div>
<div *ngIf="(puchReportloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#punch-report-grid' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#punch-report-grid">
    </dx-load-panel>
</div>
<div *ngIf="(activityReportloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#activity-report-grid' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#activity-report-grid">
    </dx-load-panel>
</div>
<div *ngIf="(activityDashboardloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#dashboard-report-grid' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
        container="#dashboard-report-grid">
    </dx-load-panel>
</div>