import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Asset, AssetService } from 'src/app/model/admin';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { indicate } from 'src/app/services/loading.service';
import { ToastService } from 'src/app/services/toast.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-asset-view',
  templateUrl: './asset-view.component.html',
  styleUrls: ['./asset-view.component.scss']
})
export class AssetViewComponent implements OnInit {
  asset: Asset = new Asset();
  intializeData: any = null;
  typeList: KeyValue<number, string>[];
  loading$ = new Subject<boolean>();
  // tslint:disable-next-line:max-line-length
  constructor(public global: Global, private commonApiService: CommonApiService, private adminApiService: AdminApiService, private toastService: ToastService) { }
  ngOnInit() {
    this.commonApiService.getMasterData('asset_service_type')
      .subscribe(apiresponse => {
        this.typeList = apiresponse.data;
      });
    if (this.intializeData && this.intializeData.id) {
      const assetId = this.intializeData.id;
      this.adminApiService.getAsset(assetId)
        .pipe(indicate(this.loading$))
        .subscribe(apiresponse => {
          this.asset = apiresponse.data[0];
        });
    }
  }
  refresh() {
    this.ngOnInit();
  }
  serviceInserted(e) {
    const service: AssetService = e.data;
    service.asset_id = this.asset.asset_id;
    this.adminApiService.postAssetService(service)
      .subscribe(apiresponse => {
        e.data.asset_service_id = apiresponse.data[0].asset_service_id;
        this.toastService.success('Asset Service saved successfully');
      });
  }
  serviceUpdated(e) {
    const service: AssetService = e.data;
    service.asset_id = this.asset.asset_id;
    this.adminApiService.postAssetService(service)
      .subscribe(apiresponse => {
        this.toastService.success('Asset Service updated successfully');
      });
  }
  serviceRemoved(e) {
    this.adminApiService.deleteAssetService(e.data.asset_service_id)
      .subscribe(apiresponse => {
        this.toastService.success('Asset Service removed successfully');
      });
  }
}
