import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Global } from 'src/app/shared/global';
import { Employee, EmployeeComment, EmployeeDocument, EmployeeKpi, EmployeeRelease, EmployeeSalaryIncrement, EmployeeWorkHistory, Recurver, RecurverRelease } from 'src/app/model/admin';
import { Tab } from 'src/app/model/tab';
import { TabService } from 'src/app/services/tab.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserProfileComponent } from '../../common/user-profile/user-profile.component';
import { EmployeeSalary, SalaryComponent } from 'src/app/model/payroll';
import { PayrollApiService } from 'src/app/services/payroll-api.service';
import { RelievingLetterComponent } from '../relieving-letter/relieving-letter.component';
import { SalarySheetComponent } from '../../payroll/salary-sheet/salary-sheet.component';
import { EditEmployeeComponent } from '../edit-employee/edit-employee.component';
import { confirm } from 'devextreme/ui/dialog';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { EmployeeLeaveBalance } from 'src/app/model/attendance';
import { indicate } from 'src/app/services/loading.service';
import { Subject, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ChangePasswordComponent } from '../../common/change-password/change-password.component';
import { User } from 'src/app/model/common';
import { ProfilePictureUploadComponent } from '../../common/profile-picture-upload/profile-picture-upload.component';
import { PerformanceViewComponent } from '../performance-view/performance-view.component';
import { EditRecurverComponent } from '../edit-recurver/edit-recurver.component';
import { OfferLetterComponent } from '../offer-letter/offer-letter.component';
//import { OfferLetterComponent } from '../offer-letter/offer-letter.component';

@Component({
  selector: 'app-recurver-list',
  templateUrl: './recurver-list.component.html',
  styleUrls: ['./recurver-list.component.scss']
})
export class RecurverListComponent implements OnInit, OnDestroy {
  offerLetterData: any;
inter: any;
  employee: any;
  offerLetter: any;
 

addRecurver() {
  const emp = new Recurver();
  emp.is_new = true;
  this.tabService.addTab(new Tab(EditRecurverComponent, 'New', { recurver: emp }));
}

// refresh() {
// throw new Error('Method not implemented.');
// }
  role = '';
  intializeData: any;
   //employeeResignation: EmployeeRelease = new EmployeeRelease();
  recurverTermination: RecurverRelease = new RecurverRelease();
  // employee: Employee = new Employee();
  recurver: Recurver = new Recurver();
  employeeSalary: EmployeeSalary = new EmployeeSalary();
  // employeeList: Employee[] = [];
  recurverList: Recurver[] = [];
  statusList: KeyValue<number, string>[];
  terminationTypeList: KeyValue<number, string>[];
  releaseTypeList: KeyValue<number, string>[];
  earningTypeList: KeyValue<number, string>[];
  deductionTypeList: KeyValue<number, string>[];
  documentTypeList: KeyValue<number, string>[];
  designationList: KeyValue<number, string>[];
  leaveTypeList: KeyValue<number, string>[];
  file: File = null;
  locationId: number;
  designationId: number;
  departmentId: number;
  statusId: number;
  recurver_id: number;
  company_id:number;
  isNew = false;
  employeeId:number
  documentTypeId: number;
  employeeDocumentList: EmployeeDocument[] = [];
  employeeWorkHistory: EmployeeWorkHistory[] = [];
  commentTypeList: KeyValue<number, string>[];
  today: Date = new Date();
  empCodeList: string[] = [];

  recurverNames: KeyValue<number, {first_name: string, last_name: string}>[] = [];
  recurverNameList: KeyValue<number, string>[];
  departmentList: KeyValue<number, string>[];
  locationList: KeyValue<number, string>[];
  ddloading$ = new Subject<boolean>();
  empcodeloading$ = new Subject<boolean>();
  empgridloading$ = new Subject<boolean>();
  locationloading$ = new Subject<boolean>();
  docloading$ = new Subject<boolean>();
  subscriptions: Subscription[] = [];
  profilefile: File = null;
  dateSearchType: string;
  fromDate: Date;
  toDate: Date;
  first_name: string;
  last_name: string;
  gender: string;
  qualification: string;
  employee_name:string;
  recurver_name:string;
  @ViewChild('terminatemodalcontent', { static: true }) terminatemodalcontent: ElementRef;
  @ViewChild('scheduledmodalcontent', { static: true }) scheduledmodalcontent: ElementRef;
  @ViewChild('OfferLetterComponent', { static: true }) OfferLetterComponent: ElementRef;
  @ViewChild('resignmodalcontent', { static: true }) resignmodalcontent: ElementRef;
  @ViewChild('empsalmodalcontent', { static: true }) empsalmodalcontent: ElementRef;
  @ViewChild('documentmodalcontent', { static: true }) documentmodalcontent: ElementRef;
  @ViewChild('empworkhistmodalcontent', { static: true }) empworkhistmodalcontent: ElementRef;
  @ViewChild('emphikemodalcontent', { static: true }) emphikemodalcontent: ElementRef;
  @ViewChild('empcommentmodalcontent', { static: true }) empcommentmodalcontent: ElementRef;
  @ViewChild('empkramodalcontent', { static: true }) empkramodalcontent: ElementRef;
  @ViewChild('empleavemodalcontent', { static: true }) empleavemodalcontent: ElementRef;
  @ViewChild('profilepicturemodalcontent', { static: true }) profilepicturemodalcontent: ElementRef;
  @ViewChild('changePassword', { read: ChangePasswordComponent }) changePassword: ChangePasswordComponent;

  user: User;

  constructor(
    public attendanceApiService: AttendanceApiService,
    private tabService: TabService,
    private modalService: NgbModal,
    public global: Global,
    public payrollApiService: PayrollApiService,
    public commonApiService: CommonApiService,
    public adminApiService: AdminApiService,
    private toastService: ToastService
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.initialize();
    this.statusId =10049;
    this.subscriptions.push(
      this.commonApiService.getMasterData('department,designation,kra,employee_remark_type,recurver_status,termination_type,release_type,document_type,payroll_earnings,payroll_deduction,leave_type')
        .pipe(indicate(this.ddloading$))
        .subscribe(apiresponse => {
           this.statusList = apiresponse.data.filter((d: { type: string; }) => d.type === 'recurver_status');
           this.terminationTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'termination_type');
           this.releaseTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'release_type');
           this.designationList = apiresponse.data.filter((d: { type: string; }) => d.type === 'designation');
           this.earningTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'payroll_earnings');
          this.deductionTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'payroll_deduction');
           this.documentTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'document_type');
           this.commentTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'employee_remark_type');
           this.departmentList = apiresponse.data.filter((d: { type: string; }) => d.type === 'department');
           this.leaveTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'leave_type');
          // this.recurver_name = this.recurver.first_name + ' ' + this.recurver.last_name;
        })
    );
    this.subscriptions.push(
      this.commonApiService.getUtilityData('employee_code')
        .subscribe(apiresponse => {
          if (apiresponse.data) {
            this.empCodeList = apiresponse.data.map((a: { value: string; }) => a.value);
          }
        })
    );
    
    this.subscriptions.push(
      this.commonApiService.getUtilityData('recurver_name', this.global.isAltLogin)
        .pipe(indicate(this.empcodeloading$))
        .subscribe(apiresponse => {
          this.recurverNames = apiresponse.data;
          this.recurverNameList = this.recurverNames.reduce((a, o) => (a.push(o.value), a), []);
        })
    );

    this.subscriptions.push(
      this.commonApiService.getUtilityData('location')
        .pipe(indicate(this.locationloading$))
        .subscribe(apiresponse => {
          this.locationList = apiresponse.data;
        })
    );
  }

  initialize() {
    if (this.intializeData && this.intializeData.role) {
      this.role = this.intializeData.role;
    }
    if (this.role === 'manager') {
      let recurver_id;
      recurver_id = this.global.loggedUser.user_type_internal_id;
      this.adminApiService.getRecurverList(this.recurver_id, this.designationId,this.company_id,
        this.statusId,this.first_name,this.last_name,this.recurver_name,this.qualification,this.gender,this.locationId,this.dateSearchType,this.fromDate,
        this.toDate).subscribe((response) => {
          this.recurverList = response.data;
        })
    }
  }
  
  searchRecurver() {
    this.subscriptions.push(
      this.adminApiService.getRecurverList
        (
        this.recurver_id,
        this.designationId,
        this.company_id,
        this.statusId,
        this.first_name,
        this.last_name,
        this.recurver_name,
        this.qualification,
        this.gender,
        this.locationId,
        this.dateSearchType,  // Make sure this is defined in your component
        this.fromDate,        // Ensure these are initialized if used
        this.toDate
        )
        .pipe(indicate(this.empgridloading$))
        .subscribe(apiresponse => {
          console.log('Recurver List:', apiresponse.data);
          this.recurverList = apiresponse.data;
        }));
  }
 refresh() {
    this.ngOnInit();
    this.searchRecurver();
  }
  
  // openModal(modalContent, recurver: Recurver) {
  //   this.recurver = { ...recurver };
  //   this.modalService.open(modalContent, { size: 'lg' });
  // }

  selectName(e) {
    if (e.selectedItem === null) {
      this.recurver_id = null;
    } else {
      const emp: KeyValue<number, {first_name: string, last_name: string}> = this.recurverNames.find(d => d.value === e.selectedItem);
      if (emp) {
        this.recurver_id = emp.key;
      }
    }
  }
  find(arg0: (d: any) => boolean): KeyValue<number, string> {
    throw new Error('Method not implemented.');
  }

  recurverExcelSheet() {
    const win = window.open(this.commonApiService.geDocumentUrl(), '_blank');
    win.focus();
  }

  inactiveRecurver(e: any) {
    confirm('Are you sure, you want to change the employee status to inactive ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.recurver = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'Offer Rejected').key;
        this.subscriptions.push(
          this.adminApiService
            .changeStatusRecurver(satusId, this.recurver.recurver_id)
            .subscribe(apiresponse => {
              this.toastService.success('Employee status successfully changed to Inactive.');
              this.searchRecurver();
            }));
      }
    });
  }
  startRecurver(e: any) {
    confirm('Are you sure, you want to change the employee status to releived ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.recurver = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'Yet to Start').key;
        this.subscriptions.push(
          this.adminApiService
            .changeStatusRecurver(satusId, this.recurver.recurver_id)
            .subscribe(apiresponse => {
              this.toastService.success('Successfully changed to Yet to start.');
              this.searchRecurver();
            }));
      }
    });
  }

  scheduledRecurver(e: any) {
    confirm('Are you sure, you want to change the employee status to releived ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.recurver = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'Scheduled').key;
        this.subscriptions.push(
          this.adminApiService
            .changeStatusRecurver(satusId, this.recurver.recurver_id)
            .subscribe(apiresponse => {
              //this.toastService.success('Successfully Scheduled.');
              this.modalService.open(this.scheduledmodalcontent, { size: 'lg' });
              this.searchRecurver();
            }));
      }
    });
  }

  selected(e: any) {
    // Log the event data to confirm it is being passed correctly
    console.log('Selected Clicked:', e);
  
    confirm('Are you sure, you want to change the employee status to Selected?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        // Log the row data being used to update the status
        this.recurver = e.row.data;
        console.log('Recurver Data:', this.recurver);
  
        // Find the status ID for 'Selected'
        const statusId: number = this.statusList.find(d => d.value === 'Selected')?.key;
        console.log('Status ID for Selected:', statusId);
  
        // Ensure the status ID is valid before making the API call
        if (statusId) {
          this.subscriptions.push(
            this.adminApiService
              .changeStatusRecurver(statusId, this.recurver.recurver_id)
              .subscribe(
                apiresponse => {
                  // Log the API response
                  console.log('API Response:', apiresponse);
                  this.toastService.success('Successfully Selected.');
                  this.searchRecurver(); // Refresh the list to reflect changes
                },
                error => {
                  // Log errors if the API call fails
                  console.error('Status Change Error:', error);
                  this.toastService.error('Failed to Select.');
                }
              )
          );
        } else {
          console.error('Invalid Status ID for Selected');
        }
      }
    });
  }

  rejected(e: any) {
    console.log('Cell Info:', e);
    confirm('Are you sure, you want to change the recurver status to Rejected?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.recurver = e.row.data;
        console.log('Recurver Data:', this.recurver);
        
        const statusId: number = this.statusList.find(d => d.value === 'Rejected')?.key;
        console.log('Status ID:', statusId);
        
        if (statusId) {
          this.subscriptions.push(
            this.adminApiService
              .changeStatusRecurver(statusId, this.recurver.recurver_id)
              .subscribe(
                apiresponse => {
                  console.log('API Response:', apiresponse);
                  this.toastService.success('Successfully Rejected.');
                  this.searchRecurver();
                },
                error => {
                  console.error('API Error:', error);
                  this.toastService.error('Failed to Reject.');
                }
              )
          );
        } else {
          console.error('Invalid Status ID');
        }
      }
    });
  }


  hold(e: any) {
    confirm('Are you sure, you want to change the employee status to releived ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.recurver = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'On Hold').key;
        this.subscriptions.push(
          this.adminApiService
            .changeStatusRecurver(satusId, this.recurver.recurver_id)
            .subscribe(apiresponse => {
              this.toastService.success('Successfully Rejected.');
              this.searchRecurver();
            }));
      }
    });
  }

  released(e: any) {
    confirm('Are you sure, you want to give a offer letter?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.recurver = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'Offer Released').key;
         this.subscriptions.push(
          this.adminApiService
             .changeStatusRecurver(satusId, this.recurver.recurver_id)
             .subscribe(apiresponse => {
              this.modalService.open(this.terminatemodalcontent, { size: 'lg' });
              // this.toastService.success('Successfully Offer Released.');
              this.searchRecurver();
             })
            );
      }
    });
  }

  offerRejected(e: any) {
    confirm('Are you sure, you want to change the employee status to releived ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.recurver = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'Offer Rejected').key;
        this.subscriptions.push(
          this.adminApiService
            .changeStatusRecurver(satusId, this.recurver.recurver_id)
            .subscribe(apiresponse => {
              this.toastService.success('Successfully Offer Rejected.');
              this.searchRecurver();
            }));
      }
    });
  }

  joined(e: any) {
    confirm('Are you sure, you want to change the recurver status to joined ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.recurver = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'Joined').key;
        this.subscriptions.push(
          this.adminApiService
            .changeStatusRecurver(satusId, this.recurver.recurver_id)
            .subscribe(apiresponse => {
              this.toastService.success('Successfully Joined.');
              this.searchRecurver();
            }));
      }
    });
  }

  offerRecurver(frm: any) {
    if (frm.valid) {
      this.recurverTermination.recurver_id = this.recurver.recurver_id;
      const satusId: number = this.statusList.find(d => d.value === 'Offer Released').key;
      this.recurverTermination.release_type_id = satusId;
      this.subscriptions.push(
        this.adminApiService
          .releaseRecurver(this.recurverTermination)
          .subscribe(apiresponse => {
            this.toastService.success('Offer letter saved successfully');
            this.searchRecurver();
            this.modalService.dismissAll();
          }));
    }
  }

  interviewRecurver(e: any) {
    if (e.valid) {
      this.recurverTermination.recurver_id = this.recurver.recurver_id;
      const satusId: number = this.statusList.find(d => d.value === 'Scheduled').key;
      this.recurverTermination.release_type_id = satusId;
      this.subscriptions.push(
        this.adminApiService
          .releaseRecurver(this.recurverTermination)
          .subscribe(apiresponse => {
            this.toastService.success('Scheduled Date saved successfully');
            this.searchRecurver();
            this.modalService.dismissAll();
          }));
    }
  }
  

  viewOfferLetter(cellInfo: any) {
    this.recurverTermination = cellInfo.row.data;

    console.log('Recurver data from cellInfo:', this.recurverTermination);

    this.subscriptions.push(
      this.adminApiService.getReleaseRecurver(this.recurverTermination.recurver_id).subscribe(apiresponse => {

        console.log('API Response:', apiresponse);
  

        if (apiresponse.data && apiresponse.data.length > 0) {
          const releaseData = apiresponse.data[0];
  
          // Log releaseData to check if it contains the correct values
          console.log('Release Data:', releaseData);
  
          // Open the modal and pass the offer letter data
          const modalRef = this.modalService.open(OfferLetterComponent, { size: 'lg' });
  
          // Pass the offer letter data to the modal component
          modalRef.componentInstance.offerLetter = {
            recurver_name: releaseData.recurver_name,
            company_name: releaseData.company_name,
            first_name: releaseData.first_name,
            last_name: releaseData.last_name,
            join_date: releaseData.join_date,
            deadline: releaseData.deadline,
            location: releaseData.location,
            company_id: releaseData.company_id,
            designation_id: releaseData.designation_id,
            address: releaseData.address || 'Address not available',
            position: releaseData.position || 'Position not available',
            salary: releaseData.salary || 'Salary not available',
            user_name:releaseData.user_name,
            display_name:releaseData.display_name,
            date: new Date().toLocaleDateString(),
            startDate: cellInfo.row.data.joining_date || 'Start date not available',
            additionalInfo: 'As discussed, the joining formalities and other details will be shared.',
            responseDeadline: new Date(new Date().setDate(new Date().getDate() + 7))
          };
  
          // Log the offer letter data passed to the modal
          console.log('Offer Letter Data:', modalRef.componentInstance.offerLetter);
        } else {
          console.error('No data returned from API');
        }
      })
    );
  }
   

  openProfile(e: any) {
    this.recurver = e.row.data;
    // tslint:disable-next-line:max-line-length
    this.tabService.addTab(new Tab(UserProfileComponent, this.recurver.employee_code, { id: this.recurver.recurver_id }));
  }
  openProfilePerformance(e: any) {
    this.recurver = e.row.data;
    // tslint:disable-next-line:max-line-length
    this.tabService.addTab(new Tab(PerformanceViewComponent, `Performance-${this.recurver.recurver_id}`, { recurver: this.recurver }));
  }
  activeRecurver(e: any) {
    confirm('Are you sure, you want to select the recurver?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.recurver = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'Yet to Start').key;
        this.subscriptions.push(
          this.adminApiService
            .changeStatusRecurver(satusId, this.recurver.recurver_id)
            .subscribe(apiresponse => {
              this.toastService.success('Recurver status successfully changed to Yet to Start.');
              this.searchRecurver();
            }));
      }
    });
  }
  relieveEmployee(e: any) {
    confirm('Are you sure, you want to change the employee status to releived ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.recurver = e.row.data;
        const satusId: number = this.statusList.find(d => d.value === 'Relieved').key;
        this.subscriptions.push(
          this.adminApiService
            .changeStatusRecurver(satusId, this.recurver.recurver_id)
            .subscribe(apiresponse => {
              this.toastService.success('Employee status successfully changed to Relieved.');
              this.searchRecurver();
            }));
      }
    });
  }


  uploadDocument(frm: { valid: boolean; }) {
    if (frm.valid) {
      this.subscriptions.push(
        this.adminApiService
          .uploadEmployeeDocument(this.file, this.documentTypeId, this.recurver.recurver_id)
          .pipe(indicate(this.docloading$))
          .pipe(
            concatMap(apiresponse => {
              this.toastService.success('Document uploaded successfully');
              this.documentTypeId = undefined;
              return this.adminApiService.getEmployeeDocuments(this.recurver.recurver_id);
            })
          ).subscribe(
            apiresponse => {
              this.employeeDocumentList = apiresponse.data;
              this.employeeDocumentList.forEach(element => {
                element.document_name = element.document_path.replace(/^.*[\\\/]/, '');
              });
            }
          ));
    }
  }

  fetchEmployeeDocuments() {
    this.subscriptions.push(
      this.adminApiService.getEmployeeDocuments(this.recurver_id)
        .subscribe(response => {
          this.employeeDocumentList = response.data;
        })
    );
  }


  openRelievingLetterModal() {
    this.modalService.open(RelievingLetterComponent, { size: 'lg' });
  }

  openEditEmployeeModal() {
    this.modalService.open(EditRecurverComponent, { size: 'lg' });
  }

  // editEmployee(e: any) {
  //   this.isNew = false;
  //   this.recurver = e.row.data;
  //   this.recurver.is_new = false;
  //   // tslint:disable-next-line:max-line-length
  //   this.tabService.addTab(new Tab(EditRecurverComponent, 'Recurver Edit - ' + this.recurver.recurver_id ,{ recurver: this.recurver }));
  // }

  editEmployee(e: any) {
    // Set this to false when editing an existing record
    this.isNew = false;
    
    // Get the recurver details from the selected row
    this.recurver = e.row.data;
    
    // Ensure is_new is set to false for editing
    this.recurver.is_new = false;
    
    // Call the service to open the recurver tab with the Edit form
    // The recurver object will contain the recurver_id if it exists
    this.tabService.addTab(
        new Tab(EditRecurverComponent, 'Recurver Edit - ' + this.recurver.recurver_id, 
        { recurver: this.recurver })
    );
}

  

    fromdateChanged(e: any) {
    if (e.event !== undefined) {
      this.fromDate = e.value;
      this.toDate = null;
    }
  }
  todateChanged(e: any) {
    if (e.event !== undefined) {
      this.toDate = e.value;
    }
  }
  resetDate(e: any) {
    if (!this.dateSearchType) {
      this.toDate = null;
      this.fromDate = null;
    }
  }
}
