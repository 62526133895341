<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 *ngIf="recurver && recurver.is_new===true" class="page-title">Add Details</h3>
                <h3 *ngIf="recurver && recurver.is_new===false" class="page-title">Edit</h3>
            </div>
        </div>
     <form #empForm="ngForm">
        <div class="row">
            <div class="col-md-12 d-flex">
                <div class="card profile-box flex-fill">
                    <div class="card-body p-5" id="emp-personal-loading">
                        <h3 class="card-title text-muted bottom-border">Personal Information</h3>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="fname">First Name <span class="text-danger">*</span></label>
                                <input class="form-control " [(ngModel)]="recurver.first_name" required type="text"
                                    id="fname" name="fname" #fname="ngModel" [pattern]="global.alphaNumericValidator">
                                <div *ngIf="fname.invalid && (fname.dirty || fname.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="fname.errors.required">
                                        First Name is required.
                                    </div>
                                    <div *ngIf="fname.errors.pattern">
                                        First Name is invalid.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="lname">Last Name</label>
                                <input class="form-control " [(ngModel)]="recurver.last_name" type="text" id="lname"
                                    name="lname" #lname="ngModel" [pattern]="global.alphaNumericValidator">
                                <div *ngIf="lname.invalid && (lname.dirty || lname.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="lname.errors.pattern">
                                        Last Name is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="qualification">Qualification</label>
                                <input class="form-control " [(ngModel)]="recurver.qualification"
                                    type="text" id="qualification" name="qualification" #qualification="ngModel">
                            </div>
                            
                            <!-- <div class="col-sm-6">
                                <label for="bgroup">Blood Group</label>
                                <select class="form-control " id="bgroup" name="bgroup"
                                    >
                                    <option [ngValue]="null" selected>Select Blood Group</option>
                                    <option *ngFor="let item of bloodGroupList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div> -->

                            <div class="col-sm-6">
                              <label for="bgroup">Blood Group</label>
                              <select class="form-control " id="bgroup" name="bgroup"
                                  [(ngModel)]="recurver.blood_group_id" #bgroup="ngModel"
                                  [ngClass]="{'is-invalid': bgroup.invalid && bgroup.touched}">
                                  <option [ngValue]="null" selected>Select Blood Group</option>
                                  <option *ngFor="let item of bloodGroupList; let i = index" [ngValue]="item.key">
                                      {{item.value}}</option>
                              </select>
                          </div>
            
                        </div>
                        
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="date_of_birth">Date of Birth <span class="text-danger">*</span></label>
                                <dx-date-box [(ngModel)]="recurver.date_of_birth" name="date_of_birth" id="date_of_birth"
                                    #date_of_birth="ngModel" required type="date" displayFormat="dd/MM/yyyy"
                                    dateSerializationFormat="yyyy-MM-dd">
                                </dx-date-box>
                                <div *ngIf="date_of_birth.invalid && (date_of_birth.dirty || date_of_birth.touched || formsubmitted)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="date_of_birth.errors.required">
                                        Date of Birth is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6" [ngClass]="{'loading-control': (ddloading$ | async) === true}">
                                <label for="gender">Gender</label><br>
                                <div *ngFor="let item of genderList" class="d-inline">
                                    <input id="gender{{item.key}}" [value]='item.key' type="radio" name="gender"
                                        class="radio-inline" [(ngModel)]="recurver.gender_id">
                                    {{item.value}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex">
                <div class="card profile-box flex-fill">
                    <div class="card-body p-5" id="emp-contact-loading">
                        <h3 class="card-title text-muted bottom-border">Contact Information</h3>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="phone">Personal Phone</label>
                                <input class="form-control " [(ngModel)]="recurver.phone" type="text"
                                    id="phone" name="phone" #phone="ngModel" [pattern]="global.numericValidator">
                                <div *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="phone.errors.pattern">
                                        Personal Phone Number is invalid.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="email">Personal Email</label>
                                <input class="form-control " [(ngModel)]="recurver.email" type="text"
                                    id="email" name="email" #email="ngModel" [pattern]="global.emailValidator">
                                <div *ngIf="email.invalid && (email.dirty || email.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="email.errors.pattern">
                                        Personal Email is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="econtact">Alternate Contact No</label>
                                <input class="form-control " [(ngModel)]="recurver.emergency_contact"
                                    type="text" id="econtact" name="econtact" #econtact="ngModel"
                                    [pattern]="global.numericValidator">
                                <div *ngIf="econtact.invalid && (econtact.dirty || econtact.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="econtact.errors.pattern">
                                        Emergency Contact is invalid.
                                    </div>
                                    <div *ngIf="econtact.errors.required">
                                        Emergency Contact is required.
                                    </div>
                                </div>
                            </div>
         
                        </div>
                        <div class="form-group mt-5 row">
                            <div class="col-sm-12">
                                <h6 class="text-muted bottom-border">Present Address</h6>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <label for="address">Address</label><br>
                                <textarea rows="4" class="form-control" id="address" name="address"
                                    [(ngModel)]="recurver.address"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="city">City</label>
                                <input class="form-control " [(ngModel)]="recurver.city" type="text"
                                    id="city" name="city" #city="ngModel" [pattern]="global.alphaNumericValidator">
                                <div *ngIf="city.invalid && (city.dirty || city.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="city.errors.pattern">
                                        City is invalid.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label for="state">State</label>
                                <select class="form-control " id="state" name="state"
                                    [(ngModel)]="recurver.state_id" #state="ngModel"
                                    [ngClass]="{'is-invalid': state.invalid && state.touched}">
                                    <option [ngValue]="null" selected>Select State</option>
                                    <option *ngFor="let item of stateList; let i = index" [ngValue]="item.key">
                                        {{item.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label for="pincode">Pincode</label>
                                <input class="form-control " [(ngModel)]="recurver.pincode" type="text"
                                    id="pincode" name="pincode" #pincode="ngModel" [pattern]="global.numericValidator">
                                <div *ngIf="pincode.invalid && (pincode.dirty || pincode.touched)"
                                    class="badge badge-danger position-absolute">
                                    <div *ngIf="pincode.errors.pattern">
                                        Pincode is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group row">
                            
                            <div class="col-sm-6">
                               
                                        <label for="resume">Upload Resume (PDF only) <span class="text-danger">*</span></label>
                                        <input type="file" id="resume" filename="resume" accept=".pdf" (change)="onFileChange($event)" />
                                        <div *ngIf="resumeError" class="badge badge-danger position-absolute">
                                            {{ resumeError }}
                                        </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-md-3 d-flex offset-md-9">
                <button type="submit" class="btn btn-secondary btn-block" (click)="saveRecurver(empForm)">{{ recurver.is_new ? 'Save' : 'Update' }}</button>
            </div>
        </div>
    </form>
</div>
</div>

<ng-template #uploadFileModal let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h5 class="modal-title">Upload Offer Letter (PDF Only) {{recurver.recurver_id }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
          <label for="resume">Select PDF File:</label>
          <input type="file" id="resume" (change)="onFileChange($event)" accept=".pdf" />
          <div *ngIf="resumeError" class="badge badge-danger">
            {{ resumeError }}
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="uploadDocument(empForm)">Upload</button>
      <button type="button" class="btn btn-secondary" (click)="hideUploadModal()">Cancel</button>
    </div>
  </ng-template>
 

  <!-- <div class="content container-fluid">
    <div class="page-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 *ngIf="recurver && recurver.is_new === true" class="page-title">Add Details</h3>
          <h3 *ngIf="recurver && recurver.is_new === false" class="page-title">Edit</h3>
        </div>
      </div>
    </div>
    

    <form #empForm="ngForm">
      <div class="row">
        <div class="col-md-12 d-flex">
          <div class="card profile-box flex-fill">
            <div class="card-body p-5" id="emp-personal-loading">
              <h3 class="card-title text-muted bottom-border">Personal Information</h3>
              
              <div class="form-group row">
                <div class="col-sm-6">
                  <label for="fname">First Name <span class="text-danger">*</span></label>
                  <input class="form-control" [(ngModel)]="recurver.first_name" required type="text"
                    id="fname" name="fname" #fname="ngModel" [pattern]="global.alphaNumericValidator">
                  <div *ngIf="fname.invalid && (fname.dirty || fname.touched || formsubmitted)" class="badge badge-danger position-absolute">
                    <div *ngIf="fname.errors?.required">First Name is required.</div>
                    <div *ngIf="fname.errors?.pattern">First Name is invalid.</div>
                  </div>
                </div>
  
                <div class="col-sm-6">
                  <label for="lname">Last Name</label>
                  <input class="form-control" [(ngModel)]="recurver.last_name" type="text" id="lname" name="lname" #lname="ngModel"
                    [pattern]="global.alphaNumericValidator">
                  <div *ngIf="lname.invalid && (lname.dirty || lname.touched)" class="badge badge-danger position-absolute">
                    <div *ngIf="lname.errors?.pattern">Last Name is invalid.</div>
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <div class="col-sm-6">
                  <label for="qualification">Qualification</label>
                  <input class="form-control" [(ngModel)]="recurver.qualification" type="text" id="qualification" name="qualification" #qualification="ngModel">
                </div>
  

                <div class="col-sm-6">
                    <label for="bgroup">Blood Group</label>
                    <select class="form-control " id="bgroup" name="bgroup"
                        [(ngModel)]="recurver.personal_info.blood_group_id" #bgroup="ngModel"
                        [ngClass]="{'is-invalid': bgroup.invalid && bgroup.touched}">
                        <option [ngValue]="null" selected>Select Blood Group</option>
                        <option *ngFor="let item of bloodGroupList; let i = index" [ngValue]="item.key">
                            {{item.value}}</option>
                    </select>
                </div>

               
              </div>
  
              <div class="form-group row">
                <div class="col-sm-6">
                  <label for="date_of_birth">Date of Birth <span class="text-danger">*</span></label>
                  <dx-date-box [(ngModel)]="recurver.date_of_birth" name="date_of_birth" id="date_of_birth" #date_of_birth="ngModel" required type="date"
                    displayFormat="dd/MM/yyyy" dateSerializationFormat="yyyy-MM-dd">
                  </dx-date-box>
                  <div *ngIf="date_of_birth.invalid && (date_of_birth.dirty || date_of_birth.touched || formsubmitted)" class="badge badge-danger position-absolute">
                    <div *ngIf="date_of_birth.errors?.required">Date of Birth is required.</div>
                  </div>
                </div>
  
                <div class="col-sm-6">
                  <label for="gender">Gender</label><br>
                  <div *ngFor="let item of genderList" class="d-inline">
                    <input id="gender_{{item.key}}" [value]="item.key" type="radio" name="gender" class="radio-inline" [(ngModel)]="recurver.gender_id">
                    {{item.value}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    
      <div class="row">
        <div class="col-md-12 d-flex">
          <div class="card profile-box flex-fill">
            <div class="card-body p-5" id="emp-contact-loading">
              <h3 class="card-title text-muted bottom-border">Contact Information</h3>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label for="phone">Personal Phone</label>
                  <input class="form-control" [(ngModel)]="recurver.phone" type="text" id="phone" name="phone" #phone="ngModel" [pattern]="global.numericValidator">
                  <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="badge badge-danger position-absolute">
                    <div *ngIf="phone.errors?.pattern">Personal Phone Number is invalid.</div>
                  </div>
                </div>
  
                <div class="col-sm-6">
                  <label for="email">Personal Email</label>
                  <input class="form-control" [(ngModel)]="recurver.email" type="text" id="email" name="email" #email="ngModel" [pattern]="global.emailValidator">
                  <div *ngIf="email.invalid && (email.dirty || email.touched)" class="badge badge-danger position-absolute">
                    <div *ngIf="email.errors?.pattern">Personal Email is invalid.</div>
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <div class="col-sm-6">
                  <label for="econtact">Alternate Contact No</label>
                  <input class="form-control" [(ngModel)]="recurver.emergency_contact" type="text" id="econtact" name="econtact" #econtact="ngModel"
                    [pattern]="global.numericValidator">
                  <div *ngIf="econtact.invalid && (econtact.dirty || econtact.touched)" class="badge badge-danger position-absolute">
                    <div *ngIf="econtact.errors?.pattern">Emergency Contact is invalid.</div>
                  </div>
                </div>
              </div>
  
      
              <div class="form-group mt-5 row">
                <div class="col-sm-12">
                  <h6 class="text-muted bottom-border">Present Address</h6>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label for="address">Address</label><br>
                  <textarea rows="4" class="form-control" id="address" name="address" [(ngModel)]="recurver.address"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label for="city">City</label>
                  <input class="form-control" [(ngModel)]="recurver.city" type="text" id="city" name="city" #city="ngModel" [pattern]="global.alphaNumericValidator">
                  <div *ngIf="city.invalid && (city.dirty || city.touched)" class="badge badge-danger position-absolute">
                    <div *ngIf="city.errors?.pattern">City is invalid.</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="state">State</label>
                  <select class="form-control" id="state" name="state" [(ngModel)]="recurver.state_id" #state="ngModel">
                    <option [ngValue]="null" selected>Select State</option>
                    <option *ngFor="let item of stateList" [ngValue]="item.key">{{item.value}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label for="pincode">Pincode</label>
                  <input class="form-control" [(ngModel)]="recurver.pincode" type="text" id="pincode" name="pincode" #pincode="ngModel" [pattern]="global.numericValidator">
                  <div *ngIf="pincode.invalid && (pincode.dirty || pincode.touched)" class="badge badge-danger position-absolute">
                    <div *ngIf="pincode.errors?.pattern">Pincode is invalid.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    
      <div class="row mb-5">
        <div class="col-md-3 d-flex offset-md-9">
          <button type="submit" class="btn btn-secondary btn-block" (click)="saveRecurver(empForm)">Save</button>
        </div>
      </div>
    </form>
  

    <ng-template #uploadFileModal let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h5 class="modal-title">Upload Offer Letter (PDF Only)</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label for="resume">Select PDF File:</label>
            <input type="file" id="resume" (change)="onFileChange($event)" accept=".pdf" />
            <div *ngIf="resumeError" class="badge badge-danger">{{ resumeError }}</div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="uploadDocument(empForm)">Upload</button>
        <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
      </div>
    </ng-template>
  
  </div>
   -->