<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Notifications</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
                <button class="btn add-btn" (click)="openModel(notificationmodalcontent)" hidden=true><i
                        class="fa fa-paper-plane"></i>
                    Send</button>
            </div>
        </div>
    </div>
    <div class="row" id="notification-loading-div">
        <div class="col-md-12">
            <div class="activity">
                <div class="activity-box">
                    <ul class="activity-list">
                        <li *ngFor="let item of notificationList; let i = index"
                            [ngClass]="{'read-message': (!item.is_read)}" class="cursor-pointer"
                            (click)="openWindow(item)">
                            <div class="activity-user">
                                <ngx-avatar size="40" [src]="item.created_by_profile_picture"
                                    [name]="item.created_by_name"></ngx-avatar>
                            </div>
                            <div [ngStyle]="{'background-color': (!item.is_read) ? '#e2e3e5' : '#fff'}"
                                class="activity-content pl-3">
                                <div class="timeline-content"
                                    [ngStyle]="{'color': (!item.is_read) ? '#383d41' : '#bdbdbd'}">
                                    {{item.created_by_name}} added new {{item.notification_type}}
                                    <br>
                                    {{item.notification_message}}
                                    <br>
                                    <a class="gridlink" *ngIf="item.file_path" href="javascript:void(0);"
                                        (click)="openDocument(item.notification_id)">Click to view
                                        attachment:
                                        {{item.file_path.split("\\").pop().split("/").pop().split("\\").pop()}}</a>
                                    <span class="time">{{ item.created_date | messageTime }}
                                        <a href="javascript:void(0);" class="edit-icon" *ngIf="!item.is_read"
                                            (click)="markAsRead(item)"><i class="fa fa-envelope-open-o"></i></a>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #notificationmodalcontent let-c="close" let-d="dismiss">
    <form #notificationForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Send Message</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="employeeName">Employee Name<span class="text-danger">*</span></label>
                    <dx-autocomplete class="form-control" id="employeeName" placeholder="Type Employee Name...."
                        (onSelectionChanged)="selectName($event)" [dataSource]="employeeNameList">
                    </dx-autocomplete>
                </div>
                <div class="col-sm-12 mt-2">
                    <label for="message">Message <span class="text-danger">*</span></label>
                    <textarea rows="4" class="form-control" id="message" name="message"
                        [(ngModel)]="notification.notification_message" #message="ngModel" required></textarea>
                    <div *ngIf="message.invalid && (message.dirty || message.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="message.errors.required">
                            Message is required.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="sendMessage(notificationForm)"
                [disabled]="!notificationForm.valid">Send</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#notification-loading-div' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#notification-loading-div">
    </dx-load-panel>
</div>