import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { OfficeLocation, Shift } from 'src/app/model/admin';
import { AttendanceActivityReport } from 'src/app/model/attendance';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { indicate } from 'src/app/services/loading.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-date-range-attendance-report',
  templateUrl: './date-range-attendance-report.component.html',
  styleUrls: ['./date-range-attendance-report.component.scss']
})
export class DateRangeAttendanceReportComponent implements OnInit, OnDestroy {
  reportFromDate: Date;
  reportToDate: Date;
  departmentId: number;
  locationId: number;
  shiftId: number;
  categoryId: number;
  departmentList: KeyValue<number, string>[];
  shiftList: Shift[] = [];
  ddlocationloading$ = new Subject<boolean>();
  locationList: OfficeLocation[] = [];
  categoryList: OfficeLocation[] = [];
  ddshiftloading$ = new Subject<boolean>();
  ddloading$ = new Subject<boolean>();
  subscriptions: Subscription[] = [];
  activityReportloading$ = new Subject<boolean>();
  reportData: AttendanceActivityReport[] = [];
  constructor(
    public global: Global,
    public commonApiService: CommonApiService,
    private adminApiService: AdminApiService,
    private attendanceApiService: AttendanceApiService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.reportToDate = new Date();
    this.reportFromDate = new Date();
    this.reportFromDate.setFullYear(this.reportToDate.getFullYear());
    this.reportFromDate.setMonth(this.reportToDate.getMonth());
    this.reportFromDate.setDate(1);
    this.subscriptions.push(
      this.commonApiService.getMasterData('department,employee_category')
        .pipe(indicate(this.ddloading$))
        .subscribe(apiresponse => {
          this.departmentList = apiresponse.data.filter((d: { type: string; }) => d.type === 'department');
          this.categoryList = apiresponse.data.filter((d: { type: string; }) => d.type === 'employee_category');
        })
    );
    this.subscriptions.push(
      this.adminApiService.getLocation()
        .pipe(indicate(this.ddlocationloading$))
        .subscribe(apiresponse => {
          this.locationList = apiresponse.data;
        })
    );
    this.subscriptions.push(
      this.adminApiService.getShift()
        .pipe(indicate(this.ddshiftloading$))
        .subscribe(apiresponse => {
          this.shiftList = apiresponse.data;
        })
    );
    this.loadReport();
  }
  loadReport(): void {
    this.subscriptions.push(
      this.attendanceApiService.getAttendanceActivityReport(
        this.reportFromDate,
        this.reportToDate,
        this.locationId,
        this.departmentId,
        this.shiftId,
        this.categoryId)
        .pipe(indicate(this.activityReportloading$))
        .subscribe(apiresponse => {
          this.reportData = apiresponse.data;
        })
    );
  }
  fromdateChanged(e: any) {
    if (e.event !== undefined) {
      this.reportFromDate = e.value;
      this.reportToDate = null;
    }
  }
  todateChanged(e: any) {
    if (e.event !== undefined) {
      this.reportToDate = e.value;
    }
  }
}
