import { Component, OnInit, ViewChild } from '@angular/core';
import { AttendanceRegularization } from 'src/app/model/attendance';
import { confirm } from 'devextreme/ui/dialog';
import { KeyValue } from '@angular/common';
import { CommonApiService } from 'src/app/services/common-api.service';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { Global } from 'src/app/shared/global';
import { ToastService } from 'src/app/services/toast.service';
import { RequestRegularizeComponent } from '../request-regularize/request-regularize.component';
import { forkJoin, Subject } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';

@Component({
  selector: 'app-attendance-regularize',
  templateUrl: './attendance-regularize.component.html',
  styleUrls: ['./attendance-regularize.component.scss']
})
export class AttendanceRegularizeComponent implements OnInit {
  isNew = true;
  regularization: AttendanceRegularization = new AttendanceRegularization();
  regularizationList: AttendanceRegularization[] = [];
  fromDate: Date;
  toDate: Date;
  regularizationStatusList: KeyValue<number, string>[] = [];
  loading$ = new Subject<boolean>();
  @ViewChild('applyRegularization', { read: RequestRegularizeComponent }) applyRegularization: RequestRegularizeComponent;
  // tslint:disable-next-line:max-line-length
  constructor(private toastService: ToastService, private commonApiService: CommonApiService, private attendanceService: AttendanceApiService, private global: Global) { }
  ngOnInit() {
    this.fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.toDate = new Date();
    forkJoin(
      {
        master: this.commonApiService.getMasterData('regularization_status'),
        request: this.attendanceService.getRegularizationRequest(this.global.loggedUser.user_type_internal_id, this.fromDate, this.toDate),
      })
      .pipe(indicate(this.loading$))
      .subscribe(apiresponse => {
        this.regularizationStatusList = apiresponse.master.data
          .filter((d: { type: string; }) => d.type === 'regularization_status');
        this.regularizationList = apiresponse.request.data;
      });
  }
  searchRegularization() {
    this.attendanceService.getRegularizationRequest(this.global.loggedUser.user_type_internal_id, this.fromDate, this.toDate)
      .pipe(indicate(this.loading$))
      .subscribe(apiresponse => {
        this.regularizationList = apiresponse.data;
      });
  }
  openModel() {
    this.isNew = true;
    this.clearModel();
    this.regularization.employee_id = this.global.loggedUser.user_type_internal_id;
    this.applyRegularization.openModal(true, this.regularization, false);
  }
  clearModel() {
    this.regularization = new AttendanceRegularization();
  }
  editRegularization(e: { row: { data: AttendanceRegularization; }; }) {
    this.isNew = false;
    this.regularization = e.row.data;
    this.regularization.employee_id = this.global.loggedUser.user_type_internal_id;
    this.applyRegularization.openModal(false, this.regularization, false);
  }
  fromdateChanged(e: any) {
    if (e.event !== undefined) {
      this.toDate = null;
    }
  }
  cancelRegularization(e: { row: { data: AttendanceRegularization; }; }) {
    this.regularization = e.row.data;
    confirm('Are you sure, you want to cancel ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        const stat: KeyValue<number, string> = this.regularizationStatusList.find(d => d.value === 'Cancelled');
        if (stat) {
          this.regularization.regularization_status_id = stat.key;
          this.attendanceService.postRegularizationRequest(this.regularization)
            .subscribe(apiresponse => {
              this.toastService.success('Regularization Request cancelled successfully');
              this.searchRegularization();
            });
        }
      }
    });
  }
}
