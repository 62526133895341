<div class="content container-fluid">
	<div class="page-header">
		<div class="row align-items-center" *ngIf="!global.isMobileApp && global.loggedUser">
			<div class="col" *ngIf="!global.isMobile">
				<h3 class="page-title">Leaves</h3>
			</div>
			<div class="col-auto float-right ml-auto">
				<button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
				<button class="btn add-btn ml-3" (click)="openModel()"><i class="fa fa-plus"></i> Apply Leave</button>
			</div>
		</div>		
	</div>
	<app-leave-balance #leaveBalance *ngIf="global.loggedUser"></app-leave-balance>
	<div class="row" *ngIf="global.isMobileApp">
		<div class="col-md-12 mobile-filter mb-3">
			<button class="btn mobile-btn col-md-12" (click)="openModel()"><i class="fa fa-plus"></i> Apply
				Leave</button>
		</div>
	</div>
	<ng-template #desktop>
		<div class="row">
			<div class="col-md-12 table-responsive" id="leave-grid">
				<dx-data-grid #gridLeave id="gridContainerDesktop" [dataSource]="leaveData" [remoteOperations]="false"
					[allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
					[showColumnLines]="false">
					<dxo-export [enabled]="true" fileName="leaveReport"></dxo-export>
					<dxo-paging [enabled]="false"></dxo-paging>
					<dxi-column dataField="leave_type" caption="Leave Type" dataType="string"></dxi-column>
					<dxi-column dataField="leave_from_date" caption="From" cellTemplate="dateTemplate"></dxi-column>
					<dxi-column dataField="leave_to_date" caption="To" cellTemplate="dateTemplate"></dxi-column>
					<dxi-column dataField="no_of_days" caption="No Of Days" dataType="number"></dxi-column>
					<dxi-column dataField="leave_reason" caption="Reason" dataType="string"></dxi-column>
					<dxi-column dataField="leave_status" caption="Satus" [allowFiltering]="false" [allowSorting]="false"
						cellTemplate="statusTemplate" alignment="center">
					</dxi-column>
					<dxi-column dataField="employee_leave_id" [allowFiltering]="false" width=50 caption=""
						[allowSorting]="false" cellTemplate="actionTemplate" alignment="center">
					</dxi-column>
					<div *dxTemplate="let data of 'dateTemplate'">
						{{data.value | date }}
					</div>
					<div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
						<a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
							*ngIf="cellInfo.value=='Approved'">
							<i class="fa fa-dot-circle-o text-success"></i> Approved
						</a>
						<a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
							*ngIf="cellInfo.value=='Pending'">
							<i class="fa fa-dot-circle-o text-purple"></i> Pending
						</a>
						<a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
							*ngIf="cellInfo.value=='Declined'">
							<i class="fa fa-dot-circle-o text-danger"></i> Declined
						</a>
						<a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
							*ngIf="cellInfo.value=='Cancelled'">
							<i class="fa fa-dot-circle-o text-grey"></i> Cancelled
						</a>
						<a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
							*ngIf="cellInfo.value=='Manager Approved'">
							<i class="fa fa-dot-circle-o text-warning"></i> M Approved
						</a>
					</div>
					<div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
						<div ngbDropdown container="body" *ngIf="cellInfo.row.data.leave_status!='Cancelled'">
							<a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle><i
									class="material-icons">more_vert</i></a>
							<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
								<a *ngIf="cellInfo.row.data.leave_status=='Pending'" class="dropdown-item"
									href="javascript:void(0);" (click)="editLeave(cellInfo.row.data)"><i
										class="fa fa-pencil m-r-5"></i> Edit</a>
								<a class="dropdown-item" href="javascript:void(0);"
									(click)="cancelLeave(cellInfo.row.data)"
									*ngIf="cellInfo.row.data.leave_status=='Pending'">
									<i class="fa fa-trash-o m-r-5"></i> Cancel</a>
								<a class="dropdown-item" href="javascript:void(0);"
									(click)="viewLeave(cellInfo.row.data)"
									*ngIf="cellInfo.row.data.leave_status!='Pending'">
									<i class="fa fa-search m-r-5"></i> View</a>
							</div>
						</div>
					</div>
				</dx-data-grid>
			</div>
		</div>
	</ng-template>
	<ng-template #mobile>
		<div class="row">
			<div class="col-md-12" id="leave-grid">
				<dx-data-grid #gridLeave id="gridContainerMobile" [dataSource]="leaveData" [remoteOperations]="false"
					[allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
					[showColumnLines]="false" [showColumnHeaders]="false" rowTemplate="rowLeaveTemplate">
					<dxo-paging [enabled]="false"></dxo-paging>
					<dxi-column dataField="leave_type" [width]="50+'%'"></dxi-column>
					<dxi-column dataField="leave_status" [width]="35+'%'">
					</dxi-column>
					<dxi-column dataField="leave_status">
					</dxi-column>
					<tbody class="leave dx-row" [ngClass]="{'dx-row-alt': leave.rowIndex % 2}"
						*dxTemplate="let leave of 'rowLeaveTemplate'">
						<tr class="main-row">
							<td class="mobile-column">
								<h6 class="mb-0">{{leave.data.leave_type}}</h6>
							</td>
							<td class="status-column">
								<span class="badge"
									[ngClass]="{'bg-inverse-danger': leave.data.leave_status=='Declined','bg-inverse-success': leave.data.leave_status=='Approved','bg-inverse-purple': leave.data.leave_status=='Pending','bg-inverse-warning': leave.data.leave_status=='Manager Approved','bg-inverse-grey': leave.data.leave_status=='Cancelled'}">{{leave.data.leave_status}}</span>
							</td>
							<td rowspan="2">
								<div ngbDropdown container="body" *ngIf="leave.data.leave_status!='Cancelled'">
									<a href="javascript:void(0);" class="action-icon dropdown-toggle-no"
										ngbDropdownToggle><i class="material-icons">more_vert</i></a>
									<div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
										<a *ngIf="leave.data.leave_status=='Pending'" class="dropdown-item"
											href="javascript:void(0);" (click)="editLeave(leave.data)"><i
												class="fa fa-pencil m-r-5"></i> Edit</a>
										<a class="dropdown-item" href="javascript:void(0);"
											(click)="cancelLeave(leave.data)"
											*ngIf="leave.data.leave_status=='Pending'">
											<i class="fa fa-trash-o m-r-5"></i> Cancel</a>
										<a class="dropdown-item" href="javascript:void(0);"
											(click)="viewLeave(leave.data)" *ngIf="leave.data.leave_status!='Pending'">
											<i class="fa fa-search m-r-5"></i> View</a>
									</div>
								</div>
							</td>
						</tr>
						<tr class="alt-row">
							<td colspan="2" class="mobile-column">
								<div>
									<span class="text-sm text-muted">{{leave.data.leave_from_date | date:
										"M/d/yyyy"}} -
										{{leave.data.no_of_days}} {{leave.data.no_of_days === 1 ? 'Day' : 'Days'
										}}</span>
								</div>
							</td>
						</tr>
					</tbody>
				</dx-data-grid>
			</div>
		</div>
	</ng-template>
	<ng-container *ngTemplateOutlet="global.isMobile===true?mobile:desktop">
	</ng-container>
	<app-apply-leave #applyLeave (modalClose)="reloadGrid()" *ngIf="global.loggedUser"></app-apply-leave>
</div>
<div *ngIf="(gridloading$ | async)">
	<dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#leave-grid' }"
		[showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false"
		container="#leave-grid">
	</dx-load-panel>
</div>