<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Attendance</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
            </div>
        </div>
    </div>
    <div class="card tab-box">
        <div class="row user-tabs">
            <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul ngbNav #navTab="ngbNav" (activeId)="1" class="nav nav-tabs nav-tabs-bottom" [destroyOnHide]="false">
                    <li [ngbNavItem]="2">
                        <a ngbNavLink class="nav-link">
                            Attendance
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row filter-row">
                                <div class="col-sm-4">
                                    <dx-date-box [(value)]="fromDate" type="date" class="form-control filter-control"
                                        placeholder="From Date">
                                    </dx-date-box>
                                </div>
                                <div class="col-sm-4">
                                    <dx-date-box [(value)]="toDate" type="date" class="form-control filter-control"
                                        placeholder="To Date">
                                    </dx-date-box>
                                </div>
                                <div class="col-sm-4">
                                    <button class="btn btn-secondary btn-block" (click)="searchAttendance()"> Search
                                    </button>
                                </div>
                            </div>
                            <div class="row no-margin">
                                <div class="col-sm-12 mt-3 table-responsive" id="attendance-section">
                                    <dx-data-grid #gridattendance id="gridattendance" [dataSource]="attendanceActivity"
                                        [remoteOperations]="false" [allowColumnReordering]="true"
                                        [rowAlternationEnabled]="true" [showBorders]="false" [showColumnLines]="false">
                                        <dxo-export [enabled]="true" fileName="attendanceReport"></dxo-export>
                                        <dxo-paging [pageSize]="10"></dxo-paging>
                                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]">
                                        </dxo-pager>
                                        <dxi-column dataField="attendance_date" caption="Date"
                                            cellTemplate="dateTemplate">
                                        </dxi-column>
                                        <dxi-column dataField="attendance_type" caption="Type"></dxi-column>
                                        <dxi-column dataField="in_punch_time" caption="Punch In"
                                            cellTemplate="timeTemplate"></dxi-column>
                                        <dxi-column dataField="out_punch_time" caption="Punch Out"
                                            cellTemplate="timeTemplate"></dxi-column>
                                        <div *dxTemplate="let data of 'dateTemplate'">
                                            {{data.value | date: 'EEEE, MMM d, y' }}
                                        </div>
                                        <div *dxTemplate="let data of 'timeTemplate'">
                                            {{data.value | date:'shortTime'}}
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="1">
                        <a ngbNavLink class="nav-link">
                            Attendance Activity
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row filter-row">
                                <div class="col-sm-4">
                                    <dx-date-box [(value)]="reportDate" type="date" class="form-control filter-control"
                                        placeholder="Date">
                                    </dx-date-box>
                                </div>
                                <div class="col-sm-4">
                                    <button class="btn btn-secondary btn-block" (click)="dailyAttendance()">
                                        Load </button>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-6">
                                    <app-daily-attendance-summary [ngModel]="dailyAttendanceData" name="dailyAttendance"
                                        ngDefaultControl [showPunchOut]="showPunchOut" id="attendance-activity-summary">
                                    </app-daily-attendance-summary>
                                </div>
                                <div class="col-md-6">
                                    <app-daily-attendance-activity [ngModel]="dailyAttendanceData" name="dailyActivity" id="attendance-activity"
                                        ngDefaultControl>
                                    </app-daily-attendance-activity>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div [ngbNavOutlet]="navTab" id="tabcontentPanel" class="tab-content"></div>
</div>
<div *ngIf="(attendanceloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#attendance-section' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#attendance-section">
    </dx-load-panel>
</div>
<div *ngIf="(activityloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#attendance-activity-summary' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#attendance-activity-summary">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#attendance-activity' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#attendance-activity">
    </dx-load-panel>
</div>