import { Observable, Subject, defer } from 'rxjs';
import { finalize, map, take, takeWhile } from 'rxjs/operators';
import { timer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { tService } from '../shared/global';

export const prepare = <T>(callback: () => void) => {
  return (source: Observable<T>): Observable<T> =>
    defer(() => {
      callback();
      return source;
    });
};

export const indicate = <T>(indicator: Subject<boolean>) => {
  let alive = true;
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      prepare(() =>
        timer(10)
          .pipe(
            takeWhile(() => alive),
            take(1)
          )
          .subscribe(() => {
            indicator.next(true);
          })
      ),
      finalize(() => {
        alive = false;
        indicator.next(false);
      })
    );
};
export const showError = (error: HttpErrorResponse) => {
  let msg = '';
  if (error.error instanceof ErrorEvent) {
    msg = 'An error occurred: ' + error.error.message;
  } else if (error.error && error.error.err) {
    msg = 'An error occurred: ' + error.error.err;
  } else if (error.error && error.error.message) {
    msg = 'An error occurred: ' + error.error.message;
  } else if (error.message) {
    msg = error.message;
  } else {
    msg = 'Something bad happened; please try again later.';
  }
  tService.error(msg);
};
export const toClass = <T>(classType: new () => T) =>
  (source: Observable<T>) =>
    source.pipe(map(val => Object.assign(new classType(), val)));
