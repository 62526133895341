import { KeyValue, Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Global } from 'src/app/shared/global';
import { Leave } from 'src/app/model/attendance';
import { confirm } from 'devextreme/ui/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { ApplyLeaveComponent } from '../apply-leave/apply-leave.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';

@Component({
  selector: 'app-leave-admin-view',
  templateUrl: './leave-admin-view.component.html',
  styleUrls: ['./leave-admin-view.component.scss']
})
export class LeaveAdminViewComponent implements OnInit {
  leaveStat: KeyValue<string, string>[];
  employeeNames: KeyValue<number, string>[];
  employeeNameList: string[];
  leaveTypeList: KeyValue<number, string>[] = [];
  leaveStatusList: KeyValue<number, string>[] = [];
  leaveTypeId: number;
  leaveStatusId: number;
  employeeId: number;
  fromDate: Date;
  toDate: Date;
  leave: Leave = new Leave();
  leaveData: Leave[] = [];
  hasHrRole = false;
  hasManagerRole = false;
  hasAdminRole = false;
  showAddLeaveFor = false;
  loading$ = new Subject<boolean>();
  gridloading$ = new Subject<boolean>();
  @ViewChild('applyLeave', { read: ApplyLeaveComponent }) applyLeave: ApplyLeaveComponent;
  @ViewChild('declineleavemodalcontent', { static: true }) declineleavemodalcontent: ElementRef;
  subscriptions: Subscription[] = [];
  // tslint:disable-next-line:max-line-length
  constructor(
    private modalService: NgbModal,
    public attendanceApiService: AttendanceApiService,
    public global: Global,
    public commonApiService: CommonApiService,
    private toastService: ToastService,
    private location: Location) {

  }
  ngOnInit() {
    const url = this.location.path();
    if (url === '/leaveadmin') {
      this.subscriptions.push(
        this.commonApiService.getRequestHeader()
          .subscribe(apiresponse => {
            this.global.loadUserSession(apiresponse.data.user);
            this.commonApiService.setToken(this.global.loggedUser.token);
            this.attendanceApiService.setToken(this.global.loggedUser.token);
            this.global.isMobileApp = true;
            this.initialize();
          }));
    } else {
      this.initialize();
    }
  }
  initialize() {
    this.hasHrRole = this.global.loggedUser.roles.filter(d => d.role_name === 'HR-Manager').length > 0;
    if (!this.hasHrRole) {
      this.leaveStatusId = 33;
    } else {
      this.leaveStatusId = 36;
    }
    this.hasHrRole = this.global.loggedUser.roles.filter(d => d.role_name === 'HR-Manager').length > 0;
    this.hasManagerRole = this.global.loggedUser.roles.filter(d => d.role_name === 'Manager').length > 0;
    this.hasAdminRole = this.global.loggedUser.access.filter(a => a.module_name === 'Admin').length > 0;
    this.showAddLeaveFor = this.global.loggedUser.manager_leave_permission_add === 1;
    this.commonApiService.getMasterData('leave_type,leave_status')
      .pipe(indicate(this.loading$))
      .subscribe(apiresponse => {
        this.leaveTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'leave_type');
        this.leaveStatusList = apiresponse.data.filter((d: { type: string; }) => d.type === 'leave_status');
      });

    this.getLeaveStats();
    this.commonApiService.getUtilityData('reporting_employee_name')
      .subscribe(apiresponse => {
        this.employeeNames = apiresponse.data;
        this.employeeNameList = this.employeeNames.map(a => a.value);
      });
    this.attendanceApiService.getLeave(null, null, this.leaveStatusId)
      .pipe(indicate(this.gridloading$))
      .subscribe(apiresponse => {
        this.leaveData = apiresponse.data;
      });
  }
  refresh() {
    this.ngOnInit();
  }
  getLeaveStats() {
    this.attendanceApiService.getLeaveStats(this.global.loggedUser.user_type_internal_id)
      .subscribe(apiresponse => {
        this.leaveStat = apiresponse.data;
      });
  }
  selectName(e) {
    const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
    if (emp) {
      this.employeeId = emp.key;
    } else {
      this.employeeId = null;
    }
  }
  declineLeave(frm) {
    if (frm.valid) {
      this.postLeaveStatus('decline');
    }
  }
  postLeaveStatus(status: string) {
    this.attendanceApiService.postLeaveStatus(this.leave).subscribe(apiresponse => {
      this.toastService.success('Leave ' + status + ' successfully');
      this.modalService.dismissAll();
      this.searchLeave();
      this.getLeaveStats();
    });
  }
  changeLeaveStatus(l: Leave, status: string) {
    this.leave = l;
    const st = status === 'Approved' ? 'approve' : (status === 'Cancelled' ? 'cancel' : 'decline');
    const stat: KeyValue<number, string> = this.leaveStatusList.find(d => d.value === status);
    if (stat.key === 37) {
      this.leave.leave_status_id = stat.key;
      this.modalService.open(this.declineleavemodalcontent, { size: 'lg' });
    } else {
      confirm('Are you sure, you want to ' + st + ' ?', 'Confirmation ').then((dialogResult: any) => {
        if (dialogResult) {
          if (stat) {
            this.leave.leave_status_id = stat.key;
            this.postLeaveStatus(status);
          }
        }
      });
    }
  }
  clearModel() {
    this.leave = new Leave();
  }
  openApplyLeave() {
    this.clearModel();
    this.applyLeave.openModal(true, this.leave, true);
  }
  reloadGrid() {
    this.searchLeave();
    this.getLeaveStats();
  }
  fromdateChanged(e: any) {
    if (e.event !== undefined) {
      this.toDate = null;
    }
  }
  searchLeave() {
    this.attendanceApiService.getLeave(this.employeeId, this.leaveTypeId, this.leaveStatusId, this.fromDate, this.toDate)
      .pipe(indicate(this.gridloading$))
      .subscribe(apiresponse => {
        this.leaveData = apiresponse.data;
      });
  }
}
