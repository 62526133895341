import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { EmployeeLeaveBalance } from 'src/app/model/attendance';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { indicate } from 'src/app/services/loading.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-leave-balance',
  templateUrl: './leave-balance.component.html',
  styleUrls: ['./leave-balance.component.scss']
})
export class LeaveBalanceComponent implements OnInit {
  leaveStat: EmployeeLeaveBalance[];
  statClass = 'col-md-4';
  loading$ = new Subject<boolean>();
  constructor(public global: Global, public apiService: AttendanceApiService) { }

  ngOnInit(): void {
  }
  loadStats(year: number = null, employeeId: number = this.global.loggedUser.user_type_internal_id) {
    this.apiService.getLeaveBalance(employeeId, year)
      .pipe(indicate(this.loading$))
      .subscribe(apiresponse => {
        this.leaveStat = apiresponse.data;
        if (this.leaveStat.length === 2) {
          this.statClass = 'col-md-6';
        } else if (this.leaveStat.length === 4) {
          this.statClass = 'col-md-3';
        } else if (this.leaveStat.length < 4 || this.leaveStat.length === 5 || this.leaveStat.length === 6) {
          this.statClass = 'col-md-4';
        }
      });
  }
}
