<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Manage Regularization</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn" (click)="openModel()"><i class="fa  fa-user-circle"></i>
                    Request Regularization For</button>
            </div>
        </div>
    </div>
    <div class="row filter-row">
        <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-12">
            <dx-date-box [(value)]="fromDate" type="date" class="form-control filter-control" placeholder="From Date"
                (onValueChanged)="fromdateChanged($event)">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-12">
            <dx-date-box [(value)]="toDate" type="date" class="form-control filter-control" placeholder="To Date" [min]="fromDate">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-12">
            <button class="btn btn-secondary btn-block" (click)="searchRegularization()"
                [disabled]="!fromDate || !toDate"> Search </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-3 table-responsive" id="loading-div">
            <dx-data-grid #gridregularization id="gridContainer" [dataSource]="regularizationList"
                [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
                [showBorders]="false" [showColumnLines]="false">
                <dxo-export [enabled]="true" fileName="regularizationReport"></dxo-export>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                <dxi-column dataField="employee_name" [allowFiltering]="false" [allowSorting]="false"
                    cellTemplate="imageTemplate" caption="Employee" width="250"></dxi-column>
                <dxi-column dataField="employee_code" caption="Code" dataType="string">
                </dxi-column>
                <dxi-column dataField="regularization_date" caption="Regularization Date" cellTemplate="dateTemplate">
                </dxi-column>
                <dxi-column dataField="regularization_from_time" caption="From Time" cellTemplate="timeTemplate">
                </dxi-column>
                <dxi-column dataField="regularization_to_time" caption="To Time" cellTemplate="timeTemplate">
                </dxi-column>
                <dxi-column dataField="regularization_reason" caption="Reason" dataType="string"></dxi-column>
                <dxi-column dataField="regularization_status" caption="Satus" [allowFiltering]="false"
                    [allowSorting]="false" cellTemplate="statusTemplate" alignment="center">
                </dxi-column>
                <div *dxTemplate="let data of 'imageTemplate'">
                    <h2 class="table-avatar">
                        <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                            [name]="data.row.data.employee_name">
                        </ngx-avatar>
                        <a class="ml-1 mt-1"> {{data.row.data.employee_name}}<span>{{data.row.data.designation}}
                            </span></a>
                    </h2>
                </div>
                <div *dxTemplate="let data of 'timeTemplate'">
                    {{data.value | date:"shortTime" }}
                </div>
                <div *dxTemplate="let data of 'dateTemplate'">
                    {{data.value | date }}
                </div>
                <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                    <div ngbDropdown container="body">
                        <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Approved'" ngbDropdownToggle>
                            <i class="fa fa-dot-circle-o text-success"></i> Approved
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Manager Approved'" ngbDropdownToggle>
                            <i class="fa fa-dot-circle-o text-warning"></i> M Approved
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Pending'" ngbDropdownToggle>
                            <i class="fa fa-dot-circle-o text-purple"></i> Pending
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Declined'" ngbDropdownToggle>
                            <i class="fa fa-dot-circle-o text-danger"></i> Declined
                        </a>
                        <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0);"
                            *ngIf="cellInfo.value=='Cancelled'">
                            <i class="fa fa-dot-circle-o text-grey"></i> Cancelled
                        </a>
                        <div ngbDropdownMenu>
                            <a class="dropdown-item"
                                *ngIf="cellInfo.value=='Declined' || cellInfo.value=='Pending' || (cellInfo.value=='Manager Approved' && hasHrRole===true)"
                                href="javascript:void(0);" (click)="changeStatus(cellInfo, 'Approved')"><i
                                    class="fa fa-dot-circle-o text-success"></i> Approve</a>
                            <a class="dropdown-item"
                                *ngIf="cellInfo.value=='Pending' || (cellInfo.value=='Manager Approved' && hasHrRole===true)"
                                href="javascript:void(0);" (click)="changeStatus(cellInfo, 'Declined')"><i
                                    class="fa fa-dot-circle-o text-danger"></i> Decline</a>
                            <a class="dropdown-item"
                                *ngIf="cellInfo.value=='Approved'"
                                href="javascript:void(0);" (click)="changeStatus(cellInfo, 'Cancelled')"><i
                                    class="fa fa-dot-circle-o text-grey"></i> Cancel</a>
                        </div>
                    </div>
                </div>
            </dx-data-grid>
        </div>
    </div>
    <app-request-regularize #applyRegularization (modalClose)="searchRegularization()"></app-request-regularize>
</div>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#loading-div' }" [showIndicator]="true"
        [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#loading-div">
    </dx-load-panel>
</div>
