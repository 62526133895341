import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { AttendancePermission } from 'src/app/model/attendance';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { indicate } from 'src/app/services/loading.service';
import { Global } from 'src/app/shared/global';
import { confirm } from 'devextreme/ui/dialog';
import { KeyValue, Location } from '@angular/common';
import { ToastService } from 'src/app/services/toast.service';
import { CommonApiService } from 'src/app/services/common-api.service';

@Component({
  selector: 'app-permission-view',
  templateUrl: './permission-view.component.html',
  styleUrls: ['./permission-view.component.scss']
})
export class PermissionViewComponent implements OnInit, OnDestroy {
  role = '';
  intializeData: any;
  isNew = true;
  fromDate: Date;
  toDate: Date;
  permission: AttendancePermission = new AttendancePermission();
  employeeNameList: KeyValue<number, string>[];
  permissionStatusList: KeyValue<number, string>[] = [];
  permissionSummary: any;
  permissionList: AttendancePermission[] = [];
  loading$ = new Subject<boolean>();
  summaryloading$ = new Subject<boolean>();
  hasHrRole = false;
  hasManagerRole = false;
  isPermissionSaved = true;
  currDate: Date = new Date();
  hasAdminRole = false;
  showAddPermissionFor = false;
  employeeId: number;
  employeeNames: KeyValue<number, string>[];
  permissionStatusId = 48;
  subscriptions: Subscription[] = [];
  todayDate: Date = new Date();
  @ViewChild('permissioncontent', { static: true }) permissioncontent: ElementRef;
  //@ViewChild('starttime', { static: true }) starttime: DxDateBoxComponent;
  constructor(
    private toastService: ToastService,
    public global: Global,
    private commonApiService: CommonApiService,
    private attendanceService: AttendanceApiService,
    private modalService: NgbModal,
    private location: Location) { }

  ngOnInit(): void {
    const url = this.location.path();
    //this.starttime.calendarOptions = { interval:15 };
    if (url === '/permission' || url === '/permissionadmin') {
      if (url === '/permissionadmin') {
        this.intializeData = { role: 'approver' };
      }
      this.subscriptions.push(
        this.commonApiService.getRequestHeader()
          .subscribe(apiresponse => {
            this.global.loadUserSession(apiresponse.data.user);
            this.commonApiService.setToken(this.global.loggedUser.token);
            this.attendanceService.setToken(this.global.loggedUser.token);
            this.global.isMobileApp = true;
            this.initialize();
          }));
    } else {
      this.initialize();
    }
  }
  initialize() {
    if (this.intializeData && this.intializeData.role) {
      this.role = this.intializeData.role;
    }
    this.fromDate = new Date(new Date().setDate(new Date().getDate() - 5));
    this.toDate = new Date(new Date().setDate(new Date().getDate() + 2));
    this.hasHrRole = this.global.loggedUser.roles.filter(d => d.role_name === 'HR-Manager').length > 0;
    this.hasManagerRole = this.global.loggedUser.roles.filter(d => d.role_name === 'Manager').length > 0;
    this.hasAdminRole = this.global.loggedUser.access.filter(a => a.module_name === 'Admin').length > 0;
    this.showAddPermissionFor = this.global.loggedUser.manager_leave_permission_add === 1;
    let employeeid;
    if (this.role !== 'approver') {
      employeeid = this.global.loggedUser.user_type_internal_id;
    }
    this.subscriptions.push(
      forkJoin(
        {
          master: this.commonApiService.getMasterData('permission_status'),
          request: this.attendanceService.getPermissionRequest
            (
              this.role !== 'approver' ? null : employeeid,
              this.fromDate,
              this.toDate,
              this.role !== 'approver' ? false : true,
              null,
              null,
              null,
              null,
              this.role !== 'approver' ? null : this.permissionStatusId
            ),
          utility: this.commonApiService.getUtilityData('reporting_employee_name'),
        })
        .pipe(indicate(this.loading$))
        .subscribe(apiresponse => {
          this.permissionStatusList = apiresponse.master.data;
          this.permissionList = apiresponse.request.data;
          this.employeeNames = apiresponse.utility.data;
          this.employeeNameList = apiresponse.utility.data.reduce((a, o) => (a.push(o.value), a), []);
        }));
    if (this.role !== 'approver') {
      this.subscriptions.push(
        this.attendanceService.getPermissionSummary(this.global.loggedUser.user_type_internal_id)
          .pipe(indicate(this.summaryloading$))
          .subscribe(apiresponse => {
            this.permissionSummary = apiresponse.data[0];
          }));
    }
    //this.starttime
  }
  refresh() {
    this.ngOnInit();
  }
  openModel() {
    this.isNew = true;
    this.clearModel();
    this.permission.employee_id = this.global.loggedUser.user_type_internal_id;
    this.modalService.open(this.permissioncontent, { size: 'lg' });
  }
  clearModel() {
    this.permission = new AttendancePermission();
  }
  fromdateChanged(e: any) {
    if (e.event !== undefined) {
      this.toDate = null;
    }
  }
  searchPermission() {
    if (this.role !== 'approver') {
      this.employeeId = this.global.loggedUser.user_type_internal_id;
      this.permissionStatusId = null;
    }
    this.subscriptions.push(
      this.attendanceService.getPermissionRequest
        (
          this.role !== 'approver' ? this.employeeId : null,
          this.fromDate,
          this.toDate,
          this.hasAdminRole,
          null,
          null,
          null,
          null,
          this.permissionStatusId
        )
        .pipe(indicate(this.loading$))
        .subscribe(apiresponse => {
          this.permissionList = apiresponse.data;
        }));
  }
  editPermission(e: AttendancePermission) {
    this.isNew = false;
    this.permission = e;
    this.permission.employee_id = this.global.loggedUser.user_type_internal_id;
    this.modalService.open(this.permissioncontent, { size: 'lg' });
  }
  changeStatus(e: AttendancePermission, status: string) {
    const per = e;
    const st = status === 'Approved' ? 'approve' : status === 'Cancelled' ? 'Cancel' : 'decline';
    confirm('Are you sure, you want to ' + st + ' ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        const stat: KeyValue<number, string> = this.permissionStatusList.find(d => d.value === status);
        if (stat) {
          per.permission_status_id = stat.key;
          this.subscriptions.push(
            this.attendanceService.postPermissionRequest(per)
              .subscribe(apiresponse => {
                this.toastService.success('Permission Request ' + status + ' successfully');
                this.searchPermission();
              }));
        }
      }
    });
  }
  savePermission(frm: { valid: any; reset: () => void; }) {
    if (frm.valid) {
      const cdt: Date = new Date();
      const edt: Date = new Date(this.permission.permission_to_time);
      const sdt: Date = new Date(this.permission.permission_from_time);
      edt.setFullYear(cdt.getFullYear());
      edt.setMonth(cdt.getMonth());
      edt.setDate(cdt.getDate());
      sdt.setFullYear(cdt.getFullYear());
      sdt.setMonth(cdt.getMonth());
      sdt.setDate(cdt.getDate());
      this.permission.permission_from_time = sdt;
      this.permission.permission_to_time = edt;
      this.isPermissionSaved = false;
      this.subscriptions.push(
        this.attendanceService.postPermissionRequest(this.permission)
          .subscribe(apiresponse => {
            this.permission = new AttendancePermission();
            if (this.isNew) {
              this.toastService.success('Permission Request submitted successfully');
            } else {
              this.toastService.success('Permission Request updated successfully');
            }
            frm.reset();
            this.modalService.dismissAll();
            this.searchPermission();
            this.isPermissionSaved = true;
          }));
    }
  }
  fromtimeChanged(e: any) {
    if
      (
      this.isPermissionSaved && e !== undefined &&
      this.permission.permission_from_time &&
      this.permission.permission_to_time &&
      e.previousValue != null
    ) {
      const fdate = new Date(this.permission.permission_from_time);
      const tdate = new Date(this.permission.permission_to_time);
      let diff = (tdate.getTime() - fdate.getTime()) / 1000;
      diff /= (60 * 60);
      this.permission.no_of_hours = null;
      this.permission.permission_to_time = null;
    }
  }

  openApplyPermission() {
    this.isNew = true;
    this.clearModel();
    this.modalService.open(this.permissioncontent, { size: 'lg' });
  }
  totimeChanged(e: any) {
    if (this.isPermissionSaved) {
      if (e !== undefined && this.permission.permission_from_time && this.permission.permission_to_time) {
        const fdate = new Date(this.permission.permission_from_time);
        const tdate = new Date(this.permission.permission_to_time);
        let diff = (tdate.getTime() - fdate.getTime()) / 1000;
        diff /= (60 * 60);
        this.permission.no_of_hours = diff;
      }
    }
    this.isPermissionSaved = true;
  }
  selectName(e) {
    const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
    if (emp) {
      this.employeeId = emp.key;
    } else {
      this.employeeId = null;
    }
  }
  selectPermissionFor(e) {
    if (this.employeeNames) {
      const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
      if (emp) {
        this.permission.employee_id = emp.key;
      } else {
        this.permission.employee_id = null;
      }
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
