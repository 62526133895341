<ng-template #profilepicturemodalcontent let-c="close" let-d="dismiss">
    <form #uploadForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Upload Profile Picture</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="upload">Select a File to upload <span class="text-danger">*</span></label>
                    <input type="file" id="picFile" class="form-control" (change)="onFileChange($event)" />
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <label>Preview</label>
                    <br>
                    <h2 class="table-avatar">
                        <ngx-avatar size="100" [src]="imageSrc"
                            [name]="employee.first_name + ' ' + employee.last_name">
                        </ngx-avatar>
                    </h2>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="uploadDocument(uploadForm)"
                [disabled]="!uploadForm.valid || !file">Upload</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>