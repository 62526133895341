import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { Global } from '../shared/global';
import { ApiResponse } from '../model/common';
import { Resumeupload } from '../model/admin';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {

  base_path: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'x-access-token': ''
    })
  };
  constructor(private http: HttpClient, private datePipe: DatePipe, public global: Global) {
    this.base_path = global.adminApiEndPoint;
  }
  setToken(token: string) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'x-access-token': token
      })
    };
  }
  uploadProfilePicture(file: File, userid: string) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('user_id', userid);
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-profile-picture', formData, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }

  uploadresume(resumeUpload: Resumeupload, file: File, user_id: string, recurverId: string) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('user_id', user_id);
    formData.append('recurver_id', recurverId);
    
    return this.http
      .post<ApiResponse>(this.base_path + '/recurver-profile-picture', formData, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  resetPassword(user: any, isresetrequired = false) {
    return this.http
      .post<ApiResponse>(`${this.base_path}/employee-reset-password?isresetrequired=${isresetrequired}`, user, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeWorkHistory(employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-work-history?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),

        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeComments(employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-comment?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeKpi(employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-kpi?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getHrDashboardReport(designationId: number, departmentId: number, salaryyear: number, locationId: number) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(`${this.base_path}/hr-dashboard-report?designationid=${designationId}&departmentid=${departmentId}&salaryyear=${salaryyear}&locationid=${locationId}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeDocuments(employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-document?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }

  getEmployeeDetails( employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-details?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }

  //performance api
  getPerformanceCycle(employeeid: number = null,year:number) {
    return this.http.get<ApiResponse>(`${this.base_path}/performance-cycle?employeeid=${employeeid}&year=${year}`, this.httpOptions).pipe(
      retry(2),
      catchError(err => {
        this.global.showError(err);
        throw err;
      })
    )
  }
  getSurveyList(){
    return this.http.get<ApiResponse>(`${this.base_path}/survey`,this.httpOptions).pipe(
      retry(2),
      catchError(err => {
        this.global.showError(err);
        throw err;
      })
    )
  }
  getSurveyReport(surveyid:number){
    return this.http.get<ApiResponse>(`${this.base_path}/surveyreport?suveryid=${surveyid}`,this.httpOptions).pipe(
      retry(2),
      catchError(err => {
        this.global.showError(err);
        throw err;
      })
    )
  }
  getShift() {
    return this.http
      .get<ApiResponse>(this.base_path + '/shift', this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getLocation(id: number = null) {
    return this.http
      .get<ApiResponse>(this.base_path + '/location?locationid=' + id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getRelease(employeeId: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-release/' + employeeId, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }



  getReleaseRecurver(recurver_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/recurver-release/' + recurver_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }

  getAsset(assetId: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/asset/' + assetId, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getAssetList(employeeId: number, fromDate: Date = null, toDate: Date = null, statusId: number = null) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(this.base_path + '/asset?employeeid=' + employeeId + '&fromdate=' + this.datePipe.transform(fromDate, 'yyyy-MM-dd') + '&todate=' + this.datePipe.transform(toDate, 'yyyy-MM-dd') + '&statusid=' + statusId, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getAssetRequestList(employeeId: number, isAdmin: boolean = false) {
    return this.http
      .get<ApiResponse>(this.base_path + '/asset-request?employeeid=' + employeeId + '&isadmin=' + isAdmin, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployee(employeeId: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee/' + employeeId, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeList(
    employeeId: number,
    designationId: number,
    departmentId: number,
    statusId: number,
    isAltLogin: boolean = false,
    locationId: number = null,
    dateSearchType: string = null,
    fromDate: Date = null,
    toDate: Date = null
  ) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(`${this.base_path}/employee?employeeid=${employeeId}&designationid=${designationId}&employeestatusid=${statusId}&departmentid=${departmentId}&altlogin=${isAltLogin}&locationid=${locationId}&datesearchtype=${dateSearchType}&fromdate=${this.datePipe.transform(fromDate, 'yyyy-MM-dd')}&todate=${this.datePipe.transform(toDate, 'yyyy-MM-dd')}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }

  // getRecurverList(
  //   recurver_id: number,
  //   designationId: number,
  //   statusId: number,
  //   first_name: string,
  //   last_name: string,
  //   recurver_name:string,
  //   qualification: string,
  //   gender: string,
  //   locationId: number = null,
  //    searchFromDate: Date,
  //    searchToDate: Date,
  //    dateSearchType: string = null,
  //   fromDate: Date = null,
  //  toDate: Date = null
  // ) {
  //   return this.http
  //     // tslint:disable-next-line:max-line-length
  //     .get<ApiResponse>(`${this.base_path}/recurver?recurverid=${recurver_id}&designationid=${designationId}&statusId=${statusId}&first_name=${first_name}&last_name=${last_name}&recurver_name=${recurver_name}&qualification=${qualification}&gender=${gender}&locationid=${locationId}&datesearchtype=${dateSearchType}`, this.httpOptions)
  //     .pipe(
  //       retry(2),
  //       catchError(err => {
  //         this.global.showError(err);
  //         throw err;
  //       })
  //     );
  // }

  getRecurverList(
    recurver_id: number,
    designationId: number,
    company_id:number,
    statusId: number,
    first_name: string,
    last_name: string,
    recurver_name:string,
    qualification: string,
    gender: string,
    locationId: number = null,
    dateSearchType: string = null,
    fromDate: Date = null,
    toDate: Date = null
  ) {
    // Create a query string with optional parameters
    let queryParams = `recurver_id=${recurver_id}&designation_id=${designationId}&company_id=${company_id}&recurver_status_id=${statusId}&first_name=${first_name}&last_name=${last_name}&recurver_name=${recurver_name}&qualification=${qualification}&gender=${gender}&locationid=${locationId}`;
  
    if (dateSearchType) {
      queryParams += `&datesearchtype=${dateSearchType}`;
    }
  
    if (fromDate) {
      queryParams += `&fromdate=${this.datePipe.transform(fromDate, 'yyyy-MM-dd')}`;
    }
  
    if (toDate) {
      queryParams += `&todate=${this.datePipe.transform(toDate, 'yyyy-MM-dd')}`;
    }
  
    return this.http
      .get<ApiResponse>(`${this.base_path}/recurver?${queryParams}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  
  deleteEmployeeDocument( documentId: number) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .delete<ApiResponse>(this.base_path + '/employee-document?employeedocumentid=' + documentId , this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  uploadEmployeeDocument(file: File, documentTypeId: number, employeeId: number) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      // tslint:disable-next-line:max-line-length
      .post<ApiResponse>(this.base_path + '/employee-document-upload?documenttype=' + documentTypeId + '&employeeid=' + employeeId, formData, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  changeStatusEmployee(satusId: number, employeeId: number) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .post<ApiResponse>(this.base_path + '/employee-status', { employee_status_id: satusId, employee_id: employeeId }, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }

  // changeStatusRecurver(recurver_status_id: number, recurver_id: number) {
  //   return this.http
  //     // tslint:disable-next-line:max-line-length
  //     .post<ApiResponse>(this.base_path + '/recurver-status', { recurver_status_id: recurver_status_id, recurver_id: recurver_id }, this.httpOptions)
  //     .pipe(
  //       retry(2),
  //       catchError(err => {
  //         this.global.showError(err);
  //         throw err;
  //       })
  //     );
  // }

  changeStatusRecurver(recurver_status_id: number, recurver_id: number) {
    console.log('Calling API to change status:', recurver_status_id, recurver_id);
    return this.http
      .post<ApiResponse>(this.base_path + '/recurver-status', 
        { recurver_status_id: recurver_status_id, recurver_id: recurver_id}, 
        this.httpOptions
      )
      .pipe(
        retry(2),
        catchError(err => {
          console.error('API Error:', err);
          this.global.showError(err);
          throw err;
        })
      );
  }
  

// ///new
//   postRecurver(recurver: Recurver) {
//     return this.http
//       .post<ApiResponse>(`${this.base_path}/recurver`, recurver, this.httpOptions)
//       .pipe(
//         retry(2),
//         catchError(err => {
//           this.global.showError(err);
//           throw err;
//         })
//       );
//   }

  getRecurver(recurverId: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/recurver/' + recurverId, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }

//   deleteRecurver(recurverId: number) {
//     return this.http
//       .delete<ApiResponse>(this.base_path + '/recurver/' + recurverId, this.httpOptions)
//       .pipe(
//         retry(2),
//         catchError(err => {
//           this.global.showError(err);
//           throw err;
//         })
//       );
//   }







  postWorkHistory(h: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-work-history', h, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postEmployeeKpi(s: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-kpi', s, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postEmployeeComment(s: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-comment', s, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  releaseEmployee(employeeRelease: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-release', employeeRelease, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  

  releaseRecurver(recurverRelease: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/recurver-release', recurverRelease, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }

  assignAsset(input: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/assign-asset', input, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postShift(shift: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/shift', shift, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postLocation(location: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/location', location, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postEmployee(employee: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee', employee, this.httpOptions)
      .pipe(
        retry(0),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postRecurver(recurver: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/recurver', recurver, this.httpOptions)
      .pipe(
        retry(0),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }



  postAssetRequest(assetReq: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/asset-request', assetReq, this.httpOptions)
      .pipe(
        retry(0),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postRequestStatus(req: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/asset-request-status', req, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postAssetService(service: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/asset-service', service, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postAssetStatus(input: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/asset-status', input, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postAsset(asset: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/asset', asset, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postEmployeeBankInfo(employee: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-bank-info', employee, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postEmployeePersonalInfo(employee_id: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-personal', employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postEmployeePerformance(activeCycle: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-performance-status' , activeCycle, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postEmployeeCustomFields(customFields: any) {
    return this.http
    .post<ApiResponse>(this.base_path + '/employee-custom-field', customFields, this.httpOptions)
    .pipe(
      retry(2),
      catchError(err => {
        this.global.showError(err);
        throw err;
      })
    );
  }
  deleteAssetService(asset_service_id: number) {
    return this.http
      .delete<ApiResponse>(this.base_path + '/asset-service/' + asset_service_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  deleteWorkHistory(employee_work_history_id: any) {
    return this.http
      .delete<ApiResponse>(this.base_path + '/employee-work-history?employee_work_history_id=' + employee_work_history_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  deleteEmployeeKpi(employee_kpi_id: number) {
    return this.http
      .delete<ApiResponse>(this.base_path + '/employee-kpi?employeekpiid=' + employee_kpi_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  deleteEmployeeComment(employee_comment_id: any) {
    return this.http
      .delete<ApiResponse>(this.base_path + '/employee-comment?employeecommentid=' + employee_comment_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeDocumentUrl(id: number, attendanceactivityid: number = null): string {
    return `${this.base_path}/view-employee-document?documentid=${id}&attendanceactivityid=${attendanceactivityid}&token=${this.global.loggedUser.token}`;
  }
}
