import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Employee } from 'src/app/model/admin';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-profile-picture-upload',
  templateUrl: './profile-picture-upload.component.html',
  styleUrls: ['./profile-picture-upload.component.scss']
})
export class ProfilePictureUploadComponent implements OnInit, OnDestroy {
  @Input() employee: Employee = new Employee();
  file: File = null;
  imageSrc: string | ArrayBuffer;
  subscriptions: Subscription[] = [];
  @ViewChild('profilepicturemodalcontent', { static: true }) profilepicturemodalcontent: ElementRef;

  constructor(
    private adminApiService: AdminApiService,
    private toastService: ToastService,
    private modalService: NgbModal) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  ngOnInit(): void {
  }
  openModal(employee: Employee = null) {
    if (employee) {
      this.employee = employee;
    }
    this.modalService.open(this.profilepicturemodalcontent, { size: 'lg' });
  }
  onFileChange(e) {
    if (e.target.files && e.target.files[0]) {
      this.file = e.target.files[0];
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = ea => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }
  uploadDocument(frm) {
    if (frm.valid) {
      this.toastService.success('Profile Picture uploaded successfully');
       //this.adminApiService.uploadProfilePicture(this.file)
      //   .subscribe(apiresponse => {
      //     debugger;
      //     this.toastService.success('Profile Picture uploaded successfully');
      //   });
    }
  }
}
