import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Employee, EmployeeRelease, Recurver, RecurverRelease } from 'src/app/model/admin';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { Global } from 'src/app/shared/global';



@Component({
  selector: 'app-offer-letter',
  templateUrl: './offer-letter.component.html',
  styleUrls: ['./offer-letter.component.scss']
})


export class OfferLetterComponent implements OnInit {

  public adminApiService: AdminApiService
  public global: Global
  printContent: ElementRef;
  recurver: Recurver = new Recurver();
  subscriptions: Subscription[] = [];
  recurverTermination: RecurverRelease = new RecurverRelease();


  @Input() offerLetter: any = {};
  @Input() employee: Employee = new Employee();


  
  constructor() { }

  ngOnInit(): void { }

     // Optionally, clean up subscriptions
   ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  print() {
    this.global.print(this.printContent.nativeElement.innerHTML);
  }
}