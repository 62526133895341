import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from './shared/global';
import { Tab } from './model/tab';
import { TabService } from './services/tab.service';
import { UserProfileComponent } from './components/common/user-profile/user-profile.component';
import { CommonApiService } from './services/common-api.service';
import { CompanyStyle, NotificationMessage } from './model/common';
import { NotificationComponent } from './components/common/notification/notification.component';
import { Location } from '@angular/common';
import { AttendanceApiService } from './services/attendance-api.service';
import { PayrollApiService } from './services/payroll-api.service';
import { AdminApiService } from './services/admin-api.service';
import { Title } from '@angular/platform-browser';
import { ChangePasswordComponent } from './components/common/change-password/change-password.component';
import { LeaveViewComponent } from './components/attendance/leave-view/leave-view.component';
import { LeaveAdminViewComponent } from './components/attendance/leave-admin-view/leave-admin-view.component';
import { AttendancePunchComponent } from './components/attendance/attendance-punch/attendance-punch.component';
import { PermissionViewComponent } from './components/attendance/permission-view/permission-view.component';
import { PayslipViewComponent } from './components/payroll/payslip-view/payslip-view.component';
import { AssetRequestComponent } from './components/admin/asset-request/asset-request.component';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  loading = false;
  showPasswordreset = false;
  notificationList: NotificationMessage[] = [];
  companyStyle: CompanyStyle = new CompanyStyle();
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  hasHrRole = false;
  hasEmpRole = false;
  hasManagerRole = false;
  subscriptions: Subscription[] = [];
  params: any;
  mobileAppUrl: string[] = ['/leave', '/permission', '/leaveadmin', '/permissionadmin', '/payslip'];
  @ViewChild('changePassword', { read: ChangePasswordComponent }) changePassword: ChangePasswordComponent;
  constructor(
    private titleService: Title,
    private location: Location,
    private router: Router,
    private tabService: TabService,
    public global: Global,
    private commonApiService: CommonApiService,
    private adminApiService: AdminApiService,
    private payrollApiService: PayrollApiService,
    private attendanceApiService: AttendanceApiService) {
    let siteurl = window.location.host;
    this.params = (new URL(window.location.href.replace('#', ''))).searchParams;
    // let url = 'hrm.krishivinfotech.com'
    siteurl = siteurl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
    const companyid = this.params.get('company');

    // if (url === '/leave') {
    //   this.subscriptions.push(
    //     this.commonApiService.getRequestHeader()
    //       .subscribe(apiresponse => {
    //         this.global.loadUserSession(apiresponse.data.user);
    //         this.commonApiService.setToken(this.global.loggedUser.token);
    //         this.apiService.setToken(this.global.loggedUser.token);
    //         this.global.isMobileApp = true;
    //       }));
    // }
    this.subscriptions.push(
      this.commonApiService
        .getCompanyStyle(siteurl, companyid)
        .subscribe(apiresponse => {
          this.companyStyle = apiresponse.data[0];
          this.favIcon.href = this.companyStyle.fav_ico_path;
          this.titleService.setTitle(this.companyStyle.site_title);
          this.global.companyLogo = this.companyStyle.company_logo;
          this.global.companyId = this.companyStyle.company_id;
          this.companyStyle.styles.forEach(element => {
            document.documentElement.style.setProperty(element.style_name, element.style_value);
          });
          if (this.global.isMobile) {
            document.documentElement.style.setProperty('--tab-content-top-margin', 'none');
          }
          const url = this.location.path();
          if(url.indexOf('survey') != -1) {
            this.global.landingScreen = 'survey';
            this.global.surveyId = this.params.get('surveyid');
          }
          else if (url !== '/login' && url !== '/userlogin' && this.mobileAppUrl.indexOf(url) == -1) {
            this.router.navigate(['/login']);
          }
          if (this.mobileAppUrl.indexOf(url) !== -1){
            this.global.isMobileApp = true;
          }
        }));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  hasAccess(module: string) {
    return this.global.loggedUser.access.filter(a => a.module_name === module).length > 0;
  }
  loadProfileScreen() {
    this.tabService.addTab(new Tab(UserProfileComponent, this.global.loggedUser.name));
  }
  loadPasswordreset() {
    this.changePassword.openModal();
  }
  loadAttendance() {
    this.tabService.removeAllTab();
    this.tabService.addTab(new Tab(AttendancePunchComponent, 'Manual Attendance'));
  }
  loadLeave() {
    this.tabService.removeAllTab();
    this.tabService.addTab(new Tab(LeaveViewComponent, 'Leave'));
  }
  loadLeaveAdmin() {
    this.tabService.removeAllTab();
    this.tabService.addTab(new Tab(LeaveAdminViewComponent, 'Manage Leave'));
  }
  loadPermissionAdmin() {
    this.tabService.removeAllTab();
    this.tabService.addTab(new Tab(PermissionViewComponent, 'Manage Permission', { role: 'approver' }));
  }
  loadPermission() {
    this.tabService.removeAllTab();
    this.tabService.addTab(new Tab(PermissionViewComponent, 'Request Permission'));
  }
  loadPayslip() {
    this.tabService.removeAllTab();
    this.tabService.addTab(new Tab(PayslipViewComponent, 'Pay Slip'));
  }
  refreshNotification() {
    this.commonApiService.getUnreadNotification(this.global.loggedUser.user_id)
      .subscribe(apiresponse => {
        this.notificationList = apiresponse.data;
      });
  }
  loadHome() {
    this.refreshNotification();
    this.router.navigate(['/home']);
    this.hasHrRole = this.global.loggedUser.roles.filter(d => d.role_name === 'HR-Manager').length > 0;
    this.hasEmpRole = this.global.loggedUser.roles.filter(d => d.role_name === 'Employee').length > 0;
    this.hasManagerRole = this.global.loggedUser.roles.filter(d => d.role_name === 'Manager').length > 0;
  }
  loadNotificationScreen() {
    this.tabService.addTab(new Tab(NotificationComponent, 'Notifications'));
  }
  markAllAsRead() {
    this.commonApiService.markAsReadNotification()
      .subscribe(apiresponse => {
        this.refreshNotification();
      });
  }
  markAsRead(notification: NotificationMessage) {
    const n: NotificationMessage = new NotificationMessage();
    n.is_read = true;
    n.notification_id = notification.notification_id;
    this.commonApiService.postNotification(n)
      .subscribe(apiresponse => {
        this.refreshNotification();
      });
  }
  openWindow(notification: NotificationMessage) {
    this.markAsRead(notification);
    switch (notification.notification_type_id) {
      case 193: {
        this.tabService.addTab(new Tab(LeaveAdminViewComponent, 'Manage Leave'));
        break;
      }
      case 194: {
        this.tabService.addTab(new Tab(LeaveViewComponent, 'Leave'));
        break;
      }
      case 195: {
        this.tabService.addTab(new Tab(AssetRequestComponent, 'Manage Asset Request', { role: 'approver' }));
        break;
      }
      case 199: {
        this.tabService.addTab(new Tab(AssetRequestComponent, 'Asset Request'));
        break;
      }
      case 200: {
        this.tabService.addTab(new Tab(PermissionViewComponent, 'Manage Permission', { role: 'approver' }));
        break;
      }
      case 201: {
        this.tabService.addTab(new Tab(PermissionViewComponent, 'Request Permission'));
        break;
      }
    }
  }
  logout() {
    this.commonApiService.setToken('');
    this.adminApiService.setToken('');
    this.payrollApiService.setToken('');
    this.attendanceApiService.setToken('');
    this.tabService.removeAllTab();
    this.global.clear();
    this.router.navigate(['/login']);
  }
}
