import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, AfterViewInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { User } from 'src/app/model/common';
import { AppComponent } from 'src/app/app.component';
import { Global } from 'src/app/shared/global';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Location } from '@angular/common';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { PayrollApiService } from 'src/app/services/payroll-api.service';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/services/toast.service';
import { Subject, Subscription } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';
import { concatMap } from 'rxjs/operators';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  user: User = new User();
  userNameForgotPassword: string;
  @ViewChild('uname', { read: ElementRef }) uname: ElementRef;
  @ViewChild('changePassword', { read: ChangePasswordComponent }) changePassword: ChangePasswordComponent;
  @ViewChild('forgotpasscontent', { static: true }) forgotpasscontent: ElementRef;
  loading$ = new Subject<boolean>();
  subscriptions: Subscription[] = [];
  constructor(
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    public myapp: AppComponent,
    public global: Global,
    private commonApiService: CommonApiService,
    private location: Location,
    private adminApiService: AdminApiService,
    private payrollApiService: PayrollApiService,
    private attendanceApiService: AttendanceApiService,
    private toastService: ToastService) { }
  ngOnInit(): void {
    //this.user.user_name = 'MOHAMEDABDULKADER';
    //this.user.password = 'Test@123';
  }
  ngAfterViewInit() {
    this.global.loggedUser = undefined;
    this.uname.nativeElement.focus();
    // this returns null
  }

  openForgotPassword() {
    this.userNameForgotPassword = '';
    this.modalService.open(this.forgotpasscontent, { size: 'lg' });
  }
  forgotPassword(frm) {
    if (frm.valid) {
      this.subscriptions.push(
        this.commonApiService.forgotPassword(this.userNameForgotPassword)
          .subscribe(apiresponse => {
            this.toastService.success('New password sent to email address successfully.');
            this.modalService.dismissAll();
          }));
    }
  }
  login(frm): void {
    if (frm.valid) {
      const url = this.location.path();
      if (url === '/userlogin') {
        this.global.isParentCompany = true;
        this.global.isAltLogin = true;
      } else {
        this.global.isParentCompany = true;
        this.global.isAltLogin = false;
      }
      this.user.is_parent_company = this.global.isParentCompany;
      this.user.company_id = this.global.companyId;
      let token: string;
      this.subscriptions.push(
        this.commonApiService.login(this.user)
          .pipe(indicate(this.loading$))
          .pipe(
            concatMap(apiresponse => {
              this.commonApiService.setToken(apiresponse.data.token);
              this.adminApiService.setToken(apiresponse.data.token);
              this.payrollApiService.setToken(apiresponse.data.token);
              this.attendanceApiService.setToken(apiresponse.data.token);
              token = apiresponse.data.token;
              return this.commonApiService.getUser(this.global.isParentCompany);
            })
          ).subscribe(
            apiresponse => {
              this.global.loggedUser = apiresponse.data;
              this.global.loggedUser.token = token;
              if (this.global.loggedUser.is_password_reset !== true && this.global.loggedUser.is_password_reset !== 1) {
                this.global.loggedUser.show_header = true;
                this.myapp.loadHome();
                frm.reset();
              } else {
                this.global.loggedUser.password = null;
                this.cd.detectChanges();
                this.changePassword.openModal();
              }
            }
          ));
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
