<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Assets</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
                <button class="btn add-btn" (click)="openModel(assetmodalcontent)"><i class="fa fa-plus"></i> Add
                    Asset</button>
            </div>
        </div>
    </div>
    <div class="row filter-row">
        <div class="col-sm-6 col-md-3">
            <dx-autocomplete class="form-control filter-control" id="employeeName" placeholder="Type Employee Name..."
                (onSelectionChanged)="selectName($event)" [dataSource]="employeeNameList" [showClearButton]="true">
            </dx-autocomplete>
        </div>
        <div class="col-sm-6 col-md-3">
            <select class="form-control filter-control" id="status" name="status" [(ngModel)]="statusId" [ngClass]="{'loading-control': (loading$ | async) === true }">
                <option [ngValue]="undefined" selected>Select Status</option>
                <option *ngFor="let item of statusList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-2">
            <dx-date-box [(value)]="fromDate" type="date" class="form-control filter-control" placeholder="From Date">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-2">
            <dx-date-box [(value)]="toDate" type="date" class="form-control filter-control" placeholder="To Date">
            </dx-date-box>
        </div>
        <div class="col-sm-6 col-md-2">
            <button class="btn btn-secondary btn-block" (click)="searchAsset()"> Search </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-3 table-responsive" id="asset-list-grid-loading">
            <dx-data-grid #gridEmployee id="gridAsset" [dataSource]="assetList" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false">
                <dxo-export [enabled]="true" fileName="assetReport"></dxo-export>
                <dxo-paging [pageSize]="10"></dxo-paging>
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
                <dxi-column dataField="employee_name" [allowFiltering]="false" [allowSorting]="false"
                    cellTemplate="imageTemplate" caption="Employee" width="250" [allowGrouping]="true"></dxi-column>
                <dxi-column dataField="employee_code" caption="Employee code" width="120" dataType="string" [allowGrouping]="true"></dxi-column>
                <dxi-column dataField="asset_type" caption="Type" dataType="string" [allowGrouping]="true"></dxi-column>
                <dxi-column dataField="asset_name" caption="Asset Name" dataType="string" [allowGrouping]="false">
                </dxi-column>
                <dxi-column dataField="asset_code" caption="Asset Code" dataType="string" [allowGrouping]="false">
                </dxi-column>
                <dxi-column dataField="purchase_date" caption="Purchase Date" cellTemplate="dateTemplate"
                    [allowGrouping]="false"></dxi-column>
                <dxi-column dataField="asset_value" caption="Amount" [allowGrouping]="false"></dxi-column>
                <dxi-column dataField="asset_condition" caption="Condition" dataType="string" [allowGrouping]="false">
                </dxi-column>
                <dxi-column dataField="asset_ownership_type" caption="Ownership Type" dataType="string"
                    [allowGrouping]="true"></dxi-column>
                <dxi-column dataField="asset_status" caption="Satus" [allowFiltering]="false" [allowSorting]="false"
                    cellTemplate="statusTemplate" alignment="center" [allowGrouping]="true"></dxi-column>
                <dxi-column dataField="asset_id" [allowFiltering]="false" width=50 caption="" [allowSorting]="false"
                    cellTemplate="actionTemplate" alignment="center" [allowGrouping]="false">
                </dxi-column>
                <div *dxTemplate="let data of 'dateTemplate'">
                    {{data.value | date}}
                </div>
                <div *dxTemplate="let data of 'imageTemplate'">
                    <h2 class="table-avatar"
                        *ngIf="data.row.data.employee_name!==null && data.row.data.employee_name!==''">
                        <ngx-avatar size="40" [src]="data.row.data.profile_picture"
                            [name]="data.row.data.employee_name">
                        </ngx-avatar>
                        <a class="ml-1 mt-1"> {{data.row.data.employee_name}}<span>{{data.row.data.designation}}
                            </span></a>
                    </h2>
                </div>
                <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Active'">
                        <i class="fa fa-dot-circle-o text-success"></i> Active
                    </a>
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Scrap'">
                        <i class="fa fa-dot-circle-o text-warning"></i> Scrap
                    </a>
                    <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                        *ngIf="cellInfo.value=='Resale'">
                        <i class="fa fa-dot-circle-o text-danger"></i> Resale
                    </a>
                </div>
                <div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
                    <div ngbDropdown container="body">
                        <a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle><i
                                class="material-icons">more_vert</i></a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                            <a *ngIf="cellInfo.row.data.asset_status=='Active'" class="dropdown-item"
                                href="javascript:void(0);" (click)="assignAssetModal(cellInfo)"><i
                                    class="fa fa-user m-r-5"></i> Assign</a>
                            <a *ngIf="cellInfo.row.data.asset_status=='Active'" class="dropdown-item"
                                href="javascript:void(0);" (click)="resaleAsset(cellInfo)"><i
                                    class="fa fa-recycle m-r-5"></i> Resale</a>
                            <a *ngIf="cellInfo.row.data.asset_status=='Active'" class="dropdown-item"
                                href="javascript:void(0);" (click)="scrapAsset(cellInfo)"><i
                                    class="fa fa-trash m-r-5"></i> Scrap</a>
                            <a *ngIf="cellInfo.row.data.asset_status==='Active'" class="dropdown-item"
                                href="javascript:void(0);" (click)="activeAsset(cellInfo)"><i
                                    class="fa fa-arrow-up m-r-5"></i> Return</a>
                            <a *ngIf="cellInfo.row.data.asset_status==='Scrap' || cellInfo.row.data.asset_status==='Resale'"
                                class="dropdown-item" href="javascript:void(0);" (click)="activeAsset(cellInfo)"><i
                                    class="fa fa-arrow-up m-r-5"></i> Active</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="editAsset(cellInfo)"><i
                                    class="fa fa-pencil m-r-5"></i> Edit</a>
                            <a class="dropdown-item" href="javascript:void(0);" (click)="viewAsset(cellInfo)"><i
                                    class="fa fa-search m-r-5"></i> View</a>
                        </div>
                    </div>
                </div>
            </dx-data-grid>
        </div>
    </div>
</div>
<!-- Modal window contents -->
<ng-template #assetmodalcontent let-c="close" let-d="dismiss">
    <form #assetForm="ngForm">
        <div class="modal-header">
            <div *ngIf="isNew;else edit_content">
                <h4 class="modal-title" id="modal-basic-title">Add Asset</h4>
            </div>
            <ng-template #edit_content>
                <h4 class="modal-title" id="modal-basic-title">Edit Asset</h4>
            </ng-template>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="aname">Asset Name <span class="text-danger">*</span></label>
                    <input class="form-control" [(ngModel)]="asset.asset_name" required type="text"
                        id="aname" name="aname" #aname="ngModel" [pattern]="global.alphaNumericValidator">
                    <div *ngIf="aname.invalid && (aname.dirty || aname.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="aname.errors.required">
                            Asset Name is required.
                        </div>
                        <div *ngIf="aname.errors.pattern">
                            Asset Name is invalid.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="acode">Asset Code <span class="text-danger">*</span></label>
                    <input class="form-control" [(ngModel)]="asset.asset_code" required type="text"
                        id="acode" name="acode" #acode="ngModel" [pattern]="global.alphaNumericValidator"
                       readonly>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="condition">Asset Condition</label>
                    <select class="form-control" id="condition" name="condition"
                        [(ngModel)]="asset.asset_condition_id">
                        <option [ngValue]="null" selected>Select Asset Condition</option>
                        <option *ngFor="let item of conditionList; let i = index" [ngValue]="item.key">
                            {{item.value}}</option>
                    </select>
                </div>
                <div class="col-sm-6">
                    <label for="type">Asset Type <span class="text-danger">*</span></label>
                    <select class="form-control" id="type" name="type" [(ngModel)]="asset.asset_type_id"
                        required #type="ngModel">
                        <option [ngValue]="null" selected>Select Asset Type</option>
                        <option *ngFor="let item of typeList; let i = index" [ngValue]="item.key">
                            {{item.value}}</option>
                    </select>
                    <div *ngIf="type.invalid && (type.dirty || type.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="type.errors.required">
                            Asset Type is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="otype">Asset Ownership Type</label>
                    <select class="form-control" id="otype" name="otype"
                        [(ngModel)]="asset.asset_ownership_type_id">
                        <option [ngValue]="null" selected>Select Asset ownership Type</option>
                        <option *ngFor="let item of ownershiptypeList; let i = index" [ngValue]="item.key">
                            {{item.value}}</option>
                    </select>
                </div>
                <div class="col-sm-6">
                    <label for="manufacturer">Manufacturer</label>
                    <input class="form-control" [(ngModel)]="asset.manufacturer" type="text"
                        id="manufacturer" name="manufacturer">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="pdate">Purchase Date <span class="text-danger">*</span></label>
                    <dx-date-box [(ngModel)]="asset.purchase_date" name="pdate" id="pdate" #pdate="ngModel" required
                        type="date" displayFormat="dd/MM/yyyy" dateSerializationFormat="yyyy-MM-dd">
                    </dx-date-box>
                    <div *ngIf="pdate.invalid && (pdate.dirty || pdate.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="pdate.errors.required">
                            Puchase Date is required.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="model">Warranty Till</label>
                    <dx-date-box [(ngModel)]="asset.warranty_till_date" name="warranty" id="warranty" type="date"
                        displayFormat="dd/MM/yyyy" dateSerializationFormat="yyyy-MM-dd">
                    </dx-date-box>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="model">Model</label>
                    <input class="form-control" [(ngModel)]="asset.model" type="text" id="model"
                        name="model">
                </div>
                <div class="col-sm-6">
                    <label for="snumber">Serial Number</label>
                    <input class="form-control" [(ngModel)]="asset.serial_number" type="text"
                        id="snumber" name="snumber">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="value">Asset Value</label>
                    <input class="form-control" [(ngModel)]="asset.asset_value" type="number" id="value"
                        name="value">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="desc">Description</label>
                    <textarea rows="4" class="form-control" id="desc" name="desc"
                        [(ngModel)]="asset.description"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="saveAsset(assetForm)"
                [disabled]="!assetForm.valid">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>
<ng-template #assetassignmodalcontent let-c="close" let-d="dismiss">
    <form #assetassignForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Assign Asset</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-scroll">
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="aname">Asset Name</label>
                    <input class="form-control" [(ngModel)]="asset.asset_name" readonly type="text"
                        id="aname" name="aname">

                </div>
                <div class="col-sm-6">
                    <label for="acode">Asset Code <span class="text-danger">*</span></label>
                    <input class="form-control" [(ngModel)]="asset.asset_code" readonly type="text"
                        id="acode" name="acode">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="assignto">Assign to</label>
                    <dx-autocomplete class="form-control" id="assignto" name="assignto"
                        placeholder="Type Employee Name...." (onSelectionChanged)="selectAssigntoName($event)"
                        [dataSource]="employeeNameList" [value]="asset.employee_name" [showClearButton]="true">
                    </dx-autocomplete>
                </div>
                <div class="col-sm-6">

                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="assignAsset(assetassignForm)"
                [disabled]="!assetassignForm.valid">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>
<div *ngIf="(gridloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#asset-list-grid-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#asset-list-grid-loading">
    </dx-load-panel>
</div>