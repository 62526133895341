import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Global } from '../shared/global';
import { ApiResponse } from '../model/common';

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {
  saveEmployee(employee: any) {
    throw new Error('Method not implemented.');
  }
  saveRecurver(recurver: any) {
    throw new Error('Method not implemented.');
  }
  base_path: string;
  constructor(private http: HttpClient, private datePipe: DatePipe, public global: Global) {
    this.base_path = global.commonApiEndPoint;
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'x-access-token': ''
    })
  };
  setToken(token: string) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'x-access-token': token
      })
    };
  }
  getCompanyField(fieldsourcetype: number = 226, custom_relation_id: string = null) {
    return this.http
      .get<ApiResponse>(`${this.base_path}/company-custom-field?fieldsourcetype=${fieldsourcetype}&customrelationid=${custom_relation_id}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getRequestHeader() {
    return this.http
      .get<ApiResponse>(this.base_path + '/header', this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeCustomFields(employee_id: number, fieldsourcetype: number = 226, year: number = null, performancecycleid: number = null, surveyId: number = 1) {
    return this.http
      .get<ApiResponse>(`${this.base_path}/employee-custom-field?employeeid=${employee_id}&fieldsourcetype=${fieldsourcetype}&surveyid=${surveyId}&performanceyear=${year}&performancecycleid=${performancecycleid}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }

  getRecurverCustomFields(recurver_id: number, fieldsourcetype: number = 226, year: number = null, performancecycleid: number = null, surveyId: number = 1) {
    return this.http
      .get<ApiResponse>(`${this.base_path}/recurver-custom-field?recurverid=${recurver_id}&fieldsourcetype=${fieldsourcetype}&surveyid=${surveyId}&performanceyear=${year}&performancecycleid=${performancecycleid}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }

  getNextSequenceNumber(name: string) {
    return this.http
      .get<ApiResponse>(this.base_path + '/sequence?name=' + name, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getUserAccess(employeeid: any) {
    return this.http
      .get<ApiResponse>(this.base_path + '/user-access?employeeid=' + employeeid, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }


  getRecurver(recurver_id: any) {
    return this.http
      .get<ApiResponse>(this.base_path + '/recurver?recurver_id=' + recurver_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }

  getUser(isparent: boolean = true, user_id: number = null) {
    return this.http
      .get<ApiResponse>(`${this.base_path}/user-info?isparentcompany=${isparent}&userid=${user_id}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getCompanyStyle(href: string, companyid: string = null) {
    return this.http
      .get<ApiResponse>(`${this.base_path}/company-style?siteurl=${href}&companyid=${companyid}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getCompany() {
    return this.http
      .get<ApiResponse>(this.base_path + '/company', this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getUtilityData(type: string, isAltLogin: boolean = false): Observable<ApiResponse> {
    return this.http
      .get<ApiResponse>(`${this.base_path}/utility/${type}?&altlogin=${isAltLogin}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getUnreadNotification(user_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/notification?userid=' + user_id + '&unread=true', this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getMasterData(type: string): Observable<ApiResponse> {
    return this.http
      .get<ApiResponse>(this.base_path + '/master?type=' + type, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getPolicy(policyTypeId: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/policy/' + policyTypeId, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getNotification(user_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/notification?userid=' + user_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  forgotPassword(userNameForgotPassword: string) {
    return this.http
      .post<ApiResponse>(this.base_path + '/forgot-password?username=' + userNameForgotPassword, null, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  deletePolicy(policy_id: number) {
    return this.http
      .delete<ApiResponse>(this.base_path + '/policy/' + policy_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postPolicy(policy: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/policy', policy, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  login(u: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/token', u, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postMaster(masterData: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/master', masterData, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  uploadNotification(file: File, notification: any = null) {
    const formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }
    formData.append('data', JSON.stringify(notification));
    return this.http
      .post<ApiResponse>(this.base_path + '/notification-document-upload', formData, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postNotification(notification: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/notification', notification, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  markAsReadNotification() {
    return this.http
      .post<ApiResponse>(this.base_path + '/mark-all-read-notification', null, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getNotificationDocumentUrl(id: number) {
    return `${this.base_path}/view-notification-document?notificationid=${id}&token=${this.global.loggedUser.token}`;
  }
  geDocumentUrl() {
    return `${this.base_path}/download-document?type=employeeinfo&token=${this.global.loggedUser.token}`;
  }
  getAttendanceDocumentUrl(reportDate: Date, locationId: number, departmentId: number, shiftId: number, categoryId: number = null) {
    // tslint:disable-next-line:max-line-length
    return `${this.base_path}/download-document?type=attendancereport&token=${this.global.loggedUser.token}&reportdate=${this.datePipe.transform(reportDate, 'yyyy-MM-dd')}&locationid=${locationId}&departmentid=${departmentId}&shiftid=${shiftId}&categoryid=${categoryId}`;
  }
}
