import { KeyValue } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDateBoxComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { HrDashboardReportData } from 'src/app/model/admin';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { indicate } from 'src/app/services/loading.service';

@Component({
  selector: 'app-hr-dashboard',
  templateUrl: './hr-dashboard.component.html',
  styleUrls: ['./hr-dashboard.component.scss']
})
export class HrDashboardComponent implements OnInit {
  designationList: KeyValue<number, string>[];
  departmentList: KeyValue<number, string>[];
  locationList: KeyValue<number, string>[];
  reportData: HrDashboardReportData = new HrDashboardReportData();
  designationId: number;
  departmentId: number;
  locationId: number;
  salaryReportYear: Date;
  loading$ = new Subject<boolean>();
  chartloading$ = new Subject<boolean>();
  locationloading$ = new Subject<boolean>();
  @ViewChild('dxSalaryDate', { static: true }) dxSalaryDate: DxDateBoxComponent;
  constructor(public commonApiService: CommonApiService, public adminApiService: AdminApiService) { }

  ngOnInit() {
    this.dxSalaryDate.calendarOptions = { zoomLevel: 'decade', maxZoomLevel: 'decade', minZoomLevel: 'decade' };
    this.commonApiService.getMasterData('designation,department')
      .pipe(indicate(this.loading$))
      .subscribe(apiresponse => {
        this.designationList = apiresponse.data.filter((d: { type: string; }) => d.type === 'designation');
        this.departmentList = apiresponse.data.filter((d: { type: string; }) => d.type === 'department');
      });
    this.commonApiService.getUtilityData('location')
      .pipe(indicate(this.locationloading$))
      .subscribe(apiresponse => {
        this.locationList = apiresponse.data;
      });
    this.salaryReportYear = new Date();
    this.loadDashboard();
  }
  locationTooltip = (info: any) => {
    return {
      html: '<div><div class=\'tooltip-header\'>' +
        '</div>' +
        '<div class=\'tooltip-body\'><div class=\'series-name\'>' +
        '<span class=\'top-series-name\'>' + info.points[0].seriesName + '</span>' +
        ': </div><div class=\'value-text\'>' +
        '<span class=\'top-series-value\'>' +  info.argumentText + '</span>' +
        '</div><div class=\'series-name\'>' +
        '<span class=\'bottom-series-name\'>Employee Count: </span>' +
        ': </div><div class=\'value-text\'>' +
        '<span class=\'bottom-series-value\'>' + info.points[0].valueText  + '</span>' +
        '</div></div></div>'
    };
  }
  customizeTooltip = (info: any) => {
    return {
      html: '<div><div class=\'tooltip-header\'>' +
        info.argumentText + '</div>' +
        '<div class=\'tooltip-body\'><div class=\'series-name\'>' +
        '<span class=\'top-series-name\'>' + info.points[0].seriesName + '</span>' +
        ': </div><div class=\'value-text\'>' +
        '<span class=\'top-series-value\'>' + info.points[0].valueText + '</span>' +
        '</div><div class=\'series-name\'>' +
        '<span class=\'bottom-series-name\'>Employee Count: </span>' +
        ': </div><div class=\'value-text\'>' +
        '<span class=\'bottom-series-value\'>' + info.point.data.employee_count + '</span>' +
        '</div></div></div>'
    };
  }
  refresh() {
    this.ngOnInit();
  }
  saldateChanged(e: any) {
    if (e.event !== undefined) {
      this.loadDashboard();
    }
  }
  loadDashboard() {
    this.adminApiService.getHrDashboardReport(this.designationId, this.departmentId, this.salaryReportYear.getFullYear(), this.locationId)
      .pipe(indicate(this.chartloading$))
      .subscribe(apiresponse => {
        this.reportData.gender_report = apiresponse.data.gender_report;
        this.reportData.location_report = apiresponse.data.location_report;
        this.reportData.salary_report = apiresponse.data.salary_report;
        this.reportData.summary_report = apiresponse.data.summary_report;
        if (apiresponse.data.age_report) {
          this.reportData.age_report = [];
          this.reportData.age_report.push({ age: '14 to 20 years', val: apiresponse.data.age_report.emp14to20 });
          this.reportData.age_report.push({ age: '20 to 30 years', val: apiresponse.data.age_report.emp20to30 });
          this.reportData.age_report.push({ age: '30 to 45 years', val: apiresponse.data.age_report.emp30to45 });
          this.reportData.age_report.push({ age: '45 to 55 years', val: apiresponse.data.age_report.emp45to55 });
          this.reportData.age_report.push({ age: '55 Plus', val: apiresponse.data.age_report.emp55plus });
          if (apiresponse.data.age_report.NA > 0) {
            this.reportData.age_report.push({ age: 'Not Available', val: apiresponse.data.age_report.NA });
          }
        }
      });
  }
}
