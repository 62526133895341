import { Component, OnInit } from '@angular/core';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { Global } from 'src/app/shared/global';
import { AttendanceActivity, EmployeeDailyAttendance, EmployeeMonthlyAttendance } from 'src/app/model/attendance';
import { forkJoin, Subject } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';
import { ApiResponse } from 'src/app/model/common';

@Component({
  selector: 'app-attendance-view',
  templateUrl: './attendance-view.component.html',
  styleUrls: ['./attendance-view.component.scss']
})
export class AttendanceViewComponent implements OnInit {
  dailyAttendanceData: EmployeeDailyAttendance = new EmployeeDailyAttendance();
  attendanceActivity: AttendanceActivity[] = [];
  fromDate: Date = new Date();
  toDate: Date = new Date();
  showPunchOut = true;
  reportDate: Date;
  activityloading$ = new Subject<boolean>();
  attendanceloading$ = new Subject<boolean>();
  constructor(private attendanceApiService: AttendanceApiService, private global: Global) { }
  refresh() {
    this.ngOnInit();
  }
  ngOnInit() {
    this.reportDate = new Date();
    this.fromDate = new Date();
    this.toDate = this.reportDate;
    this.fromDate.setDate(this.reportDate.getDate() - 30);
    forkJoin(
      {
        attendance: this.attendanceApiService.getDailyAttendanceData(this.reportDate, this.global.loggedUser.user_type_internal_id),
        activity: this.attendanceApiService.getAttendanceActivity(this.global.loggedUser.user_type_internal_id, this.fromDate, this.toDate)
      })
      .pipe(indicate(this.attendanceloading$))
      .subscribe((apiresponse) => {
        this.dailyAttendanceLoad(apiresponse.attendance);
        this.attendanceActivity = apiresponse.activity.data;
      });
  }
  dailyAttendanceLoad(apiresponse: ApiResponse) {
    this.dailyAttendanceData = apiresponse.data[0];
    if (!this.dailyAttendanceData) {
      this.dailyAttendanceData = new EmployeeDailyAttendance();
      this.dailyAttendanceData.employee_name = this.global.loggedUser.name;
      this.dailyAttendanceData.report_date = this.reportDate;
    }
  }
  dailyAttendance() {
    this.attendanceApiService.getDailyAttendanceData(this.reportDate, this.global.loggedUser.user_type_internal_id)
      .pipe(indicate(this.activityloading$))
      .subscribe(apiresponse => {
        this.dailyAttendanceLoad(apiresponse);
      });
  }
  searchAttendance() {
    this.attendanceApiService.getAttendanceActivity(this.global.loggedUser.user_type_internal_id, this.fromDate, this.toDate)
      .pipe(indicate(this.attendanceloading$))
      .subscribe(apiresponse => {
        this.attendanceActivity = apiresponse.data;
      });
  }
}
