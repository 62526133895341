import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AttendanceRegularization } from 'src/app/model/attendance';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { ToastService } from 'src/app/services/toast.service';
import { Global } from 'src/app/shared/global';
import { confirm } from 'devextreme/ui/dialog';
import { RequestRegularizeComponent } from '../request-regularize/request-regularize.component';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';
@Component({
  selector: 'app-attendance-regularize-admin',
  templateUrl: './attendance-regularize-admin.component.html',
  styleUrls: ['./attendance-regularize-admin.component.scss']
})
export class AttendanceRegularizeAdminComponent implements OnInit , OnDestroy{
  regularization: AttendanceRegularization = new AttendanceRegularization();
  regularizationList: AttendanceRegularization[] = [];
  fromDate: Date;
  toDate: Date;
  regularizationStatusList: KeyValue<number, string>[] = [];
  hasHrRole = false;
  loading$ = new Subject<boolean>();
  subscriptions: Subscription[] = [];
  @ViewChild('applyRegularization', { read: RequestRegularizeComponent }) applyRegularization: RequestRegularizeComponent;
  // tslint:disable-next-line:max-line-length
  constructor(private toastService: ToastService, private commonApiService: CommonApiService, private attendanceService: AttendanceApiService, private global: Global) { }

  ngOnInit() {
    this.fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.toDate = new Date();
    this.subscriptions.push(
      forkJoin(
        {
          master: this.commonApiService.getMasterData('regularization_status'),
          // tslint:disable-next-line:max-line-length
          request: this.attendanceService.getRegularizationRequest(null, this.fromDate, this.toDate, true),
        })
        .pipe(indicate(this.loading$))
        .subscribe(apiresponse => {
          // tslint:disable-next-line:max-line-length
          this.regularizationStatusList = apiresponse.master.data.filter((d: { type: string; }) => d.type === 'regularization_status');
          this.regularizationList = apiresponse.request.data;
        }));
    this.hasHrRole = this.global.loggedUser.roles.filter(d => d.role_name === 'HR-Manager').length > 0;
  }
  fromdateChanged(e: any) {
    if (e.event !== undefined) {
      this.toDate = null;
    }
  }
  openModel() {
    this.clearModel();
    this.applyRegularization.openModal(true, this.regularization, true);
  }
  clearModel() {
    this.regularization = new AttendanceRegularization();
  }
  searchRegularization() {
    // tslint:disable-next-line:max-line-length
    this.subscriptions.push(
      this.attendanceService.getRegularizationRequest(null, this.fromDate, this.toDate, true)
        .pipe(indicate(this.loading$))
        .subscribe(apiresponse => {
          this.regularizationList = apiresponse.data;
        }));
  }
  changeStatus(e: { row: { data: AttendanceRegularization; }; }, status: string) {
    const reg = e.row.data;
    const st = status === 'Approved' ? 'approve' : status === 'Cancelled' ? 'Cancel' : 'decline';
    confirm('Are you sure, you want to ' + st + ' ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        const stat: KeyValue<number, string> = this.regularizationStatusList.find(d => d.value === status);
        if (stat) {
          reg.regularization_status_id = stat.key;
          this.subscriptions.push(
            this.attendanceService.postRegularizationRequest(reg)
              .subscribe(() => {
                this.toastService.success('Regularization ' + status + ' successfully');
                this.searchRegularization();
              }));
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
