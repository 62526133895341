<div class="card punch-status">
    <div class="card-body">
        <h5 class="card-title">Time - {{ngModel.employee_name}}
            <br><small class="text-muted" *ngIf="ngModel.shift_start_time && ngModel.shift_end_time">
                {{ ngModel.shift_name }} - ( {{ngModel.shift_start_time | date : "shortTime" }} to
                {{ngModel.shift_end_time | date :
                "shortTime"}})</small>
            <br><small class="text-muted">
                {{ ngModel.report_date | date : 'EEEE, MMM d, y'  }}</small>
        </h5>
        <div class="punch-det">
            <h6>Punch In at</h6>
            <p>{{ ngModel.first_punch_in==null? 'NA' : (ngModel.first_punch_in | date : "EEEE, MMMM d, y, h:mm:ss a") }}
            </p>
        </div>
        <div class="punch-info">
            <div class="punch-hours">
                <span>{{!ngModel ||
                    !ngModel.total_hours || ngModel.total_hours===null || ngModel.total_hours===0?'NA' :
                    ngModel.total_hours | number : '1.2-2'}} hrs </span>
            </div>
        </div>
        <div class="punch-det" *ngIf="showPunchOut">
            <h6>Punch Out at</h6>
            <p>{{ ngModel.last_punch_out==null? 'NA' : ( ngModel.last_punch_out | date : "EEEE, MMMM d, y, h:mm:ss a")
                }}
            </p>
        </div>
        <div class="statistics" *ngIf="ngModel.punch_type">
            <div class="row">
                <div class="col-md-12 col-12 text-center">
                    <div class="stats-box">
                        <h6>{{ngModel.punch_type}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>