import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast.service';
import { ApiResponse } from '../model/common';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map((response: any) => {
      if (response.body) {
        const res: ApiResponse = response.body;
        if (res.meta.status === 'error') {
          this.toastService.error(res.err);
        } else if (res.meta.status === 'validationerror') {
          res.validationerrors.forEach(v => {
            this.toastService.warning(v);
          });
        } else if (res.meta.status === 'success') {
          return response;
        }
      } else {
        return response;
      }
    }));
  }
}

