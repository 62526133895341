import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Global } from 'src/app/shared/global';
import { MasterData, Policy } from 'src/app/model/common';
import { ToastService } from 'src/app/services/toast.service';
import { forkJoin, Subject } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-policy-view',
  templateUrl: './policy-view.component.html',
  styleUrls: ['./policy-view.component.scss']
})
export class PolicyViewComponent implements OnInit {
  isNew = false;
  policyTypeList: KeyValue<number, string>[] = [];
  policyMasterList: any[] = [];
  policyMasterListFiltered: any[] = [];
  policySubTypeList: KeyValue<string, string>[] = [];
  policyMasterId: number;
  policyTypeIdmain: number;
  policyTypeId: number;
  policyTypeName: string;
  policy: Policy;
  policyMaster: MasterData = new MasterData();
  policyItemList: KeyValue<number, string>[];
  policyDataList: Policy[];
  masterData: any[];
  loading$ = new Subject<boolean>();
  gridloading$ = new Subject<boolean>();
  @ViewChild('policymodalcontent', { static: true }) policymodalcontent: ElementRef;
  // tslint:disable-next-line:max-line-length
  constructor(private toastService: ToastService, private modalService: NgbModal, private commonApiService: CommonApiService, public global: Global) {
    this.policysubtypeValidation = this.policysubtypeValidation.bind(this);
    this.policyvalueValidation = this.policyvalueValidation.bind(this);
  }

  ngOnInit() {
    this.commonApiService.getMasterData('policy_type')
      .pipe(indicate(this.loading$))
      .pipe(
        concatMap(apiresponse => {
          this.policyTypeList = apiresponse.data;
          let policySubType = '';
          this.policyTypeList.forEach(item => {
            policySubType = policySubType + ',' + item.value;
          });
          return this.commonApiService.getMasterData(policySubType);
        })
      ).subscribe(
        apiresponse => {
          this.policyMasterList = apiresponse.data;
          if (this.policyTypeIdmain) {
            const v = this.policyTypeList.filter(x => x.key === this.policyTypeIdmain)[0].value;
            this.policyMasterListFiltered = this.policyMasterList.filter(x => x.type === v);
          }
        }
      );
    // tslint:disable-next-line:max-line-length
    this.commonApiService.getMasterData('ctc_component,leave_type,payroll_earnings,payroll_deduction,permission_hour')
      .pipe(indicate(this.gridloading$))
      .subscribe(apiresponse => {
        this.masterData = apiresponse.data;
      });
  }
  loadPolicyMasterList() {
    let policySubType = '';
    this.policyTypeList.forEach(item => {
      policySubType = policySubType + ',' + item.value;
    });
    forkJoin(
      {
        type:  this.commonApiService.getMasterData(policySubType),
        policy: this.commonApiService.getMasterData('ctc_component,leave_type,payroll_earnings,payroll_deduction,permission_hour'),
      })
      .pipe(indicate(this.gridloading$))
      .subscribe(apiresponse => {
        this.policyMasterList = apiresponse.type.data;
        if (this.policyTypeIdmain) {
          const v = this.policyTypeList.filter(x => x.key === this.policyTypeIdmain)[0].value;
          this.policyMasterListFiltered = this.policyMasterList.filter(x => x.type === v);
          this.masterData = apiresponse.policy.data;
        }
      });
  }
  policysubtypeValidation(params) {
    const p: Policy = this.policyDataList.find(x => x.policy_sub_type_id === params.data.policy_sub_type_id);
    if (p) {
      return new Promise((resolve, reject) => {
        resolve(false);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }
  }
  policyvalueValidation(params) {
    const sum = this.policyDataList.reduce((s, current) => s + current.policy_value, 0);
    if ((this.policyTypeIdmain === 150 || this.policyTypeIdmain === 151) && (sum + params.data.policy_value) > 100) {
      return new Promise((resolve, reject) => {
        resolve(false);
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    }
  }
  searchPolicy(updateList: boolean = true) {
    if (this.policyMasterId) {
      const t: string = this.policyMasterList.filter(x => x.key === this.policyMasterId)[0].type;
      if (t === 'payroll_earning_policy_type') {
        this.policyItemList = this.masterData.filter(x => x.type === 'payroll_earnings');
      } else if (t === 'payroll_deduction_policy_type') {
        this.policyItemList = this.masterData.filter(x => x.type === 'payroll_deduction');
      } else if (t === 'leave_policy_type') {
        this.policyItemList = this.masterData.filter(x => x.type === 'leave_type');
      } else if (t === 'ctc_policy_type') {
        this.policyItemList = this.masterData.filter(x => x.type === 'ctc_component');
      } else if (t === 'permission_policy_type') {
        this.policyItemList = this.masterData.filter(x => x.type === 'permission_hour');
      }
      this.commonApiService.getPolicy(this.policyMasterId)
        .pipe(indicate(this.gridloading$))
        .subscribe(apiresponse => {
          this.policyDataList = apiresponse.data;
        });
    }
  }
  clearModel() {
    this.policy = new Policy();
    this.policyTypeId = undefined;
    this.policyTypeName = undefined;
  }
  openModel(content: any) {
    this.isNew = true;
    this.clearModel();
    this.modalService.open(content, { size: 'lg' });
  }
  savePolicy(frm: any) {
    if (frm.valid) {
      const pm: string = this.policyTypeList.filter(p => p.key === this.policyTypeId)[0].value;
      if (!this.isNew) {
        this.policyMaster.key = this.policyMasterId;
      }
      this.policyMaster.type = pm;
      this.policyMaster.value = this.policyTypeName;
      this.commonApiService.postMaster(this.policyMaster)
        .subscribe(apiresponse => {
          if (this.isNew) {
            this.toastService.success(this.policyMaster.type + ' Value successfully created.');
          } else {
            this.toastService.success(this.policyMaster.type + ' Value successfully updated.');
          }
          this.clearModel();
          this.modalService.dismissAll();
          this.loadPolicyMasterList();
        });
    }
  }
  onTypeChange(event) {
    const selectElementText = event.target.options
    [event.target.options.selectedIndex].text;
    this.policyMasterListFiltered = this.policyMasterList.filter(x => x.type === selectElementText);
    this.policyMasterId = undefined;
  }
  editPolicy() {
    this.isNew = false;
    const p: any = this.policyMasterList.filter(po => po.key === this.policyMasterId)[0];
    this.policyTypeName = p.value;
    this.policyTypeId = this.policyTypeList.filter(x => x.value === p.type)[0].key;
    this.modalService.open(this.policymodalcontent, { size: 'lg' });
  }
  policyInserted(e) {
    const p: Policy = e.data;
    p.policy_type_id = this.policyMasterId;
    this.commonApiService.postPolicy(p).subscribe(apiresponse => {
      this.toastService.success('Policy Value successfully created.');
      this.searchPolicy(true);
      this.clearModel();
      this.modalService.dismissAll();
    });
  }
  policyUpdated(e) {
    const p: Policy = e.data;
    this.commonApiService.postPolicy(p).subscribe(apiresponse => {
      this.toastService.success('Policy Value successfully updated.');
      this.searchPolicy(true);
      this.clearModel();
      this.modalService.dismissAll();
    });
  }
  policyRemoved(e) {
    this.commonApiService.deletePolicy(e.data.policy_id).subscribe(apiresponse => {
      this.toastService.success('Policy Value removed successfully');
    });
  }
}
