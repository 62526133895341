<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Policy</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn" (click)="openModel(policymodalcontent)"><i class="fa fa-plus"></i>
                    Add Policy</button>
            </div>
        </div>
    </div>
    <div class="row filter-row">
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
            <select class="form-control filter-control" id="policyType" name="policyType" [(ngModel)]="policyTypeIdmain"
                (change)="onTypeChange($event)" [ngClass]="{'loading-control': (loading$ | async) === true }">
                <option [ngValue]="undefined" selected>Select Policy Type</option>
                <option *ngFor="let item of policyTypeList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
            <select class="form-control filter-control" id="policyMaster" name="policyMaster"
                [(ngModel)]="policyMasterId" [ngClass]="{'loading-control': (loading$ | async) === true }">
                <option [ngValue]="undefined" selected>Select Policy</option>
                <option *ngFor="let item of policyMasterListFiltered; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
            <button class="btn btn-secondary btn-block" (click)="searchPolicy()" [disabled]="policyMasterId===undefined"> Search </button>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
            <button class="btn btn-secondary btn-block" (click)="editPolicy()" [disabled]="policyMasterId===undefined">
                Edit </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-3 table-responsive" id="policy-grid">
            <dx-data-grid #gridpolicy id="gridContainer" [dataSource]="policyDataList" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false" (onRowInserted)="policyInserted($event)"
                (onRowUpdated)="policyUpdated($event)" (onRowRemoved)="policyRemoved($event)">
                <dxo-editing mode="form" [allowDeleting]="true" [allowAdding]="true">
                </dxo-editing>
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxi-column dataField="policy_sub_type_id" caption="Name" dataType="string" [allowEditing]="true">
                    <dxo-lookup [dataSource]="policyItemList" displayExpr="value" valueExpr="key">
                    </dxo-lookup>
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxi-validation-rule type="async" message="Policy subtype already exist."
                        [validationCallback]="policysubtypeValidation"></dxi-validation-rule>
                </dxi-column>
                <dxi-column dataField="policy_value" caption="Value" dataType="number" [allowEditing]="true">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxi-validation-rule type="async" message="Invalid policy value."
                        [validationCallback]="policyvalueValidation"></dxi-validation-rule>
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>
    <ng-template #policymodalcontent let-c="close" let-d="dismiss">
        <form #policyForm="ngForm">
            <div class="modal-header">
                <div *ngIf="isNew;else edit_content">
                    <h4 class="modal-title" id="modal-basic-title">Add Policy</h4>
                </div>
                <ng-template #edit_content>
                    <h4 class="modal-title" id="modal-basic-title">Edit Policy</h4>
                </ng-template>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="type">Type <span class="text-danger">*</span></label>
                        <select class="form-control" id="policytype" name="policytype" [(ngModel)]="policyTypeId"
                            #policytype="ngModel" [disabled]="!isNew" required>
                            <option [ngValue]="undefined" selected>Select Type</option>
                            <option *ngFor="let item of policyTypeList; let i = index" [ngValue]="item.key">
                                {{item.value}}</option>
                        </select>
                        <div *ngIf="policytype.invalid && (policytype.dirty || policytype.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="policytype.errors.required">
                                Policy Type is Required.
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="type">Name <span class="text-danger">*</span></label>
                        <input class="form-control" [(ngModel)]="policyTypeName" id="policyValue" name="policyValue"
                            #policyValue="ngModel" [pattern]="global.masterValueValidator" required>
                        <div *ngIf="policyValue.invalid && (policyValue.dirty || policyValue.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="policyValue.errors.pattern">
                                Policy Name is invalid.
                            </div>
                            <div *ngIf="policyValue.errors.required">
                                Policy Name is Required.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="savePolicy(policyForm)"
                    [disabled]="!policyForm.valid">Save</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
</div>
<div *ngIf="(gridloading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#policy-grid' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#policy-grid">
    </dx-load-panel>
</div>