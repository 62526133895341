<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Asset View</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn ml-3" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="card profile-box flex-fill">
            <div class="card-body" id="asset-details-loading">
                <div class="row">
                    <div class="col-md-6 left-dash-border">
                        <ul class="personal-info">
                            <li>
                                <div class="title">Type:</div>
                                <div class="text">
                                    {{asset.asset_type===null?'NA':asset.asset_type}}
                                </div>
                            </li>
                            <li>
                                <div class="title">Asset Code:</div>
                                <div class="text">
                                    {{asset.asset_code===null?'NA':asset.asset_code}}
                                </div>
                            </li>
                            <li>
                                <div class="title">Asset Name:</div>
                                <div class="text">
                                    {{asset.asset_name===null?'NA':asset.asset_name}}
                                </div>
                            </li>
                            <li>
                                <div class="title">Purchase Date:</div>
                                <div class="text">
                                    {{asset.purchase_date===null?'NA': asset.purchase_date | date : "dd-MM-yyyy" }}
                                </div>
                            </li>
                            <li>
                                <div class="title">Manufacturer:</div>
                                <div class="text">
                                    {{asset.manufacturer===null?'NA':asset.manufacturer}}
                                </div>
                            </li>
                            <li>
                                <div class="title">Model:</div>
                                <div class="text">
                                    {{asset.model===null?'NA':asset.model}}
                                </div>
                            </li>
                            <li>
                                <div class="title">Serial No:</div>
                                <div class="text">
                                    {{asset.serial_number===null?'NA':asset.serial_number}}
                                </div>
                            </li>

                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul class="personal-info">
                            <li>
                                <div class="title">Condition:</div>
                                <div class="text">
                                    {{asset.asset_condition===null?'NA':asset.asset_condition}}
                                </div>
                            </li>
                            <li>
                                <div class="title">Warranty:</div>
                                <div class="text">
                                    {{asset.warranty_till_date===null?'NA': asset.warranty_till_date | date :
                                    "dd-MM-yyyy"
                                    }}
                                </div>
                            </li>
                            <li>
                                <div class="title">Value:</div>
                                <div class="text">
                                    {{asset.asset_value===null?'NA':asset.asset_value}}
                                </div>
                            </li>
                            <li>
                                <div class="title">Description:</div>
                                <div class="text">
                                    {{asset.description===null?'NA':asset.description}}
                                </div>
                            </li>
                            <li>
                                <div class="title">Ownership Type:</div>
                                <div class="text">
                                    {{asset.asset_ownership_type===null?'NA':asset.asset_ownership_type}}
                                </div>
                            </li>
                            <li>
                                <div class="title">Status:</div>
                                <div class="text">
                                    {{asset.asset_status===null?'NA':asset.asset_status}}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card tab-box">
        <div class="row user-tabs">
            <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul ngbNav #navTab="ngbNav" (activeId)="1" class="nav nav-tabs nav-tabs-bottom" [destroyOnHide]="false">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink class="nav-link">
                            Services
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row p-3">
                                <div class="col-md-12 table-responsive" id="asset-service-loading">
                                    <dx-data-grid id="gridService" [dataSource]="asset.services" [showBorders]="false"
                                        [showColumnLines]="false" [rowAlternationEnabled]="true"
                                        (onRowInserted)="serviceInserted($event)"
                                        (onRowUpdated)="serviceUpdated($event)" (onRowRemoved)="serviceRemoved($event)">
                                        <dxo-paging [enabled]="false"></dxo-paging>
                                        <dxo-load-panel [enabled]="false"></dxo-load-panel>  
                                        <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true"
                                            [allowAdding]="true"> </dxo-editing>
                                        <dxi-column dataField="service_type_id" caption="Service type" dataType="string"
                                            [allowEditing]="true">
                                            <dxo-lookup [dataSource]="typeList" displayExpr="value" valueExpr="key">
                                            </dxo-lookup>
                                            <dxi-validation-rule type="required"></dxi-validation-rule>
                                        </dxi-column>
                                        <dxi-column dataField="service_amount" caption="Service Amount"
                                            dataType="number">
                                        </dxi-column>
                                        <dxi-column dataField="service_date" caption="Date" dataType="date"
                                            [allowEditing]="true">
                                        </dxi-column>
                                        <dxi-column dataField="description" caption="Description" dataType="string">
                                        </dxi-column>
                                    </dx-data-grid>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink class="nav-link">
                            Assignments
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row p-3">
                                <div class="col-md-12 table-responsive" id="asset-assign-loading">
                                    <dx-data-grid id="gridAssign" [dataSource]="asset.employee_assets"
                                        [showBorders]="false" [showColumnLines]="false" [rowAlternationEnabled]="true">
                                        <dxo-paging [enabled]="false"></dxo-paging>
                                        <dxi-column dataField="employee_name" [allowFiltering]="false"
                                            [allowSorting]="false" cellTemplate="imageTemplate" caption="Employee"
                                            width="250"></dxi-column>
                                        <dxi-column dataField="assigned_date" caption="Assigned Date"
                                            cellTemplate="dateTemplate">
                                        </dxi-column>
                                        <dxi-column dataField="return_date" caption="Return Date"
                                            cellTemplate="dateTemplate">
                                        </dxi-column>
                                        <dxi-column dataField="description" caption="Description" dataType="string">
                                        </dxi-column>
                                        <dxi-column dataField="employee_asset_status" caption="Status"
                                            cellTemplate="statusTemplate" alignment="center">
                                        </dxi-column>
                                        <div *dxTemplate="let data of 'dateTemplate'">
                                            {{data.value | date}}
                                        </div>
                                        <div *dxTemplate="let data of 'imageTemplate'">
                                            <h2 class="table-avatar" *ngIf="data.row.data.employee_name!==null">
                                                <a href="javascript:void(0)" class="avatar"><img alt=""
                                                        [src]="data.row.data.profile_picture!= null ? data.row.data.profile_picture : global.maleAvatar"></a>
                                                <a> {{data.row.data.employee_name}} <span
                                                        *ngIf="data.row.data.designation!=undefined">{{data.row.data.designation}}
                                                    </span></a>
                                            </h2>
                                        </div>
                                        <div *dxTemplate="let cellInfo of 'statusTemplate'" class="text-center">
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Assigned'">
                                                <i class="fa fa-dot-circle-o text-success"></i> Assigned
                                            </a>
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Returned'">
                                                <i class="fa fa-dot-circle-o text-warning"></i> Returned
                                            </a>
                                            <a class="btn btn-white btn-sm btn-rounded" href="javascript:void(0);"
                                                *ngIf="cellInfo.value=='Damaged'">
                                                <i class="fa fa-dot-circle-o text-danger"></i> Damaged
                                            </a>
                                        </div>
                                    </dx-data-grid>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div [ngbNavOutlet]="navTab" id="tabcontentPanel" class="tab-content"></div>
</div>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#asset-details-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#asset-details-loading">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#asset-service-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#asset-service-loading">
    </dx-load-panel>
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#asset-assign-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#asset-assign-loading">
    </dx-load-panel>
</div>