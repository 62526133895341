import { KeyValue } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeSalary, SalaryComponent } from 'src/app/model/payroll';

@Component({
  selector: 'app-salary-component',
  templateUrl: './salary-component.component.html',
  styleUrls: ['./salary-component.component.scss']
})
export class SalaryComponentComponent implements OnInit {
  @Input() employeeSalary: EmployeeSalary = new EmployeeSalary();
  @Input() earningTypeList: KeyValue<number, string>[];
  @Input() deductionTypeList: KeyValue<number, string>[];
  enableEarningAdd = false;
  @ViewChild('salaryForm')
  private salaryForm: NgForm;
  constructor() { }

  ngOnInit(): void {

  }
  isValid() {
    return this.salaryForm.valid;
  }
  removeEarning(e: any) {
    this.employeeSalary.earning_components_new.splice(e.index, 1);
  }
  removeDeduction(e: any) {
    this.employeeSalary.deduction_components_new.splice(e.index, 1);
  }
  addEarning() {
    const newComponent: SalaryComponent = new SalaryComponent();
    this.earningTypeList.forEach(element => {
      const c = this.employeeSalary.earning_components
        .filter(co => co.salary_component_sub_type === element.value);
      if (c.length === 0) {
        newComponent.salaryComponentList.push(element);
      }
    });
    newComponent.index = this.employeeSalary.earning_components_new.length;
    newComponent.salary_component_type_id = 70;
    this.employeeSalary.earning_components_new.push(newComponent);
  }
  addDeduction() {
    const newComponent: SalaryComponent = new SalaryComponent();
    this.deductionTypeList.forEach(element => {
      const c = this.employeeSalary.deduction_components
        .filter(co => co.salary_component_sub_type === element.value);
      if (c.length === 0) {
        newComponent.salaryComponentList.push(element);
      }
    });
    newComponent.index = this.employeeSalary.deduction_components_new.length;
    newComponent.salary_component_type_id = 69;
    this.employeeSalary.deduction_components_new.push(newComponent);
  }
}
