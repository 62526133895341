import { UpperCasePipe } from '@angular/common';
import { Directive, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[app-compare][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CompareValidator,
      multi: true
    }
  ]
})
export class CompareValidator implements Validator {
  // tslint:disable-next-line:no-input-rename
  @Input('app-compare') compare: string;
  validator: ValidatorFn;
  constructor(private casePipe: UpperCasePipe) { }
  validate(c: FormControl) {
    if (!this.compare || this.compare === c.value || c.value === '') {
      return null;
    } else {
      return { compare: 'The Value ' + c.value + ' is not valid.' };
    }
  }
}
