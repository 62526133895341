<div class="content container-fluid" id="relieving-loading">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col centered">
                <h3 class="page-title">Relieving Letter</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <div class="btn-group btn-group-sm">
                    <button class="btn btn-white" (click)="print()"><i class="fa fa-print fa-lg"></i> Print</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row" #printContent>
        <div class="col-md-12 mt-5">
            <div class="card">
                <div class="card-body ml-2">
                    <div class="row">
                        <!-- <div class="col-sm-6 m-b-20" style="height: 300px;">
                             <div class="mt-5">
                                <img src="{{global.companyLogo}}" class="inv-logo" alt="">
                            </div>
                            <ul class="list-unstyled mb-0">
                                
                                <li>{{relievingLetter.full_name}}</li>
                            </ul>
                          <ul class="list-unstyled mb-0">
                                <li> {{ currentdate | date : "MMM d yyyy" }}</li>
                                <li>{{relievingLetter.full_name}}</li> 
                            </ul>
                            <h4>{{ employeereliev.first_name }}{{ employeereliev.last_name }}</h4>
                            <h3>{{ employeereliev.first_name }}</h3>
                            <h1>{{ employeereliev.first_name }}</h1>
                            <p class="mt-1">Sub: Relieving Letter</p>

                        </div> -->
                       <!-- <p>Dear <strong>{{ offerLetter.first_name }}</strong>,</p><br> -->
                    </div>

                    <div class="row">
                        <p class="mt-5">TO WHOM IT MAY CONCERN</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mb-20 mt-20" style="height: 500px;">
                            <!-- <p>
                                This has reference to your letter of resignation dated {{
                                employeeResignation.release_date | date : "MMM d yyyy" }}, wherein you have
                                requested to be relieved from the services of the company on {{
                                employeeResignation.last_date | date : "MMM d yyyy" }}.
                                We would like to inform you that your resignation is hereby accepted and you are being
                                relieved from the services of the company after serving one month notice period, with
                                effect from closing office hours of {{ employeeResignation.last_date | date : "MMM d
                                yyyy" }}.
                            </p> -->
                            <p>
                                We at company wish you all the best in your future endeavors.
                            </p>
                        </div>
                    </div>
                    <div>
                        <p>
                            For
                            <br>
                            Yours Sincerely,
                            <br>
                            _____________ __________________
                            <br>
                            (HR Officer) (Director)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="(loading$ | async)">
    <dx-load-panel shadingColor="rgba(0,0,0,0.4)" visible="true" [position]="{ of: '#relieving-loading' }"
        [showIndicator]="true" [showPane]="false" [shading]="true" [closeOnOutsideClick]="false" container="#relieving-loading">
    </dx-load-panel>
</div>