<div class="card recent-activity">
    <div class="card-body">
        <h5 class="card-title">Activity</h5>
        <div class="row">
            <div class="col-md-12 col-12">
                <ul class="res-activity-list" *ngIf="ngModel.activity!=null">
                    <li *ngFor="let p of ngModel.activity">
                        <p class="mb-0">Punch {{p.punch_direction}} at <a  id="photolink{{p.employee_attendance_activity_id}}" *ngIf="p.photo_path"> (View Photo)</a></p>
                        <p class="res-activity-time">
                            <i class="fa fa-clock-o"></i>
                            {{ p.time | date : "mediumTime" }} <span
                                *ngIf="p.punch_description">({{p.punch_description}})</span>
                        </p>
                        <dx-popover target="#photolink{{p.employee_attendance_activity_id}}" showEvent="mouseenter" hideEvent="mouseleave" position="top" [height]="250" [width]="250">
                            <div *dxTemplate="let data = model; of: 'content'" style="align-items: center; text-align: center;">
                                <img alt="loading" style="align-items: center;; text-align: center;" [src]="adminApiService.getEmployeeDocumentUrl(null,p.employee_attendance_activity_id)" height="200">
                            </div>
                        </dx-popover>
                    </li>
                </ul>
                <p *ngIf="ngModel.activity==null">NA</p>
                
               
            </div>
        </div>
    </div>
</div>