import { EmployeeKpi } from "./admin";

export class TreeSource {
    ID: string;
    categoryId: string;
    name: string;
    expanded: boolean;
}
export class ApiResponse {
    data: any;
    meta: {
        id: number;
        requestid: number;
        status: string;
    };
    err: string;
    validationerrors: string[];
}
export class UserRole {
    role_id: number;
    role_name: string;
}
export class User {
    email: string;
    user_id: number;
    employee_id: number;
    recurver_id:number;
    user_name: string;
    name: string;
    password: string;
    newpassword: string;
    confirmpassword: string;
    roles: UserRole[];
    access: UserAccess[];
    user_type: string;
    token: string;
    profile_picture: string;
    user_type_id: number = null;
    user_type_internal_id: number = null;
    company_id: number;
    company_name: string;
    company_logo: string;
    pending_leave: number;
    pending_regularization: number;
    is_password_reset: any;
    is_parent_company: boolean;
    show_header: boolean;
    attendance_type_id: number;
    holiday_calendar_id: number;
    manager_leave_permission_add: number;
}
export class Company {
    company_id: number;
    company_name: string;
    company_logo: string;
    company_logo_mini: string;
    week_end_policy: string;
    week_end_policy_id: number;
    address: string;
    address1: string;
    city: string;
    state: string;
    pin: string;
}
export class NotificationMessage {
    notification_id: number;
    location_id: number;
    user_id: string;
    employee_id: number;
    designation_id: number;
    department_id: number;
    created_by_profile_picture: string;
    notification_message: string;
    notification_type_id: number = null;
    notification_type: string;
    notification_date: Date;
    created_date: Date;
    created_by: number;
    created_by_name: string;
    is_read: boolean;
    file_path: string;
    constructor() {
    }
}
export class Policy {
    policy_id: number;
    policy_type_id: number = null;
    policy_type: string;
    policy_sub_type_id: number = null;
    policy_sub_type: string;
    policy_value: number;
    policy_value_calculated: number;
    prorated_value: number;
    updated_date: Date;
    updated_by_name: string;
    constructor() {
    }
}
export class MasterData {
    key: number;
    value: string;
    type: string;
    constructor() {
    }
}
export class SiteStyle {
    style_name: string;
    style_value: string;
    constructor() {
    }
}
export class CompanyStyle {
    site_url: string;
    company_logo: string;
    fav_ico_path: string;
    site_title: string;
    company_id: number;
    styles: SiteStyle[];
    constructor() {
    }
}
export class CustomField {
    company_custom_field_id: number;
    employee_custom_field_id: number;
    company_id: number;
    employee_id: number;
    recurver_id:number;
    recurver_name:string;
    survey_id:number;
    field_name: string;
    field_type_id: number;
    field_type: string;
    field_value: string;
    field_rating_value: number;
    field_source: string;
    field_source_type: number;
    custom_field_type_id: number;
    custom_relation_id:number;
    custom_field_type: string;
    performance_cycle_id: number;
    performance_year: number;
    group_id: number;
    row_id: string;
    rating_report: RatingReport;
    constructor() {
    }
}
export class RatingReport {
    val: number;
    rating: string;
    color: string;
}
export class UserAccess {
    module_id: number;
    user_id: number;
    user_module_id: number;
    has_access: number;
    module_name: string;
    constructor() {
    }
}

export class Recurver{
    recurver_id:number;
    recurver_name:string;

}


export class PerformanceCycle {
    performance_cycle_id: number; 
    employee_id:number;
    performance_cycle_name: string;
    year:number;
    review_status_description: string;
    from_month: number; 
    to_month:number;
    review_status: number;
    hike_percentage: number;
    recommended_promotion: number;
    review_remarks: string;
    overall_status: number;
    employee_kpi_performance: EmployeeKpi[];
    rating_report: RatingReport;
    overall_status_description: string;
}
export class AgmMarker {
    latitude: number; 
    longitude:number;
    iconUrl: string;
    message: string;
}

