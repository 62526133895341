import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { Global } from '../shared/global';
import { ApiResponse } from '../model/common';
@Injectable({
  providedIn: 'root'
})
export class AttendanceApiService {
  base_path: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'x-access-token': ''
    })
  };
  constructor(private http: HttpClient, private datePipe: DatePipe, public global: Global) {
    this.base_path = global.attendanceApiEndPoint;
  }
  setToken(token: string) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'x-access-token': token
      })
    };
  }
  postRegularizationRequest(regularization: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/attendance-regularization', regularization, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postPermissionRequest(permission: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/attendance-permission', permission, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postEmployeeLeave(leave: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-leave-balance', leave, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postMonthlyAttendanceData(month: number, year: number, attendanceData: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/monthly-attendance?month=' + month + '&year=' + year, attendanceData, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postAtttendance(employeeAttendance: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-attendance', employeeAttendance, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postHoliday(holiday: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/holiday', holiday, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postLeaveStatus(leave: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-leave-status', leave, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  deleteEmployeeLeave(employee_leave_balance_id: any) {
    return this.http
      .delete<ApiResponse>(this.base_path + '/employee-leave-balance/' + employee_leave_balance_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  deleteHoliday(holiday_id: number) {
    return this.http
      .delete<ApiResponse>(this.base_path + '/holiday/' + holiday_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getRegularizationRequest(employee_id: number, fromDate: Date, toDate: Date, isAdmin: boolean = false) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(this.base_path + '/attendance-regularization?employeeid=' + employee_id + '&fromdate=' + this.datePipe.transform(fromDate, 'yyyy-MM-dd') + '&todate=' + this.datePipe.transform(toDate, 'yyyy-MM-dd') + '&isadmin=' + isAdmin, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getPermissionSummary(employee_id: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/attendance-permission-summary?employeeid=' + employee_id, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeAttendanceReport(employee_id: number, reportmonth: number, reportyear: number) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(`${this.base_path}/employee-attendance-report?employeeid=${employee_id}&reportmonth=${reportmonth}&reportyear=${reportyear}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getDailyAttendanceActivityReport(
    locationId: number,
    departmentId: number,
    shiftId: number,
    reportDate: Date,
    categoryId: number = null) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(`${this.base_path}/daily-attendance-manual-punch-report?employeeid=null&reportdate=${this.datePipe.transform(reportDate, 'yyyy-MM-dd')}&locationid=${locationId}&departmentid=${departmentId}&shiftid=${shiftId}&categoryid=${categoryId}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getDailyAttendanceActivityDashboard(
    locationId: number,
    departmentId: number,
    shiftId: number,
    reportDate: Date,
    categoryId: number = null) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(`${this.base_path}/daily-attendance-dashboard-report?reportdate=${this.datePipe.transform(reportDate, 'yyyy-MM-dd')}&locationid=${locationId}&departmentid=${departmentId}&shiftid=${shiftId}&categoryid=${categoryId}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeeLeaveHistory(employee_id: number, leaveYear: number) {
    return this.http
      .get<ApiResponse>(`${this.base_path}/employee-leave-history?employeeid=${employee_id}&year=${leaveYear}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getEmployeePermissionHistory(employee_id: number, leaveYear: number) {
    return this.http
      .get<ApiResponse>(`${this.base_path}/employee-permission-history?employeeid=${employee_id}&year=${leaveYear}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getDailyLatePunchReport(
    locationId: number,
    departmentId: number,
    shiftId: number,
    reportDate: Date,
    employeeId: number = null,
    fromDate: Date = null,
    toDate: Date = null,
    categoryId: number = null
  ) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(`${this.base_path}/daily-attendance-late-punch-report?reportdate=${this.datePipe.transform(reportDate, 'yyyy-MM-dd')}&locationid=${locationId}&departmentid=${departmentId}&shiftid=${shiftId}&employeeid=${employeeId}&fromdate=${this.datePipe.transform(fromDate, 'yyyy-MM-dd')}&todate=${this.datePipe.transform(toDate, 'yyyy-MM-dd')}&categoryid=${categoryId}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getPermissionRequest(
    employee_id: number,
    fromDate: Date,
    toDate: Date,
    isAdmin: boolean = false,
    locationId: number = null,
    departmentId: number = null,
    shiftId: number = null,
    permissionDate: Date = null,
    permissionStatusId: number = null,
    categoryId: number = null
  ) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(`${this.base_path}/attendance-permission?employeeid=${employee_id}&fromdate=${this.datePipe.transform(fromDate, 'yyyy-MM-dd')}&todate=${this.datePipe.transform(toDate, 'yyyy-MM-dd')}&isadmin=${isAdmin}&locationid=${locationId}&departmentid=${departmentId}&shiftid=${shiftId}&permissiondate=${this.datePipe.transform(permissionDate, 'yyyy-MM-dd')}&permissionstatusid=${permissionStatusId}&categoryid=${categoryId}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getCurrentDayAttendance(employee_id: number) {
    return this.http
      .get<ApiResponse>(`${this.base_path}/current-day-attendance?employeeid=${employee_id}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getHoliday(holidayYear: number, holidayCalendarId: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/holiday?year=' + holidayYear + '&calendar=' + holidayCalendarId, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getLeaveDays(leave_from_date: Date, leave_to_date: Date) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(this.base_path + '/no-of-leave-days?fromdate=' + this.datePipe.transform(leave_from_date, 'yyyy-MM-dd') + '&todate=' + this.datePipe.transform(leave_to_date, 'yyyy-MM-dd'), this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getAttendanceActivity(employeeId: number, fromDate: Date, toDate: Date) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(this.base_path + '/attendance-details?employeeid=' + employeeId + '&fromdate=' + this.datePipe.transform(fromDate, 'yyyy-MM-dd') + '&todate=' + this.datePipe.transform(toDate, 'yyyy-MM-dd'), this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getMonthlyReportAttendanceData(monthNumber: number, employeeId: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/attendance-details?employeeid=' + employeeId + '?month=' + monthNumber, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  // tslint:disable-next-line:max-line-length
  getMonthlyAttendanceData(monthNumber: number, yearNumber: number, designationid: number, departmentid: number, employeeId: number = null, isAltLogin: boolean = false, locationid: number = null) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(`${this.base_path}/monthly-attendance-report-summary?month=${monthNumber}&year=${yearNumber}&designationid=${designationid}&departmentid=${departmentid}&employeeid=${employeeId}&altlogin=${isAltLogin}&locationid=${locationid}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getDailyAttendanceData(reportDate: Date, employeeId: number) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(this.base_path + '/daily-attendance?employeeid=' + employeeId + '&date=' + this.datePipe.transform(reportDate, 'yyyy-MM-dd'), this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getLeaveStats(employeeId: number) {
    return this.http
      .get<ApiResponse>(this.base_path + '/employee-leave-stats?employeeid=' + employeeId, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getLeaveBalance(employeeId: number, year: number = null) {
    return this.http
      .get<ApiResponse>(this.base_path + `/employee-leave-balance/${employeeId}?year=${year}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getLeave(
    employeeId: number = null,
    leaveTypeId: number = null,
    leavestatusId: number = null,
    fromDate: Date = null,
    toDate: Date = null,
    leaveDate: Date = null,
    locationId: number = null,
    departmentId: number = null,
    shiftId: number = null,
    categoryId: number = null) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(`${this.base_path}/employee-leave?employeeid=${employeeId}&leavestatusid=${leavestatusId}&leavetypeid=${leaveTypeId}&fromdate=${this.datePipe.transform(fromDate, 'yyyy-MM-dd')}&todate=${this.datePipe.transform(toDate, 'yyyy-MM-dd')}&leavedate=${this.datePipe.transform(leaveDate, 'yyyy-MM-dd')}&locationid=${locationId}&departmentid=${departmentId}&shiftid=${shiftId}&categoryid=${categoryId}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  postLeave(leave: any) {
    return this.http
      .post<ApiResponse>(this.base_path + '/employee-leave', leave, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
  getAttendanceActivityReport(
    reportFromDate: Date,
    reportToDate: Date,
    locationId: number,
    departmentId: number,
    shiftId: number,
    categoryId: number) {
    return this.http
      // tslint:disable-next-line:max-line-length
      .get<ApiResponse>(`${this.base_path}/attendance-activity-report?fromdate=${this.datePipe.transform(reportFromDate, 'yyyy-MM-dd')}&todate=${this.datePipe.transform(reportToDate, 'yyyy-MM-dd')}&locationid=${locationId}&departmentid=${departmentId}&shiftid=${shiftId}&categoryid=${categoryId}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(err => {
          this.global.showError(err);
          throw err;
        })
      );
  }
}
