import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Global } from 'src/app/shared/global';
import { EmployeeAttendanceListPost, EmployeeDailyAttendance } from 'src/app/model/attendance';
import { DatePipe } from '@angular/common';
import { ToastService } from 'src/app/services/toast.service';
import { DxDateBoxComponent } from 'devextreme-angular';
import { AgmMarker, ApiResponse, Company } from 'src/app/model/common';
import { indicate } from 'src/app/services/loading.service';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { ApplyLeaveComponent } from '../apply-leave/apply-leave.component';

@Component({
  selector: 'app-attendance-admin-view',
  templateUrl: './attendance-admin-view.component.html',
  styleUrls: ['./attendance-admin-view.component.scss']
})
export class AttendanceAdminViewComponent implements OnInit, OnDestroy {
  reportDate: Date;
  employeeNameList: string[];
  employeeList: KeyValue<number, string>[];
  attendanceData: any = [];
  dailyAttendanceData: EmployeeDailyAttendance;
  employeeAttendancePostData: EmployeeAttendanceListPost[] = [];
  columns: any = [];
  employeeId: number;
  salaryDate: any;
  designationId: number;
  departmentId: number;
  designationList: KeyValue<number, string>[];
  departmentList: KeyValue<number, string>[];
  isGridEditMode = false;
  showMap = false;
  lat: number;
  lng: number;
  company: Company;
  loading$ = new Subject<boolean>();
  gridloading$ = new Subject<boolean>();
  locationloading$ = new Subject<boolean>();
  locationId: number;
  locationList: KeyValue<number, string>[];
  subscriptions: Subscription[] = [];
  weekenddays: number[] = [];
  checkAll = false;
  hasHrRole = false;
  origin: any = {};
  destination: any = {};
  waypoints: any[] = [];
  agmMarker: AgmMarker[] = [];
  @ViewChild('attendanceModalcontent', { static: true }) attendanceModalcontent: ElementRef;
  @ViewChild('dxSalaryDate', { static: true }) dxSalaryDate: DxDateBoxComponent;
  cdr: any;
  // tslint:disable-next-line:max-line-length
  constructor(private toastService: ToastService, private modalService: NgbModal, public commonApiService: CommonApiService, public attendaceApiService: AttendanceApiService, public global: Global, private datePipe: DatePipe) {
    this.salaryDate = new Date();
    this.subscriptions.push(
      this.commonApiService.getCompany()
        .pipe(indicate(this.gridloading$))
        .subscribe(apiresponse => {
          this.initializeGrid(this.salaryDate.getFullYear(), this.salaryDate.getMonth(), apiresponse);
        }));
  }
  
  // checkAllDays() {
  //   this.checkAll = !this.checkAll;
  //   if (this.checkAll === true) {
  //     const noofdays = new Date(this.salaryDate.getFullYear(), this.salaryDate.getMonth() + 1, 0).getDate();
  //     this.employeeAttendancePostData = [];
  //     this.attendanceData.forEach(element => {
  //       const postData: EmployeeAttendanceListPost = new EmployeeAttendanceListPost();
  //       postData.present = [];
  //       postData.employee_id = element.employee_id;
  //       for (let i = 1; i <= noofdays; i++) {
  //         if (!element[i]) {
  //           postData.present.push(i.toString());
  //           element[i] = 'attendance';
  //         }
  //       }
  //       this.employeeAttendancePostData.push(postData);
  //     });
  //   } else {
  //     this.searchAttendance();
  //   }
  // }

  checkAllDays() {
    this.checkAll = !this.checkAll;  // Toggle checkAll status
  
    if (this.checkAll) {
      const noOfDays = new Date(this.salaryDate.getFullYear(), this.salaryDate.getMonth() + 1, 0).getDate();
      this.employeeAttendancePostData = [];  // Clear previous data
  
      this.attendanceData.forEach(element => {
        const postData: EmployeeAttendanceListPost = new EmployeeAttendanceListPost();
        postData.present = [];
        postData.employee_id = element.employee_id;
  
        // Iterate over all days in the month
          for (let i = 1; i <= noOfDays; i++) {
            if (!element[i] || element[i] === 'Ab') {  
              postData.present.push(i.toString());  
              element[i] = 'P'; 
            }
        }
  
        // Add the updated postData to employeeAttendancePostData
        this.employeeAttendancePostData.push(postData);
      });
  
    } else {
      // Re-fetch attendance data if checkAll is false
      this.searchAttendance();
    }
  
    // Ensure the grid reflects changes
    // Use Angular's change detection if needed
    // this.cdr.detectChanges(); // Uncomment if using ChangeDetectorRef
  }
  

  // ngOnInit() {
  //   this.dxSalaryDate.calendarOptions = { zoomLevel: 'year', maxZoomLevel: 'year', minZoomLevel: 'decade' };
  //   this.subscriptions.push(
  //     this.commonApiService.getUtilityData('employee_name', this.global.isAltLogin)
  //       .subscribe(apiresponse => {
  //         this.employeeList = apiresponse.data;
  //         this.employeeNameList = this.employeeList.map(a => a.value);
  //       },
  //       error => {
  //         console.error('Error fetching employee names', error);
  //         // Handle the error appropriately
  //       }));
  //   this.subscriptions.push(
  //     this.commonApiService
  //       // tslint:disable-next-line:max-line-length
  //       .getMasterData('bonus_type,designation,department,employee_status,salary_type,employee_category,holiday_calendar,gender,termination_type,release_type')
  //       .pipe(indicate(this.loading$))
  //       .subscribe(apiresponse => {
  //         this.designationList = apiresponse.data.filter((d: { type: string; }) => d.type === 'designation');
  //         this.departmentList = apiresponse.data.filter((d: { type: string; }) => d.type === 'department');
  //       }));
  //   this.subscriptions.push(
  //     this.commonApiService.getUtilityData('location')
  //       .pipe(indicate(this.locationloading$))
  //       .subscribe(apiresponse => {
  //         this.locationList = apiresponse.data;
  //       }));
  //   this.hasHrRole = this.global.loggedUser.roles.filter(d => d.role_name === 'HR-Manager').length > 0;
  // }
  ngOnInit() {
    // Set calendar options
    this.dxSalaryDate.calendarOptions = {
        zoomLevel: 'year',
        maxZoomLevel: 'year',
        minZoomLevel: 'decade'
    };

    // Fetch employee names and populate employeeNameList
    this.subscriptions.push(
        this.commonApiService.getUtilityData('employee_name', this.global.isAltLogin)
            .subscribe(apiresponse => {
                this.employeeList = apiresponse.data;
                this.employeeNameList = this.employeeList.map(a => a.value);
                console.log('Employee Name List:', this.employeeNameList);  // Debug log to verify data
            })
    );

    // Fetch master data and populate designationList and departmentList
    this.subscriptions.push(
        this.commonApiService
            .getMasterData('bonus_type,designation,department,employee_status,salary_type,employee_category,holiday_calendar,gender,termination_type,release_type')
            .pipe(indicate(this.loading$))
            .subscribe(apiresponse => {
                this.designationList = apiresponse.data.filter(d => d.type === 'designation');
                this.departmentList = apiresponse.data.filter(d => d.type === 'department');
                console.log('Designation List:', this.designationList);  // Debug log to verify data
                console.log('Department List:', this.departmentList);  // Debug log to verify data
            })
    );

    // Fetch location data and populate locationList
    this.subscriptions.push(
        this.commonApiService.getUtilityData('location')
            .pipe(indicate(this.locationloading$))
            .subscribe(apiresponse => {
                this.locationList = apiresponse.data;
                console.log('Location List:', this.locationList);  // Debug log to verify data
            })
    );

    // Check for HR role
    this.hasHrRole = this.global.loggedUser.roles.some(role => role.role_name === 'HR-Manager');
    console.log('Has HR Role:', this.hasHrRole);  // Debug log to verify role check
}


  refresh() {
    this.ngOnInit();
    this.searchAttendance();
  }
  renderOptions = {
    suppressMarkers: true,
  }
  initializeGrid(year: number, month: number, apiresponse: ApiResponse) {
    this.company = apiresponse.data[0];
    const noofdays = new Date(year, month + 1, 0).getDate();
    this.columns = [];
    this.weekenddays = [];ApplyLeaveComponent

    this.columns.push({ dataField: 'employee_name', caption: 'Employee', width: 250, showBorders: false, cellTemplate: 'imageTemplate' });
    this.columns.push({ dataField: 'employee_code', caption: 'Code', width: 80});

    for (let i = 1; i <= noofdays; i++) {
      const dt = new Date(year, month, i);
      const day: string = this.datePipe.transform(dt, 'EEEE');
      // tslint:disable-next-line:max-line-length
      if (((this.company.week_end_policy_id === 179 || this.company.week_end_policy_id === 178) && day === 'Sunday') || (this.company.week_end_policy_id === 179 && (day === 'Sunday' || day === 'Saturday'))) {
        this.weekenddays.push(i);
        this.columns.push({ dataField: i.toString(), caption: i.toString(), cellTemplate: 'dayTemplate', cssClass: 'weekend-grid' });
      } else {
        this.columns.push({ dataField: i.toString(), caption: i.toString(), cellTemplate: 'dayTemplate', cssClass: 'weekday-grid' });
      }
    }
     // Add a column for Total Number of Days
     this.columns.push({
      dataField: 'total_days' ,
      caption: 'Total Days',
      calculateCellValue: (rowData) => noofdays, // Return the total number of days
      cssClass: 'total-days-grid'
  });

  // Add a column for Total Present Days
  this.columns.push({
    dataField: 'total_present',
    caption: 'Present',
    calculateCellValue: (rowData) => rowData.present_days || 0, // Calculate total present days
    cssClass: 'total-present-grid'
});

// Add a column for Total Leave Days
this.columns.push({
  dataField: 'total_leave',
  caption: 'Leave',
  calculateCellValue: (rowData) => rowData.leave_days || 0, // Calculate total leave days
  cssClass: 'total-leave-grid'
});

// Add a column for Total Absent Days

this.columns.push({
  dataField: 'Absent_days',
  caption: 'LOP',
  calculateCellValue: (rowData) => rowData.Absent_days || 0,
  cssClass: 'total-Absent-grid'
});
  }
  minActivity = (...arrays) => {
    let result = [...arrays].reduce((min, obj) => {
      return min.time >
        obj.time ? obj : min;
    });
    return [result];
  };
  maxActivity = (...arrays) => {
    let result = [...arrays].reduce((max, obj) => {
      return max.time <
        obj.time ? obj : max;
    });
    return [result];
  };
  openModel(data: any) {
    this.reportDate = new Date(this.salaryDate.getFullYear() + '-' + (this.salaryDate.getMonth() + 1) + '-' + data.columnIndex);
    this.subscriptions.push(
      this.attendaceApiService
        .getDailyAttendanceData(this.reportDate, data.data.employee_id)
        .subscribe(apiresponse => {
          this.agmMarker = [];
          this.dailyAttendanceData = apiresponse.data[0];
          if (this.dailyAttendanceData) {
            const ac = this.minActivity(this.dailyAttendanceData.activity.find(x => x.lat != null && x.long != null && x.punch_direction == 'In'))[0];
            const acOut = this.maxActivity(this.dailyAttendanceData.activity.find(x => x.lat != null && x.long != null && x.punch_direction == 'Out'))[0];
            this.showMap = ac != null;
            if (this.showMap) {
              this.lat = +ac.lat;
              this.lng = +ac.long;
              this.origin.lat = +ac.lat;
              this.origin.lng = +ac.long;
            }
            if (acOut != null) {
              this.destination.lat = +acOut.lat;
              this.destination.lng = +acOut.long;
            } else {
              const wayPoint = this.maxActivity(this.dailyAttendanceData.waypoints.find(x => x.lat != null && x.lng != null))[0];
              if (wayPoint != null) {
                this.destination.lat = +wayPoint.lat;
                this.destination.lng = +wayPoint.lng;
              }
            }
            this.dailyAttendanceData.activity.forEach(a => {
              const marker = this.agmMarker.filter(m => m.latitude === a.lat && m.longitude === a.long);
              if (marker && marker.length > 0) {
                marker[0].iconUrl = this.global.map_icon_user;
                marker[0].message = `${marker[0].message ?? ''}<br/>${a.punch_direction}: ${this.datePipe.transform(a.time, 'mediumTime')}`;
              } else {
                this.agmMarker.push({
                  iconUrl: a.punch_direction === 'In' ? this.global.map_icon_in : this.global.map_icon_out,
                  latitude: a.lat,
                  longitude: a.long,
                  message: `<br/>${a.punch_direction}: ${this.datePipe.transform(a.time, 'mediumTime')}`
                } as AgmMarker);
              }
            });
            this.waypoints = this.dailyAttendanceData.waypoints.map<any>(a => {
              const marker = this.agmMarker.filter(m => m.latitude === a.lat && m.longitude === a.lng);
              if (!(this.dailyAttendanceData.waypoints.length === 1 && marker && marker.length > 0)) {
                let wayPoint: any = {};
                wayPoint.location = { lat: a.lat as number, lng: a.lng as number };
                if (a.location_time > 59) {
                  this.agmMarker.push({
                    iconUrl: this.global.map_icon_clock,
                    latitude: a.lat,
                    longitude: a.lng,
                    message: `<br/>In: ${this.datePipe.transform(a.time, 'mediumTime')}<br/>Time in Location: ${a.location_time} Minutes`
                  } as AgmMarker);
                }
                return wayPoint;
              }
            });
            this.waypoints = this.waypoints.filter(w => w != undefined);
            this.modalService.open(this.attendanceModalcontent, { size: 'lg' });
          }
        }));
  }
  selectName(e: { selectedItem: string; }) {
    const emp: KeyValue<number, string> = this.employeeList.find(d => d.value === e.selectedItem);
    if (emp) {
      this.employeeId = emp.key;
    } else {
      this.employeeId = null;
    }
  }
  // searchAttendance() {
  //   const pipe = new DatePipe('en-US');
  //   this.subscriptions.push(
  //     forkJoin(
  //       {
  //         company: this.commonApiService.getCompany(),
  //         // tslint:disable-next-line:max-line-length
  //         attendance: this.attendaceApiService.getMonthlyAttendanceData(this.salaryDate.getMonth() + 1, this.salaryDate.getFullYear(), this.designationId, this.departmentId, this.employeeId, this.global.isAltLogin, this.locationId)
  //       })
  //       .pipe(indicate(this.gridloading$))
  //       .subscribe((apiresponse) => {
  //         this.initializeGrid(this.salaryDate.getFullYear(), this.salaryDate.getMonth(), apiresponse.company);
  //         this.attendanceData = [];
  //         apiresponse.attendance.data.forEach(element => {
  //           const att: any = {};
  //           att.employee_id = element.employee_id;
  //           att.employee_code = element.employee_code;
  //           att.employee_name = element.employee_name;
  //           att.designation = element.designation;
  //           att.department = element.department;
  //           att.profile_picture = element.profile_picture;  
  //           const punchtime: string[] = element.attendance?.split(',');
  //           let attendance: number[] = punchtime?.map(t => new Date(t).getDate());
  //           let holiday: number[] = JSON.parse(element.holiday);
  //           let leave: number[] = JSON.parse(element.leave);
  //           let halfleave: number[] = JSON.parse(element.halfleave);
  //           let permission: number[] = JSON.parse(element.permission);
          
  //           // let Absent: number[] = JSON.parse(element.Absent);

  //           console.log('Parsed Attendance Data:', attendance);
  //           console.log('Parsed Holiday Data:', holiday);
  //           console.log('Parsed Leave Data:', leave);
  //           console.log('Parsed Half Leave Data:', halfleave);
  //           console.log('Parsed Permission Data:', permission);
            
  //           if (attendance == null) {
  //             attendance = [];
  //           }
  //           if (holiday == null) {
  //             holiday = [];
  //           }
  //           if (leave == null) {
  //             leave = [];
  //           }
  //           if (halfleave == null) {
  //             halfleave = [];
  //           }
  //           if (permission == null) {
  //             permission = [];
  //           }
  //           // if (Absent == null) {
  //           //   Absent = [];
  //           // }
            
  //           for (let i = 1; i <= 31; i++) {
  //             att[i] = 'Ab'; // Default status for all days
  //         }
      
  //           attendance.forEach((a, index) => {
  //             //const timeStr = pipe.transform(new Date(punchtime[index]), 'h:mm a');
  //             att[a] = 'P';
  //           });
  //           holiday.forEach(a => {
  //             att[a] = 'H';
  //           });
  //           leave.forEach(a => {
  //             att[a] = 'L';
  //           });
  //           halfleave.forEach(a => {
  //             att[a] = 'HL';
  //           });
  //           permission.forEach(a => {
  //             att[a] = 'Pp';
  //           });
  //           this.weekenddays.forEach(a => {
  //             att[a] = 'W';
  //           });

  //           // New Calculate absent days
  //       const totalDaysInMonth = new Date(this.salaryDate.getFullYear(), this.salaryDate.getMonth() + 1, 0).getDate();
  //       let Absent: number[] = [];

  //       for (let day = 1; day <= totalDaysInMonth; day++) {
  //         if (att[day] === 'Ab') {
  //           Absent.push(day);
  //         }
  //       }
  //          // Calculate totals
  //       const totalPresentDays = attendance.length;
  //       const totalLeaveDays = leave.length;
  //       const totalAbsentDays = Absent.length;

  //       // Include totals in the data
  //       att.present_days = totalPresentDays;
  //       att.leave_days = totalLeaveDays;
  //       att.Absent_days = totalAbsentDays;

  //       // Log total absent days for verification
  //       console.log('Total Absent Days:', totalAbsentDays);

  //           this.attendanceData.push(att);
  //         });
  //         this.employeeAttendancePostData = [];
  //       }));
  // }

  searchAttendance() {
    const pipe = new DatePipe('en-US');
    this.subscriptions.push(
      forkJoin({
        company: this.commonApiService.getCompany(),
        attendance: this.attendaceApiService.getMonthlyAttendanceData(
          this.salaryDate.getMonth() + 1,
          this.salaryDate.getFullYear(),
          this.designationId,
          this.departmentId,
          this.employeeId,
          this.global.isAltLogin,
          this.locationId
        )
      })
      .pipe(indicate(this.gridloading$))
      .subscribe((apiresponse) => {
        this.initializeGrid(this.salaryDate.getFullYear(), this.salaryDate.getMonth(), apiresponse.company);
        this.attendanceData = [];
        
        apiresponse.attendance.data.forEach(element => {
          const att: any = {
            employee_id: element.employee_id,
            employee_code: element.employee_code,
            employee_name: element.employee_name,
            designation: element.designation,
            department: element.department,
            profile_picture: element.profile_picture
          };
          
          // Initialize fields as empty arrays if they are null
          const punchtime: string[] = element.attendance ? element.attendance.split(',') : [];
          let attendance: number[] = punchtime.map(t => new Date(t).getDate());
          let holiday: number[] = element.holiday ? JSON.parse(element.holiday) : [];
          let leave: number[] = element.leave ? JSON.parse(element.leave) : [];
          let halfleave: number[] = element.halfleave ? JSON.parse(element.halfleave) : [];
          let permission: number[] = element.permission ? JSON.parse(element.permission) : [];
          
          // Default to 'Ab' if the attendance status is null
          for (let i = 1; i <= 31; i++) {
            att[i] = 'Ab'; // Default status for all days
          }
      
          attendance.forEach(a => {
            att[a] = 'P';
          });
          holiday.forEach(a => {
            att[a] = 'H';
          });
          leave.forEach(a => {
            att[a] = 'L';
          });
          halfleave.forEach(a => {
            att[a] = 'HL';
          });
          permission.forEach(a => {
            att[a] = 'Pp';
          });
          this.weekenddays.forEach(a => {
            att[a] = 'W';
          });
      
          // Calculate absent days
          const totalDaysInMonth = new Date(this.salaryDate.getFullYear(), this.salaryDate.getMonth() + 1, 0).getDate();
          let Absent: number[] = [];
      
          for (let day = 1; day <= totalDaysInMonth; day++) {
            if (att[day] === 'Ab') {
              Absent.push(day);
            }
          }
      
          // Calculate totals
          const totalPresentDays = attendance.length;
          const totalLeaveDays = leave.length;
          const totalAbsentDays = Absent.length;
      
          // Include totals in the data
          att.present_days = totalPresentDays;
          att.leave_days = totalLeaveDays;
          att.Absent_days = totalAbsentDays;
      
          this.attendanceData.push(att);
        });
        this.employeeAttendancePostData = [];
      }));
  }
  
  editAttendanceMode() {
    this.isGridEditMode = !this.isGridEditMode;
      this.employeeAttendancePostData = [];
  }

  saveAttendance() {

    this.subscriptions.push(
      this.attendaceApiService
        // tslint:disable-next-line:max-line-length
        .postMonthlyAttendanceData(this.salaryDate.getMonth() + 1, this.salaryDate.getFullYear(), this.employeeAttendancePostData)
        .pipe(indicate(this.gridloading$))
        .subscribe(apiresponse => {
          this.employeeAttendancePostData = [];
          this.isGridEditMode = false;
          this.checkAll = false;
          this.toastService.success('Attendance Saved successfully');
          this.searchAttendance();
        }));
  }
  editAttendance(d: { data: { employee_id: any; }; column: { dataField: string; }; }, e: { srcElement: { checked: boolean; }; }) {
    const eid = d.data.employee_id;
    const dt = d.column.dataField;
    let postData: EmployeeAttendanceListPost = this.employeeAttendancePostData.filter(r => r.employee_id === eid)[0];
    let isNewEmp = false;
    if (!postData) {
      postData = new EmployeeAttendanceListPost();
      postData.employee_id = eid;
      isNewEmp = true;
    }
    if (e.srcElement.checked === true) {
      if (postData.absent.includes(dt)) {
        postData.absent.splice(postData.absent.indexOf(dt), 1);
      }
      postData.present.push(d.column.dataField);
    } else {
      if (postData.present.includes(dt)) {
        postData.present.splice(postData.present.indexOf(dt), 1);
      }
      postData.absent.push(d.column.dataField);
    }
    if (isNewEmp) {
      this.employeeAttendancePostData.push(postData);
    }
  }
  onExporting(e) {
    console.log(e);
    //new
    console.log('Data Source:', e.component.getDataSource().items());
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
