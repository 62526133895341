import { Injectable } from '@angular/core';
import { Tab } from '../model/tab';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
@Injectable({
  providedIn: 'root'
})
export class TabService {
  public active = 1;
  public tabs: Tab[] = [];
  public tabSub = new BehaviorSubject<Tab[]>(this.tabs);
  constructor(private toastService: ToastService) { }
  public removeTab(id: number) {
    const index = this.tabs.findIndex(item => item.id === id);
    this.tabs.splice(index, 1);
    if (this.tabs.length > 0 && id === this.active) {
      this.active = this.tabs[this.tabs.length - 1].id;
    }
    this.tabSub.next(this.tabs);
  }
  public removeAllTab() {
    this.tabs = [];
    this.tabSub.next(this.tabs);
  }
  public addTab(tab: Tab) {
    const t = this.tabs.find(x => x.title === tab.title);
    if (!t) {
      if (this.tabs.length === 3) {
        const tabid = this.tabs.map(ta => ta.id).reduce((a, b) => Math.min(a, b));
        this.removeTab(tabid);
      }
      this.tabs.forEach((ta) => {
        if (tab.active === true) {
          ta.active = false;
        }
      });
      tab.id = this.tabs.length === 0 ? 1 : this.tabs.map(ta => ta.id).reduce((a, b) => Math.max(a, b)) + 1;
      tab.active = true;
      this.active = tab.id;
      const tb = this.tabs.find(x => x.id === tab.id);
      if (!tb) {
        this.tabs.push(tab);
        this.tabSub.next(this.tabs);
      }
    } else {
      this.active = t.id;
      this.tabSub.next(this.tabs);
    }
  }
}
