<div [ngClass]="{'mobile-background':  global.isMobileApp === true}">
    <div *ngIf="global.isMobileApp === true">&nbsp;</div>
    <!-- Main Wrapper -->
    <div class="main-wrapper" id="main-content">
        <!-- Header -->
        <div class="header" *ngIf="global.loggedUser && global.loggedUser.show_header==true">
            <!-- Logo -->
            <div class="header-left">
                <a href="/home" class="logo">
                    <img src="{{global.companyLogo}}" height="40" alt="">
                </a>
            </div>
            <!-- Header Menu -->
            <ul class="nav user-menu">
                <!-- Notifications -->
                <li class="nav-item dropdown" ngbDropdown #notificationMenu="ngbDropdown">
                    <a href="javascript:void(0)" class="dropdown-toggle nav-link" ngbDropdownToggle>
                        <i class="fa fa-bell-o"></i> <span class="badge badge-pill">{{notificationList.length}}</span>
                    </a>
                    <div class="dropdown-menu notifications" ngbDropdownMenu>
                        <div class="topnav-dropdown-header">
                            <span class="notification-title">Notifications</span>
                        </div>
                        <div class="noti-content">
                            <ul class="notification-list">
                                <li *ngFor="let item of notificationList; let i = index" (click)="openWindow(item)">
                                    <a href="javascript:void(0)">
                                        <div class="media">
                                            <ngx-avatar size="40" [src]="item.created_by_profile_picture"
                                                [name]="item.created_by_name"></ngx-avatar>
                                            <div class="media-body ml-2">
                                                <p class="noti-details"><span
                                                        class="noti-title">{{item.created_by_name}}</span> added
                                                    new
                                                    {{item.notification_type}} <span
                                                        class="noti-title">{{item.notification_message}}</span></p>
                                                <p class="noti-time"><span class="notification-time"> {{
                                                        item.created_date | messageTime }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="topnav-dropdown-footer">
                            <div class="row">
                                <div class="col-md-6">
                                    <a href="javascript:void(0)"
                                        (click)="notificationMenu.close();loadNotificationScreen()">View all
                                        Notifications</a>
                                </div>
                                <div class="col-md-6">
                                    <a href="javascript:void(0)" (click)="markAllAsRead();">Mark all as read</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- /Notifications -->
                <li class="nav-item dropdown has-arrow main-drop" ngbDropdown>
                    <a href="javascript:void(0)" class="dropdown-toggle nav-link" ngbDropdownToggle>
                        <span class="user-img">
                            <ngx-avatar size="40" [src]="global.loggedUser.profile_picture"
                                [name]="global.loggedUser.name"></ngx-avatar>
                            <span class="status online"></span>
                        </span>
                        &nbsp;<span>{{global.loggedUser.name}}</span>
                    </a>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <a *ngIf="global.loggedUser.employee_id!==null" class="dropdown-item" href="javascript:void(0)"
                            (click)="loadProfileScreen()">My Profile</a>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="loadPasswordreset()">Change
                            Password</a>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
                    </div>
                </li>
            </ul>
            <!-- /Header Menu -->

            <!-- Mobile Menu -->
            <div class="dropdown mobile-user-menu" ngbDropdown>
                <a href="javascript:void(0)" class="nav-link dropdown-toggle" data-toggle="dropdown"
                    aria-expanded="false" ngbDropdownToggle>
                    <i class="fa fa-ellipsis-v"></i></a>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="loadAttendance()"
                        *ngIf="hasEmpRole===true && (global.loggedUser.attendance_type_id==144 || global.loggedUser.attendance_type_id==186) && hasAccess('Leave & Attendance')">Attendance</a>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="loadLeave()"
                        *ngIf="hasEmpRole===true && hasAccess('Leave & Attendance')">Apply Leave</a>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="loadLeaveAdmin()"
                        *ngIf="(hasHrRole===true || hasManagerRole===true) && hasAccess('Leave & Attendance')">Manage
                        Leave</a>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="loadPermission()"
                        *ngIf="hasEmpRole===true && hasAccess('Leave & Attendance')">Apply Permission</a>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="loadPermissionAdmin()"
                        *ngIf="(hasHrRole===true || hasManagerRole===true) && hasAccess('Leave & Attendance')">Manage
                        Permission</a>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="loadPayslip()"
                        *ngIf="hasEmpRole===true && hasAccess('Payroll')">Payslip</a>
                    <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
                </div>
            </div>
            <!-- /Mobile Menu -->
        </div>
        <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
        <!-- /Header -->
        <router-outlet></router-outlet>
    </div>
    <app-print-page *ngIf="global.printContent"></app-print-page>
    <app-change-password [user]="global.loggedUser" name="changepass" #changePassword></app-change-password>