<ng-template #regularizationcontent let-c="close" let-d="dismiss">
    <form #regularizeForm="ngForm">
        <div class="modal-header">
            <div *ngIf="isNew;else edit_content">
                <h4 class="modal-title" id="modal-basic-title">Add Regularization Request</h4>
            </div>
            <ng-template #edit_content>
                <h4 class="modal-title" id="modal-basic-title">Edit Regularization Request</h4>
            </ng-template>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <div class="col-sm-6" *ngIf="isAdmin">
                    <label for="regularizationFor">Regularization For <span class="text-danger">*</span></label>
                    <dx-autocomplete class="form-control" id="regularizationFor" name="regularizationFor"
                        placeholder="Type Employee Name...." (onSelectionChanged)="selectRegularizationFor($event)"
                        [dataSource]="employeeNameList" [value]="regularization.regularization_for_name" [showClearButton]="true">
                    </dx-autocomplete>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="starttime">From Date Time <span class="text-danger">*</span></label>
                    <dx-date-box [(ngModel)]="regularization.regularization_from_time" name="starttime" id="starttime"
                        #starttime="ngModel" required type="datetime">
                    </dx-date-box>
                    <div *ngIf="starttime.invalid && (starttime.dirty || starttime.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="starttime.errors.required">
                            From Time is Required.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="endtime">To Date Time <span class="text-danger">*</span></label>
                    <dx-date-box [(ngModel)]="regularization.regularization_to_time" name="endtime" id="endtime" #endtime="ngModel"
                        required type="datetime" [min]="regularization.regularization_from_time">
                    </dx-date-box>
                    <div *ngIf="endtime.invalid && (endtime.dirty || endtime.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="endtime.errors.required">
                            To Time is Required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="reason">Reason</label>
                    <select class="form-control" id="reason" name="reason"
                        [(ngModel)]="regularization.regularization_reason_id" #reason="ngModel"
                        [ngClass]="{'is-invalid': reason.invalid && reason.touched}">
                        <option [ngValue]="undefined" selected>Select Reason</option>
                        <option *ngFor="let item of regularizationReasonList; let i = index" [ngValue]="item.key">
                            {{item.value}}</option>
                    </select>
                </div>
            </div>
           
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="saveRegularization(regularizeForm)"
                [disabled]="!regularizeForm.valid  || (isAdmin && (regularization.employee_id===null || !regularization.employee_id ))">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>