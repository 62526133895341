import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CustomField } from 'src/app/model/common';
import { CommonApiService } from 'src/app/services/common-api.service';
import { indicate } from 'src/app/services/loading.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss']
})
export class CustomFieldsComponent implements OnInit {
  @Input() customFieldList: CustomField[];
  @Input() readOnly = false;
  @Input() reportMode = false;
  customGroupFields: CustomField[];
  customGroupFieldsOut: CustomField[] = [];
  customFields: CustomField[];
  groups: any[];
  selectSource: string[];
  selectList: any[];
  listloading$ = new Subject<boolean>();
  constructor(public global: Global, public commonApiService: CommonApiService) { }

  ngOnInit(): void {
    this.selectSource = this.customFieldList.filter(c => c.field_type_id === 196).map(c => c.field_source);
    this.customFields = this.customFieldList.filter(c => c.group_id === null);
    this.customGroupFields = this.customFieldList.filter(c => c.group_id !== null);
    this.groups = this.customGroupFields.filter(c => c.field_type_id === 197);
    this.setCustomFieldList(this.customFields);
  }
  groupBy(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  loadgroupgrid(empcf: CustomField[] = null) {
    this.groups.forEach(group => {
      group.columns = [];
      group.dataSource = [];
      const grpf = this.customGroupFields.filter(f => f.group_id === group.group_id && f.field_type_id !== 197);
      if (empcf !== null) {
        const grps: any[] = this.groupBy(empcf, 'group_id');
        for (const key in grps) {
          if (grpf.filter(g => g.company_custom_field_id === grps[key][0].company_custom_field_id).length > 0) {
            const dt: any = {};
            grps[key].forEach(field => {
              dt[`field_${field.company_custom_field_id}`] = field.field_value;
            });
            group.dataSource.push(dt);
          }
        }
        grpf.forEach(field => {
          switch (field.field_type) {
            case 'date': {
              group.columns.push({
                dataField: `field_${field.company_custom_field_id}`,
                caption: field.field_name,
                showBorders: false,
                dataType: 'date',
                cellTemplate: 'dateTemplate'
              });
              break;
            }
            case 'string': {
              group.columns.push({
                dataField: `field_${field.company_custom_field_id}`,
                caption: field.field_name,
                showBorders: false,
                dataType: 'string'
              });
              break;
            }
            case 'integer': {
              group.columns.push({
                dataField: `field_${field.company_custom_field_id}`,
                caption: field.field_name,
                showBorders: false,
                dataType: 'number'
              });
              break;
            }
            case 'decimal': {
              group.columns.push({
                dataField: `field_${field.company_custom_field_id}`,
                caption: field.field_name,
                showBorders: false,
                dataType: 'number'
              });
              break;
            }
            case 'list': {
              group.columns.push({
                dataField: `field_${field.company_custom_field_id}`,
                caption: field.field_name,
                showBorders: false,
                dataType: 'number',
                lookup: {
                  dataSource: this.selectList.filter(s => s.type === field.field_source),
                  displayExpr: 'value',
                  valueExpr: 'key'
                }
              });
              break;
            }
          }
        });
      }
    });
  }
  customInserted(e) {
    const rowid = e.data.__KEY__;
    for (const key in e.data) {
      if (e.data.hasOwnProperty(key)) {
        if (key.toString().indexOf('field_') !== -1) {
          let cfield: CustomField;
          // tslint:disable-next-line:max-line-length
          cfield = (JSON.parse(JSON.stringify(this.customGroupFields.filter(f => f.company_custom_field_id.toString() === key.toString().replace('field_', ''))[0])));
          cfield.field_value = e.data[key];
          cfield.row_id = rowid;
          this.customGroupFieldsOut.push(cfield);
        }
      }
    }
  }
  fieldDisplayName(value: string) {
    return this.selectList?.filter(s => s.key === value)[0].value;
  }
  customUpdated(e) {
    const rowid = e.data.__KEY__;
    for (const key in e.data) {
      if (e.data.hasOwnProperty(key)) {
        if (key.toString().indexOf('field_') !== -1) {
          let cfield: CustomField;
          // tslint:disable-next-line:max-line-length
          cfield = this.customGroupFieldsOut.filter(c => c.row_id === rowid && c.company_custom_field_id.toString() === key.toString().replace('field_', ''))[0];
          cfield.field_value = e.data[key];
        }
      }
    }
  }
  customRemoved(e) {
    const rowid = e.data.__KEY__;
    for (const key in e.data) {
      if (e.data.hasOwnProperty(key)) {
        if (key.toString().indexOf('field_') !== -1) {
          let cfield: CustomField;
          // tslint:disable-next-line:max-line-length
          cfield = this.customGroupFieldsOut.filter(c => c.row_id === rowid && c.company_custom_field_id.toString() === key.toString().replace('field_', ''))[0];
          cfield.field_value = null;
        }
      }
    }
  }
  getCustomFieldList() {
    return this.customFields
      .filter(c => c.field_value && c.field_value !== '' && c.field_value !== null)
      .concat(this.customGroupFieldsOut
        .filter(c => c.field_value && c.field_value !== '' && c.field_value !== null));
  }
  resetsetCustomFieldList() {
    this.customFields.filter(f => f.group_id === null)
      .forEach(cf => {
        cf.field_value = undefined;
        cf.employee_custom_field_id = undefined;
        cf.employee_id = undefined;
        cf.rating_report = undefined;
      });
  }
  setFieldValue(ecf: CustomField) {
    if (this.selectList) {
      ecf.field_value = this.selectList.filter(s => s.type === ecf.field_source && s.value == ecf.field_rating_value)[0].key;
    }
  }
  setCustomFieldList(ecf: CustomField[]) {
    ecf.filter(f => f.group_id === null)
      .forEach(field => {
        const cf = this.customFields.filter(c => c.company_custom_field_id === field.company_custom_field_id)[0];
        cf.field_value = field.field_value;
        cf.employee_custom_field_id = field.employee_custom_field_id;
        cf.employee_id = field.employee_id;
        if (field.field_source_type === 227 && cf.field_source === 'performance_rating') {
          let percentValue = 0;
          let ratingColor = '';
          if (field.field_value) {
            switch (field.field_value) {
              case '233':
                percentValue = 100;
                ratingColor = '#00A884';
                break;
              case '234':
                percentValue = 90;
                ratingColor = '#76f2c0';
                break;
              case '235':
                percentValue = 75;
                ratingColor = '#b7f7dd';
                break;
              case '236':
                percentValue = 50;
                ratingColor = '#FF7200';
                break;
              default:
                break;
            }
            // eslint-disable-next-line no-param-reassign
            cf.rating_report = {
              val: percentValue,
              rating: '',
              color: ratingColor,
            };
          }
        }
      });
    ecf.filter(f => f.group_id !== null)
      .forEach(field => {
        // tslint:disable-next-line:max-line-length
        const cf = JSON.parse(JSON.stringify(this.customGroupFields.filter(c => c.company_custom_field_id === field.company_custom_field_id)[0]));
        cf.field_value = field.field_value;
        cf.employee_custom_field_id = field.employee_custom_field_id;
        cf.employee_id = field.employee_id;
        this.customGroupFieldsOut.push(cf);
      });
    if (this.selectSource.length === 0) {
      this.loadgroupgrid(ecf.filter(f => f.group_id !== null));
    } else {
      this.commonApiService.getMasterData(this.selectSource.toString())
        .pipe(indicate(this.listloading$))
        .subscribe(apiresponse => {
          this.selectList = [];
          apiresponse.data.forEach(element => {
            this.selectList.push({
              key: element.key.toString(),
              value: element.value,
              type: element.type,
            });
          });
          //this.selectList?.filter(s=>s.type==='performance_rating' && s.key == field.field_value)[0].value

          ecf.filter(f => f.group_id === null)
            .forEach(field => {
              const cf = this.customFields.filter(c => c.company_custom_field_id === field.company_custom_field_id)[0];
              cf.field_value = field.field_value;
              cf.employee_custom_field_id = field.employee_custom_field_id;
              cf.employee_id = field.employee_id;
              if (field.field_source_type === 227 && cf.field_source === 'performance_rating' && cf.rating_report) {
                cf.rating_report.rating = this.selectList?.filter(s => s.type === 'performance_rating' && s.key == field.field_value)[0].value;
              }
            });


          this.loadgroupgrid(ecf.filter(f => f.group_id !== null));
        });
    }
  }
}
