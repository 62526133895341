import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Asset } from 'src/app/model/admin';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { ToastService } from 'src/app/services/toast.service';
import { Global } from 'src/app/shared/global';
import { confirm } from 'devextreme/ui/dialog';
import { TabService } from 'src/app/services/tab.service';
import { Tab } from 'src/app/model/tab';
import { AssetViewComponent } from '../asset-view/asset-view.component';
import { Subject } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';
@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent implements OnInit {
  statusList: KeyValue<number, string>[] = [];
  employeeNameList: KeyValue<number, string>[];
  employeeId: number;
  statusId: number;
  fromDate: Date;
  toDate: Date;
  asset: Asset = new Asset();
  isNew = false;
  assetList: Asset[] = [];
  typeList: KeyValue<number, string>[];
  ownershiptypeList: KeyValue<number, string>[];
  conditionList: KeyValue<number, string>[];
  employeeNames: KeyValue<number, string>[];
  @ViewChild('assetmodalcontent', { static: true }) assetmodalcontent: ElementRef;
  @ViewChild('assetassignmodalcontent', { static: true }) assetassignmodalcontent: ElementRef;
  loading$ = new Subject<boolean>();
  gridloading$ = new Subject<boolean>();
  // tslint:disable-next-line:max-line-length
  constructor(private tabService: TabService, private commonApiService: CommonApiService, private modalService: NgbModal, public global: Global, public adminApiService: AdminApiService, private toastService: ToastService) { }

  ngOnInit() {
    this.commonApiService.getUtilityData('employee_name', this.global.isAltLogin)
      .subscribe(apiresponse => {
        this.employeeNames = apiresponse.data;
        this.employeeNameList = this.employeeNames.reduce((a, o) => (a.push(o.value), a), []);
      });
    this.commonApiService.getMasterData('asset_status,asset_type,asset_ownership_type,asset_condition')
      .pipe(indicate(this.loading$))
      .subscribe(apiresponse => {
        this.statusList = apiresponse.data.filter((d: { type: string; }) => d.type === 'asset_status');
        this.typeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'asset_type');
        this.ownershiptypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'asset_ownership_type');
        this.conditionList = apiresponse.data.filter((d: { type: string; }) => d.type === 'asset_condition');
      });
  }
  refresh() {
    this.ngOnInit();
    this.searchAsset();
  }
  viewAsset(e) {
    this.asset = e.row.data;
    this.tabService.addTab(new Tab(AssetViewComponent, 'Asset - ' + this.asset.asset_code, { id: this.asset.asset_id }));
  }
  openModel(content: any) {
    this.isNew = true;
    this.clearModel();
    this.commonApiService.getNextSequenceNumber('asset_code')
      .subscribe(apiresponse => {
        this.asset.asset_code = apiresponse.data[0].next_sequence_value;
        this.modalService.open(content, { size: 'lg' });
      });
  }
  clearModel() {
    this.asset = new Asset();
  }
  selectName(e) {
    const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
    if (emp) {
      this.employeeId = emp.key;
    } else {
      this.employeeId = null;
    }
  }
  searchAsset() {
    this.adminApiService.getAssetList(this.employeeId, this.fromDate, this.toDate, this.statusId)
      .pipe(indicate(this.gridloading$))
      .subscribe(apiresponse => {
        this.assetList = apiresponse.data;
      });
  }
  resaleAsset(e) {
    confirm('Are you sure, you want to change the status ?', 'Confirmation ')
      .then((dialogResult: any) => {
        if (dialogResult) {
          this.asset = e.row.data;
          const statId = this.statusList.filter(s => s.value === 'Resale')[0].key;
          this.assetStatusChange(statId);
        }
      });
  }
  activeAsset(e) {
    confirm('Are you sure, you want to change the status ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.asset = e.row.data;
        const statId = this.statusList.filter(s => s.value === 'Active')[0].key;
        this.assetStatusChange(statId);
      }
    });
  }
  scrapAsset(e) {
    confirm('Are you sure, you want to change the status ?', 'Confirmation ').then((dialogResult: any) => {
      if (dialogResult) {
        this.asset = e.row.data;
        const statId = this.statusList.filter(s => s.value === 'Scrap')[0].key;
        this.assetStatusChange(statId);
      }
    });
  }
  assetStatusChange(statId: number) {
    const input: any = {};
    input.asset_id = this.asset.asset_id;
    input.asset_status_id = statId;
    this.adminApiService.postAssetStatus(input).subscribe(apiresponse => {
      this.toastService.success('Asset status changed successfully');
      this.searchAsset();
      this.clearModel();
      this.modalService.dismissAll();
    });
  }
  selectAssigntoName(e) {
    const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
    if (emp) {
      this.asset.employee_id = emp.key;
      this.asset.employee_name = emp.value;
    }
  }
  editAsset(e) {
    this.isNew = false;
    this.asset = e.row.data;
    this.modalService.open(this.assetmodalcontent, { size: 'lg' });
  }
  assignAssetModal(e) {
    this.isNew = false;
    this.asset = (JSON.parse(JSON.stringify(e.row.data)));
    const assModal = this.modalService.open(this.assetassignmodalcontent, { size: 'lg' });
    assModal.result.then(
      () => { console.log('When user closes'); },
      () => {
        this.clearModel();
      }
    );
  }
  assignAsset(frm) {
    if (frm.valid) {
      const input: any = {};
      input.employee_id = this.asset.employee_id;
      input.asset_id = this.asset.asset_id;
      this.adminApiService.assignAsset(input).subscribe(apiresponse => {
        this.toastService.success('Asset Assigned to ' + this.asset.employee_name + ' successfully');
        this.searchAsset();
        this.clearModel();
        this.modalService.dismissAll();
      });
    }
  }
  saveAsset(frm) {
    if (frm.valid) {
      this.adminApiService.postAsset(this.asset).subscribe(apiresponse => {
        if (this.isNew) {
          this.toastService.success('Asset created successfully');
        } else {
          this.toastService.success('Asset updated successfully');
        }
        this.searchAsset();
        this.clearModel();
        this.modalService.dismissAll();
      });
    }
  }
}
