<div class="content container-fluid">
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col">
                <h3 class="page-title">Admin</h3>
            </div>
            <div class="col-auto float-right ml-auto">
                <button class="btn add-btn" (click)="openModel(mastermodalcontent)"><i class="fa fa-plus"></i>
                    Add</button>
            </div>
        </div>
    </div>
    <div class="row filter-row">
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
            <select class="form-control filter-control" id="searchKey" name="searchKey" [(ngModel)]="masterType"
                [ngClass]="{'loading-control': (loading$ | async) === true }">
                <option [ngValue]="undefined" selected>Select Type</option>
                <option *ngFor="let item of masterList; let i = index" [ngValue]="item.key">
                    {{item.value}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
            <button class="btn btn-secondary btn-block" (click)="searchMaster()"> Search </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-3 table-responsive">
            <dx-data-grid #gridmaster id="gridContainer" [dataSource]="masterGridData" [remoteOperations]="false"
                [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="false"
                [showColumnLines]="false">
                <dxo-load-panel [enabled]="false"></dxo-load-panel>  
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxi-column dataField="type" caption="Type" dataType="string"></dxi-column>
                <dxi-column dataField="value" caption="Value" dataType="string"></dxi-column>
                <dxi-column dataField="key" [allowFiltering]="false" width=50 caption="" [allowSorting]="false"
                    cellTemplate="actionTemplate" alignment="center">

                    <div *dxTemplate="let cellInfo of 'actionTemplate'" class="text-center">
                        <div ngbDropdown container="body">
                            <a href="javascript:void(0);" class="action-icon dropdown-toggle-no" ngbDropdownToggle><i
                                    class="material-icons">more_vert</i></a>
                            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript:void(0);" (click)="editMaster(cellInfo)"><i
                                        class="fa fa-pencil m-r-5"></i> Edit</a>
                            </div>
                        </div>
                    </div>
                </dxi-column>
            </dx-data-grid>
        </div>
    </div>
    <ng-template #mastermodalcontent let-c="close" let-d="dismiss">
        <form #masterForm="ngForm">
            <div class="modal-header">
                <div *ngIf="isNew;else edit_content">
                    <h4 class="modal-title" id="modal-basic-title">Add Master value</h4>
                </div>
                <ng-template #edit_content>
                    <h4 class="modal-title" id="modal-basic-title">Edit Master Value</h4>
                </ng-template>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label for="type">Type <span class="text-danger">*</span></label>
                        <input *ngIf="!isNew;else edit_type_content" class="form-control" [(ngModel)]="masterData.type"
                            readonly type="text" id="type" name="type">
                        <ng-template #edit_type_content>
                            <select class="form-control" id="mastertype" name="mastertype" [(ngModel)]="masterData.type"
                                #mastertype="ngModel" required>
                                <option [ngValue]="undefined" selected>Select Type</option>
                                <option *ngFor="let item of masterList; let i = index" [ngValue]="item.key">
                                    {{item.value}}</option>
                            </select>
                            <div *ngIf="mastertype.invalid && (mastertype.dirty || mastertype.touched)"
                                class="badge badge-danger position-absolute">
                                <div *ngIf="mastertype.errors.required">
                                    Master Type is Required.
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-sm-6">
                        <label for="masterValue">Value <span class="text-danger">*</span></label>
                        <input class="form-control" [(ngModel)]="masterData.value" type="text" id="masterValue"
                            name="masterValue" #masterValue="ngModel" required>
                        <div *ngIf="masterValue.invalid && (masterValue.dirty || masterValue.touched)"
                            class="badge badge-danger position-absolute">
                            <div *ngIf="masterValue.errors.required">
                                Master Value is Required.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button type="button" class="btn btn-dark btn-sm" (click)="saveMaster(masterForm)"
                    [disabled]="!masterForm.valid">Save</button>
                <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
            </div>
        </form>
    </ng-template>
</div>