import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Asset, AssetRequest } from 'src/app/model/admin';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Global } from 'src/app/shared/global';
import { confirm } from 'devextreme/ui/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { indicate } from 'src/app/services/loading.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-asset-request',
  templateUrl: './asset-request.component.html',
  styleUrls: ['./asset-request.component.scss']
})
export class AssetRequestComponent implements OnInit {
  employeeId: number;
  employeeNameList: KeyValue<number, string>[];
  reqStatusList: KeyValue<number, string>[];
  employeeNames: KeyValue<number, string>[];
  assetList: Asset[] = [];
  asset: Asset = null;
  assetCodeList: KeyValue<number, string>[];
  typeList: KeyValue<number, string>[];
  // tslint:disable-next-line:max-line-length
  constructor(public global: Global, private commonApiService: CommonApiService, private adminApiService: AdminApiService, private modalService: NgbModal, private toastService: ToastService) { }
  isNew = false;
  intializeData: any;
  role = '';
  assetReqList: AssetRequest[] = [];
  assetReq: AssetRequest = new AssetRequest();
  description: string;
  gridloading$ = new Subject<boolean>();
  @ViewChild('assignmodalcontent', { static: true }) assignmodalcontent: ElementRef;
  ngOnInit() {
    if (this.intializeData && this.intializeData.role) {
      this.role = this.intializeData.role;
    }
    this.commonApiService.getMasterData('asset_request_status,asset_type')
      .subscribe(apiresponse => {
        this.typeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'asset_type');
        this.reqStatusList = apiresponse.data.filter((d: { type: string; }) => d.type === 'asset_request_status');
      });
    this.commonApiService.getUtilityData('reporting_employee_name')
      .subscribe(apiresponse => {
        this.employeeNames = apiresponse.data;
        this.employeeNameList = this.employeeNames.reduce((a, o) => (a.push(o.value), a), []);
      });
    this.adminApiService.getAssetList(null, null, null, 89)
      .subscribe(apiresponse => {
        this.assetList = apiresponse.data;
      });
    if (this.role !== 'approver') {
      this.adminApiService.getAssetRequestList(this.global.loggedUser.user_type_internal_id, false)
        .pipe(indicate(this.gridloading$))
        .subscribe(apiresponse => {
          this.assetReqList = apiresponse.data;
        });
      this.employeeId = this.global.loggedUser.user_type_internal_id;
    } else {
      this.adminApiService.getAssetRequestList(null, true)
        .pipe(indicate(this.gridloading$))
        .subscribe(apiresponse => {
          this.assetReqList = apiresponse.data;
        });
    }
  }
  refresh() {
    this.ngOnInit();
    this.searchRequests();
  }
  changeStatus(e: { row: { data: AssetRequest; }; }, status: string) {
    const req = e.row.data;
    const st = status === 'Approved' ? 'approve' : status === 'Cancelled' ? 'cancel' : 'reject';
    confirm('Are you sure, you want to ' + st + ' ?', 'Confirmation ')
      .then((dialogResult: any) => {
        if (dialogResult) {
          const stat: KeyValue<number, string> = this.reqStatusList.find(d => d.value === status);
          if (stat) {
            req.asset_request_status_id = stat.key;
            this.adminApiService.postRequestStatus(req)
              .pipe(indicate(this.gridloading$))
              .subscribe(apiresponse => {
                this.toastService.success('Asset request ' + status + ' successfully');
                this.searchRequests();
              });
          }
        }
      });
  }
  openModel(content: any) {
    this.isNew = true;
    this.clearModel();
    this.modalService.open(content, { size: 'md' });
  }
  clearModel() {
    this.asset = null;
    this.assetReq = new AssetRequest();
  }
  saveRequest(frm) {
    if (frm.valid) {
      this.assetReq.employee_id = this.global.loggedUser.user_type_internal_id;
      this.adminApiService.postAssetRequest(this.assetReq)
        .subscribe(apiresponse => {
          this.toastService.success('Asset request created successfully');
          this.searchRequests();
          this.clearModel();
          this.modalService.dismissAll();
        });
    }
  }
  saveAssignAsset(frm) {
    if (frm.valid) {
      const input: any = {};
      input.employee_id = this.assetReq.employee_id;
      input.asset_id = this.asset.asset_id;
      input.description = this.description;
      input.asset_request_id = this.assetReq.asset_request_id;
      if (this.asset.employee_id === null) {
        this.callAssignAsset(input);
      } else {
        // tslint:disable-next-line:max-line-length
        confirm('The asset is currently assigned to ' + this.asset.employee_name + ', Do you want to assign to ' + this.assetReq.employee_name + '?', 'Confirmation ')
          .then((dialogResult: any) => {
            if (dialogResult) {
              this.callAssignAsset(input);
            }
          });
      }
    }
  }
  callAssignAsset(input: any) {
    this.adminApiService.assignAsset(input).subscribe(apiresponse => {
      this.toastService.success('Asset Assigned to ' + this.assetReq.employee_name + ' successfully');
      this.searchRequests();
      this.clearModel();
      this.modalService.dismissAll();
    });
  }
  searchRequests() {
    let isAdmin = false;
    if (this.role === 'approver') {
      isAdmin = true;
    }
    this.adminApiService.getAssetRequestList(this.employeeId, isAdmin)
      .pipe(indicate(this.gridloading$))
      .subscribe(apiresponse => {
        this.assetReqList = apiresponse.data;
      });
  }
  selectName(e) {
    const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
    if (emp) {
      this.employeeId = emp.key;
    } else {
      this.employeeId = null;
    }
  }
  assignAsset(e: any) {
    this.clearModel();
    this.assetReq = (JSON.parse(JSON.stringify(e.data)));
    // tslint:disable-next-line:max-line-length
    this.assetCodeList = this.assetList.filter(x => x.asset_type_id === this.assetReq.asset_type_id).reduce((a, o) => (a.push(o.asset_name + ' - ' + o.asset_code), a), []);
    this.modalService.open(this.assignmodalcontent, { size: 'md' });
  }
  selectAsset(e) {
    let s: string[];
    if (e.selectedItem !== null) {
      s = e.selectedItem.split('-');
      const a: any = this.assetList.find(d => d.asset_name === s[0].trim() && d.asset_code === s[1].trim());
      if (a) {
        this.asset = a;
      } else {
        this.asset = null;
      }
    } else {
      this.asset = null;
    }
  }
}
