import { KeyValue, Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { Leave } from 'src/app/model/attendance';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { indicate } from 'src/app/services/loading.service';
import { ToastService } from 'src/app/services/toast.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-apply-leave',
  templateUrl: './apply-leave.component.html',
  styleUrls: ['./apply-leave.component.scss']
})
export class ApplyLeaveComponent implements OnInit, OnDestroy {
  isNew = false;
  isAdmin = false;
  isReadonly = false;
  leave: Leave = new Leave();
  leaveTypeList: KeyValue<number, string>[] = [];
  employeeNames: KeyValue<number, string>[];
  employeeNameList: KeyValue<number, string>[];
  loading$ = new Subject<boolean>();
  subscriptions: Subscription[] = [];
  @ViewChild('leavemodalcontent', { static: true }) leavemodalcontent: ElementRef;
  @Output() modalClose: EventEmitter<any> = new EventEmitter();
  constructor(
    private toastService: ToastService,
    private modalService: NgbModal,
    public attendanceApiService: AttendanceApiService,
    private commonApiService: CommonApiService,
    public global: Global,
    private location: Location) {
  }
  ngOnInit() {
    const url = this.location.path();
    if (url === '/leaveadmin') {
      this.subscriptions.push(
        this.commonApiService.getRequestHeader()
          .subscribe(apiresponse => {
            this.global.loadUserSession(apiresponse.data.user);
            this.commonApiService.setToken(this.global.loggedUser.token);
            this.attendanceApiService.setToken(this.global.loggedUser.token);
            this.global.isMobileApp = true;
            this.initialize();
          }));
    } else {
      this.initialize();
    }
  }
  initialize() {
    this.subscriptions.push(
      this.commonApiService.getMasterData('leave_type')
        .subscribe(apiresponse => {
          this.leaveTypeList = apiresponse.data.filter((d: { type: string; }) => d.type === 'leave_type');
        }));
    this.subscriptions.push(
      this.commonApiService.getUtilityData('reporting_employee_name')
        .subscribe(apiresponse => {
          this.employeeNames = apiresponse.data;
          this.employeeNameList = this.employeeNames.reduce((a, o) => (a.push(o.value), a), []);
        }));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  selectLeaveFor(e) {
    if (this.employeeNames) {
      const emp: KeyValue<number, string> = this.employeeNames.find(d => d.value === e.selectedItem);
      if (emp) {
        this.leave.employee_id = emp.key;
      } else {
        this.leave.employee_id = null;
      }
    }
  }
  openModal(isNew: boolean, leave: Leave, isAdmin: boolean = false, isReadonly: boolean = false) {
    this.leave = leave;
    this.isNew = isNew;
    this.isAdmin = isAdmin;
    this.isReadonly = isReadonly;
    this.modalService.open(this.leavemodalcontent, { size: 'lg' });
  }
  halfDay(e) {
    if (e.target.checked === true) {
      this.leave.no_of_days = 0.5;
    } else {
      this.getNoOfDays();
    }
  }
  fromdateChanged(e: any) {
    if (e.event !== undefined) {
      this.leave.leave_from_date = e.value;
      this.leave.leave_to_date = null;
      this.leave.half_day = false;
      this.getNoOfDays();
    }
  }
  todateChanged(e: any) {
    if (e.event !== undefined) {
      this.leave.leave_to_date = e.value;
      this.leave.half_day = false;
      this.getNoOfDays();
    }
  }
  getNoOfDays() {
    // tslint:disable-next-line:max-line-length
    if (this.leave.leave_from_date !== undefined && this.leave.leave_to_date !== undefined && this.leave.leave_from_date !== null && this.leave.leave_to_date !== null) {
      this.subscriptions.push(
        this.attendanceApiService
          .getLeaveDays(this.leave.leave_from_date, this.leave.leave_to_date)
          .pipe(indicate(this.loading$))
          .subscribe(apiresponse => {
            this.leave.no_of_days = apiresponse.data[0].noofdays;
          }));
    }
  }
  saveLeave(frm: { valid: any; reset: () => void; }) {
    if (frm.valid) {
      this.attendanceApiService.postLeave(this.leave).subscribe(apiresponse => {
        this.leave = new Leave();
        if (this.isNew) {
          this.toastService.success('Leave submitted successfully');
        } else {
          this.toastService.success('Leave updated successfully');
        }
        frm.reset();
        this.modalService.dismissAll();
        this.modalClose.emit();
      });
    }
  }
}
