import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Employee, EmployeeKpi } from 'src/app/model/admin';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { CustomField, PerformanceCycle } from 'src/app/model/common';
import { concatMap } from 'rxjs/operators';
import { Global } from 'src/app/shared/global';
import { CustomFieldsComponent } from '../../common/custom-fields/custom-fields.component';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-performance-view',
  templateUrl: './performance-view.component.html',
  styleUrls: ['./performance-view.component.scss']
})
export class PerformanceViewComponent implements OnInit, OnDestroy {
  role = 'manager';
  performanceYear: number;
  yearList: KeyValue<number, string>[] = [];
  subscriptions: Subscription[] = [];
  performanceCycles: PerformanceCycle[];
  customFieldList: CustomField[];
  performanceCycleLoading$ = new Subject<boolean>();
  performanceFieldsLoading$ = new Subject<boolean>();
  tabActiveId: number;
  activeStatus: number;
  intializeData: any = null;
  employee: Employee;
  performanceRating: KeyValue<number, string>[];
  performanceRatingList: KeyValue<number, string>[];
  performancePercentage: KeyValue<number, string>[];
  activeCycle: PerformanceCycle = new PerformanceCycle();
  @ViewChild('customField', { read: CustomFieldsComponent }) customFieldComponent: CustomFieldsComponent;
  constructor(private toastService: ToastService, public commonApiService: CommonApiService, public adminApiService: AdminApiService, public global: Global) { }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  ngOnInit(): void {

    if (this.intializeData && this.intializeData.employee) {
      this.employee = this.intializeData.employee;
      this.initialize();
    }
    else if (this.intializeData && this.intializeData.role) {
      this.role = this.intializeData.role;
      this.subscriptions
        .push(this.adminApiService.getEmployee(this.global.loggedUser.user_type_internal_id)
          .subscribe(apiresponse => {
            if (apiresponse.data.length !== 0) {
              this.employee = apiresponse.data[0];
              this.initialize();
            }
          }))
    }
  }
  initialize(changeYear: boolean = true) {
    if (changeYear) {
      const dt: Date = new Date();
      this.yearList = [];
      this.yearList.push({ key: dt.getFullYear() - 3, value: (dt.getFullYear() - 3).toString() });
      this.yearList.push({ key: dt.getFullYear() - 2, value: (dt.getFullYear() - 2).toString() });
      this.yearList.push({ key: dt.getFullYear() - 1, value: (dt.getFullYear() - 1).toString() });
      this.yearList.push({ key: dt.getFullYear(), value: (dt.getFullYear()).toString() });
      this.performanceYear = dt.getFullYear();
    }
    this.subscriptions.push(
      forkJoin(
        {
          performance: this.adminApiService.getPerformanceCycle(this.employee.employee_id, this.performanceYear),
          companyfield: this.commonApiService.getCompanyField(227),
          masterfield: this.commonApiService.getMasterData('performance_rating,performance_rating_type,recommended_promotion,increment_percentage')
        })
        .pipe(indicate(this.performanceCycleLoading$))
        .pipe(
          concatMap((apiresponse: any) => {
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1;
            this.performanceCycles = apiresponse.performance.data;
            if (!this.tabActiveId && this.tabActiveId !== null) {
              this.activeCycle = this.performanceCycles.filter(c => c.from_month <= currentMonth && c.to_month >= currentMonth)[0];
              this.tabActiveId = this.activeCycle.performance_cycle_id;
            }
            else {
              this.activeCycle = this.performanceCycles.filter(c => c.performance_cycle_id === this.tabActiveId)[0];
            }
            if (!this.activeCycle.year || this.activeCycle.year === null) {
              this.activeCycle.year = currentDate.getFullYear();
            }

            this.activeStatus = this.activeCycle.review_status;
            this.customFieldList = apiresponse.companyfield.data;
            this.performanceRating = apiresponse.masterfield.data.filter((d: { type: string; }) => d.type === 'performance_rating');
            this.performanceRatingList = apiresponse.masterfield.data.filter((d: { type: string; }) => d.type === 'recommended_promotion');
            this.performancePercentage = apiresponse.masterfield.data.filter((d: { type: string; }) => d.type === 'increment_percentage');
            return this.commonApiService
              .getEmployeeCustomFields(this.employee.employee_id, 227, this.performanceYear, this.tabActiveId);
          }))
        .subscribe(apiresponse => {
          if (apiresponse.data.length !== 0) {
            this.customFieldComponent?.setCustomFieldList(apiresponse.data);
          }
        }));
  }
  addOffset(index: number, employeeKpi: any) {
    var row = Math.floor(employeeKpi.length / 4);
    if (employeeKpi.length % 4 === 2 && index + 1 > row * 4 && index + 1 != employeeKpi.length)
      return true;
    else
      return false;
  }
  refresh() {
    this.ngOnInit();
  }
  savePerformance() {
    this.customFieldList = this.customFieldComponent.getCustomFieldList();
    this.customFieldList.forEach(f => {
      f.employee_id = this.employee.employee_id;
      f.performance_cycle_id = this.tabActiveId;
      f.performance_year = this.performanceYear;
    });
    this.subscriptions.push(
      forkJoin(
        {
          companyfield: this.adminApiService.postEmployeeCustomFields({ custom_fields: this.customFieldList }),
          performance: this.commonApiService.getCompanyField(227)
        })
        .pipe(indicate(this.performanceFieldsLoading$))
        .pipe(
          concatMap(() => {
            this.toastService
              .success('Performance fields updated successfully');
            return forkJoin({
              kpiPerformance: this.adminApiService.postEmployeePerformance(this.activeCycle),
              customFields: this.commonApiService.getEmployeeCustomFields(this.employee.employee_id, 227, this.performanceYear, this.tabActiveId)
            })
          }))
        .subscribe(apiresponse => {
          if (apiresponse.customFields.data.length !== 0) {
            this.customFieldComponent.setCustomFieldList(apiresponse.customFields.data);
            this.initialize();
          }
        })
    );
  }
  performanceYearChanged() {
    // this.subscriptions.push(
    //   this.commonApiService
    //     .getEmployeeCustomFields(this.employee.employee_id, 227, this.performanceYear, this.tabActiveId)
    //     .pipe(indicate(this.performanceFieldsLoading$))
    //     .subscribe(apiresponse => {
    //       if (apiresponse.data.length !== 0) {
    //         this.customFieldComponent.setCustomFieldList(apiresponse.data);
    //       } else {
    //         this.customFieldComponent.resetsetCustomFieldList();
    //       }
    //     }));
    this.initialize(false);
  }
  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.tabActiveId = changeEvent.nextId;
    this.activeCycle = this.performanceCycles.filter(c => c.performance_cycle_id === this.tabActiveId)[0];
    this.activeStatus = this.activeCycle.review_status;
    this.customFieldComponent?.resetsetCustomFieldList();
    this.subscriptions.push(
      this.commonApiService
        .getEmployeeCustomFields(this.employee.employee_id, 227, this.performanceYear, this.tabActiveId)
        .pipe(indicate(this.performanceFieldsLoading$))
        .subscribe(apiresponse => {
          if (apiresponse.data.length !== 0) {
            this.customFieldComponent?.setCustomFieldList(apiresponse.data);
          } else {
            this.customFieldComponent?.resetsetCustomFieldList();
          }
        }));
  }
  sendToEmployee() {
    this.activeCycle.review_status = 229;
    this.savePerformance();
  }
  accept() {
    this.activeCycle.review_status = 230;
    this.savePerformance();
  }
  // reject() {
  //   this.activeCycle.review_status = 231;
  //   this.savePerformance();
  // }
}
