import { KeyValue } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Employee, Recurver, Resumeupload } from 'src/app/model/admin';
import { Global } from 'src/app/shared/global';
import { BehaviorSubject, forkJoin, Subject, Subscription } from 'rxjs';
import { indicate } from 'src/app/services/loading.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { concatMap, switchMap } from 'rxjs/operators';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { ToastService } from 'src/app/services/toast.service';
import { CustomFieldsComponent } from '../../common/custom-fields/custom-fields.component';
import { CustomField } from 'src/app/model/common';
import { error } from 'console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-recurver.component.html',
  styleUrls: ['./edit-recurver.component.scss'],
})

export class EditRecurverComponent implements OnInit {
  recurver: Recurver = new Recurver();
  intializeData: any = null;
  relationshipList: any;
  emergencyContactRelationList: any;
  designationList: any;
  departmentList: any;
  categoryList: any;
  ctcComponentList: any;
  roleList: any;
  recurverNames: KeyValue<number, string>[];
  userNameList: string[] = [];
hideUploadModal() {
throw new Error('Method not implemented.');
}

  //recurver: Recurver = new Recurver();
  loading$ = new Subject<boolean>();
  customFieldList: CustomField[];
  recurverList: Recurver[] = [];
  shiftList: KeyValue<number, string>[];
  shiftloading$ = new Subject<boolean>();
  locationloading$ = new Subject<boolean>();
  customloading$ = new Subject<boolean>();
  locationList: KeyValue<number, string>[];
  usernameloading$ = new Subject<boolean>();
  resumeUpload: Resumeupload = new Resumeupload();
  empcodeloading$ = new Subject<boolean>();
  employeeStatusList: Array<{ key: number, value: string }> = [];
  @ViewChild('customField', { read: CustomFieldsComponent }) customFieldComponent: CustomFieldsComponent;
  @ViewChild('profilepicmodalcontent', { static: true }) profilePicModalContent: ElementRef;
  @ViewChild('uploadFileModal', { static: true }) uploadFileModal: ElementRef;
  resumeFile: File;
  file: File | null = null;
  enableHrLocation = false;
  bloodGroupList: KeyValue<number, string>[];
  subscriptions: Subscription[] = [];
  resume: any;
  resumeError: string = '';
  



// saveRecurver(frm: any) {
//   if (frm.valid) {
//     this.formsubmitted = false;
//     const emp: Recurver = (JSON.parse(JSON.stringify(this.recurver)));
//     emp.recurver_status_id = 10049;
//     this.adminApiService.postRecurver(emp)
//       .pipe(indicate(this.loading$))
//       .pipe(
//         concatMap(apiresponse => {
//           if (this.recurver.is_new) {
//             this.toastService.success('File created successfully' );
//             this.modalService.open(this.uploadFileModal, { size: 'xl' });
//             this.recurver.is_new = false;
//             this.recurver.recurver_id = apiresponse.data[0].recurver_id;
//           } else {
//             this.toastService.success('File updated successfully');
//           }
//           this.loadUserNames();
//           this.togglePermanentAddress = false;
//           return this.commonApiService.getRecurverCustomFields(this.recurver.recurver_id);
//         })
//       ).subscribe(
//         apiresponse => {
//           if (apiresponse.data.length !== 0) {
//             this.customFieldComponent.setCustomFieldList(apiresponse.data);
//           }
//         },
//         error=> {
//           // Handle error response
//           this.toastService.error('Failed to save recurver data. Please try again.');
//           console.error('Error saving recurver:', error);
//         }
        
//       );
//   } else {
//     this.toastService.error('Fix the errors to proceed');
//     this.formsubmitted = true;
//   }
// }

saveRecurver(frm: any) {
  if (frm.valid) {
    this.formsubmitted = false;
    const emp: Recurver = (JSON.parse(JSON.stringify(this.recurver)));
    emp.recurver_status_id = 10049;
    //emp.address = this.recurver.address == null ? null : btoa(this.recurver.address);
    this.adminApiService.postRecurver(emp)
      .pipe(indicate(this.loading$))
      .pipe(
        concatMap(apiresponse => {
          if (this.recurver.is_new) {
            this.toastService.success('File created successfully' );
            this.modalService.open(this.uploadFileModal, { size: 'xl' });
            this.recurver.is_new = false;
            this.recurver.recurver_id = apiresponse.data[0].recurver_id;
            // this.recurver.employee_code = apiresponse.data[0].employee_code;
          } else {
            this.toastService.success('File updated successfully');
          }
          this.loadUserNames();
          this.togglePermanentAddress = false;
          return this.commonApiService.getRecurverCustomFields(this.recurver.recurver_id);
        })
      ).subscribe(
        apiresponse => {
          if (apiresponse.data.length !== 0) {
            this.customFieldComponent.setCustomFieldList(apiresponse.data);
          }
        },
        error=> {
          // Handle error response
          this.toastService.error('Failed to save recurver data. Please try again.');
          console.error('Error saving recurver:', error);
        }
        
      );
  } else {
    this.toastService.error('Fix the errors to proceed');
    this.formsubmitted = true;
  }
}


// uploadDocument(frm) {
//   if (frm.valid && this.file) {
//     //const recurverId = this.recurver.recurver_id.toString(); 
//     this.subscriptions.push(
//       this.adminApiService.uploadresume(this.resumeUpload,this.file,this.global.loggedUser.user_id.toString())
      
//         .subscribe(apiresponse => {
//           this.toastService.success('Offer letter saved successfully');
//           //this.searchRecurver();
//           //this.modalService.dismissAll();
//         }));
//   } else {
//     // Handle the case where no file is selected or the form is invalid
//     console.log('No file selected or form is invalid');
//   }
// }

uploadDocument(frm) {
  if (frm.valid && this.file) {
    const recurverId = this.recurver.recurver_id.toString();
    this.subscriptions.push(
      this.adminApiService.uploadresume(this.resumeUpload, this.file, this.global.loggedUser.user_id.toString(), recurverId)
        .subscribe(apiresponse => {
          this.toastService.success('Offer letter saved successfully');
          // You can perform additional actions here after successful upload
        },
        error => {
          this.toastService.error('Failed to upload the document. Please try again.');
          console.error('Error uploading document:', error);
        })
    );
  } else {
    console.log('No file selected or form is invalid');
  }
}


loadUserNames() {
  this.commonApiService.getUtilityData('user_name')
    .pipe(indicate(this.usernameloading$))
    .subscribe(apiresponse => {
      if (apiresponse.data) {
        this.userNameList = apiresponse.data.map((a: { key: any; }) => a.key);
      }
    });
}
  stateList: Array<{ key: string, value: string }> = [];
  genderList: Array<{ key: string, value: string }> = [];
  formsubmitted: boolean = false;
  ddloading$ = new BehaviorSubject<boolean>(false);
  togglePermanentAddress: boolean = false;

  constructor(
    private commonApiService: CommonApiService,public global: Global,
    public adminApiService: AdminApiService,
    private modalService: NgbModal,
    private toastService: ToastService) {}

  // ngOnInit(): void {
  //   this.commonApiService.getMasterData('father_or_husband_relationship,blood_group,state,gender,employee_status')
  //     .pipe(indicate(this.ddloading$))
  //     .subscribe(apiresponse => {
  //       this.stateList = apiresponse.data.filter((d: { type: string; }) => d.type === 'state');
  //       this.genderList = apiresponse.data.filter((d: { type: string; }) => d.type === 'gender');
  //       this.employeeStatusList = apiresponse.data.filter((d: { type: string; }) => d.type === 'employee_status');
  //     });
    
  // }

  ngOnInit(): void {
    // First, fetch the master data (state, gender, employee status)
    this.commonApiService.getMasterData('father_or_husband_relationship,blood_group,state,gender,employee_status')
        .pipe(indicate(this.ddloading$))
        .subscribe(apiresponse => {
            this.stateList = apiresponse.data.filter((d: { type: string; }) => d.type === 'state');
            this.genderList = apiresponse.data.filter((d: { type: string; }) => d.type === 'gender');
            this.bloodGroupList = apiresponse.data.filter((d: { type: string; }) => d.type === 'blood_group');
            this.employeeStatusList = apiresponse.data.filter((d: { type: string; }) => d.type === 'employee_status');
            
            // Now check if we are editing an existing recurver
            if (this.intializeData && this.intializeData.recurver && this.intializeData.recurver.is_new === false) {
                // Fetch the recurver details if it's not a new recurver
                this.commonApiService.getRecurver(this.intializeData.recurver.recurver_id)
                    .subscribe((apiresponse: any) => {
                        // Update the recurver object with the fetched details
                        this.recurver = apiresponse.data[0];
                        this.recurver.is_new = false; // Ensure it's treated as an existing record
                    });
            } else {
                // Handle the case for a new recurver
                this.recurver.is_new = true;
            }
        });
}

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        this.resumeError = 'Only PDF files are allowed.';
        return;
      }
      this.resumeError = ''; // Clear any previous errors
      // Handle the file upload process here
      this.file = file;
      console.log('File selected:', file.resume);
    }
  }

  onSubmit(form: NgForm): void {
    this.formsubmitted = true;
    if (form.valid) {
      // Call the service to save the employee data
      this.commonApiService.postMaster(this.recurver).subscribe(response => {
        // Handle success
        console.log('Employee data saved successfully:', response);
        // Optionally reset the form or provide user feedback
      }, error => {
        // Handle error
        console.error('Error saving employee data:', error);
      });
    } else {
      console.log('Form is invalid');
    }
  }

  hasHrRole() {
    return (this.recurver.roles.filter(d => d.role_name === 'HR-Manager' || d.role_id === 3).length > 0);    
  }
}
