import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Employee, EmployeeRelease } from 'src/app/model/admin';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { indicate } from 'src/app/services/loading.service';
import { Global } from 'src/app/shared/global';

@Component({
  selector: 'app-relieving-letter',
  templateUrl: './relieving-letter.component.html',
  styleUrls: ['./relieving-letter.component.scss']
})
export class RelievingLetterComponent implements OnInit {
d(arg0: string) {
throw new Error('Method not implemented.');
}
  currentdate: Date = new Date();
  intializeData: any = null;
  @Input() employee: Employee = new Employee();
 
  employeeResignation: EmployeeRelease = new EmployeeRelease();
  loading$ = new Subject<boolean>();
  @ViewChild('printContent', { static: true }) printContent: ElementRef;
relievingLetter: any;
  constructor(public adminApiService: AdminApiService, public global: Global) { }
  async ngOnInit(): Promise<void> {
    if (this.intializeData && this.intializeData.id) {
      this.employee = this.intializeData.id;
      this.adminApiService.getRelease(this.employee.employee_id)
        .pipe(indicate(this.loading$))
        .subscribe(apiresponse => {
          this.employeeResignation = apiresponse.data[0];
        });
    }
  }
  print() {
    this.global.print(this.printContent.nativeElement.innerHTML);
  }
}
