import { DatePipe, KeyValue } from '@angular/common';
import { AfterViewInit, Component, ElementRef, KeyValueDiffers, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Global } from 'src/app/shared/global';
import { Asset, Employee, EmployeeComment, EmployeeKpi, EmployeeWorkHistory, OfficeLocation } from 'src/app/model/admin';
import { CustomField, NotificationMessage } from 'src/app/model/common';
import { Tab } from 'src/app/model/tab';
import { TabService } from 'src/app/services/tab.service';
import { ToastService } from 'src/app/services/toast.service';
import { PayrollApiService } from 'src/app/services/payroll-api.service';
import { EmployeeSalary, SalaryComponent } from 'src/app/model/payroll';
import dxChart from 'devextreme/viz/chart';
import { indicate } from 'src/app/services/loading.service';
import { Subject, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { CustomFieldsComponent } from '../custom-fields/custom-fields.component';
import { LeaveBalanceComponent } from '../../attendance/leave-balance/leave-balance.component';
import { AttendanceApiService } from 'src/app/services/attendance-api.service';
import { AttendanceHistoryReport, AttendancePermission, MonthlyAttendanceReport, PunchReport } from 'src/app/model/attendance';
import { DxDateBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements AfterViewInit, OnInit, OnDestroy {
  employee: Employee = new Employee();
  genderList: KeyValue<number, string>[] = [];
  notificationTypeList: KeyValue<number, string>[] = [];
  emergencyContactRelationList: KeyValue<number, string>[];
  bloodGroupList: KeyValue<number, string>[];
  policyTypeList: KeyValue<number, string>[] = [];
  // kraList: KeyValue<number, string>[] = [];
  intializeData: any = null;
  empId: number;
  leaveYear: number;
  showEdit = false;
  notification: NotificationMessage = new NotificationMessage();
  location: OfficeLocation = new OfficeLocation();
  employeeSalary: EmployeeSalary;
  hasHrRole = false;
  hasEmpRole = false;
  imageSrc: string | ArrayBuffer;
  file: File = null;
  workHistoryList: EmployeeWorkHistory[];
  employeeKpi: EmployeeKpi[];
  experienceDuration: string;
  months: KeyValue<string, string>[] = [];
  fromMonth = 4;
  fromYear: number;
  annualCtc = 0;
  totalCompensation = 0;
  salaryComparisionData: any[] = [];
  leaveHistoryData: any[] = [];
  permissionHistoryData: any[] = [];
  leaves: any[];
  hikeData: any;
  bonusData: any;
  assetList: Asset[];
  employeeRemarkList: EmployeeComment[];
  @ViewChild('printContent', { static: true }) printContent: ElementRef;
  @ViewChild('navTab', { static: true }) navTab: NgbNav;
  salChart: dxChart;
  bonusChart: dxChart;
  hikeChart: dxChart;
  leaveChart: dxChart;
  permissionChart: dxChart;
  customFieldList: CustomField[];
  emploading$ = new Subject<boolean>();
  payloading$ = new Subject<boolean>();
  hikeloading$ = new Subject<boolean>();
  bonusloading$ = new Subject<boolean>();
  totalctcloading$ = new Subject<boolean>();
  comparisionloading$ = new Subject<boolean>();
  permissionLoading$ = new Subject<boolean>();
  historyloading$ = new Subject<boolean>();
  kraloading$ = new Subject<boolean>();
  remarksloading$ = new Subject<boolean>();
  customloading$ = new Subject<boolean>();
  leavehistoryloading$ = new Subject<boolean>();
  assetloading$ = new Subject<boolean>();
  attendanceloading$ = new Subject<boolean>();
  lateLoading$ = new Subject<boolean>();
  yearList: KeyValue<number, string>[] = [];
  attendanceDate: Date;
  permissionDate: Date;
  lateDate: Date;
  attendanceList: MonthlyAttendanceReport[];
  permissionList: AttendancePermission[];
  lateList: PunchReport[];
  subscriptions: Subscription[] = [];
  stateList: KeyValue<number, string>[];

  @ViewChild('customField', { read: CustomFieldsComponent }) customFieldComponent: CustomFieldsComponent;
  @ViewChild('leaveBalance', { read: LeaveBalanceComponent }) leaveBalance: LeaveBalanceComponent;
  @ViewChild('dxAttendanceDate', { static: false }) dxAttendanceDate: DxDateBoxComponent;
  @ViewChild('dxPermissionDate', { static: false }) dxPermissionDate: DxDateBoxComponent;
  @ViewChild('dxLateDate', { static: false }) dxLateDate: DxDateBoxComponent;
  constructor(
    private datePipe: DatePipe,
    public payrollApiService: PayrollApiService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private attendanceApiService: AttendanceApiService,
    private commonApiService: CommonApiService,
    private adminApiService: AdminApiService,
    public global: Global,
    private tabService: TabService) {
    this.hasHrRole = global.loggedUser.roles.filter(d => d.role_name === 'HR-Manager').length > 0;
    this.hasEmpRole = global.loggedUser.roles.filter(d => d.role_name === 'Employee').length > 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  ngOnInit() {
    this.clearModel();
    this.empId = this.global.loggedUser.user_type_internal_id;
    if (this.intializeData && this.intializeData.id) {
      this.empId = this.intializeData.id;
    }
    this.showEdit = this.empId === this.global.loggedUser.user_type_internal_id;
    this.subscriptions.push(
      this.commonApiService.getMasterData('gender,notification_type,policy_type,state,emergency_contact_relation,blood_group,kra')
        .subscribe(apiresponse => {
          this.genderList = apiresponse.data.filter(d => d.type === 'gender');
          this.notificationTypeList = apiresponse.data.filter(d => d.type === 'notification_type');
          this.stateList = apiresponse.data.filter((d: { type: string; }) => d.type === 'state');
          this.emergencyContactRelationList = apiresponse.data.filter((d: { type: string; }) => d.type === 'emergency_contact_relation');
          this.bloodGroupList = apiresponse.data.filter((d: { type: string; }) => d.type === 'blood_group');
          // this.kraList = apiresponse.data.filter((d: { type: string; }) => d.type === 'kra');
        }));

    this.subscriptions.push(
      this.adminApiService.getEmployee(this.empId)
        .pipe(indicate(this.emploading$))
        .pipe(
          concatMap(apiresponse => {
            this.employee = apiresponse.data[0];
            // tslint:disable-next-line:max-line-length
            this.employee.personal_info.address = this.employee.personal_info.address == null ? '' : atob(this.employee.personal_info.address);
            // tslint:disable-next-line:max-line-length
            this.employee.personal_info.permanent_address = this.employee.personal_info.permanent_address == null ? '' : atob(this.employee.personal_info.permanent_address);
            this.employee.personal_info.pan_number = this.employee.bank_details.pan_number;
            this.employee.full_name = this.employee.first_name + ' ' + this.employee.last_name;
            return this.adminApiService.getLocation(this.employee.location_id);
          })
        )
        .subscribe(apiresponse => {
          this.location = apiresponse.data[0];
          this.location.address1 = this.location.address1 == null ? '' : atob(this.location.address1);
          this.location.address2 = this.location.address2 == null ? '' : atob(this.location.address2);
          this.experienceDuration = this.calcDateDifference(new Date(this.employee.date_of_joining), new Date());
        }));
    const dt: Date = new Date();
    this.attendanceDate = new Date();
    this.permissionDate = new Date();
    this.lateDate = new Date();
    this.yearList = [];
    this.yearList.push({ key: dt.getFullYear() - 2, value: (dt.getFullYear() - 2).toString() });
    this.yearList.push({ key: dt.getFullYear() - 1, value: (dt.getFullYear() - 1).toString() });
    this.yearList.push({ key: dt.getFullYear(), value: (dt.getFullYear()).toString() });
    this.leaveYear = dt.getFullYear();
  }
  ngAfterViewInit() {
    this.dxAttendanceDate.calendarOptions = { zoomLevel: 'year', maxZoomLevel: 'year', minZoomLevel: 'decade' };
    this.dxPermissionDate.calendarOptions = { zoomLevel: 'year', maxZoomLevel: 'year', minZoomLevel: 'decade' };
    this.dxLateDate.calendarOptions = { zoomLevel: 'year', maxZoomLevel: 'year', minZoomLevel: 'decade' };
  }
  openMangerProfile() {
    if (this.employee.reporting_to_id != null) {
      // tslint:disable-next-line:max-line-length
      this.tabService.addTab(new Tab(UserProfileComponent, this.employee.reporting_to_name, { id: this.employee.reporting_to_id }));
    }
  }
  onValueChanged(evt: any, data: any): void {
    data.setValue(evt.value);
  }
  leaveYearChanged() {
    this.leaveBalance.loadStats(this.leaveYear, this.employee.employee_id);
    this.subscriptions.push(
      this.attendanceApiService.getEmployeeLeaveHistory(this.employee.employee_id, this.leaveYear)
        .pipe(indicate(this.leavehistoryloading$))
        .subscribe(apiresponse => {
          this.leaves = apiresponse.data.data_members;
          const permissionrpt: AttendanceHistoryReport[] = apiresponse.data.permission_report_data;
          const rpt: AttendanceHistoryReport[] = apiresponse.data.report_data;
          this.leaveHistoryData.forEach(hd => {
            this.leaves.forEach(element => {
              const e = rpt.filter(l => l.month_number === hd.monthnumber && l.leave_id === element.id);
              if (e.length > 0) {
                hd[element.name] = e[0].noofdays;
              } else {
                hd[element.name] = 0;
              }
            });
          });
          const monthListPermission = [
            { monthname: 'January', monthnumber: 1, noofhours: null },
            { monthname: 'February', monthnumber: 2, noofhours: null },
            { monthname: 'March', monthnumber: 3, noofhours: null },
            { monthname: 'April', monthnumber: 4, noofhours: null },
            { monthname: 'May', monthnumber: 5, noofhours: null },
            { monthname: 'June', monthnumber: 6, noofhours: null },
            { monthname: 'July', monthnumber: 7, noofhours: null },
            { monthname: 'August', monthnumber: 8, noofhours: null },
            { monthname: 'September', monthnumber: 9, noofhours: null },
            { monthname: 'October', monthnumber: 10, noofhours: null },
            { monthname: 'November', monthnumber: 11, noofhours: null },
            { monthname: 'December', monthnumber: 12, noofhours: null },
          ];
          monthListPermission.forEach(phd => {
            const el = permissionrpt.filter(l => l.month_number === phd.monthnumber);
            if (el.length > 0) {
              phd.noofhours = el[0].noofhours;
            } else {
              phd.noofhours = 0;
            }
          });
          this.permissionHistoryData = (JSON.parse(JSON.stringify(monthListPermission)));
          this.leaveChart.render();
          this.permissionChart.render();
        }));
  }
  refresh() {
    this.clearModel();
    this.ngOnInit();
    this.onNavChange({ nextId: this.navTab.activeId } as NgbNavChangeEvent);
    this.salChart.render();
  }
  loadEmployee() {
    this.subscriptions.push(
      this.adminApiService.getEmployee(this.empId)
        .subscribe(apiresponse => {
          this.employee = apiresponse.data[0];
          // tslint:disable-next-line:max-line-length
          this.employee.personal_info.address = this.employee.personal_info.address === null ? this.employee.personal_info.address : atob(this.employee.personal_info.address);
          // tslint:disable-next-line:max-line-length
          this.employee.personal_info.permanent_address = this.employee.personal_info.permanent_address === null ? this.employee.personal_info.permanent_address : atob(this.employee.personal_info.permanent_address);
          this.employee.personal_info.pan_number = this.employee.bank_details.pan_number;
          this.employee.full_name = this.employee.first_name + ' ' + this.employee.last_name;
        }));
  }
  saveEmployee(frm: any) {
    if (frm.valid) {
      this.employee.personal_info.address = this.employee.personal_info.address == null ? null : btoa(this.employee.personal_info.address);
      // tslint:disable-next-line:max-line-length
      this.employee.personal_info.permanent_address = this.employee.personal_info.permanent_address == null ? null : btoa(this.employee.personal_info.permanent_address);
      this.subscriptions.push(
        this.adminApiService.postEmployeePersonalInfo(this.employee.personal_info)
          .subscribe(apiresponse => {
            this.loadEmployee();
            this.toastService.success('Personal Information Updated successfully');
            this.modalService.dismissAll();
          }));
    }
  }
  saveBankInfo(frm: any) {
    if (frm.valid) {
      this.subscriptions.push(
        this.adminApiService.postEmployeeBankInfo(this.employee.bank_details)
          .subscribe(apiresponse => {
            this.loadEmployee();
            this.toastService.success('Bank details Updated successfully');
            this.modalService.dismissAll();
          }));
    }
  }
  customizeTooltip(arg: any) {
    return {
      text: `${arg.seriesName} : ${arg.valueText}`
    };
  }
  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 2 && this.employeeSalary === null) {
      this.subscriptions.push(
        this.payrollApiService.getEmployeeSalaryMaster(this.employee.employee_id)
          .pipe(indicate(this.payloading$))
          .pipe(
            concatMap(apiresponse => {
              const salary_components = apiresponse.data;
              this.employeeSalary = new EmployeeSalary();
              this.employeeSalary.employee_code = this.employee.employee_code;
              this.employeeSalary.employee_name = this.employee.first_name + ' ' + this.employee.last_name;
              this.employeeSalary.deduction_components = salary_components
                .filter((d: { salary_component_type: string; }) => d.salary_component_type === 'Deduction');
              this.employeeSalary.earning_components = salary_components
                .filter((d: { salary_component_type: string; }) => d.salary_component_type === 'Earning');
              this.annualCtc = 0;
              return this.payrollApiService.getEmployeeCTC(this.employee.employee_id);
            })
          )
          .subscribe(apiresponse => {
            const ctc_components = apiresponse.data;
            this.employeeSalary.earning_components.forEach(e => {
              this.annualCtc = this.annualCtc + e.salary_component_annual_value;
            });
            ctc_components.forEach(e => {
              const s: SalaryComponent = new SalaryComponent();
              if (e.ctc_component_value !== 0) {
                s.salary_component_annual_value = e.ctc_component_value * 12;
                s.salary_component_sub_type = e.ctc_component_type;
                this.annualCtc = this.annualCtc + s.salary_component_annual_value;
                this.employeeSalary.earning_components.push(s);
              }
            });
          }));
      this.subscriptions.push(this.payrollApiService.getEmployeeHikeHistory(this.employee.employee_id)
        .pipe(indicate(this.hikeloading$))
        .subscribe(apiresponse => {
          this.hikeData = apiresponse.data;
          this.hikeChart.render();
        }));
      this.subscriptions.push(this.payrollApiService.getEmployeeBonusHistory(this.employee.employee_id)
        .pipe(indicate(this.bonusloading$))
        .subscribe(apiresponse => {
          this.bonusData = apiresponse.data;
          this.bonusChart.render();
        }));
      // this.payrollApiService.getEmployeeTotalCompensation(this.employee.employee_id)
      //   .pipe(indicate(this.totalctcloading$))
      //   .subscribe(apiresponse => {
      //     this.totalCompensation = apiresponse.data[0].total_compensation;
      //   });
      let dtnow = new Date();
      const curryear = dtnow.getFullYear();
      this.subscriptions.push(
        this.payrollApiService.getSalaryComparisonData(this.employee.employee_id, dtnow.getMonth(), curryear - 1)
          .pipe(indicate(this.comparisionloading$))
          .subscribe(apiresponse => {
            const yearstr: string = 'Year' + curryear;
            const prevyearstr: string = 'Year' + (curryear - 1);
            const yeartot = apiresponse.data.filter(s => s.pay_year === curryear).reduce((total, item) => total + item.net_salary, 0);
            // tslint:disable-next-line:max-line-length
            const yearprevtot = apiresponse.data.filter(s => s.pay_year === curryear - 1).reduce((total, item) => total + item.net_salary, 0);
            this.months.push({ key: prevyearstr, value: ('Year :' + (curryear - 1).toString() + '<br>Total Salary : ' + yearprevtot) });
            this.months.push({ key: yearstr, value: ('Year :' + (curryear - 1).toString() + '<br>Total Salary : ' + yeartot) });
            dtnow = new Date(dtnow.getFullYear(), 0, 1);
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, dtnow));
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, new Date(dtnow.setMonth(dtnow.getMonth() + 1))));
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, new Date(dtnow.setMonth(dtnow.getMonth() + 1))));
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, new Date(dtnow.setMonth(dtnow.getMonth() + 1))));
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, new Date(dtnow.setMonth(dtnow.getMonth() + 1))));
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, new Date(dtnow.setMonth(dtnow.getMonth() + 1))));
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, new Date(dtnow.setMonth(dtnow.getMonth() + 1))));
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, new Date(dtnow.setMonth(dtnow.getMonth() + 1))));
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, new Date(dtnow.setMonth(dtnow.getMonth() + 1))));
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, new Date(dtnow.setMonth(dtnow.getMonth() + 1))));
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, new Date(dtnow.setMonth(dtnow.getMonth() + 1))));
            this.salaryComparisionData.push(this.getSalaryItem(apiresponse.data, new Date(dtnow.setMonth(dtnow.getMonth() + 1))));
            this.salChart.render();
          }));
    } else if (changeEvent.nextId === 3 && (!this.employeeKpi || this.employeeKpi === null)) {
      this.loadKra();
    } else if (changeEvent.nextId === 4 && (!this.attendanceList || this.attendanceList === null)) {
      this.loadAttendanceReport();
    } else if (changeEvent.nextId === 5 && this.leaveHistoryData.length === 0 && this.permissionHistoryData.length === 0) {
      const monthList = [
        { monthname: 'January', monthnumber: 1 },
        { monthname: 'February', monthnumber: 2 },
        { monthname: 'March', monthnumber: 3 },
        { monthname: 'April', monthnumber: 4 },
        { monthname: 'May', monthnumber: 5 },
        { monthname: 'June', monthnumber: 6 },
        { monthname: 'July', monthnumber: 7 },
        { monthname: 'August', monthnumber: 8 },
        { monthname: 'September', monthnumber: 9 },
        { monthname: 'October', monthnumber: 10 },
        { monthname: 'November', monthnumber: 11 },
        { monthname: 'December', monthnumber: 12 },
      ];
      this.leaveHistoryData = (JSON.parse(JSON.stringify(monthList)));
      this.leaveYearChanged();
    } else if (changeEvent.nextId === 6 && (!this.permissionList || this.permissionList === null)) {
      this.loadPermissionReport();
    } else if (changeEvent.nextId === 7 && (!this.lateList || this.lateList === null)) {
      this.loadLateReport();
    } else if (changeEvent.nextId === 8 &&
      (this.workHistoryList === null || this.employeeRemarkList === null || this.assetList === null)) {
      this.subscriptions.push(
        this.adminApiService.getEmployeeWorkHistory(this.employee.employee_id)
          .pipe(indicate(this.historyloading$))
          .subscribe(apiresponse => {
            this.workHistoryList = apiresponse.data;
            this.workHistoryList.forEach(element => {
              element.duration = this.calcDateDifference(new Date(element.from_date), new Date(element.to_date));
            });
          }));

      this.subscriptions.push(
        this.adminApiService.getEmployeeComments(this.employee.employee_id)
          .pipe(indicate(this.remarksloading$))
          .subscribe(apiresponse => {
            this.employeeRemarkList = apiresponse.data;
          }));
      this.subscriptions.push(
        this.adminApiService.getAssetList(this.employee.employee_id)
          .pipe(indicate(this.assetloading$))
          .subscribe(apiresponse => {
            this.assetList = apiresponse.data;
          }));
      this.subscriptions.push(
        this.commonApiService.getCompanyField()
          .pipe(indicate(this.customloading$))
          .pipe(
            concatMap(apiresponse => {
              this.customFieldList = apiresponse.data;
              return this.commonApiService.getEmployeeCustomFields(this.employee.employee_id);
            })
          ).subscribe(
            apiresponse => {
              this.customFieldComponent.setCustomFieldList(apiresponse.data);
            }
          ));
    }
  }
  attendanceDateChanged(e: any) {
    if (e.event !== undefined) {
      this.loadAttendanceReport();
    }
  }
  permissionDateChanged(e: any) {
    if (e.event !== undefined) {
      this.loadPermissionReport();
    }
  }
  lateDateChanged(e: any) {
    if (e.event !== undefined) {
      this.loadLateReport();
    }
  }
  loadAttendanceReport() {
    this.subscriptions.push(
      this.attendanceApiService.getEmployeeAttendanceReport(this.employee.employee_id, (this.attendanceDate.getMonth() + 1), (this.attendanceDate.getFullYear()))
        .pipe(indicate(this.attendanceloading$))
        .subscribe(apiresponse => {
          this.attendanceList = apiresponse.data;
        }));
  }
  loadLateReport() {
    const fdt: Date = new Date(this.lateDate.getFullYear(), this.lateDate.getMonth(), 1);
    const tdt: Date = new Date(this.lateDate.getFullYear(), this.lateDate.getMonth() + 1, 0);
    this.subscriptions.push(
      this.attendanceApiService.getDailyLatePunchReport
        (
          null,
          null,
          null,
          null,
          this.employee.employee_id,
          fdt,
          tdt
        )
        .pipe(indicate(this.lateLoading$))
        .subscribe(apiresponse => {
          this.lateList = apiresponse.data.filter(r => r.punch_status === 'Late');
        }));
  }
  loadPermissionReport() {
    const fdt: Date = new Date(this.permissionDate.getFullYear(), this.permissionDate.getMonth(), 1);
    const tdt: Date = new Date(this.permissionDate.getFullYear(), this.permissionDate.getMonth() + 1, 0);
    this.subscriptions.push(
      this.attendanceApiService.getPermissionRequest
        (
          this.employee.employee_id,
          fdt,
          tdt,
          false,
          null,
          null,
          null,
          null,
          47
        )
        .pipe(indicate(this.permissionLoading$))
        .subscribe(apiresponse => {
          this.permissionList = apiresponse.data;
        }));
  }
  getSalaryItem(data: any[], dtnow: Date) {
    const item: any = {};
    const month = dtnow.getMonth() + 1;
    const year = dtnow.getFullYear();
    const prevyear = dtnow.getFullYear() - 1;
    const yearstr: string = 'Year' + year;
    const prevyearstr: string = 'Year' + prevyear;
    const sal = data.filter(s => s.pay_month === month && s.pay_year === year);
    const prevsal = data.filter(s => s.pay_month === month && s.pay_year === prevyear);
    item.month = this.datePipe.transform(dtnow, 'MMM');
    if (prevsal && prevsal.length > 0) {
      item[prevyearstr] = prevsal[0].net_salary ?? 0;
    } else {
      item[prevyearstr] = null;
    }
    if (sal && sal.length > 0) {
      item[yearstr] = sal[0].net_salary ?? 0;
    } else {
      item[yearstr] = null;
    }
    return item;
  }
  calcDateDifference(dtfrom: Date, dtto: Date) {
    let months: number;
    let years: number;
    months = (dtto.getFullYear() - dtfrom.getFullYear()) * 12;
    months -= dtfrom.getMonth();
    months += dtto.getMonth();
    months = months <= 0 ? 0 : months;
    years = Math.ceil(months >= 12 ? (months / 12) - ((months % 12) / 12) : 0);
    return years + ' Years and ' + months % 12 + ' Months';
  }
  uploadDocument(frm) {
    if (frm.valid) {
      this.subscriptions.push(
        this.adminApiService.uploadProfilePicture(this.file, this.global.loggedUser.user_id.toString())
          .subscribe(apiresponse => {
            this.toastService.success('Profile Picture uploaded successfully');
            this.modalService.dismissAll();
          }));
    }
  }
  clearModel() {
    this.employeeSalary = null;
    this.workHistoryList = null;
    this.employeeKpi = null;
    this.employeeRemarkList = null;
    this.assetList = null;
    this.salaryComparisionData = [];
    this.leaveHistoryData = [];
    this.permissionHistoryData = [];
    this.months = [];
    this.leaves = null;
  }
  onFileChange(e) {
    if (e.target.files && e.target.files[0]) {
      this.file = e.target.files[0];
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = ea => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }
  sendMessage(frm: any) {
    if (frm.valid) {
      this.notification.created_by = this.global.loggedUser.user_type_internal_id;
      this.notification.employee_id = this.empId;
      this.notification.created_date = new Date();
      this.notification.notification_type_id = this.notificationTypeList
        .filter(res => res.value === 'Message').map((a) => a.key)[0];
      this.subscriptions.push(
        this.commonApiService.postNotification(this.notification)
          .subscribe(apiresponse => {
            this.notification = new NotificationMessage();
            this.toastService.success('Message sent successfully');
            this.modalService.dismissAll();
            frm.reset();
          }));
    }
  }
  openModel(content) {
    this.modalService.open(content, { size: 'lg' });
  }
  openXlModel(content) {
    this.modalService.open(content, { size: 'xl' });
  }
  onInitialized(evt: any) {
    this.salChart = evt.component;
  }
  onHikeInitialized(evt: any) {
    this.hikeChart = evt.component;
  }
  onLeaveInitialized(evt: any) {
    this.leaveChart = evt.component;
  }
  onPermissionInitialized(evt: any) {
    this.permissionChart = evt.component;
  }
  onBonusInitialized(evt: any) {
    this.bonusChart = evt.component;
  }
  print() {
    this.global.print(this.printContent.nativeElement.innerHTML);
  }
  empKraInserted(e) {
    const s: EmployeeKpi = e.data;
    console.log(e.data);
    if (s.kpi_description && s.kpi_description !== null) {
      s.employee_id = this.employee.employee_id;
      this.subscriptions.push(
        this.adminApiService
          .postEmployeeKpi(s)
          .subscribe(apiresponse => {
            this.toastService.success('Employee KPI successfully created.');
            e.data.employee_kpi_id = apiresponse.data[0].id;
            this.loadKra();
          }));
    } else {
      this.toastService.warning('Employee KPI description required.');
    }
  }
  loadKra() {
    this.subscriptions.push(this.adminApiService.getEmployeeKpi(this.employee.employee_id)
      .pipe(indicate(this.kraloading$))
      .subscribe(apiresponse => {
        this.employeeKpi = apiresponse.data;
      }));
  }
  empKraUpdated(e) {
    const s: EmployeeKpi = e.data;
    if (s.kpi_description && s.kpi_description !== null) {
      this.subscriptions.push(
        this.adminApiService
          .postEmployeeKpi(s)
          .subscribe(() => {
            this.toastService.success('Employee KPI successfully updated.');
            this.loadKra();
          }));
    } else {
      this.toastService.warning('Employee KPI description required.');
    }
  }
  empKraRemoved(e) {
    this.subscriptions.push(
      this.adminApiService
        .deleteEmployeeKpi(e.data.employee_kpi_id)
        .subscribe(() => {
          this.toastService.success('Employee KPI removed successfully');
          this.loadKra();
        }));
  }
}
