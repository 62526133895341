<ng-template #leavemodalcontent let-c="close" let-d="dismiss">
    <form #leaveForm="ngForm">
        <div class="modal-header">
            <div *ngIf="isNew===true">
                <h4 class="modal-title" id="modal-basic-title">Add Leave</h4>
            </div>
            <div *ngIf="isNew===false && isReadonly===false">
                <h4 class="modal-title" id="modal-basic-title">Edit Leave</h4>
            </div>
            <div *ngIf="isNew===false && isReadonly===true">
                <h4 class="modal-title" id="modal-basic-title">View Leave</h4>
            </div>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <div class="col-sm-6" *ngIf="isAdmin">
                    <label for="leaveType">Leave For <span class="text-danger">*</span></label>
                    <dx-autocomplete class="form-control" id="leaveFor" name="leaveFor"
                        placeholder="Type Employee Name...." (onSelectionChanged)="selectLeaveFor($event)"
                        [dataSource]="employeeNameList" [value]="leave.leave_for_name" [showClearButton]="true"
                        [disabled]="isReadonly">
                    </dx-autocomplete>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="leaveType">Leave Type <span class="text-danger">*</span></label>
                    <select class="form-control" id="leaveType" name="leaveType" [(ngModel)]="leave.leave_type_id"
                        #leaveType="ngModel" [ngClass]="{'is-invalid': leaveType.invalid && leaveType.touched}" required
                        [disabled]="isReadonly">
                        <option [ngValue]="undefined" selected>Select Leave Type</option>
                        <option *ngFor="let item of leaveTypeList; let i = index" [ngValue]="item.key">
                            {{item.value}}</option>
                    </select>
                    <div *ngIf="leaveType.invalid && (leaveType.dirty || leaveType.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="leaveType.errors.required">
                            Leave Type is required.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="noofdays">No of Days</label>
                    <input class="form-control" [(ngModel)]="leave.no_of_days" readonly type="text"
                        [ngClass]="{'loading-control': (loading$ | async) === true }" id="noofdays" name="noofdays">
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label for="fromDate">From Date <span class="text-danger">*</span></label>
                    <dx-date-box [(ngModel)]="leave.leave_from_date" name="fromDate" id="fromDate"
                        displayFormat="dd/MM/yyyy" dateSerializationFormat="yyyy-MM-dd" #fromDate="ngModel" required
                        type="date" (onValueChanged)="fromdateChanged($event)" [disabled]="isReadonly" [max]="leave.leave_to_date">
                    </dx-date-box>
                    <div *ngIf="fromDate.invalid && (fromDate.dirty || fromDate.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="fromDate.errors.required">
                            From date is required.
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="toDate">To Date <span class="text-danger">*</span></label>
                    <dx-date-box [(ngModel)]="leave.leave_to_date" name="toDate" id="toDate" #toDate="ngModel"
                        displayFormat="dd/MM/yyyy" dateSerializationFormat="yyyy-MM-dd" type="date" required
                        (onValueChanged)="todateChanged($event)" [min]="leave.leave_from_date" [disabled]="isReadonly">
                    </dx-date-box>
                    <div *ngIf="toDate.invalid && (toDate.dirty || toDate.touched)"
                        class="badge badge-danger position-absolute">
                        <div *ngIf="toDate.errors.required">
                            To date is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-4">
                    <label for="hday">Half Day</label>
                    <br>
                    <div class="d-inline">
                        <input id="hday" type="checkbox" name="ckhalfday" class="radio-inline"
                            [(ngModel)]="leave.half_day" (change)="halfDay($event)" [disabled]="!leave.no_of_days || (leave.no_of_days!==1 && leave.no_of_days!==0.5)">
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <label for="reason">Reason</label>
                    <textarea rows="4" class="form-control" id="reason" name="reason" [(ngModel)]="leave.leave_reason"
                        [readonly]="isReadonly"></textarea>
                </div>
            </div>
            <div class="form-group row" *ngIf="leave.leave_status_id===37">
                <div class="col-sm-12">
                    <label for="dreason">Decline Reason</label>
                    <textarea rows="4" class="form-control" id="dreason" name="dreason"
                        [(ngModel)]="leave.leave_decline_reason" readonly></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer pt-3">
            <button type="button" class="btn btn-dark btn-sm" (click)="saveLeave(leaveForm)"
                [disabled]="!leaveForm.valid || (isAdmin && (leave.employee_id===null || !leave.employee_id )|| isReadonly)">Save</button>
            <button type="button" class="btn btn-dark btn-sm" (click)="d('Cross click')">Close</button>
        </div>
    </form>
</ng-template>